import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BuildingOfficeIcon, MapPinIcon, HomeModernIcon } from '@heroicons/react/24/outline';
import PropertyCard from '../components/PropertyCard';
import Sidebar from '../../../partials/Sidebar';
import { db } from '../../../db/firebase.config';
import InfoBox from '../../../components/InfoBox';

function NomadCamps() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [campsData, setCampsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCamps = async () => {
      try {
        const campsCollection = collection(db, 'properties');
        const supplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        const campsQuery = query(campsCollection, where('supplier.id', '==', supplierId));
        const campsSnapshot = await getDocs(campsQuery);
        const fetchedCampsData = campsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          park: doc.data().park.name,
          caption: doc.data().caption,
          heroImageUrl: doc.data().heroImageUrl,
          locationCategory: doc.data().locationCategory,
          mobileLocation: doc.data().mobileLocation,
          propertyType: doc.data().propertyType,
        }));

        const sortedCampsData = fetchedCampsData.sort((a, b) => a.name.localeCompare(b.name));
        setCampsData(sortedCampsData);
      } catch (error) {
        console.error('Error fetching camps:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCamps();
  }, []);

  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
          >
            <div className="mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue-800 mb-4">
                Nomad Camps
              </h1>
              <div className="flex items-center space-x-2 text-gray-600">
                <MapPinIcon className="h-5 w-5" />
                <span>Nomad Tanzania's signature collection of intimate safari camps and lodges</span>
              </div>
            </div>

            <div className="mb-6">
  <InfoBox
    icon={HomeModernIcon}
     text="From the Serengeti to Ruaha, explore Nomad's handpicked locations offering authentic Tanzanian safari experiences."
    bgColor="bg-white"
    textColor="text-nomadBlue-700"
    fontSize="text-md"
    className="shadow-sm border border-nomadGray-400"
  />
</div>

            <motion.div
              variants={containerAnimation}
              initial="hidden"
              animate="show"
              className="bg-white rounded-xl shadow-sm overflow-hidden p-6"
            >
              {isLoading ? (
                <div className="flex items-center justify-center h-64">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-nomadBlue"></div>
                </div>
              ) : campsData.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  {campsData.map((camp) => (
                    <motion.div
                      key={camp.id}
                      variants={itemAnimation}
                    >
                      <Link to={`/propertyLibrary/${camp.id}`}>
                        <PropertyCard
                          camp={{
                            id: camp.id,
                            name: camp.name,
                            park: camp.park,
                            description: camp.caption,
                            imageUrl: camp.heroImageUrl,
                            mobileLocation: camp.mobileLocation,
                            propertyType: camp.propertyType,
                          }}
                        />
                      </Link>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                  <BuildingOfficeIcon className="h-12 w-12 mb-4" />
                  <p>No camps available at the moment.</p>
                </div>
              )}
            </motion.div>
          </motion.div>
        </main>
      </div>
    </div>
  );
}

export default NomadCamps;