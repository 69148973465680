import React from 'react';
import { motion } from 'framer-motion';
import ReadMoreButton from '../../components/ReadMoreButton';
import DocumentDownloadsBox from '../../components/DocumentDownloadsBox';
import { urlFor } from '../../../../db/sanity';

function TravelInfoTab({ tabData }) {
  const { sectionsContent, documentDownloads } = tabData;

  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      variants={containerAnimation}
      initial="hidden"
      animate="show"
      className="space-y-16"
    >
      {/* SECTION - DESCRIPTION AND OVERVIEW BOX */}
      <motion.div 
        variants={itemAnimation}
        className="grid grid-cols-1 md:grid-cols-10 gap-8"
      >
        <div className="md:col-span-7 text-base font-sans">
          <h2 className="text-4xl font-roca text-nomadBlue-800 mb-6">
            {tabData.tabTitle}
          </h2>
          <div className="prose max-w-none">
            {sectionsContent && sectionsContent[0] ? (
              <ReadMoreButton 
                text={sectionsContent[0].text} 
                maxRows={5} 
                buttonText="Read More" 
                className="text-gray-600 leading-relaxed"
              />
            ) : (
              <p className="text-gray-500">No information available.</p>
            )}
          </div>
        </div>
        
        {documentDownloads && documentDownloads.length > 0 && (
          <motion.div 
            variants={itemAnimation}
            className="md:col-start-8 md:col-span-3"
          >
            <DocumentDownloadsBox documents={documentDownloads} />
          </motion.div>
        )}
      </motion.div>

      {/* SECTION - CONTENT TYPES */}
      <div className="space-y-16">
        {sectionsContent && sectionsContent.slice(1).map((section, index) => (
          <motion.div
            key={index}
            variants={itemAnimation}
            className={`grid grid-cols-1 md:grid-cols-2 gap-12 items-center`}
          >
            <div className={`${index % 2 === 0 ? 'md:order-1' : 'md:order-2'}`}>
              <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden shadow-lg">
                <img
                  src={section.image ? urlFor(section.image).width(800).url() : 'https://via.placeholder.com/800x450?text=No+Image'}
                  alt={section.title}
                  className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-500"
                />
              </div>
            </div>

            <div className={`${index % 2 === 0 ? 'md:order-2' : 'md:order-1'}`}>
              <div className="space-y-6">
                <h3 className="text-3xl font-roca text-nomadBlue">
                  {section.title}
                </h3>
                <div className="prose max-w-none">
                  <ReadMoreButton 
                    text={section.text} 
                    maxRows={5} 
                    buttonText="Read More"
                    className="text-gray-600 leading-relaxed" 
                  />
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Show when no sections available */}
      {(!sectionsContent || sectionsContent.length === 0) && (
        <motion.div 
          variants={itemAnimation}
          className="text-center py-12 text-gray-500"
        >
          <div className="max-w-md mx-auto">
            <p className="text-lg">No content sections available.</p>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}

export default TravelInfoTab;