import React from 'react';
import { motion } from 'framer-motion';
import { CalendarIcon, CloudIcon, SunIcon } from '@heroicons/react/24/outline';

function SeasonalGuideGrid({ parkData, view }) {
  const getThreeLetterMonth = (monthName) => {
    return monthName.slice(0, 3).toUpperCase();
  };

  if (view === 'table') {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Park</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rainfall</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">High Temp</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Park Info</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {parkData.map((park) =>
              park.months.map((month, idx) => (
                <tr key={`${park.name}-${month.month}`} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-nomadBlue">{park.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{getThreeLetterMonth(month.month)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{month.rainfall}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{month.highTemp}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{month.description}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {parkData.map((park) =>
        park.months.map((month, idx) => (
          <motion.div
            key={`${park.name}-${month.month}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: idx * 0.1 }}
            className="bg-white rounded-lg shadow-sm border border-nomadGray-400 overflow-hidden hover:shadow-md transition-shadow duration-200"
          >
            <div className="px-6 pt-6 pb-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-roca text-nomadBlue-800 mb-1">{park.name}</h3>
                  <div className="flex items-center text-nomadBlue-600">
                    <CalendarIcon className="h-4 w-4 mr-1.5" />
                    <span className="text-sm">{month.month}</span>
                  </div>
                </div>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-nomadBlue-100 text-nomadBlue-700">
                  {getThreeLetterMonth(month.month)}
                </span>
              </div>
            </div>
            
            <div className="px-6 pb-6">
              <div className="grid grid-cols-2 gap-4 mb-4 pt-4 border-t border-nomadGray-300">
                <div className="flex items-center space-x-2">
                  <CloudIcon className="h-5 w-5 text-nomadBlue-400" />
                  <div>
                    <div className="text-xs text-nomadGray-600">Rainfall</div>
                    <div className="text-sm font-medium text-nomadBlue-700">{month.rainfall}</div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2">
                  <SunIcon className="h-5 w-5 text-nomadBlue-400" />
                  <div>
                    <div className="text-xs text-nomadGray-600">High Temp</div>
                    <div className="text-sm font-medium text-nomadBlue-700">{month.highTemp}</div>
                  </div>
                </div>
              </div>
              
              <p className="text-sm text-nomadGray-600 leading-relaxed">
                {month.description}
              </p>
            </div>
          </motion.div>
        ))
      )}
    </div>
  );
}

export default SeasonalGuideGrid;