import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/24/outline';
import ImagePreviewModal from './ImagePreviewModal';

function MediaCard({ image, view }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const downloadSizes = {
    'Original': {
      url: image.imageUrl,
      info: 'Full resolution'
    },
    'Large': {
      url: `${image.imageUrl}?w=1920&fit=max`,
      info: '1920px width'
    },
    'Medium': {
      url: `${image.imageUrl}?w=1280&fit=max`,
      info: '1280px width'
    },
    'Small': {
      url: `${image.imageUrl}?w=800&fit=max`,
      info: '800px width'
    }
  };

  const handleDownload = async (url, size) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const fileName = `${image.title}-${size}.${image.imageUrl.split('.').pop()}`;
      
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      setShowDownloadOptions(false);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const gridView = (
    <motion.div 
      initial={{ opacity: 0, y: 20 }} 
      animate={{ opacity: 1, y: 0 }} 
      className="group relative bg-white rounded-lgshadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200"
    >
      {/* Image Container */}
      <div 
        className="aspect-w-16 aspect-h-9 relative cursor-pointer overflow-hidden "
        onClick={() => setIsPreviewOpen(true)}
      >
        <img
          src={image.previewUrl.grid}
          alt={image.title}
          className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
          loading="lazy"
          style={{
            backgroundColor: image.thumbnailUrl ? '#f3f4f6' : undefined,
            backgroundImage: image.thumbnailUrl ? `url(${image.thumbnailUrl})` : undefined,
            backgroundSize: 'cover',
          }}
        />
        {/* Overlay with preview button */}
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            className="flex items-center space-x-2 bg-white/90 backdrop-blur-sm px-4 py-2 rounded-full"
          >
            <EyeIcon className="h-5 w-5 text-nomadBlue-600" />
            <span className="text-sm font-medium text-nomadBlue-600">Preview</span>
          </motion.div>
        </div>
      </div>

      {/* Content */}
      <div className="p-4">
        <h3 className="font-medium text-gray-900 truncate">{image.title}</h3>
        <p className="text-sm text-gray-500 mt-1 truncate">{image.description}</p>

        <div className="mt-4 flex items-center justify-between">
          <span className="text-xs text-gray-500">
            {image.dimensions?.width} x {image.dimensions?.height}
          </span>
          <div className="relative">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setShowDownloadOptions(!showDownloadOptions);
              }}
              className="text-nomadBlue-600 hover:text-nomadBlue-800 p-2 rounded-full hover:bg-nomadBlue-50 transition-colors"
            >
              <ArrowDownTrayIcon className="h-5 w-5" />
            </button>

            {/* Download Options Dropdown */}
            <AnimatePresence>
              {showDownloadOptions && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="absolute bottom-full right-0 mb-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-10"
                >
                  {Object.entries(downloadSizes).map(([size, { url, info }]) => (
                    <button
                      key={size}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(url, size);
                      }}
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center justify-between group transition-colors"
                    >
                      <div>
                        <span className="text-sm font-medium text-gray-700 group-hover:text-nomadBlue-600">
                          {size}
                        </span>
                        <p className="text-xs text-gray-500">{info}</p>
                      </div>
                      <ArrowDownTrayIcon className="h-4 w-4 text-gray-400 group-hover:text-nomadBlue-600" />
                    </button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </motion.div>
  );

  const listView = (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="group flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200 hover:bg-gray-50 hover:shadow-sm transition-all duration-200"
    >
      {/* Thumbnail */}
      <div 
        className="flex-shrink-0 w-20 h-20 relative cursor-pointer overflow-hidden rounded-lg"
        onClick={() => setIsPreviewOpen(true)}
      >
        <img
          src={image.previewUrl.list}
          alt={image.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          loading="lazy"
        />
        {/* Hover overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 flex items-center justify-center">
          <EyeIcon className="h-5 w-5 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 min-w-0">
        <h3 className="font-medium text-gray-900">{image.title}</h3>
        <p className="text-sm text-gray-500 mt-1">{image.description}</p>
        <div className="flex items-center space-x-4 mt-2 text-xs text-gray-500">
          <span>{image.dimensions?.width} x {image.dimensions?.height}</span>
          <span>{(image.size / (1024 * 1024)).toFixed(2)} MB</span>
        </div>
      </div>

      {/* Download button with dropdown */}
      <div className="relative flex-shrink-0">
        <button 
          onClick={(e) => {
            e.stopPropagation();
            setShowDownloadOptions(!showDownloadOptions);
          }}
          className="text-nomadBlue-600 hover:text-nomadBlue-800 p-2 rounded-full hover:bg-nomadBlue-50 transition-colors"
        >
          <ArrowDownTrayIcon className="h-5 w-5" />
        </button>

        {/* Download Options Dropdown */}
        <AnimatePresence>
          {showDownloadOptions && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-10"
            >
              {Object.entries(downloadSizes).map(([size, { url, info }]) => (
                <button
                  key={size}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload(url, size);
                  }}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center justify-between group transition-colors"
                >
                  <div>
                    <span className="text-sm font-medium text-gray-700 group-hover:text-nomadBlue-600">
                      {size}
                    </span>
                    <p className="text-xs text-gray-500">{info}</p>
                  </div>
                  <ArrowDownTrayIcon className="h-4 w-4 text-gray-400 group-hover:text-nomadBlue-600" />
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );

  return (
    <>
      {view === 'grid' ? gridView : listView}
      <ImagePreviewModal 
        image={image} 
        isOpen={isPreviewOpen} 
        onClose={() => setIsPreviewOpen(false)} 
      />
    </>
  );
}

export default MediaCard;