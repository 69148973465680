import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../css/additional-styles/stepper.scss';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ClipboardDocumentListIcon, DocumentTextIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import DetailedItinerary from './components/DetailedItinerary';
import ExportDocuments from '../../../components/ExportDocuments';
import GuestsSlideOut from '../../../components/GuestsSlideOut';
import OrderDetails from './components/OrderDetails';
import Sidebar from '../../../partials/Sidebar';
import SupplierReservations from './components/SupplierReservations';
import TabbedLayout from './components/TabbedLayout';
import ViewMainHeader from './components/ViewMainHeader';
import { loadBookingSnapshot } from '../../../functions/loadDataFunctions';
import { mergePaxData } from '../../../functions/viewItineraryFunctions';
import { useSelector } from 'react-redux';

function ViewItinerary() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyPermissions } = useSelector((state) => state.companies);
  const navigate = useNavigate();

  // ************************************* // STATE START
  const { uid } = useParams();


  // Modal for on load of page auth check
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [booking, setBooking] = useState([]);
  const [bookingMaster, setBookingMaster] = useState([]);
  const [accessDenied, setAccessDenied] = useState(false);


  const [guests, setGuests] = useState([]);
  const [status, setStatus] = useState('');
  const [supplierReservations, setSupplierReservations] = useState([]);

  const [version, setVersion] = useState(0);


  // Possibly all connected to guests but needs refactoring
  const { allCountries } = useSelector((state) => state.countries);
  var countriesShort = allCountries.result;
  let tempCountries = JSON.parse(JSON.stringify(countriesShort));
  const [countries, setCountries] = useState(tempCountries);
  const [flyingDoctor, setFlyingDoctor] = useState(false);

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);

  // ************************************* // STATE END

  useEffect(() => {
    let unsubscribe;
    let unsubscribeSupplierReservations;
  
    async function fetchData() {
      unsubscribe = loadBookingSnapshot(uid, async (tempBookings) => {
        if (tempBookings.mojoItinerary || (!user.internalUser && tempBookings.hidden)) {
          setAccessDenied(true);
          setShowAccessDeniedModal(true);
          setIsLoading(false);
          return;
        }

        // Only load data if access is granted
        const rebuiltGuests = await mergePaxData(tempBookings);
        setGuests(rebuiltGuests);
        setBooking(tempBookings);
        setBookingMaster(tempBookings);
        setVersion(tempBookings.version);
        
        setIsLoading(false);

        // Set up listener for supplierReservations
        const db = getFirestore();
        const supplierReservationsRef = collection(db, 'itineraries', uid, 'supplierReservations');
        unsubscribeSupplierReservations = onSnapshot(supplierReservationsRef, (snapshot) => {
          const reservations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSupplierReservations(reservations);
        });
      });
    }
    fetchData();
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (unsubscribeSupplierReservations) {
        unsubscribeSupplierReservations();
      }
    };
  }, [navigate, uid, user.internalUser]);

  // slide out
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);

  const handleCloseSlideOut = () => {
    setIsSlideOutOpen(false);
  };

  console.log("booking log", JSON.stringify(booking, null, 2));


  return (
    <div id="confetti-canvas" className="flex h-screen overflow-hidden">

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 mt-8 overflow-y-auto">
        <main>
        <div className="px-4 sm:px-6 md:px-8 lg:px-10">
            {isLoading ? (
              <CircleSpinnerOverlay loading={isLoading} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Processing data ..." />
            ) : accessDenied ? (
              <AccessDeniedModal isOpen={showAccessDeniedModal} setIsOpen={setShowAccessDeniedModal} />
            ) : (
              <>
                {user.email === 'prideaux@gmail.com' && (
                  <div className="h-14 flex items-center pl-5 text-xs">
                    {/* Debug buttons - RP to add in here or Detailed Itinerary  */}
                    <div className="w-4"></div>
                    <button
                      onClick={() => {
                        console.log(JSON.stringify(guests, undefined, 4));
                      }}
                    >
                      guests{' '}
                    </button>
                  </div>
                )}

                <div className="mb-2 flex flex-col md:flex-row md:items-center md:justify-between">
                  <h1 className="text-2xl font-semibold text-gray-900 sm:text-3xl mb-4 lg:mb-0">
                    {booking.itineraryName} {booking.code ? `(#${booking.code})` : ''}
                  </h1>
                  <ExportDocuments booking={booking} user={user} />
                </div>

                <GuestsSlideOut
                  isOpen={isSlideOutOpen}
                  onClose={handleCloseSlideOut}
                  guests={guests}
                  setGuests={setGuests}
                  booking={booking}
                  setIsLoading={setIsLoading}
                  countries={countries}
                  flyingDoctor={flyingDoctor}
                />

                <ViewMainHeader booking={booking} status={status} guests={guests} guestsConfirmed={5} setIsSlideOutOpen={setIsSlideOutOpen} bookingMaster={bookingMaster} supplierReservations={supplierReservations}  />

                <div className="flex flex-col lg:flex-row gap-4">
                  <div className="flex-1">
                    <TabbedLayout>
                    <DetailedItinerary
                        tabName="Detailed Itinerary"
                        icon={DocumentTextIcon}
                        booking={booking}
                        bookingMaster={bookingMaster}
                        supplierReservations={supplierReservations}
                        user={user}
                      />
                      {user.internalUser && <SupplierReservations icon={ClipboardDocumentListIcon} itineraryId={uid} itineraryStatus={booking.status} dateExpiry={booking.dateExpiry} supplierReservations={supplierReservations} tabName="Supplier Reservations"  />}
                    </TabbedLayout>
                  </div>
                  <div className="lg:w-1/3">
                    <OrderDetails booking={booking} user={user} selectedCompanyPermissions={selectedCompanyPermissions} setIsSlideOutOpen={setIsSlideOutOpen} />
                  </div>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

function AccessDeniedModal({ isOpen, setIsOpen }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    navigate('/itinerary/manage/manageItineraries');
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Itinerary Unavailable
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This itinerary is currently being edited and cannot be accessed at the moment. Please try again later.
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleClose}
                  >
                    Return to Manage Bookings
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ViewItinerary;