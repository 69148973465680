import React from 'react';
import { motion } from 'framer-motion';
import { PhotoIcon } from '@heroicons/react/24/outline';
import MediaCard from './MediaCard';

function MediaGrid({ images, isLoading, view }) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-nomadBlue"></div>
      </div>
    );
  }

  if (!images.length) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-gray-500">
        <PhotoIcon className="h-12 w-12 mb-4" />
        <p>No images found matching your criteria.</p>
      </div>
    );
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className={
        view === 'grid'
          ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-4'
      }
    >
      {images.map((image) => (
        <MediaCard 
          key={image._id} 
          image={image} 
          view={view}
        />
      ))}
    </motion.div>
  );
}

export default MediaGrid;