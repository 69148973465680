import 'react-confirm-alert/src/react-confirm-alert.css';

import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../components/ui/Dropdown';
import { FiChevronDown, FiEdit, FiEye } from 'react-icons/fi';
import Select, { components } from 'react-select';
import { addDays, dateShort, getPropObj, minusDays } from './generalFunctions';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { propertyAccom, propertySelected, removeOne } from './availabilityFunctions';

import ButtonOutlineSmall from '../components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from '../components/Buttons/ButtonPrimarySmall';
import ItineraryVersionMenu from '../components/ItinerariesViewMenu';
import PropertiesSearchBox from '../components/PropertiesSearchBox';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { db } from '../../src/db/firebase.config';
import { deeShadow } from '../css/additional-styles/custom';
import packageJson from '../../package.json';
import semver from 'semver';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const moment = require('moment');

const loadBooking = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadBookingSnapshot = (uid, onUpdate) => {
  const docRef = doc(db, 'itineraries', uid);
  return onSnapshot(
    docRef,
    (doc) => {
      if (doc.exists()) {
        onUpdate(doc.data());
      } else {
        console.log('No such document!');
      }
    },
    (error) => {
      console.error('Error fetching document:', error);
    },
  );
};

const loadBookingSnapshotView = (uid, coll, onUpdate) => {
  const docRef = doc(db, coll, uid);
  return onSnapshot(
    docRef,
    (doc) => {
      if (doc.exists()) {
        onUpdate(doc.data());
      } else {
        console.log('No such document!');
      }
    },
    (error) => {
      console.error('Error fetching document:', error);
    },
  );
};

const loadItineraryUrl = async (uid, setGeneratedLink, setIsLoading) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().itineraryUrl);
    setGeneratedLink(docSnap.data().itineraryUrl);
    setIsLoading(false);
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadItineraryVersion = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().userVersion);
    if (docSnap.data().userVersion) {
      return docSnap.data().userVersion;
    } else {
      return 1;
    }
  } else {
    console.log('No such document!');
    return null;
  }
};

const loadSummaryUrl = async (uid, setGeneratedLinkSummary, setIsLoading) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().resRequestSummaryUrl);
    setGeneratedLinkSummary(docSnap.data().resRequestSummaryUrl);
    //console.log("Document found!");
    setIsLoading(false);
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadInvoiceUrl = async (uid, setInvoiceLink, setIsLoading) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().invoiceUrl);
    setInvoiceLink(docSnap.data().invoiceUrl);
    //console.log("Document found!");
    setIsLoading(false);
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadCostingUrl = async (uid, setGeneratedExcelCostingLink, setIsLoading) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().costingUrl);
    setGeneratedExcelCostingLink(docSnap.data().costingUrl);
    //console.log("Document found!");
    setIsLoading(false);
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadAgentCommission = async (uid) => {
  console.log('DEBUG 3');
  console.log(uid);
  const docRef = doc(db, 'agents', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log(docSnap.data().rateBand.commission);
    console.log('DEBUG 4');
    console.log(docSnap.data().rateBand.commission);

    return docSnap.data().rateBand.commission;
  } else {
    console.log('No such agent!');
  }
};

const loadAgentAccessibleAccounts = async (uid) => {
  console.log('DEBUG 3');
  console.log(uid);
  const docRef = doc(db, 'agents', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log(docSnap.data().accessibleAccounts);
    console.log('accessibleAccounts');
    console.log(docSnap.data().accessibleAccounts || null);

    return docSnap.data().accessibleAccounts || null;
  } else {
    console.log('No such agent!');
    return null;
  }
};

const loadBookings = async (uid) => {
  const fetchName = 'itineraries';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('uid', '==', uid));

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
        uid: doc.id,
      });
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadEnquiries = async (uid) => {
  const fetchName = 'enquiries';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('agent.id', '==', uid), where('active', '==', true));

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
        //uid: doc.id,
      });
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadAgentDetails = async (companyId) => {
  const fetchName = 'agents';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('companyId', '==', companyId));

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (!Array.isArray(data)) {
        fetch.push({
          ...data,
          //uid: doc.id,
        });
      }
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadAgent = async (uid) => {
  const fetchName = 'agents';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('uid', '==', uid));

    //Execute query
    const querySnapshot = await getDocs(q);

    // Assuming only one document matches the uid, if any.
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      if (!Array.isArray(data)) {
        return {
          ...data,
          //uid: doc.id,  // Uncomment this line if you want to include the document id as uid.
        };
      } else {
        console.log('Data is an array, not an object.');
      }
    } else {
      console.log('No document found with the specified uid.');
    }
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadConsultantDetails = async (uid) => {
  const fetchName = 'consultants';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('uid', '==', uid));

    //Execute query
    const querySnapshot = await getDocs(q);

    let fetch = null;

    querySnapshot.forEach((doc) => {
      // Check if data is not an array before pushing
      const data = doc.data();
      if (!Array.isArray(data)) {
        fetch = {
          ...data,
          //uid: doc.id,
        };
      }
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadAirports = async () => {
  const fetchName = 'airports';
  try {
    // Get reference
    const fetchRef = collection(db, fetchName);

    // Execute getDocs() on the entire collection
    const querySnapshot = await getDocs(fetchRef);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      fetch.push({
        ...doc.data(),
        uid: doc.id,
      });
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

// const loadAirlines = async () => {
//   const fetchName = 'airlines';
//   try {
//     // Get reference
//     const fetchRef = collection(db, fetchName);

//     // Execute getDocs() on the entire collection
//     const querySnapshot = await getDocs(fetchRef);

//     const fetch = [];

//     querySnapshot.forEach((doc) => {
//       fetch.push({
//         ...doc.data(),
//         uid: doc.id,
//       });
//     });

//     return fetch;
//   } catch (error) {
//     console.log(error);
//     toast.error('Could not fetch ' + fetchName);
//   }
// };

const loadBookingVc = async (uid) => {
  const docRef = doc(db, 'itinerariesVersionControl', uid);
  const docSnap = await getDoc(docRef);
  const fetch = [];
  if (docSnap.exists()) {
    console.log('loadBookingVc');
    console.log(JSON.stringify(docSnap.data(), undefined, 4));
    fetch.push({
      ...docSnap.data(),
      //uid: uid,
    });
    return fetch;
    //return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const loadProperties = async (uid, properties, navigate) => {
  let searchUid = '';
  let bookedDirect;
  if (uid.indexOf('_D-I-R-E-C-T_') !== -1) {
    searchUid = uid.split('_D-I-R-E-C-T_')[0];
    bookedDirect = true;
  } else {
    searchUid = uid;
    bookedDirect = false;
  }
  // check for booked direct properties - START

  // check for booked direct properties - END

  const docRef = doc(db, 'properties', searchUid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log('Property document data:');
    console.log(JSON.stringify(docSnap.data(), undefined, 4));
    let property = docSnap.data();

    // console.log('property rooms active');
    // console.log(JSON.stringify(property, undefined, 4));
    property.rooms = property.rooms.filter((room) => room.active);

    // Update if booked direct - START
    if (bookedDirect) {
      // redefine property - start
      // Your static newRooms, newPlatformResRequest
      const newRooms = [
        {
          min_adults: 1,
          img: 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Frooms%2FGREYSTOKE%20MAHALE%20-%20bandas.jpeg?alt=media&token=9a511458-33aa-41c5-97b9-42d86010f6bb',
          max_capacity: 100,
          name: 'Pick me',
          active: true,
          max_adults: 100,
          id: '',
          type: 'Room',
        },
      ];

      const newPlatformResRequest = {
        linkId: '',
        rates: false,
        active: false,
        principalId: '',
        id: '',
        createBooking: false,
      };

      const newSupplier = {
        name: 'Booked Direct',
        id: '8ae5bed6-e15e-4e79-8ab0-e934c2645abc',
      };

      const newRates = {
        fullBoard: false,
        fullBoardExclusive: true,
        gamePackage: false,
        gamePackageExclusive: true,
      };

      const newThirdPartyRates = [
        {
          creatioUid: 'c61b4fe4-89d6-4255-be4f-3f32428b95a9',
          rateBand: '',
          name: 'Own Arrangment',
          active: true,
          id: '',
          type: 'fullBoard',
        },
      ];

      // Create a new object with the updated and added properties
      const updatedSelectedProperty = {
        ...property,
        rooms: newRooms,
        platformResRequest: newPlatformResRequest,
        supplier: newSupplier,
        rates: newRates,
        thirdPartyRates: newThirdPartyRates,
        propertyType: '',
        minNights: 1,
        northernGuide: false,
        vehicles: false,
        bookedDirect: true,
        uid: uid,
        originalUid: searchUid,
      };

      // redefine property - end

      property = updatedSelectedProperty;
    }

    // Update if booked direct - END

    properties.push(property);
  } else {
    // doc.data() will be undefined in this case
    console.log('Property no longer exists!');
    alert('Property no longer exists!');

    //
    //navigate('/itinerary/manage/manageItineraries/', { replace: true });
    toast.error("Certain properties no longer exist. You have been redirected to the 'Manage Itineraries' page.");

    window.location.href = '/itinerary/manage/manageItineraries/';

    return;
  }
};

const loadPropertiesManageBookings = async (uid, navigate) => {
  let searchUid = '';
  let bookedDirect;
  if (uid.indexOf('_D-I-R-E-C-T_') !== -1) {
    searchUid = uid.split('_D-I-R-E-C-T_')[0];
    bookedDirect = true;
  } else {
    searchUid = uid;
    bookedDirect = false;
  }

  const docRef = doc(db, 'properties', searchUid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log('Property document data:');
    console.log(JSON.stringify(docSnap.data(), undefined, 4));
    let property = docSnap.data();

    property.rooms = property.rooms.filter((room) => room.active);

    // Update if booked direct - START
    if (bookedDirect) {
      const newRooms = [
        {
          min_adults: 1,
          img: 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Frooms%2FGREYSTOKE%20MAHALE%20-%20bandas.jpeg?alt=media&token=9a511458-33aa-41c5-97b9-42d86010f6bb',
          max_capacity: 100,
          name: 'Pick me',
          active: true,
          max_adults: 100,
          id: '',
          type: 'Room',
        },
      ];

      const newPlatformResRequest = {
        linkId: '',
        rates: false,
        active: false,
        principalId: '',
        id: '',
        createBooking: false,
      };

      const newSupplier = {
        name: 'Booked Direct',
        id: '8ae5bed6-e15e-4e79-8ab0-e934c2645abc',
      };

      const newRates = {
        fullBoard: false,
        fullBoardExclusive: true,
        gamePackage: false,
        gamePackageExclusive: true,
      };

      const newThirdPartyRates = [
        {
          creatioUid: 'c61b4fe4-89d6-4255-be4f-3f32428b95a9',
          rateBand: '',
          name: 'Own Arrangment',
          active: true,
          id: '',
          type: 'fullBoard',
        },
      ];

      property = {
        ...property,
        rooms: newRooms,
        platformResRequest: newPlatformResRequest,
        supplier: newSupplier,
        rates: newRates,
        thirdPartyRates: newThirdPartyRates,
        propertyType: '',
        minNights: 1,
        northernGuide: false,
        vehicles: false,
        bookedDirect: true,
        uid: uid,
        originalUid: searchUid,
      };
    }
    // Update if booked direct - END

    return property;
  } else {
    console.log('Property no longer exists!');
    alert('Property no longer exists!');
    toast.error("Certain properties no longer exist. You have been redirected to the 'Manage Itineraries' page.");
    window.location.href = '/itinerary/manage/manageItineraries/';
    return null;
  }
};

const loadAirportProperties = async (uid, properties) => {
  const docRef = doc(db, 'properties', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log('Property document data:');
    console.log(JSON.stringify(docSnap.data(), undefined, 4));
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
  }
};

// !not checked
const loadAgentRates = async (agentRateId, setAgentRates, hit, bookings, setBookings, updateBookings) => {
  //const docRef = doc(db, "rates", agentRateId);
  // Edit to static rates which are RACK rates
  const docRef = doc(db, 'rates', 'e2018353-8cf0-4aab-bbcb-ca4781c36f13');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log('Document data - rate:');
    console.log(JSON.stringify(docSnap.data(), undefined, 4));
    setAgentRates(docSnap.data());

    // NORTHERN GUIDE SUPPLEMENT - START
    let northernGuideVehiclePrice;
    let northernGuideVehicleResRequestId;
    if (hit?.directBookingAgent) {
      northernGuideVehiclePrice = docSnap.data().northernGuideSupplement.rack.price;
      northernGuideVehicleResRequestId = docSnap.data().northernGuideSupplement.rack.resRequestId;
    } else {
      northernGuideVehiclePrice = docSnap.data().northernGuideSupplement.net.price;
      northernGuideVehicleResRequestId = docSnap.data().northernGuideSupplement.net.resRequestId;
    }

    // let updatedBookings = bookings.map((booking) => {
    //   if (booking.northernGuideVehicleSupplement) {
    //     return {
    //       ...booking,
    //       northernGuideVehiclePrice,
    //       northernGuideVehicleResRequestId,
    //     };
    //   }
    //   return booking;
    // });

    // setBookings(updatedBookings);
    if (updateBookings) {
      let updatedBookings =
        bookings.length > 0
          ? bookings.map((booking) => {
              let filteredRooms = booking.rooms.filter((room) => room.roomType !== 'Vehicle');
              return {
                ...booking,
                rooms: filteredRooms,
                northernGuideVehiclePrice: booking.northernGuideVehicleSupplement ? northernGuideVehiclePrice : booking.northernGuideVehiclePrice,
                northernGuideVehicleResRequestId: booking.northernGuideVehicleSupplement ? northernGuideVehicleResRequestId : booking.northernGuideVehicleResRequestId,
              };
            })
          : [];

      setBookings(updatedBookings);
    }

    // NORTHERN GUIDE SUPPLEMENT - END
    return true;
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');
  }
};

const loadItinerariesVcControl = async (
  uid,
  setItinerariesVcMenu,
  setDocUid,
  setReplaceItinerary,
  selectedItemVc,
  setSelectedItemVc,
  userVersion,
  setRefreshAllRates,
  setFirstLoadAvailability,
  setFirstLoad,
) => {
  const currentVersion = await loadItineraryVersion(uid);

  const itinerariesVc = [];
  const q = query(collection(db, 'itinerariesVersionControl'), where('uid', '==', uid), orderBy('version', 'desc'), orderBy('dateModified', 'desc'), orderBy('dateCreated', 'desc'));
  const querySnapshot = await getDocs(q);

  let firstDocUserVersion = userVersion; // Default value
  // if (!querySnapshot.empty) {
  //   const firstDoc = querySnapshot.docs[0];
  //   firstDocUserVersion = firstDoc.data().userVersion || 1;
  // }

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log('dateModified: ' + doc.data().dateModified);
    console.log(JSON.stringify(doc.data(), undefined, 4));

    const newVersion = doc.data().userVersion ? doc.data().userVersion : 1;

    itinerariesVc.push(
      <option key={uuidv4()} value={doc.id}>
        {/* Version:{doc.data().version} on {moment.unix(doc.data().dateModified.seconds).format('D MMMM YYYY, HH:mm')} */}
        Version: {newVersion} on {moment.unix(doc.data().dateModified.seconds).format('D MMMM YYYY, HH:mm')}
      </option>,
    );
  });
  setItinerariesVcMenu(
    <div className="flex items-center">
      <FiEdit size={16} />
      <select
        className="appearance-none form-input bg-[#40404F] text-white text-base font-normal hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white hover:underline-offset-2 w-fit cursor-pointer" // className="w-full rounded-sm h-11 w-60"
        style={{
          backgroundImage: 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          //paddingRight: '0rem',
        }}
        value={selectedItemVc}
        onChange={(e) => {
          setSelectedItemVc(e.target.value);
          setDocUid(e.target.value);
          //alert('Selected version: ' + e.target.value);
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                  <p className="text-sm font-semibold pb-2">Are you sure you would like to edit another version?</p>
                  <p className="text-sm pb-2">This will replace the current version you are editing with the version you selected.</p>
                  <div className="flex mt-5 justify-end">
                    <div>
                      <button
                        className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                        onClick={() => {
                          //setDocUid(e.target.value);
                          setReplaceItinerary(true);
                          setFirstLoadAvailability(true);
                          setFirstLoad(true);
                          //setRefreshAllRates(true);
                          onClose();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              );
            },
          });
        }}
      >
        {/* <option value="select" defaultValue="select">
        Version
      </option> */}
        <option value={uid}>Current version: {currentVersion !== null && currentVersion !== undefined ? currentVersion : 1}</option>

        {/* <option value={uid}>Live version</option> */}
        {itinerariesVc}
      </select>
    </div>,
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#40404F',
    color: 'white',
    border: 'none',
    boxShadow: 'none',
    minWidth: 'fit-content', // Ensures the control fits the content
    width: 'auto', // Makes the control fit the width of its content
    display: 'flex', // Flex to align items
    alignItems: 'center', // Center items vertically
    whiteSpace: 'nowrap', // Prevents text wrapping
    borderTopLeftRadius: '8px', // Adds rounded corner to the top left
    borderTopRightRadius: '8px', // Adds rounded corner to the top right
    borderBottomLeftRadius: '0', // Ensures the bottom left corner is not rounded
    borderBottomRightRadius: '0', // Ensures the bottom right corner is not rounded
    '&:hover': {
      border: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
    whiteSpace: 'nowrap', // Prevents text from wrapping in single value
    overflow: 'hidden', // Hide overflow text
    textOverflow: 'ellipsis', // Adds ellipsis for overflow text
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#40404F',
    color: 'white',
    width: '320px', // Fixed width for the dropdown menu
    right: 0, // Align the menu to the right edge of the control
    position: 'absolute', // Position it absolutely relative to the control
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#6b7280' : '#40404F',
    color: 'white',
    whiteSpace: 'nowrap', // Prevents text from wrapping in options
    '&:hover': {
      backgroundColor: '#6b7280',
    },
  }),
};

// Custom Control component to include the edit icon
const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <FiEdit className="mr-1 ml-4 text-white" />
      {children}
    </components.Control>
  );
};




const loadItinerariesVc = async (
  uid,
  setItinerariesVcMenu,
  setDocUid,
  setReplaceItinerary,
  selectedItemVc,
  setSelectedItemVc,
  userVersion,
  setRefreshAllRates,
  setFirstLoadAvailability,
  setFirstLoad,
  status,
  setStep,
  handleOpenEditViewSlideOut,
  setViewItinerariesMenu,
  setSelectedViewUid,
  setSelectedViewLabel,
  selectedItemView,
  setSelectedItemView,
  setItinerariesVcOptions,
) => {
  const currentVersion = await loadItineraryVersion(uid);

  const itinerariesVcOptions = [];
  const q = query(collection(db, 'itinerariesVersionControl'), where('uid', '==', uid), orderBy('version', 'desc'), orderBy('dateModified', 'desc'), orderBy('dateCreated', 'desc'));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const newVersion = doc.data().userVersion ? doc.data().userVersion : 1;

    itinerariesVcOptions.push({
      value: doc.id,
      label: `Version: ${newVersion} on ${moment.unix(doc.data().dateModified.seconds).format('D MMM YYYY, HH:mm')}`,
    });
  });

  // Add current version option
  itinerariesVcOptions.unshift({
    value: uid,
    label: `Current version: ${currentVersion !== null && currentVersion !== undefined ? currentVersion : 1}`,
  });


  setItinerariesVcOptions(itinerariesVcOptions);

  const handleVersionChange = (option) => {
    setSelectedItemVc(option.value);
    setDocUid(option.value);

    if (status === 'confirmed') {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="p-5 bg-white rounded-lg" style={deeShadow}>
            <p className="text-sm font-semibold pb-2">Version cannot be changed</p>
            <p className="text-sm pb-2">You cannot view/edit another version of a confirmed itinerary.</p>
            <div className="flex mt-5 justify-end">
              <button
                className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                onClick={onClose}
              >
                Back
              </button>
            </div>
          </div>
        ),
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="p-5 bg-white rounded-lg" style={deeShadow}>
            <p className="text-sm font-semibold pb-2">Are you sure you would like to edit another version?</p>
            <p className="text-sm pb-2">This will replace the current version you are editing with the version you selected.</p>
            <div className="flex mt-5 justify-end">
              <div className="flex space-x-4">
                {' '}
                {/* Added this div with flex and space-x-4 */}
                {/* <button
                  className="bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                  onClick={onClose}
                >
                  Back
                </button>
                <button
                  className="brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                  onClick={() => {
                    setReplaceItinerary(true);
                    setFirstLoadAvailability(true);
                    setFirstLoad(true);
                    setStep('bookings');
                    onClose();
                  }}
                >
                  Confirm
                </button> */}
                <ButtonOutlineSmall onClick={onClose} text="Back" color="dark" />
                <ButtonPrimarySmall
                  onClick={() => {
                    setReplaceItinerary(true);
                    setFirstLoadAvailability(true);
                    setFirstLoad(true);
                    setStep('bookings');
                    onClose();
                  }}
                  text="Confirm"
                  color="dark"
                />
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  // const handleViewChange = (option) => {
  //   setSelectedItemView(option.value);
  //   setSelectedViewUid(option.value);
  //   setSelectedViewLabel(option.label);
  // }


  // setViewItinerariesMenu(
  //   <div className="flex items-center justify-end">
  //     <Dropdown>
  //       <DropdownButton className="flex items-center justify-between w-full px-3 py-2 text-sm bg-white border rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-none shadow-sm focus:outline-none">
  //         <div className="flex items-center">
  //           <FiEye className="mr-2 text-white" />
  //           <span>{itinerariesVcOptions.find((option) => option.value === selectedItemView)?.label || 'Select Version'}</span>
  //         </div>
  //         <FiChevronDown className="w-5 h-5 ml-2" />
  //       </DropdownButton>
  //       <DropdownMenu className="w-full" anchor="bottom end">
  //         {itinerariesVcOptions.map((option) => (
  //           <DropdownItem key={option.value} onClick={() => handleViewChange(option)}>
  //             <DropdownLabel>{option.label}</DropdownLabel>
  //           </DropdownItem>
  //         ))}
  //       </DropdownMenu>
  //     </Dropdown>
  //   </div>,
  // );

  setItinerariesVcMenu(
    <div className="flex items-center justify-end">
      <Dropdown>
        <DropdownButton className="flex items-center justify-between w-full px-3 py-2 text-sm bg-white border rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-none shadow-sm focus:outline-none">
          <div className="flex items-center">
          <FiEye 
              className="mr-2 text-white cursor-pointer" 
              onClick={(e) => {
                e.stopPropagation(); // Prevent dropdown from opening
                console.log('clicked');
                handleOpenEditViewSlideOut();
              }}
            />
            <FiEdit className="mr-2 text-white" />
            <span>{itinerariesVcOptions.find((option) => option.value === selectedItemVc)?.label || 'Select Version'}</span>
          </div>
          <FiChevronDown className="w-5 h-5 ml-2" />
        </DropdownButton>
        <DropdownMenu className="w-full" anchor="bottom end">
          {itinerariesVcOptions.map((option) => (
            <DropdownItem key={option.value} onClick={() => handleVersionChange(option)}>
              <DropdownLabel>{option.label}</DropdownLabel>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>,
  );
};

const loadItinerariesVcWorking = async (
  uid,
  setItinerariesVcMenu,
  setDocUid,
  setReplaceItinerary,
  selectedItemVc,
  setSelectedItemVc,
  userVersion,
  setRefreshAllRates,
  setFirstLoadAvailability,
  setFirstLoad,
  status,
) => {
  const currentVersion = await loadItineraryVersion(uid);

  const itinerariesVcOptions = [];
  const q = query(collection(db, 'itinerariesVersionControl'), where('uid', '==', uid), orderBy('version', 'desc'), orderBy('dateModified', 'desc'), orderBy('dateCreated', 'desc'));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const newVersion = doc.data().userVersion ? doc.data().userVersion : 1;

    itinerariesVcOptions.push({
      value: doc.id,
      label: `Version: ${newVersion} on ${moment.unix(doc.data().dateModified.seconds).format('D MMMM YYYY, HH:mm')}`,
    });
  });

  // Add current version option
  itinerariesVcOptions.unshift({
    value: uid,
    label: `Current version: ${currentVersion !== null && currentVersion !== undefined ? currentVersion : 1}`,
  });

  setItinerariesVcMenu(
    <div className="flex items-center justify-end">
      <Select
        components={{ Control: CustomControl }}
        styles={customStyles}
        options={itinerariesVcOptions}
        value={itinerariesVcOptions.find((option) => option.value === selectedItemVc)}
        onChange={(selectedOption) => {
          setSelectedItemVc(selectedOption.value);
          setDocUid(selectedOption.value);

          if (status === 'confirmed') {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                    <p className="text-sm font-semibold pb-2">Version cannot be changed</p>
                    <p className="text-sm pb-2">You cannot view/edit another version of a confirmed itinerary.</p>
                    <div className="flex mt-5 justify-end">
                      <div>
                        <button
                          className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Back
                        </button>
                        {/* <button
                        className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                        onClick={() => {
                          setReplaceItinerary(true);
                          setFirstLoadAvailability(true);
                          setFirstLoad(true);
                          onClose();
                        }}
                      >
                        Confirm
                      </button> */}
                      </div>
                    </div>
                  </div>
                );
              },
            });
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                    <p className="text-sm font-semibold pb-2">Are you sure you would like to edit another version?</p>
                    <p className="text-sm pb-2">This will replace the current version you are editing with the version you selected.</p>
                    <div className="flex mt-5 justify-end">
                      <div>
                        <button
                          className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Back
                        </button>
                        <button
                          className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                          onClick={() => {
                            setReplaceItinerary(true);
                            setFirstLoadAvailability(true);
                            setFirstLoad(true);
                            onClose();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                );
              },
            });
          }
        }}
      />
    </div>,
  );
};

// const loadItinerariesVc = async (
//   uid,
//   setItinerariesVcMenu,
//   setDocUid,
//   setReplaceItinerary,
//   selectedItemVc,
//   setSelectedItemVc,
//   userVersion,
//   setRefreshAllRates,
//   setFirstLoadAvailability,
//   setFirstLoad,
// ) => {
//   const currentVersion = await loadItineraryVersion(uid);

//   const itinerariesVc = [];
//   const q = query(collection(db, 'itinerariesVersionControl'), where('uid', '==', uid), orderBy('version', 'desc'), orderBy('dateModified', 'desc'), orderBy('dateCreated', 'desc'));
//   const querySnapshot = await getDocs(q);

//   querySnapshot.forEach((doc) => {
//     const newVersion = doc.data().userVersion ? doc.data().userVersion : 1;
//     itinerariesVc.push({
//       value: doc.id,
//       label: (
//         <>
//           <FiEdit size={16} /> Version: {newVersion} on {moment.unix(doc.data().dateModified.seconds).format('D MMMM YYYY, HH:mm')}
//         </>
//       ),
//     });
//   });

//   setItinerariesVcMenu(
//     <Select
//       className="appearance-none form-input bg-[#40404F] text-white text-base font-normal hover:border-0 border-0 focus:border-0 border-0 outline-none shadow-white hover:underline-offset-2 w-fit cursor-pointer"
//       value={selectedItemVc}
//       onChange={(selectedOption) => {
//         setSelectedItemVc(selectedOption.value);
//         setDocUid(selectedOption.value);
//         confirmAlert({
//           customUI: ({ onClose }) => {
//             return (
//               <div className="p-5 bg-white rounded-lg" style={deeShadow}>
//                 <p className="text-sm font-semibold pb-2">Are you sure you would like to edit another version?</p>
//                 <p className="text-sm pb-2">This will replace the current version you are editing with the version you selected.</p>
//                 <div className="flex mt-5 justify-end">
//                   <div>
//                     <button
//                       className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
//                       onClick={() => {
//                         onClose();
//                       }}
//                     >
//                       Back
//                     </button>
//                     <button
//                       className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
//                       onClick={() => {
//                         setReplaceItinerary(true);
//                         setFirstLoadAvailability(true);
//                         setFirstLoad(true);
//                         onClose();
//                       }}
//                     >
//                       Confirm
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             );
//           },
//         });
//       }}
//       options={[
//         {
//           value: uid,
//           label: (
//             <>
//               <FiEdit size={16} /> Current version: {currentVersion !== null && currentVersion !== undefined ? currentVersion : 1}
//             </>
//           ),
//         },
//         ...itinerariesVc,
//       ]}
//     />,
//   );
// };

const loadCountItinerariesVc = async (uid) => {
  const q = query(collection(db, 'itinerariesVersionControl'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.size; // .size property contains the number of docs
};

// const loadUserVersion = async (uid) => {
//   const docRef = doc(db, 'itineraries', uid);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     return docSnap.data().userVersion; // Return the userVersion from the document
//   } else {
//     console.log('No such document!');
//     return null; // Return null if the document does not exist
//   }
// };

const loadActivities = async (bookings, setActivities, setIsLoading, customLocations, user) => {
  // Firestore settings
  const fetchName = 'activities';
  const fetchRef = collection(db, fetchName);
  // Get the property Ids from the bookings
  const propUids = [...new Set(bookings.map((booking) => booking.propUid))];

  // Create a mapping of propUid to parkUid
  const propToParkMap = {};
  customLocations.forEach((location) => {
    propToParkMap[location.propUid] = location.parkId;
  });

  // Get the parkUids from the propUids using the mapping
  const parkUids = propUids.map((propUid) => propToParkMap[propUid]);

  // Create an empty object with keys for each propUid and parkUid
  const resultsByPropUid = {};
  const resultsByParkUid = {};
  propUids.forEach((propUid) => {
    resultsByPropUid[propUid] = [];
  });
  parkUids.forEach((parkUid) => {
    resultsByParkUid[parkUid] = [];
  });

  // Execute all queries concurrently and populate the resultsByPropUid and resultsByParkUid objects
  await Promise.all([
    ...propUids.map(async (propUid) => {
      try {
        // Create query
        let q = query(fetchRef, where('properties', 'array-contains', propUid), where('isActive', '==', true));
        console.log('user.internalUser', user.internalUser);
        if (user.internalUser === false) {
          q = query(fetchRef, where('properties', 'array-contains', propUid), where('isActive', '==', true), where('supplier.id', '==', 'ded3a3ed-aeaf-4495-9069-7754a649de67'));
        }

        // Execute query
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const data = {
            ...doc.data(),
            uid: doc.id,
          };
          resultsByPropUid[propUid].push(data);
        });
      } catch (error) {
        console.log(error);
      }
    }),
    ...parkUids.map(async (parkUid) => {
      try {
        // Create query
        //const q = query(fetchRef, where('parks', 'array-contains', parkUid));
        let q = query(fetchRef, where('parks', 'array-contains', parkUid), where('isActive', '==', true));
        console.log('user.internalUser', user.internalUser);
        if (user.internalUser === false) {
          q = query(fetchRef, where('parks', 'array-contains', parkUid), where('isActive', '==', true), where('supplier.id', '==', 'ded3a3ed-aeaf-4495-9069-7754a649de67'));
        }
        // Execute query
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const data = {
            ...doc.data(),
            uid: doc.id,
          };
          resultsByParkUid[parkUid].push(data);
        });
      } catch (error) {
        console.log(error);
      }
    }),
  ]);

  // Merge the results
  const mergedResults = { ...resultsByPropUid, ...resultsByParkUid };

  console.log('mergedResults');
  console.log(JSON.stringify(mergedResults, undefined, 4));
  setActivities(mergedResults);
  setIsLoading(false);
  return true;
};

const loadActivitiesOld = async (bookings, setActivities, setIsLoading) => {
  // Firestore settings
  const fetchName = 'activities';
  const fetchRef = collection(db, fetchName);
  // Get the property Ids from the bookings
  const propUids = [...new Set(bookings.map((booking) => booking.propUid))];

  // Create an empty object with keys for each propUid
  const resultsByPropUid = {};
  propUids.forEach((propUid) => {
    resultsByPropUid[propUid] = [];
  });

  // Execute all queries concurrently and populate the resultsByPropUid object
  await Promise.all(
    propUids.map(async (propUid) => {
      console.log(propUid);
      try {
        // Create query
        const q = query(fetchRef, where('properties', 'array-contains', propUid));
        // Execute query
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const data = {
            ...doc.data(),
            uid: doc.id,
          };
          resultsByPropUid[propUid].push(data);
        });
      } catch (error) {
        console.log(error);
      }
    }),
  );
  console.log('resultsByPropUid');
  console.log(JSON.stringify(resultsByPropUid, undefined, 4));
  setActivities(resultsByPropUid);
  setIsLoading(false);
  return true;
  //console.log(resultsByPropUid); // Log the final resultsByPropUid object
};
const loadFlightLocations = async (selectedFlight, objType, airports) => {
  const checkAirport = objType === 'arrival' ? selectedFlight.fromcode : selectedFlight.tocode;

  console.log('loadFlightLocations');
  console.log(JSON.stringify(selectedFlight, null, 2));
  console.log('airports');
  console.log(JSON.stringify(airports, null, 2));

  const findParkId = (airports, code) => {
    const airport = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(code));
    return airport ? airport.park.id : null;
  };

  console.log('checkAirport', checkAirport);
  const parkId = findParkId(airports, checkAirport);

  if (parkId !== null) {
    const fetchName = 'transferRoutes';
    try {
      const fetchRef = collection(db, fetchName);
      //Create query
      let q;
      q = query(fetchRef, where('destinationPark.id', '==', parkId));
      if (objType === 'departure') {
        q = query(fetchRef, where('originPark.id', '==', parkId));
      }

      console.log('loadTransferRoutes');
      console.log('parkId ', parkId);

      //Execute query
      const querySnapshot = await getDocs(q);

      const fetch = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (objType === 'arrival') {
          if (data.originPark && data.originPark.id) {
            fetch.push(data.originPark.id);
          }
        } else {
          if (data.destinationPark && data.destinationPark.id) {
            fetch.push(data.destinationPark.id);
            //fetch.push(data.originPark.id);
          }
        }
      });
      console.log('fetch');
      console.log(JSON.stringify(fetch, undefined, 4));
      return fetch;
    } catch (error) {
      console.log(error);
      toast.error('Could not fetch ' + fetchName);
    }
  }
};

const loadTransferRoutes = async (parkId, arrivalDeparture, isSafari) => {
  const fetchName = 'transferRoutes';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);
    // originPark
    // destinationPark
    //Create query
    let q;
    if (isSafari) {
      q = query(fetchRef, where('destinationPark.id', '==', parkId));
      if (arrivalDeparture === 'departure') {
        q = query(fetchRef, where('originPark.id', '==', parkId));
      }
    } else {
      q = query(fetchRef, where('destinationPark.id', '==', parkId), where('type', '==', 'townTransfer'), where('active', '==', true));
      if (arrivalDeparture === 'departure') {
        q = query(fetchRef, where('originPark.id', '==', parkId), where('type', '==', 'townTransfer'), where('active', '==', true));
      }
    }

    console.log('loadTransferRoutes');
    console.log('parkId ', parkId);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (arrivalDeparture === 'arrival') {
        if (data.originPark && data.originPark.id) {
          fetch.push(data.originPark.id);
        }
      } else {
        if (data.destinationPark && data.destinationPark.id) {
          fetch.push(data.destinationPark.id);
        }
      }
    });
    console.log('fetch');
    console.log(JSON.stringify(fetch, undefined, 4));
    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadTransferRoutesOwnArrangements = async (parkId, arrivalDeparture) => {
  const fetchName = 'transferRoutes';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);
    // originPark
    // destinationPark
    //Create query
    let q;
    q = query(fetchRef, where('destinationPark.id', '==', parkId));
    if (arrivalDeparture === 'departure') {
      q = query(fetchRef, where('originPark.id', '==', parkId));
    }

    console.log('loadTransferRoutes');
    console.log('parkId ', parkId);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (arrivalDeparture === 'arrival') {
        if (data.originPark && data.originPark.id) {
          fetch.push(data.originPark.id);
        }
      } else {
        if (data.destinationPark && data.destinationPark.id) {
          fetch.push(data.destinationPark.id);
        }
      }
    });
    console.log('fetch');
    console.log(JSON.stringify(fetch, undefined, 4));
    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadTransferRoute = async (origin, destination) => {
  const fetchName = 'transferRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('destinationPark.id', '==', destination), where('originPark.id', '==', origin), where('active', '==', true));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch');
      console.log(JSON.stringify(data, undefined, 4));
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadConnectingAirports = async (origin, destination) => {
  console.log('loadConnectingAirports 1');
  console.log(origin);
  console.log(destination);
  const fetchName = 'flightRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('destination.id', '==', destination), where('origin.id', '==', origin), where('active', '==', true));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch');
      console.log(JSON.stringify(data, undefined, 4));
      return data; // Return the fetched data object
    } else {
      console.log('No results found for loadConnectingAirports');
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};



const loadTransferRouteUid = async (uid) => {
  const fetchName = 'transferRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('uid', '==', uid));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch');
      console.log(JSON.stringify(data, undefined, 4));
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadTransferRouteTownTransfer = async (origin, destination) => {
  const fetchName = 'transferRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('destinationPark.id', '==', destination), where('originPark.id', '==', origin), where('active', '==', true));
    //    let q = query(fetchRef, where('destinationPark.id', '==', destination), where('originPark.id', '==', origin), where('active', '==', true), where('type', '==', 'availableAny'));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch loadTransferRouteTownTransfer');
      console.log(JSON.stringify(data, undefined, 4));
      //data.rates = data.rates.filter((rate) => rate.type === 'availableAny');
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

// load data from transferRoutes for airport transfers
const loadTransferRouteAirportTransfers = async (origin, destination) => {
  const fetchName = 'transferRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('destinationPark.id', '==', destination), where('originPark.id', '==', origin), where('active', '==', true));
    //    let q = query(fetchRef, where('destinationPark.id', '==', destination), where('originPark.id', '==', origin), where('active', '==', true), where('type', '==', 'availableAny'));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch loadTransferRouteAirportTransfers');
      console.log(JSON.stringify(data, undefined, 4));
      // data.rates = data.rates.filter((rate) => rate.type === 'availableAny');
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadFlightRoutes = async (id, arrivalDeparture) => {
  const fetchName = 'flightRoutes';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);
    // originPark
    // destinationPark
    //Create query
    let q = query(fetchRef, where('destination.id', '==', id));
    if (arrivalDeparture === 'departure') {
      q = query(fetchRef, where('origin.id', '==', id));
    }

    console.log('loadFlightRoutes');
    console.log('id ', id);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (arrivalDeparture === 'arrival') {
        if (data.origin && data.origin.id) {
          fetch.push(data.origin.id);
        }
      } else {
        if (data.destination && data.destination.id) {
          fetch.push(data.destination.id);
        }
      }
    });
    console.log('fetch');
    console.log(JSON.stringify(fetch, undefined, 4));
    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadAirlineDetails = async (airlineDesignator) => {
  const fetchName = 'airlines';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('companyCode', '==', airlineDesignator));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch');
      console.log(JSON.stringify(data, undefined, 4));
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadFlightRoute = async (origin, destination) => {
  const fetchName = 'flightRoutes';
  try {
    const fetchRef = collection(db, fetchName);

    // Create a query that matches both origin and destination
    let q = query(fetchRef, where('origin.id', '==', origin), where('destination.id', '==', destination));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any results exist and return the first one
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log('fetch');
      console.log(JSON.stringify(data, undefined, 4));
      return data; // Return the fetched data object
    }

    // If no results exist, return null or any other suitable value
    return null;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadCustomFlights = async (flightRouteUid, availableDay, dateString, createEdit, createVersion) => {
  const fetchName = 'customFlights';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);
    console.log('CREATEVERSION', createVersion);
    //Create query
    let q;
    if (createEdit === 'create') {
      q = query(fetchRef, where('flightRouteUid', '==', flightRouteUid), where('availableDays', 'array-contains', availableDay), where('availableNewItineraries', '==', true));
    }
    // custom flights no longer appear on new itineraries, but handle legacy cases.
    else if (createEdit === 'edit' && semver.lte(createVersion, '2.4.19')) {
      q = query(fetchRef, where('flightRouteUid', '==', flightRouteUid), where('availableDays', 'array-contains', availableDay));
    } else {
      q = query(fetchRef, where('flightRouteUid', '==', flightRouteUid), where('availableDays', 'array-contains', availableDay), where('availableNewItineraries', '==', true));
    }
    console.log('CREATEEDIT', createEdit);
    console.log('VERSION', createVersion);

    //Execute query
    const querySnapshot = await getDocs(q);

    let fetch = [];

    querySnapshot.forEach((doc) => {
      // Check if data is not an array before pushing
      const data = doc.data();
      if (!Array.isArray(data)) {
        fetch.push({
          ...data,
          STD: dateString + ' ' + data.departureTime,
          STA: dateString + ' ' + data.arrivalTime,
          //uid: uid,
        });
      }
    });

    return fetch;
  } catch (error) {
    console.log(error);
    toast.error('Could not fetch ' + fetchName);
  }
};

const loadJsonData = async (uid) => {
  const docRef = doc(db, 'itineraries', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data(); // Get the full document data
    // Check if jsonData exists, if not, return a default value like an empty array
    return data.jsonData ? data.jsonData : [];
  } else {
    console.log('No such document!');
    return []; // Return an empty array if the document doesn't exist
  }
};

// Load preferred airlines for showing recommended options - HP
const loadPreferredAirlines = async () => {
  const fetchName = 'airlines';
  try {
    const fetchRef = collection(db, fetchName);
    const q = query(fetchRef, where('preferred', '==', true));
    const querySnapshot = await getDocs(q);

    const preferredAirlines = [];
    querySnapshot.forEach((doc) => {
      preferredAirlines.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return preferredAirlines;
  } catch (error) {
    console.error('Error loading preferred airlines:', error);
    toast.error('Could not fetch preferred airlines');
    return [];
  }
};

export {
  loadBooking,
  loadBookings,
  loadProperties,
  loadAgentRates,
  loadItineraryUrl,
  loadSummaryUrl,
  loadInvoiceUrl,
  loadCostingUrl,
  loadItinerariesVc,
  loadBookingVc,
  loadActivities,
  loadAirports,
  loadAirportProperties,
  loadTransferRoutes,
  loadTransferRoute,
  loadFlightRoutes,
  loadAirlineDetails,
  loadAgentCommission,
  loadCountItinerariesVc,
  loadAgentDetails,
  loadConsultantDetails,
  loadCustomFlights,
  loadFlightRoute,
  loadAgent,
  loadTransferRouteTownTransfer,
  loadTransferRouteUid,
  loadFlightLocations,
  loadItineraryVersion,
  loadJsonData,
  loadPropertiesManageBookings,
  loadBookingSnapshot,
  loadAgentAccessibleAccounts,
  loadConnectingAirports,
  loadTransferRoutesOwnArrangements,
  loadEnquiries,
  loadTransferRouteAirportTransfers,
  loadPreferredAirlines,
  //loadUserVersion,
  loadBookingSnapshotView,
};
