import { CalendarIcon, HomeIcon } from '@heroicons/react/24/outline';

import { MdDateRange } from 'react-icons/md';
import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const TransferPropertyCard = ({ item, properties, customLocations, generateVehicleMessage, getPropObj, bookings }) => {
  const property = properties[getPropObj(item.propUid, properties)];

  return (
    <div
      className="bg-nomadBrown-100 border rounded-xl pt-4 mb-5 drop-shadow-md border-slate-200 overflow-hidden"
      style={{
        WebkitTransform: 'translateZ(0)',
      }}
    >
      <div key={uuidv4()} className="px-4 pb-4 relative">
        {property.bookedDirect && (
          <div
            className="absolute left-1/2 -translate-x-1/2 -top-4"
            style={{
              perspective: '1000',
            }}
          >
            <span className="text-xs font-medium px-3 py-0.5 bg-yellow-500 text-white rounded-b-lg">
              Accommodation booked direct
            </span>
          </div>
        )}
        <div className="flex items-start space-x-4">
          {/* Left column - Icon */}
          <div className="flex-shrink-0">
            <div className="w-10 h-10 bg-nomadBrown-400 rounded-full flex items-center justify-center">
              <HomeIcon className="w-5 h-5 text-nomadBrown-700" />
            </div>
          </div>
          
          {/* Middle column - Title, park detail, and dynamic info */}
          <div key={uuidv4()} className="flex-grow min-w-0">
            <div className="text-xl font-bold truncate">
              {property.name}
            </div>
            <div className="mt-1 text-base text-nomadGray-600">
              {(() => {
                const customLocation = customLocations.find((location) => location.propUid === item.propUid);
    
                if (property.mobileLocation && customLocation) {
                  const park = property.customLocations.find((park) => park.parkId === customLocation.parkId);
                  return park ? park.parkName : 'Camp location closed';
                } else {
                  return property.park.name;
                }
              })()}
            </div>
            <div className="mt-4 text-sm text-nomadGray-600"
              dangerouslySetInnerHTML={{
                __html: generateVehicleMessage(item, bookings, properties),
              }}
            />
          </div>
          
          {/* Right column - Image */}
          <div className="flex-shrink-0">
            <img
              className="w-16 h-16 rounded-xl object-cover"
              src={
                properties[getPropObj(item.propUid, properties)].heroImageUrl
                  ? `${properties[getPropObj(item.propUid, properties)].heroImageUrl}/320x200`
                  : 'https://firebasestorage.googleapis.com/v0/b/nomadhornbill.appspot.com/o/images%2Fproperties%2Fdefault%2Fdefault.png?alt=media&token=60f73247-fa43-4829-9c56-260803159067'
              }
              alt={properties[getPropObj(item.propUid, properties)].name || 'Property image'}
            />
          </div>
        </div>
      </div>
      
      {/* Bottom strip - Nights and Dates */}
      <div className="bg-nomadBrown-300 rounded-b-xl">
        <div className="pl-16 py-2">
          <div className="flex items-center text-sm text-nomadGray-600 space-x-2">
            <span className="bg-nomadBrown-100 rounded-xl px-2 py-1 flex items-center">
              <MdDateRange className="w-4 h-4 mr-1 flex-shrink-0" />
              <span className="font-medium">
                {item.days} Night{item.days > 1 ? 's' : ''}
              </span>
            </span>
            <span className="truncate">
              {moment(item.startDay).format('ddd, Do MMM YYYY')} - {moment(item.endDay).format('ddd, Do MMM YYYY')}
            </span>
            <span className="font-medium">
              | {item.rateFullName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPropertyCard;