import { FaRegPaste, FaRegTrashCan } from 'react-icons/fa6';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdConnectingAirports, MdContentCopy, MdContentCut, MdFlight } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import { GiJeep } from 'react-icons/gi';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import ViewFoundItemCard from './ViewFoundItem';
import { formatPrice } from '../functions/generalFunctions';
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewDropPickup = ({ item, bookings, booking, airports, pickupDropoff, customFinalItems, setCustomFinalItems, properties, transfers, shouldCollapse, firstDay, bookingMaster, manage, supplierReservations, hideStatusPill}) => {
  console.log('PICKUPDROPOFF', pickupDropoff);

  // USER
  const { user } = useSelector((state) => state.auth);
  let airportTo;
  let airportFrom;
  let airportToCode;
  let airportFromCode;
  let airportConnecting;
  console.log('item ViewDropPickup');
  // console.log as readable json
  console.log(JSON.stringify(item, null, 2));
  if (item.formValues.type === 'ownArrangements') {
    airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.destinationAirportCode));
    airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.originAirportCode));
    airportToCode = item.formValues.destinationAirportCode;
    airportFromCode = item.formValues.originAirportCode;
  } else {
    if (item.formValues.flightType === 'Multiple') {
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.flightDetailsSecond.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.flightDetails.fromcode));
      airportConnecting = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.flightDetailsSecond.fromcode));
    } else {
      airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.flightDetails.tocode));
      airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(item.formValues.flightDetails.fromcode));
      airportToCode = item.formValues.flightDetails.tocode;
      airportFromCode = item.formValues.flightDetails.fromcode;
    }
  }

  const getNextBookingOrSame = (bookings, bookingId) => {
    const index = bookings.findIndex((booking) => booking.id === bookingId);

    if (index === -1) {
      throw new Error('Booking not found');
    } else if (index < bookings.length - 1) {
      return bookings[index + 1];
    } else {
      return bookings[index];
    }
  };

  const bookingResult = getNextBookingOrSame(bookings, booking.id);

  const pickupFrom = airportTo.name;
  const dropoffTo = airportFrom.name;
  const arrivalTo = booking.propName;
  const arrivalToNext = bookingResult.propName;

  let transferUnits = 1;

  if (item.objType === 'arrival') {
    transferUnits = item.formValues.arrivalTransferUnits;
  } else if (item.objType === 'departure') {
    transferUnits = item.formValues.departureTransferUnits;
  } else if (item.objType === 'propertyTransfer') {
    if (pickupDropoff === 'pickup') {
      transferUnits = item.formValues.arrivalTransferUnits;
    } else if (pickupDropoff === 'dropoff') {
      transferUnits = item.formValues.departureTransferUnits;
    }
  }

  let searchId = item.tuuid;
  const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && search.pickupDropoff === pickupDropoff);

  //const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && search.pickupDropoff === pickupDropoff);

  const [localItem, setLocalItem] = useState({ ...item, collapsed: shouldCollapse }); // Initialize localItem with item prop and set collapsed to true

  useEffect(() => {
    // If you need to respond to changes in the item prop, update localItem here
    setLocalItem((current) => ({ ...current, ...item, collapsed: current.collapsed }));
  }, [item]);

  function getTransferAndNextIfNotDeparture(transfers, tuuidToFind) {
    // Find the index of the transfer in the transfers array where the tuuid matches tuuidToFind
    const index = transfers.findIndex((transfer) => transfer.tuuid === tuuidToFind);

    // Initialize an object to hold the current and next transfer if applicable
    const result = {
      currentTransfer: null,
      nextTransfer: null,
    };

    // If the transfer was found (index !== -1), set the current transfer
    if (index !== -1) {
      result.currentTransfer = transfers[index];

      // Check if there is a next transfer and if its objType is not 'departure'
      if (index + 1 < transfers.length && transfers[index + 1].objType !== 'departure') {
        result.nextTransfer = transfers[index + 1];
      }
    }

    // Return the result object
    return result;
  }

  // DEAL WITH HEADER
  const transfersResult = getTransferAndNextIfNotDeparture(transfers, item.tuuid);

  const transfer = transfersResult.currentTransfer;
  const nextTransfer = transfersResult.nextTransfer;

  let transferMessage;
  // ARRIVAL
  if (item.objType === 'arrival') {
    if (transfer.formValues.flightType === 'Multiple') {
      transferMessage = `Airport transfer from ${properties[getPropObj(item.propUid, properties)].name} to ${pickupFrom} (${airportTo.code})`;
    } else {
      transferMessage = `Airport transfer from ${pickupFrom} (${airportFromCode}) to ${properties[getPropObj(item.propUid, properties)].name}`;
    }
    // PROPERTY TRANSFER
  } else if (item.objType === 'propertyTransfer') {
    if (pickupDropoff === 'dropoff') {
      transferMessage = `Airport transfer from ${properties[getPropObj(item.propUid, properties)].name} to ${dropoffTo} (${airportFrom.code})`;
    } else if (pickupDropoff === 'pickup') {
      transferMessage = `Airport transfer from ${pickupFrom} (${airportTo.code}) to ${properties[getPropObj(nextTransfer.propUid, properties)].name}`;
    }
    // DEPARTURE
  } else if (item.objType === 'departure') {
    transferMessage = `Airport transfer from ${properties[getPropObj(item.propUid, properties)].name} to ${dropoffTo} (${airportFrom.code})`;
  }

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.endDay, 'YYYY-MM-DD');

  let day = 1;
  if (item.objType === 'arrival') {
    day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === item.endDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  console.log('day', day);
  console.log('firstDay', firstDay);
  console.log('item.startDay', item.startDay);
  let parentDate = item.objType === 'arrival' ? item.startDay : item.endDay;

  let date;
  if (item.objType === 'arrival') {
    date = moment(item.startDay).format('Do MMM YYYY');
  } else if (item.objType === 'propertyTransfer') {
    date = moment(item.endDay).format('Do MMM YYYY');
  } else if (item.objType === 'departure') {
    date = moment(item.endDay).format('Do MMM YYYY');
  }

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
            hideStatusPill={hideStatusPill}
          />
        </div>
      ))}

      {/* main items */}
      <div key={uuidv4()} style={{ zIndex: 1000, WebkitTransform: 'translateZ(0)' }} className={`my-10`}>
      <div className="bg-white rounded-[15px] border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
          {/* HEADER */}
          <ViewCompHeader
            day={day}
            item={localItem}
            feeTypeIcon={<GiJeep size={30} />}
            description={transferMessage}
            supplierName={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierName : item.formValues.departureTransferSupplierName}
            supplierUid={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierUid : item.formValues.departureTransferSupplierUid}
            date={date}
            total={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal}
            collapse={<FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />}
            expand={<FiChevronsDown size={30} className=" cursor-pointer text-zinc-300" onClick={() => setLocalItem({ ...localItem, collapsed: !localItem.collapsed })} />}
            bookingMaster={bookingMaster}
            manage={manage}
            supplierReservations={supplierReservations}
            hideStatusPill={hideStatusPill}
          />

          {/* ROW TITLES */}
          {!localItem.collapsed && <ViewCompTableHeader />}

          {/* Column propertyItems*/}
          {!localItem.collapsed && (
            <div key={uuidv4()} className="pb-5">
              <ViewCompTableRow
                index={0}
                lengthCheck={1}
                description={pickupDropoff === 'pickup' ? 'Pickup airport transfer' : 'Dropoff airport transfer'}
                supplierName={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierName : item.formValues.departureTransferSupplierName}
                units={transferUnits}
                pax={item.formValues.totalAllPax}
                net={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal}
                total={pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal}
              />
            </div>

            // <div key={uuidv4()} className={`grid grid-cols-12 text-xs border-b border-slate-200`}>
            //   <div className="col-span-1 px-2 border-r border-slate-200 py-2">{pickupDropoff === 'pickup' ? 'Pickup' : 'Dropoff'}</div>
            //   <div className="col-span-2 ml-5 border-r border-slate-200 py-2">
            //     {pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSupplierName : item.formValues.departureTransferSupplierName}
            //   </div>
            //   <div className="col-span-4">
            //     <div className={`grid grid-cols-12 text-xs`}>
            //       <div className="col-span-11 ml-5 border-r border-slate-200 py-2 pr-5">{pickupDropoff === 'pickup' ? 'Pickup airport transfer' : 'Dropoff airport transfer'}</div>
            //       <div className="col-span-1 border-r border-slate-200 py-2 flex justify-center items-center group"></div>
            //     </div>
            //   </div>

            //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{transferUnits}</div>
            //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">{item.formValues.totalAllPax}</div>
            //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
            //     ${formatPrice(pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal)}
            //   </div>
            //   <div className="col-span-1 ml-5 border-r border-slate-200 py-2">
            //     {' '}
            //     ${formatPrice(pickupDropoff === 'pickup' ? item.formValues.arrivalTransferSaleTotal : item.formValues.departureTransferSaleTotal)}
            //   </div>
            //   <div className="col-span-1 ml-5 py-2 flex items-center"></div>
            // </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewDropPickup;
