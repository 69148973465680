import React from 'react';
import { motion } from 'framer-motion';
import { MapPinIcon, HomeIcon, TruckIcon } from '@heroicons/react/24/outline';

function PropertyCard({ camp }) {
  const placeholderImage = 'https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/4ccc5804-560b-478d-b844-134c9360ef00/770x450';

  const imageUrl = camp.imageUrl
    ? camp.imageUrl.includes('imagedelivery.net')
      ? `${camp.imageUrl}/770x450`
      : camp.imageUrl
    : placeholderImage;

  const getBadgeIcon = (type) => {
    switch(type) {
      case 'locationCategory':
        return <MapPinIcon className="h-4 w-4" />;
      case 'mobile':
        return <TruckIcon className="h-4 w-4" />;
      case 'private':
        return <HomeIcon className="h-4 w-4" />;
      default:
        return null;
    }
  };

  return (
    <motion.div 
      whileHover={{ y: -4 }}
      transition={{ duration: 0.2 }}
      className="group relative bg-white rounded-lg border border-[#f0ece7] h-full flex flex-col"
    >
      <div className="relative h-52">
        <motion.img 
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.4 }}
          className="w-full h-full object-cover rounded-t-lg"
          src={imageUrl} 
          alt={camp.name}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-t-lg" />
      </div>

      <div className="p-4 flex-grow flex flex-col">
      <div className="flex items-center space-x-2 mb-1">
    <div className="inline-flex items-center  py-1 bg-nomadBlue-50 rounded-full">
      <MapPinIcon className="h-3.5 w-3.5 text-nomadBlue-600" />
      <p className="ml-1.5 text-xs font-semibold uppercase text-nomadBlue-700">
        {camp.park}
      </p>
    </div>
  </div>

        <h2 className="text-[#1e1e1e] text-lg font-semibold font-['Open Sans'] leading-[27px] mb-2">
          {camp.name}
        </h2>

        <p className="text-[#636366] text-sm font-normal font-['Inter'] leading-tight mb-4 line-clamp-2">
          {camp.description}
        </p>

        <div className="flex flex-wrap gap-2 mt-auto">
          {camp.locationCategory && (
            <span className="inline-flex items-center space-x-1 px-3 py-1 bg-[#f2f2f2] rounded-full text-[#333333] text-sm font-normal hover:bg-nomadBlue-50 transition-colors">
              {getBadgeIcon('locationCategory')}
              <span>{camp.locationCategory}</span>
            </span>
          )}
          {camp.mobileLocation && (
            <span className="inline-flex items-center space-x-1 px-3 py-1 bg-[#f2f2f2] rounded-full text-[#333333] text-sm font-normal hover:bg-nomadOlive-50 transition-colors">
              {getBadgeIcon('mobile')}
              <span>Mobile Camp</span>
            </span>
          )}
          {camp.propertyType === 'private' && (
            <span className="inline-flex items-center space-x-1 px-3 py-1 bg-[#f2f2f2] rounded-full text-[#333333] text-sm font-normal hover:bg-nomadBrown-50 transition-colors">
              {getBadgeIcon('private')}
              <span>Private Camp</span>
            </span>
          )}
        </div>
      </div>

      <div className="absolute inset-0 border-2 border-transparent rounded-lg group-hover:border-nomadBlue-200 transition-colors duration-200" />
    </motion.div>
  );
}

export default PropertyCard;