import React from 'react';

const ClosuresDisplay = ({ closureDates }) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle Firestore Timestamp object
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    }
    
    // Handle Unix timestamp in milliseconds
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const getClosureStatus = (closure) => {
    if (closure.indefinitelyClosed) {
      return {
        label: 'Indefinite Closure',
        color: 'bg-red-100 text-red-800',
      };
    }
    if (closure.subsequentYearsAlso) {
      return {
        label: 'Annual Closure',
        color: 'bg-amber-100 text-amber-800',
      };
    }
    return {
      label: 'Temporary Closure',
      color: 'bg-blue-100 text-blue-800',
    };
  };

  const currentDate = new Date();
  const relevantClosures = closureDates
    ?.filter((closure) => {
      const startDate = closure.periodStartDate.toDate 
        ? closure.periodStartDate.toDate() 
        : new Date(closure.periodStartDate);
      
      const endDate = closure.periodEndDate.toDate 
        ? closure.periodEndDate.toDate() 
        : new Date(closure.periodEndDate);

      if (closure.indefinitelyClosed) {
        return startDate <= currentDate;
      }
      return endDate >= currentDate;
    })
    .sort((a, b) => {
      const aDate = a.periodStartDate.toDate 
        ? a.periodStartDate.toDate() 
        : new Date(a.periodStartDate);
      const bDate = b.periodStartDate.toDate 
        ? b.periodStartDate.toDate() 
        : new Date(b.periodStartDate);
      return aDate - bDate;
    });

  if (!relevantClosures || relevantClosures.length === 0) {
    return (
      <div className="bg-[#f4f3f2] rounded-lg p-6">
        <p className="text-gray-600">No upcoming closure dates scheduled.</p>
        <p className="text-sm text-gray-500 mt-2">
          The property is expected to be open year-round, but seasonal closures may be added in the future.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {relevantClosures.map((closure) => {
        const status = getClosureStatus(closure);

        return (
          <div key={closure.uid} className="bg-[#f4f3f2] rounded-lg p-6">
            <div className="flex items-start justify-between">
              <div className="space-y-2">
                <div className="flex items-center gap-3">
                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${status.color}`}>
                    {status.label}
                  </span>
                  {closure.reason && <span className="text-gray-600 text-sm">{closure.reason}</span>}
                </div>

                <div className="text-gray-600">
                  {closure.indefinitelyClosed ? (
                    <p>Closed from {formatDate(closure.periodStartDate)} until further notice</p>
                  ) : (
                    <p>
                      Closed from {formatDate(closure.periodStartDate)} to {formatDate(closure.periodEndDate)}
                      {closure.subsequentYearsAlso && (
                        <span className="block text-sm text-gray-500 mt-1">
                          * Similar closure dates may apply in subsequent years. Please verify specific dates.
                        </span>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ClosuresDisplay;