import React from 'react';
import { motion } from 'framer-motion';
import NomadCampHeaderSection from './NomadCampHeaderSection';
import ImageGallery from './ImageGallery';
import TabSection from './TabSection';

export const PropertyContentSkeleton = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="space-y-8 bg-white rounded-xl shadow-sm overflow-hidden"
  >
    <div className="animate-pulse">
      {/* Header skeleton */}
      <div className="p-8 border-b border-gray-100">
        <div className="space-y-4 max-w-3xl">
          <div className="h-8 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="flex space-x-4 mt-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-8 w-24 bg-gray-200 rounded-full"></div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Image gallery skeleton */}
      <div className="p-8">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3 aspect-w-16 aspect-h-9 bg-gray-200 rounded-xl"></div>
          <div className="col-span-1 space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="aspect-w-4 aspect-h-3 bg-gray-200 rounded-lg"></div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Tabs skeleton */}
      <div className="px-8">
        <div className="flex space-x-1 rounded-xl bg-gray-50 p-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <div key={i} className="flex-1 h-12 bg-gray-200 rounded-lg"></div>
          ))}
        </div>
      </div>

      {/* Content skeleton */}
      <div className="p-8 space-y-6">
        {[1, 2, 3].map((i) => (
          <div key={i} className="border border-gray-100 rounded-lg p-6 space-y-4">
            <div className="h-6 bg-gray-200 rounded w-1/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </motion.div>
);

function PropertyContent({ propertyData, isLoading }) {
  if (isLoading) {
    return <PropertyContentSkeleton />;
  }

  const { camp, campDataFirestore, tripAdvisorData } = propertyData;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-xl shadow-sm overflow-hidden"
    >
      <div className="p-8 pb-0 border-b border-gray-100">
        <NomadCampHeaderSection 
          camp={camp} 
          firestoreData={campDataFirestore} 
          tripAdvisorData={tripAdvisorData} 
        />
      </div>

      <div className="p-8 pt-0 border-b border-gray-100">
        <ImageGallery 
          camp={camp} 
          firestoreData={campDataFirestore} 
        />
      </div>

      <div className="p-8">
        <TabSection 
          camp={camp} 
          firestoreData={campDataFirestore} 
          tripAdvisorData={tripAdvisorData} 
        />
      </div>
    </motion.div>
  );
}

export default PropertyContent;