import React from 'react';
import { motion } from 'framer-motion';
import { DocumentArrowDownIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { handleFileDownload } from '../../../db/sanity';

function DocumentDownloadsBox({ title = 'Document Downloads', documents }) {
  const handleDownload = (file, name) => {
    if (file && file.asset && file.asset._ref) {
      handleFileDownload(file, name);
    } else if (typeof file === 'string' && (file.startsWith('https://storage.googleapis.com/') || file.startsWith('https://pub-6817318c353849619ce9be60b9c20ffd.r2.dev/'))) {
      window.open(file, '_blank');
    } else {
      console.error('Unexpected file structure:', file);
    }
  };

  const containerAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, x: -20 },
    show: { opacity: 1, x: 0 }
  };

  return (
    <motion.div
      variants={containerAnimation}
      initial="hidden"
      animate="show"
      className="bg-gray-50 rounded-xl p-6 font-sans"
    >
      <div className="flex items-center mb-6">
        <DocumentIcon className="h-5 w-5 text-nomadBlue mr-2" />
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      </div>

      <div className="space-y-3">
        {documents.map((document, index) => (
          <motion.div
            key={index}
            variants={itemAnimation}
            className="group"
          >
            <button
              onClick={() => handleDownload(document.file, document.name)}
              className="w-full flex items-center p-3 rounded-lg bg-white hover:bg-nomadBlue/5 transition-colors border border-gray-100 group-hover:border-nomadBlue/20"
            >
              <div className="flex-1 text-left">
                <h4 className="text-sm font-medium text-gray-900 group-hover:text-nomadBlue truncate">
                  {document.name}
                </h4>
                {document.description && (
                  <p className="text-xs text-gray-500 mt-0.5 truncate">
                    {document.description}
                  </p>
                )}
              </div>
              <DocumentArrowDownIcon 
                className="h-5 w-5 text-gray-400 group-hover:text-nomadBlue ml-4 transition-colors" 
              />
            </button>
          </motion.div>
        ))}
      </div>

      {documents.length === 0 && (
        <div className="text-center py-6 text-gray-500">
          <DocumentIcon className="h-8 w-8 mx-auto mb-2 text-gray-400" />
          <p className="text-sm">No documents available</p>
        </div>
      )}
    </motion.div>
  );
}

export default DocumentDownloadsBox;