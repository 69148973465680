import { calculatePropertyVehicles, filterVehicleRoomsNoSet } from './vehicleFunctions';
import { calculateRateTransferUnits, calculateRateTransferUnitsPax, findTransferRate, findTransferRateAirportTransfers } from './transferFunctions';
import { findBookingById, findCustomLocation, getPropObj, processPackedLunch } from './generalFunctions';
import { loadTransferRoute, loadTransferRouteAirportTransfers, loadTransferRouteTownTransfer } from './loadDataFunctions';

import moment from 'moment';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

// ! USED - CHECKED
// TRANSFER FORMS BOTH **********************************************
// USED IN SINGLE AND INTER PROPERTY / bookAFlight & flightTransfer
// const handleFormSubmitAirport = async (e, transfers, setTransfers, onClose, setRefreshTransferRates, bookings, setBookings, properties, customLocations, airports) => {
//   e.preventDefault();

//   const formData = new FormData(e.target);
//   const formType = formData.get('formType');
//   const tuuid = formData.get('tuuid');
//   // const adults = formData.get("flightAdults");
//   // const children = formData.get("flightChildren");
//   // const infants = formData.get("flightInfants");
//   const adults = parseInt(formData.get('flightAdults'), 10);
//   const children = parseInt(formData.get('flightChildren'), 10);
//   const infants = parseInt(formData.get('flightInfants'), 10);
//   // const flightCost = parseInt(formData.get("flightCost"), 10);
//   // const flightRackCost = parseInt(formData.get("flightRackCost"), 10);
//   const airlineName = formData.get('airlineName');

//   let arrivalTime = 'Select time';
//   // const addFlightDetailsLater = formData.get('addFlightDetailsLater') === 'false' || formData.get('addFlightDetailsLater') === null ? false : true;

//   let detailsNow = formData.get('detailsNow') === false || formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;

//   if (formData.get('detailsNow')) {
//     // detailsNow = formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;
//   }
//   //return alert('detailsNow: ' + detailsNow);

//   if (formType === 'propertyTransfer') {
//     arrivalTime = formData.get('arrivalTime');
//     if (!arrivalTime || arrivalTime === undefined || arrivalTime === 'Select time') {
//       // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
//       return toast.error(
//         // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
//         `You must select what time you will arrive.`,
//       );
//     }
//   }

//   const dateAdjusted = formData.get('dateAdjusted');
//   let flightPricing;

//   try {
//     flightPricing = JSON.parse(formData.get('flightPricing'));
//     console.log('flightPricing value is: ');
//     console.log(JSON.stringify(flightPricing, undefined, 4));
//   } catch (error) {
//     console.error('Error parsing flightPricing', error);
//   }

//   // Flight info
//   const flightDetailsJSON = formData.get('flightDetails');
//   const flightDetails = JSON.parse(flightDetailsJSON);
//   const formName = formData.get('formName');

//   //flight info second
//   const directMultiple = formData.get('directMultiple');
//   const airlineNameSecond = formData.get('airlineNameSecond');
//   const flightDetailsSecondJSON = formData.get('flightDetailsSecond');
//   const flightDetailsSecond = flightDetailsSecondJSON ? JSON.parse(flightDetailsSecondJSON) : null;
//   let flightPricingSecond;

//   try {
//     flightPricingSecond = JSON.parse(formData.get('flightPricingSecond'));
//     console.log('flightPricingSecond value is: ');
//     console.log(JSON.stringify(flightPricingSecond, undefined, 4));
//   } catch (error) {
//     console.error('Error parsing flightPricingSecond', error);
//   }

//   // new data for supplier etc
//   const connectionType = formData.get('connectionType');
//   // const connectionType = detailsNow ? '' : formData.get('connectionType');
//   const connectionClick = formData.get('connectionClick') === 'false' || formData.get('connectionClick') === null || formData.get('connectionClick') === '' ? false : true;
//   // alert('detailsNow: ' + formData.get('detailsNow'));
//   // alert('connectionClick: ' + formData.get('connectionClick'));

//   const locationName = formData.get('locationName');
//   const locationUid = formData.get('locationUid');

//   const supplierName = formData.get('supplierName');
//   const supplierUid = formData.get('supplierUid');

//   const airlineUid = formData.get('airlineUidFlight');
//   const airlineNameFlight = formData.get('airlineNameFlight');
//   const fltNumFlight = formData.get('fltNum');
//   const timeFlight = formData.get('timeFlightFlight');

//   const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

//   // VIP start
//   let vipObject;
//   let vipObjectArrival;
//   let vipObjectDeparture;
//   let includeArrivalTransferVip = false;
//   let includeDepartureTransferVip = false;

//   let arrivalSupplierNameVip;
//   let arrivalSupplierUidVip;
//   let arrivalUnitsVip;
//   let arrivalPerGroupPriceVip;
//   let arrivalPerPersonPriceVip;
//   let arrivalSaleTotalVip;

//   let departureSupplierNameVip;
//   let departureSupplierUidVip;
//   let departureUnitsVip;
//   let departurePerGroupPriceVip;
//   let departurePerPersonPriceVip;
//   let departureSaleTotalVip;



//   const vipObjectJSON = formData.get('vipObject');
//   vipObject = vipObjectJSON ? JSON.parse(vipObjectJSON) : null;

//   const vipObjectArrivalJSON = formData.get('vipObjectArrival');
//   vipObjectArrival = vipObjectArrivalJSON ? JSON.parse(vipObjectArrivalJSON) : null;

//   const vipObjectDepartureJSON = formData.get('vipObjectDeparture');
//   vipObjectDeparture = vipObjectDepartureJSON ? JSON.parse(vipObjectDepartureJSON) : null;

//   if (transferIndex !== -1) {
//     if (transfers[transferIndex].objType === 'arrival') {
//       includeArrivalTransferVip =
//         formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
//       if (includeArrivalTransferVip) {
//         //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
//         arrivalSupplierNameVip = vipObject.supplier.displayName;
//         arrivalSupplierUidVip = vipObject.supplier.id;
//         arrivalUnitsVip = adults + children + infants;
//         arrivalPerGroupPriceVip = vipObject.supplier.perGroupPrice;
//         arrivalPerPersonPriceVip = vipObject.supplier.perPersonPrice;
//         arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
//       }
//     } else if (transfers[transferIndex].objType === 'departure') {
//       includeDepartureTransferVip =
//         formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
//       if (includeDepartureTransferVip) {
//         //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
//         departureSupplierNameVip = vipObject.supplier.displayName;
//         departureSupplierUidVip = vipObject.supplier.id;
//         departureUnitsVip = adults + children + infants;
//         departurePerGroupPriceVip = vipObject.supplier.perGroupPrice;
//         departurePerPersonPriceVip = vipObject.supplier.perPersonPrice;
//         departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
//       }
//     } else if (transfers[transferIndex].objType === 'propertyTransfer') {
//       includeArrivalTransferVip =
//         formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
//       if (includeArrivalTransferVip) {
//         //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
//         arrivalSupplierNameVip = vipObjectArrival.supplier.displayName;
//         arrivalSupplierUidVip = vipObjectArrival.supplier.id;
//         arrivalUnitsVip = adults + children + infants;
//         arrivalPerGroupPriceVip = vipObjectArrival.supplier.perGroupPrice;
//         arrivalPerPersonPriceVip = vipObjectArrival.supplier.perPersonPrice;
//         arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
//       }
//       includeDepartureTransferVip =
//         formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
//       if (includeDepartureTransferVip) {
//         //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
//         departureSupplierNameVip = vipObjectDeparture.supplier.displayName;
//         departureSupplierUidVip = vipObjectDeparture.supplier.id;
//         departureUnitsVip = adults + children + infants;
//         departurePerGroupPriceVip = vipObjectDeparture.supplier.perGroupPrice;
//         departurePerPersonPriceVip = vipObjectDeparture.supplier.perPersonPrice;
//         departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
//       }
//     }
//   }
//   // VIP end

//   console.log('transferIndex: ' + transferIndex);

//   let addFlightDetailsLater;

//   //if (connectionType === 'flight' && detailsNow && !addFlightDetailsLater && (airlineNameFlight === '' || fltNumFlight === '' || timeFlight === 'Select time')) {
//   if (connectionType === 'flight' && detailsNow && (airlineNameFlight === '' || fltNumFlight === '' || timeFlight === 'Select time')) {
//     // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
//     addFlightDetailsLater = true;
//     // return toast.error(
//     //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
//     //   //`You must select all flight detail or tick to add later`,
//     //   `You must select all flight detail or unselect 'Add details now'`,
//     // );
//   } else if (connectionType === 'road' && detailsNow && (supplierName === '' || supplierUid === '' || locationUid === '')) {
//     // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
//     addFlightDetailsLater = true;
//     // return toast.error(
//     //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
//     //   //`You must select all road transfer details or tick to add later`,
//     //   `You must select all road transfer details or unselect 'Add details now'`,
//     // );
//   } else {
//     addFlightDetailsLater = false;
//     //alert('addFlightDetailsLater 2: ' + addFlightDetailsLater + ' connectionType: ' + connectionType + ' locationUid: ' + locationUid);
//   }

//   // deal with arrival and departure transfers - START --------------------------------------
//   const includeDepartureTransfer = e.target.elements.includeDepartureTransfer && e.target.elements.includeDepartureTransfer.checked ? e.target.elements.includeDepartureTransfer.checked : null;
//   let departureTransferSupplierName;
//   let departureTransferSupplierUid;
//   let departureTransferUnits;
//   let departureTransferUnitPrice;
//   let departureTransferSaleTotal;

//   const currentBooking = findBookingById(bookings, transfers[transferIndex].uuid);
//   const currentBookingIndex = bookings.findIndex((booking) => booking.id === currentBooking.id);
//   const currentPropUid = currentBooking.propUid;

//   let nextBooking = null;
//   let nextPropUid = null;
//   if (currentBookingIndex !== bookings.length - 1) {
//     nextBooking = bookings[currentBookingIndex + 1];
//     nextPropUid = nextBooking.propUid;
//   }
//   // departure transfer
//   if (includeDepartureTransfer !== null) {
//     // if (properties[getPropObj(currentPropUid, properties)].handlesOwnAirportTransfer) {
//     //   departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
//     //   departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
//     //   departureTransferUnits = 1;
//     //   departureTransferUnitPrice = 0;
//     //   departureTransferSaleTotal = 0;
//     //   console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(currentPropUid, properties)].name);
//     // } else {
//     // let propUid = nextPropUid;
//     let origin;
//     let destination;
//     if (transfers[transferIndex].objType === 'departure') {
//       console.log('rates: departure');
//       const originObject = findCustomLocation(customLocations, currentPropUid);
//       origin = originObject.parkId;
//       //const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.fromcode));
//       const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
//       destination = destinationObject.park.id;
//       console.log('origin: ' + origin);
//       console.log('destination: ' + destination);

//       const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
//       console.log('transferRoute departure objType departure 1: ', JSON.stringify(transferRoute, null, 2));
//       if (!transferRoute) {
//         toast.error('Could not find transfer route. Please contact support.');
//         return;
//       }
//       const rates = transferRoute.rates;
//       const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, currentBooking, 'departure');

//       if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
//         const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
//         const transferUnitPrice = rateObject.stoPrice;

//         departureTransferSupplierUid = rateObject.supplier.id;
//         departureTransferSupplierName = rateObject.supplier.name;
//         departureTransferUnits = transferUnits;
//         departureTransferUnitPrice = transferUnitPrice;
//         departureTransferSaleTotal = transferUnits * transferUnitPrice;
//       } else if (currentBooking.rateName === 'fullBoardNomad') {
//         departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
//         departureTransferSupplierName = 'Nomad Tanzania';
//         departureTransferUnits = 1;
//         departureTransferUnitPrice = 0;
//         departureTransferSaleTotal = 0;
//       } else {
//         departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
//         departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
//         departureTransferUnits = 1;
//         departureTransferUnitPrice = 0;
//         departureTransferSaleTotal = 0;
//       }
//       // const transferDate = transfers[transferIndex].endDay;
//       // console.log('rates: departure');
//       // console.log('transfer date: ' + transferDate);
//       // console.log(JSON.stringify(transferDate, undefined, 4));
//       // console.log('origin: ' + origin);
//       // console.log('destination: ' + destination);
//     } else {
//       console.log('rates: departure property');
//       const originObject = findCustomLocation(customLocations, currentPropUid);
//       origin = originObject.parkId;
//       // const destinationObject = findCustomLocation(customLocations, nextPropUid);
//       // destination = destinationObject.parkId;
//       //const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.fromcode));
//       const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.fromcode));
//       destination = destinationObject.park.id;
//       console.log('origin: ' + origin);
//       console.log('destination: ' + destination);
//       console.log('destinationObject: ' + JSON.stringify(destinationObject, null, 2));
//       // CHECK ALL CODE!
//       const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
//       console.log('transferRoute departure objType departure 2: ', JSON.stringify(transferRoute, null, 2));
//       if (!transferRoute) {
//         toast.error('Could not find transfer route. Please contact support.');
//         return;
//       }
//       const rates = transferRoute.rates;
//       console.log('rates: ', JSON.stringify(rates, null, 2));
//       const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, currentBooking, 'departure');
//       if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
//         const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
//         const transferUnitPrice = rateObject.stoPrice;
//         departureTransferSupplierUid = rateObject.supplier.id;
//         departureTransferSupplierName = rateObject.supplier.name;
//         departureTransferUnits = transferUnits;
//         departureTransferUnitPrice = transferUnitPrice;
//         departureTransferSaleTotal = transferUnits * transferUnitPrice;
//       } else if (currentBooking.rateName === 'fullBoardNomad') {
//         departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
//         departureTransferSupplierName = 'Nomad Tanzania';
//         departureTransferUnits = 1;
//         departureTransferUnitPrice = 0;
//         departureTransferSaleTotal = 0;
//       } else {
//         departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
//         departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
//         departureTransferUnits = 1;
//         departureTransferUnitPrice = 0;
//         departureTransferSaleTotal = 0;
//       }
//       // const transferDate = transfers[transferIndex].endDay;
//       // console.log('rates: property departure');
//       // console.log('transfer date: ' + transferDate);
//       // console.log(JSON.stringify(transferRoute, undefined, 4));
//       // console.log('origin: ' + origin);
//       // console.log('destination: ' + destination);
//     }
//     //}
//   }

//   // arrival transfer
//   const includeArrivalTransfer = e.target.elements.includeArrivalTransfer ? e.target.elements.includeArrivalTransfer.checked : null;

//   let arrivalTransferSupplierName;
//   let arrivalTransferSupplierUid;
//   let arrivalTransferUnits;
//   let arrivalTransferUnitPrice;
//   let arrivalTransferSaleTotal;

//   if (includeArrivalTransfer !== null) {
//     let propUid = nextPropUid;
//     if (transfers[transferIndex].objType === 'arrival') {
//       propUid = currentPropUid;
//     }

//     // if (properties[getPropObj(propUid, properties)].handlesOwnAirportTransfer) {
//     //   arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
//     //   arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
//     //   arrivalTransferUnits = 1;
//     //   arrivalTransferUnitPrice = 0;
//     //   arrivalTransferSaleTotal = 0;
//     //   console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(propUid, properties)].name);
//     // } else {
//     let origin;
//     let destination;
//     if (transfers[transferIndex].objType === 'arrival') {
//       console.log('rates: arrival');
//       if (directMultiple === 'Multiple') {
//         const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetailsSecond.tocode));
//         origin = originObject.park.id;
//       } else {
//         const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
//         origin = originObject.park.id;
//       }
//       // const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
//       // origin = originObject.park.id;
//       const destinationObject = findCustomLocation(customLocations, currentPropUid);
//       destination = destinationObject.parkId;
//       console.log('origin: ' + origin);
//       console.log('destination: ' + destination);

//       const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
//       console.log('transferRoute arrival objType arrival 1: ', JSON.stringify(transferRoute, null, 2));
//       if (!transferRoute) {
//         toast.error('Could not find transfer route. Please contact support.');
//         return;
//       }
//       const rates = transferRoute.rates;
//       const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].startDay, transfers[transferIndex], properties, currentBooking, 'arrival');
//       if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
//         const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
//         const transferUnitPrice = rateObject.stoPrice;
//         arrivalTransferSupplierUid = rateObject.supplier.id;
//         arrivalTransferSupplierName = rateObject.supplier.name;
//         arrivalTransferUnits = transferUnits;
//         arrivalTransferUnitPrice = transferUnitPrice;
//         arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
//       } else if (currentBooking.rateName === 'fullBoardNomad') {
//         arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
//         arrivalTransferSupplierName = 'Nomad Tanzania';
//         arrivalTransferUnits = 1;
//         arrivalTransferUnitPrice = 0;
//         arrivalTransferSaleTotal = 0;
//       } else {
//         arrivalTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
//         arrivalTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
//         arrivalTransferUnits = 1;
//         arrivalTransferUnitPrice = 0;
//         arrivalTransferSaleTotal = 0;
//       }
//       const transferDate = transfers[transferIndex].startDay;
//       console.log('rates: arrival');
//       console.log('transfer date: ', transferDate);
//       console.log(JSON.stringify(transferRoute, undefined, 4));
//       console.log('origin: ' + origin);
//       console.log('destination: ' + destination);
//     } else {
//       console.log('rates: arrival property');
//       // const originObject = findCustomLocation(customLocations, currentPropUid);
//       // origin = originObject.parkId;
//       //const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));

//       if (directMultiple === 'Multiple') {
//         const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetailsSecond.tocode));
//         origin = arrivalObject.park.id;
//       } else {
//         const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
//         origin = arrivalObject.park.id;
//         console.log('arrivalObject: ' + JSON.stringify(arrivalObject, null, 2));
//       }

//       //origin = arrivalObject.park.id;
//       const destinationObject = findCustomLocation(customLocations, nextPropUid);
//       destination = destinationObject.parkId;
//       console.log('origin: ' + origin);
//       console.log('destination: ' + destination);

//       // CHECK ALL CODE!
//       const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
//       console.log('transferRoute arrival objType arrival 2: ', JSON.stringify(transferRoute, null, 2));
//       if (!transferRoute) {
//         toast.error('Could not find transfer route. Please contact support.');
//         return;
//       }
//       const rates = transferRoute.rates;
//       const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, nextBooking, 'arrival');
//       if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
//         const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
//         const transferUnitPrice = rateObject.stoPrice;

//         arrivalTransferSupplierUid = rateObject.supplier.id;
//         arrivalTransferSupplierName = rateObject.supplier.name;
//         arrivalTransferUnits = transferUnits;
//         arrivalTransferUnitPrice = transferUnitPrice;
//         arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
//       } else if (nextBooking.rateName === 'fullBoardNomad') {
//         arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
//         arrivalTransferSupplierName = 'Nomad Tanzania';
//         arrivalTransferUnits = 1;
//         arrivalTransferUnitPrice = 0;
//         arrivalTransferSaleTotal = 0;
//       } else {
//         arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
//         arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
//         arrivalTransferUnits = 1;
//         arrivalTransferUnitPrice = 0;
//         arrivalTransferSaleTotal = 0;
//       }
//       // const transferDate = transfers[transferIndex].startDay;
//       // console.log('rates: arrival property');
//       // console.log('transfer date: ', transferDate);
//       // console.log(JSON.stringify(transferRoute, undefined, 4));
//       // console.log('origin: ' + origin);
//       // console.log('destination: ' + destination);
//     }
//     //}
//   }

//   // deal with arrival and departure transfers - END --------------------------------------

//   const packedLunch = e.target.elements.packedLunchCheck.checked;
//   const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;

//   // PACKED LUNCH MODIFICATION
//   //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
//   const booking = findBookingById(bookings, transfers[transferIndex].uuid);
//   const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);

//   //console.log('FLIGHTDETAILS', JSON.stringify(flightDetails, undefined, 4));

//   // Function to remove classes and flightArray properties
//   const shortenFlightDetails = (details) => {
//     const { classes, flightsArray, ...simplifiedDetails } = details;
//     return simplifiedDetails;
//   };

//   const shortFlightDetails = shortenFlightDetails(flightDetails);

//   let shortFlightDetailsSecond;
//   if (directMultiple === 'Multiple') {
//     shortFlightDetailsSecond = shortenFlightDetails(flightDetailsSecond);
//   }

//   const formValues = {
//     time: moment(flightDetails.STD).format('HH:mm'),
//     type: formName,
//     ...(includeDepartureTransfer !== null
//       ? {
//           includeDepartureTransfer,
//           departureTransferSupplierName,
//           departureTransferSupplierUid,
//           departureTransferUnits, // figure out calculation
//           departureTransferUnitPrice,
//           departureTransferSaleTotal,
//         }
//       : {}),
//     ...(includeArrivalTransfer !== null
//       ? {
//           includeArrivalTransfer,
//           arrivalTransferSupplierName,
//           arrivalTransferSupplierUid,
//           arrivalTransferUnits, // figure out calculation
//           arrivalTransferUnitPrice,
//           arrivalTransferSaleTotal,
//         }
//       : {}),
//     ...(includeDepartureTransferVip !== false
//       ? {
//         includeDepartureVip: includeDepartureTransferVip,
//           departureSupplierNameVip,
//           departureSupplierUidVip,
//           departureUnitsVip, // figure out calculation
//           departurePerGroupPriceVip,
//           departurePerPersonPriceVip,
//           departureSaleTotalVip,
//           vuuid: uuidv4(),
//         }
//       : {}),
//     ...(includeArrivalTransferVip !== false
//       ? {
//         includeArrivalVip: includeArrivalTransferVip,
//           arrivalSupplierNameVip,
//           arrivalSupplierUidVip,
//           arrivalUnitsVip, // figure out calculation
//           arrivalPerGroupPriceVip,
//           arrivalPerPersonPriceVip,
//           arrivalSaleTotalVip,
//           vuuid: uuidv4(),
//         }
//       : {}),
//     adults,
//     children,
//     infants,
//     totalAllPax: adults + children + infants,
//     // flightCost,
//     // flightRackCost,
//     airlineName,
//     flightDetails: shortFlightDetails,
//     flightPricing,
//     saleTotal: directMultiple === 'Multiple' ? flightPricingSecond.saleFare.total + flightPricing.saleFare.total : flightPricing.saleFare.total,
//     dateAdjusted,
//     packedLunch,
//     packedLunchChargeable,
//     packedLunchUnits: processPackedLunchResults.units,
//     packedLunchUnitPrice: processPackedLunchResults.unitPrice,
//     packedLunchSaleTotal: processPackedLunchResults.saleTotal,
//     // new vars
//     connectionType,
//     connectionClick,
//     locationName,
//     locationUid,
//     supplierName,
//     supplierUid,
//     airlineUid,
//     airlineNameFlight,
//     fltNumFlight,
//     timeFlight,
//     addFlightDetailsLater,
//     ...(arrivalTime && arrivalTime !== 'Select time' ? { arrivalTime } : {}),
//     ...(directMultiple === 'Multiple' ? { airlineNameSecond } : {}),
//     ...(directMultiple === 'Multiple' ? { flightDetailsSecond: shortFlightDetailsSecond } : {}),
//     ...(directMultiple === 'Multiple' ? { flightPricingSecond } : {}),
//     detailsNow,
//     flightType: directMultiple,
//   };

//   //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

//   if (transferIndex !== -1) {
//     const updatedTransfer = {
//       ...transfers[transferIndex],
//       formValues,
//       edit: false,
//       isInvalid: false,
//     };

//     const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];

//     setTransfers(updatedTransfers); // Update the transfers state
//     // if (formType === "propertyTransfer" || formType === "departure") {
//     //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
//     // }
//     bookings = filterVehicleRoomsNoSet(bookings);
//     updatedTransfers.forEach(async (item) => {
//       await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
//       console.log(item.tuuid);
//     });
//   } else {
//     console.log('FLIGHT ERROR');
//     console.log('tuuid: ' + tuuid);
//     console.log('formType: ' + formType);
//   }
//   //setRefreshTransferRates(true);

//   onClose();
//   e.target.reset();
// };
const handleFormSubmitAirport = async (e, transfers, setTransfers, onClose, setRefreshTransferRates, bookings, setBookings, properties, customLocations, airports) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const formType = formData.get('formType');
  const tuuid = formData.get('tuuid');
  // const adults = formData.get("flightAdults");
  // const children = formData.get("flightChildren");
  // const infants = formData.get("flightInfants");
  const adults = parseInt(formData.get('flightAdults'), 10);
  const children = parseInt(formData.get('flightChildren'), 10);
  const infants = parseInt(formData.get('flightInfants'), 10);
  // const flightCost = parseInt(formData.get("flightCost"), 10);
  // const flightRackCost = parseInt(formData.get("flightRackCost"), 10);
  const airlineName = formData.get('airlineName');

  let arrivalTime = 'Select time';
  // const addFlightDetailsLater = formData.get('addFlightDetailsLater') === 'false' || formData.get('addFlightDetailsLater') === null ? false : true;

  let detailsNow = formData.get('detailsNow') === false || formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;

  if (formData.get('detailsNow')) {
    // detailsNow = formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;
  }
  //return alert('detailsNow: ' + detailsNow);

  if (formType === 'propertyTransfer') {
    arrivalTime = formData.get('arrivalTime');
    if (!arrivalTime || arrivalTime === undefined || arrivalTime === 'Select time') {
      // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
      return toast.error(
        // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
        `You must select what time you will arrive.`,
      );
    }
  }

  const dateAdjusted = formData.get('dateAdjusted');
  let flightPricing;

  try {
    flightPricing = JSON.parse(formData.get('flightPricing'));
    console.log('flightPricing value is: ');
    console.log(JSON.stringify(flightPricing, undefined, 4));
  } catch (error) {
    console.error('Error parsing flightPricing', error);
  }

  // Flight info
  const flightDetailsJSON = formData.get('flightDetails');
  const flightDetails = JSON.parse(flightDetailsJSON);
  const formName = formData.get('formName');

  //flight info second
  const directMultiple = formData.get('directMultiple');
  const airlineNameSecond = formData.get('airlineNameSecond');
  const flightDetailsSecondJSON = formData.get('flightDetailsSecond');
  const flightDetailsSecond = flightDetailsSecondJSON ? JSON.parse(flightDetailsSecondJSON) : null;
  let flightPricingSecond;

  try {
    flightPricingSecond = JSON.parse(formData.get('flightPricingSecond'));
    console.log('flightPricingSecond value is: ');
    console.log(JSON.stringify(flightPricingSecond, undefined, 4));
  } catch (error) {
    console.error('Error parsing flightPricingSecond', error);
  }

  // new data for supplier etc
  const connectionType = formData.get('connectionType');
  // const connectionType = detailsNow ? '' : formData.get('connectionType');
  const connectionClick = formData.get('connectionClick') === 'false' || formData.get('connectionClick') === null || formData.get('connectionClick') === '' ? false : true;
  // alert('detailsNow: ' + formData.get('detailsNow'));
  // alert('connectionClick: ' + formData.get('connectionClick'));

  const locationName = formData.get('locationName');
  const locationUid = formData.get('locationUid');

  const supplierName = formData.get('supplierName');
  const supplierUid = formData.get('supplierUid');

  const airlineUid = formData.get('airlineUidFlight');
  const airlineNameFlight = formData.get('airlineNameFlight');
  const fltNumFlight = formData.get('fltNum');
  const timeFlight = formData.get('timeFlightFlight');

  const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

  console.log('transferIndex: ' + transferIndex);

  let addFlightDetailsLater;

  //if (connectionType === 'flight' && detailsNow && !addFlightDetailsLater && (airlineNameFlight === '' || fltNumFlight === '' || timeFlight === 'Select time')) {
  if (connectionType === 'flight' && detailsNow && (airlineNameFlight === '' || fltNumFlight === '' || timeFlight === 'Select time')) {
    // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
    addFlightDetailsLater = true;
    // return toast.error(
    //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
    //   //`You must select all flight detail or tick to add later`,
    //   `You must select all flight detail or unselect 'Add details now'`,
    // );
  } else if (connectionType === 'road' && detailsNow && (supplierName === '' || supplierUid === '' || locationUid === '')) {
    // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
    addFlightDetailsLater = true;
    // return toast.error(
    //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
    //   //`You must select all road transfer details or tick to add later`,
    //   `You must select all road transfer details or unselect 'Add details now'`,
    // );
  } else {
    addFlightDetailsLater = false;
    //alert('addFlightDetailsLater 2: ' + addFlightDetailsLater + ' connectionType: ' + connectionType + ' locationUid: ' + locationUid);
  }

  // deal with arrival and departure transfers - START --------------------------------------
  const includeDepartureTransfer = e.target.elements.includeDepartureTransfer && e.target.elements.includeDepartureTransfer.checked ? e.target.elements.includeDepartureTransfer.checked : null;
  let departureTransferSupplierName;
  let departureTransferSupplierUid;
  let departureTransferUnits;
  let departureTransferUnitPrice;
  let departureTransferSaleTotal;

  const currentBooking = findBookingById(bookings, transfers[transferIndex].uuid);
  const currentBookingIndex = bookings.findIndex((booking) => booking.id === currentBooking.id);
  const currentPropUid = currentBooking.propUid;

  let nextBooking = null;
  let nextPropUid = null;
  if (currentBookingIndex !== bookings.length - 1) {
    nextBooking = bookings[currentBookingIndex + 1];
    nextPropUid = nextBooking.propUid;
  }

  // departure transfer
  if (includeDepartureTransfer !== null) {
    // if (properties[getPropObj(currentPropUid, properties)].handlesOwnAirportTransfer) {
    //   departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
    //   departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
    //   departureTransferUnits = 1;
    //   departureTransferUnitPrice = 0;
    //   departureTransferSaleTotal = 0;
    //   console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(currentPropUid, properties)].name);
    // } else {
    // let propUid = nextPropUid;
    let origin;
    let destination;
    if (transfers[transferIndex].objType === 'departure') {
      console.log('rates: departure');
      const originObject = findCustomLocation(customLocations, currentPropUid);
      origin = originObject.parkId;
      const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.fromcode));
      destination = destinationObject.park.id;
      console.log('origin: ' + origin);
      console.log('destination: ' + destination);

      const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
      console.log('transferRoute departure objType departure 1: ', JSON.stringify(transferRoute, null, 2));
      if (!transferRoute) {
        toast.error('Could not find transfer route. Please contact support.');
        return;
      }
      const rates = transferRoute.rates;
      const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, currentBooking, 'departure');

      if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
        const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
        const transferUnitPrice = rateObject.stoPrice;

        departureTransferSupplierUid = rateObject.supplier.id;
        departureTransferSupplierName = rateObject.supplier.name;
        departureTransferUnits = transferUnits;
        departureTransferUnitPrice = transferUnitPrice;
        departureTransferSaleTotal = transferUnits * transferUnitPrice;
      } else if (currentBooking.rateName === 'fullBoardNomad') {
        departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        departureTransferSupplierName = 'Nomad Tanzania';
        departureTransferUnits = 1;
        departureTransferUnitPrice = 0;
        departureTransferSaleTotal = 0;
      } else {
        departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
        departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
        departureTransferUnits = 1;
        departureTransferUnitPrice = 0;
        departureTransferSaleTotal = 0;
      }
      // const transferDate = transfers[transferIndex].endDay;
      // console.log('rates: departure');
      // console.log('transfer date: ' + transferDate);
      // console.log(JSON.stringify(transferDate, undefined, 4));
      // console.log('origin: ' + origin);
      // console.log('destination: ' + destination);
    } else {
      console.log('rates: departure property');
      const originObject = findCustomLocation(customLocations, currentPropUid);
      origin = originObject.parkId;
      // const destinationObject = findCustomLocation(customLocations, nextPropUid);
      // destination = destinationObject.parkId;
      const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.fromcode));
      destination = destinationObject.park.id;
      console.log('origin: ' + origin);
      console.log('destination: ' + destination);
      // CHECK ALL CODE!
      const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
      console.log('transferRoute departure objType property 2: ', JSON.stringify(transferRoute, null, 2));
      console.log('transferRoute departure objType departure 1: ', JSON.stringify(transferRoute, null, 2));
      if (!transferRoute) {
        toast.error('Could not find transfer route. Please contact support.');
        return;
      }
      const rates = transferRoute.rates;
      console.log('rates: ', JSON.stringify(rates, null, 2));
      const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, currentBooking, 'departure');
      if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
        const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
        const transferUnitPrice = rateObject.stoPrice;
        departureTransferSupplierUid = rateObject.supplier.id;
        departureTransferSupplierName = rateObject.supplier.name;
        departureTransferUnits = transferUnits;
        departureTransferUnitPrice = transferUnitPrice;
        departureTransferSaleTotal = transferUnits * transferUnitPrice;
      } else if (currentBooking.rateName === 'fullBoardNomad') {
        departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        departureTransferSupplierName = 'Nomad Tanzania';
        departureTransferUnits = 1;
        departureTransferUnitPrice = 0;
        departureTransferSaleTotal = 0;
      } else {
        departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
        departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
        departureTransferUnits = 1;
        departureTransferUnitPrice = 0;
        departureTransferSaleTotal = 0;
      }
      // const transferDate = transfers[transferIndex].endDay;
      // console.log('rates: property departure');
      // console.log('transfer date: ' + transferDate);
      // console.log(JSON.stringify(transferRoute, undefined, 4));
      // console.log('origin: ' + origin);
      // console.log('destination: ' + destination);
    }
    //}
  }

  // arrival transfer
  const includeArrivalTransfer = e.target.elements.includeArrivalTransfer ? e.target.elements.includeArrivalTransfer.checked : null;
  let arrivalTransferSupplierName;
  let arrivalTransferSupplierUid;
  let arrivalTransferUnits;
  let arrivalTransferUnitPrice;
  let arrivalTransferSaleTotal;

  if (includeArrivalTransfer !== null) {
    let propUid = nextPropUid;
    if (transfers[transferIndex].objType === 'arrival') {
      propUid = currentPropUid;
    }

    // if (properties[getPropObj(propUid, properties)].handlesOwnAirportTransfer) {
    //   arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
    //   arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
    //   arrivalTransferUnits = 1;
    //   arrivalTransferUnitPrice = 0;
    //   arrivalTransferSaleTotal = 0;
    //   console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(propUid, properties)].name);
    // } else {
    let origin;
    let destination;
    if (transfers[transferIndex].objType === 'arrival') {
      console.log('rates: arrival');
      if (directMultiple === 'Multiple') {
        const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetailsSecond.tocode));
        origin = originObject.park.id;
      } else {
        const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
        origin = originObject.park.id;
      }
      // const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
      // origin = originObject.park.id;
      const destinationObject = findCustomLocation(customLocations, currentPropUid);
      destination = destinationObject.parkId;
      console.log('origin: ' + origin);
      console.log('destination: ' + destination);

      const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
      console.log('transferRoute arrival objType arrival 1: ', JSON.stringify(transferRoute, null, 2));
      console.log('transferRoute departure objType departure 1: ', JSON.stringify(transferRoute, null, 2));
      if (!transferRoute) {
        toast.error('Could not find transfer route. Please contact support.');
        return;
      }
      const rates = transferRoute.rates;
      const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].startDay, transfers[transferIndex], properties, currentBooking, 'arrival');
      console.log('raterObject arrival', JSON.stringify(rateObject, null, 2));
      if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
        const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
        const transferUnitPrice = rateObject.stoPrice;
        arrivalTransferSupplierUid = rateObject.supplier.id;
        arrivalTransferSupplierName = rateObject.supplier.name;
        arrivalTransferUnits = transferUnits;
        arrivalTransferUnitPrice = transferUnitPrice;
        arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
      } else if (currentBooking.rateName === 'fullBoardNomad') {
        arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        arrivalTransferSupplierName = 'Nomad Tanzania';
        arrivalTransferUnits = 1;
        arrivalTransferUnitPrice = 0;
        arrivalTransferSaleTotal = 0;
      } else {
        arrivalTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
        arrivalTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
        arrivalTransferUnits = 1;
        arrivalTransferUnitPrice = 0;
        arrivalTransferSaleTotal = 0;
      }
      const transferDate = transfers[transferIndex].startDay;
      console.log('rates: arrival');
      console.log('transfer date: ', transferDate);
      console.log(JSON.stringify(transferRoute, undefined, 4));
      console.log('origin: ' + origin);
      console.log('destination: ' + destination);
    } else {
      console.log('rates: arrival property');
      // const originObject = findCustomLocation(customLocations, currentPropUid);
      // origin = originObject.parkId;
      //const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));

      if (directMultiple === 'Multiple') {
        const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetailsSecond.tocode));
        origin = arrivalObject.park.id;
      } else {
        const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(flightDetails.tocode));
        origin = arrivalObject.park.id;
        console.log('arrivalObject: ' + JSON.stringify(arrivalObject, null, 2));
      }

      //origin = arrivalObject.park.id;
      const destinationObject = findCustomLocation(customLocations, nextPropUid);
      destination = destinationObject.parkId;
      console.log('origin: ' + origin);
      console.log('destination: ' + destination);

      // CHECK ALL CODE!
      const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
      console.log('transferRoute arrival objType property 2: ', JSON.stringify(transferRoute, null, 2));
      console.log('transferRoute departure objType departure 1: ', JSON.stringify(transferRoute, null, 2));
      if (!transferRoute) {
        toast.error('Could not find transfer route. Please contact support.');
        return;
      }
      const rates = transferRoute.rates;
      const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, nextBooking, 'arrival');
      console.log('raterObject arrival', JSON.stringify(rateObject, null, 2));
      if (transferRoute && transferRoute.rates.length > 0 && rateObject) {
        const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
        const transferUnitPrice = rateObject.stoPrice;

        arrivalTransferSupplierUid = rateObject.supplier.id;
        arrivalTransferSupplierName = rateObject.supplier.name;
        arrivalTransferUnits = transferUnits;
        arrivalTransferUnitPrice = transferUnitPrice;
        arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
      } else if (nextBooking.rateName === 'fullBoardNomad') {
        arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
        arrivalTransferSupplierName = 'Nomad Tanzania';
        arrivalTransferUnits = 1;
        arrivalTransferUnitPrice = 0;
        arrivalTransferSaleTotal = 0;
      } else {
        arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
        arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
        arrivalTransferUnits = 1;
        arrivalTransferUnitPrice = 0;
        arrivalTransferSaleTotal = 0;
      }
      // const transferDate = transfers[transferIndex].startDay;
      // console.log('rates: arrival property');
      // console.log('transfer date: ', transferDate);
      // console.log(JSON.stringify(transferRoute, undefined, 4));
      // console.log('origin: ' + origin);
      // console.log('destination: ' + destination);
    }
    //}
  }

  // deal with arrival and departure transfers - END --------------------------------------




  // VIP start
  let vipObject;
  let vipObjectArrival;
  let vipObjectDeparture;
  let includeArrivalTransferVip = false;
  let includeDepartureTransferVip = false;

  let arrivalSupplierNameVip;
  let arrivalSupplierUidVip;
  let arrivalUnitsVip;
  let arrivalPerGroupPriceVip;
  let arrivalPerPersonPriceVip;
  let arrivalSaleTotalVip;

  let departureSupplierNameVip;
  let departureSupplierUidVip;
  let departureUnitsVip;
  let departurePerGroupPriceVip;
  let departurePerPersonPriceVip;
  let departureSaleTotalVip;



  const vipObjectJSON = formData.get('vipObject');
  vipObject = vipObjectJSON ? JSON.parse(vipObjectJSON) : null;

  const vipObjectArrivalJSON = formData.get('vipObjectArrival');
  vipObjectArrival = vipObjectArrivalJSON ? JSON.parse(vipObjectArrivalJSON) : null;

  const vipObjectDepartureJSON = formData.get('vipObjectDeparture');
  vipObjectDeparture = vipObjectDepartureJSON ? JSON.parse(vipObjectDepartureJSON) : null;

  if (transferIndex !== -1) {
    if (transfers[transferIndex].objType === 'arrival') {
      includeArrivalTransferVip =
        formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
      if (includeArrivalTransferVip) {
        //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
        arrivalSupplierNameVip = vipObject.supplier.displayName;
        arrivalSupplierUidVip = vipObject.supplier.id;
        arrivalUnitsVip = adults + children + infants;
        arrivalPerGroupPriceVip = vipObject.supplier.perGroupPrice;
        arrivalPerPersonPriceVip = vipObject.supplier.perPersonPrice;
        arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
      }
    } else if (transfers[transferIndex].objType === 'departure') {
      includeDepartureTransferVip =
        formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
      if (includeDepartureTransferVip) {
        //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
        departureSupplierNameVip = vipObject.supplier.displayName;
        departureSupplierUidVip = vipObject.supplier.id;
        departureUnitsVip = adults + children + infants;
        departurePerGroupPriceVip = vipObject.supplier.perGroupPrice;
        departurePerPersonPriceVip = vipObject.supplier.perPersonPrice;
        departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
      }
    } else if (transfers[transferIndex].objType === 'propertyTransfer') {
      includeArrivalTransferVip =
        formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
      if (includeArrivalTransferVip) {
        //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
        arrivalSupplierNameVip = vipObjectArrival.supplier.displayName;
        arrivalSupplierUidVip = vipObjectArrival.supplier.id;
        arrivalUnitsVip = adults + children + infants;
        arrivalPerGroupPriceVip = vipObjectArrival.supplier.perGroupPrice;
        arrivalPerPersonPriceVip = vipObjectArrival.supplier.perPersonPrice;
        arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
      }
      includeDepartureTransferVip =
        formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
      if (includeDepartureTransferVip) {
        //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
        departureSupplierNameVip = vipObjectDeparture.supplier.displayName;
        departureSupplierUidVip = vipObjectDeparture.supplier.id;
        departureUnitsVip = adults + children + infants;
        departurePerGroupPriceVip = vipObjectDeparture.supplier.perGroupPrice;
        departurePerPersonPriceVip = vipObjectDeparture.supplier.perPersonPrice;
        departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
      }
    }
  }
  // VIP end



  const packedLunch = e.target.elements.packedLunchCheck.checked;
  const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;

  // PACKED LUNCH MODIFICATION
  //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  const booking = findBookingById(bookings, transfers[transferIndex].uuid);
  const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);

  //console.log('FLIGHTDETAILS', JSON.stringify(flightDetails, undefined, 4));

  // Function to remove classes and flightArray properties
  const shortenFlightDetails = (details) => {
    const { classes, flightsArray, ...simplifiedDetails } = details;
    return simplifiedDetails;
  };

  const shortFlightDetails = shortenFlightDetails(flightDetails);

  let shortFlightDetailsSecond;
  if (directMultiple === 'Multiple') {
    shortFlightDetailsSecond = shortenFlightDetails(flightDetailsSecond);
  }

  const formValues = {
    time: moment(flightDetails.STD).format('HH:mm'),
    type: formName,
    ...(includeDepartureTransfer !== null
      ? {
          includeDepartureTransfer,
          departureTransferSupplierName,
          departureTransferSupplierUid,
          departureTransferUnits, // figure out calculation
          departureTransferUnitPrice,
          departureTransferSaleTotal,
        }
      : {}),
    ...(includeArrivalTransfer !== null
      ? {
          includeArrivalTransfer,
          arrivalTransferSupplierName,
          arrivalTransferSupplierUid,
          arrivalTransferUnits, // figure out calculation
          arrivalTransferUnitPrice,
          arrivalTransferSaleTotal,
        }
      : {}),
          ...(includeDepartureTransferVip !== false
      ? {
        includeDepartureVip: includeDepartureTransferVip,
          departureSupplierNameVip,
          departureSupplierUidVip,
          departureUnitsVip, // figure out calculation
          departurePerGroupPriceVip,
          departurePerPersonPriceVip,
          departureSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
    ...(includeArrivalTransferVip !== false
      ? {
        includeArrivalVip: includeArrivalTransferVip,
          arrivalSupplierNameVip,
          arrivalSupplierUidVip,
          arrivalUnitsVip, // figure out calculation
          arrivalPerGroupPriceVip,
          arrivalPerPersonPriceVip,
          arrivalSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
    adults,
    children,
    infants,
    totalAllPax: adults + children + infants,
    // flightCost,
    // flightRackCost,
    airlineName,
    flightDetails: shortFlightDetails,
    flightPricing,
    saleTotal: directMultiple === 'Multiple' ? flightPricingSecond.saleFare.total + flightPricing.saleFare.total : flightPricing.saleFare.total,
    dateAdjusted,
    packedLunch,
    packedLunchChargeable,
    packedLunchUnits: processPackedLunchResults.units,
    packedLunchUnitPrice: processPackedLunchResults.unitPrice,
    packedLunchSaleTotal: processPackedLunchResults.saleTotal,
    // new vars
    connectionType,
    connectionClick,
    locationName,
    locationUid,
    supplierName,
    supplierUid,
    airlineUid,
    airlineNameFlight,
    fltNumFlight,
    timeFlight,
    addFlightDetailsLater,
    ...(arrivalTime && arrivalTime !== 'Select time' ? { arrivalTime } : {}),
    ...(directMultiple === 'Multiple' ? { airlineNameSecond } : {}),
    ...(directMultiple === 'Multiple' ? { flightDetailsSecond: shortFlightDetailsSecond } : {}),
    ...(directMultiple === 'Multiple' ? { flightPricingSecond } : {}),
    detailsNow,
    flightType: directMultiple,
  };

  //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

  if (transferIndex !== -1) {
    const updatedTransfer = {
      ...transfers[transferIndex],
      formValues,
      edit: false,
      isInvalid: false,
    };

    const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];

    setTransfers(updatedTransfers); // Update the transfers state
    // if (formType === "propertyTransfer" || formType === "departure") {
    //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
    // }
    bookings = filterVehicleRoomsNoSet(bookings);
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
  } else {
    console.log('FLIGHT ERROR');
    console.log('tuuid: ' + tuuid);
    console.log('formType: ' + formType);
  }
  //setRefreshTransferRates(true);

  onClose();
  e.target.reset();
};

// ! USED - CHECKED
// USED IN INTER FOR ROAD TRANSFER / safariTransfer
const handleFormSubmitSafari = async (e, transfers, setTransfers, onClose, bookings, setBookings, properties) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  const formType = formData.get('formType');
  const tuuid = formData.get('tuuid');
  const transferType = formData.get('transferType');
  const gameDriveTime = formData.get('gameDriveTime');
  const distanceMins = formData.get('distanceMins');
  const supplierJson = formData.get('supplier');
  const supplier = JSON.parse(supplierJson);

  const units = formData.get('units');
  const stoPriceCost = formData.get('stoPriceCost');
  const netRackCost = formData.get('netRackCost');
  const saleTotal = formData.get('saleTotal');
  const maxCapacity = formData.get('maxCapacity');
  const rateId = formData.get('rateId');
  const totalUnits = formData.get('totalUnits');
  const rateType = formData.get('rateType');

  let arrivalTime = 'Select time';

  if (formType === 'propertyTransfer') {
    arrivalTime = formData.get('arrivalTime');

    if (!arrivalTime || arrivalTime === 'Select time') {
      // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
      return toast.error(
        // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
        `You must select what time you will arrive.`,
      );
    }
  }

  const packedLunch = e.target.elements.packedLunchCheck.checked;
  const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;
  // PACKED LUNCH MODIFICATION
  const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  const booking = findBookingById(bookings, transfers[transferIndex].uuid);
  const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);

  const formValues = {
    type: 'safari',
    transferType: transferType,
    gameDriveTime: gameDriveTime,
    distanceMins: distanceMins,
    supplier: supplier.name === '' ? null : supplier.name,
    units: totalUnits,
    rateId: rateId,
    stoPriceCost: stoPriceCost,
    netRackCost: netRackCost,
    saleTotal: Number(saleTotal),
    maxCapacity: Number(maxCapacity),
    route: supplier.route,
    supplierName: supplier.name === '' ? null : supplier.name,
    supplierUid: supplier.id,
    packedLunch,
    packedLunchChargeable,
    packedLunchUnits: processPackedLunchResults.units,
    packedLunchUnitPrice: processPackedLunchResults.unitPrice,
    packedLunchSaleTotal: processPackedLunchResults.saleTotal,
    ...(arrivalTime && arrivalTime !== 'Select time' ? { arrivalTime } : {}),
    transferRouteUid: transfers[transferIndex].routeTransfer.transferRouteUid || null,
    rateType: rateType,
  };

  //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

  if (transferIndex !== -1) {
    const updatedTransfer = {
      ...transfers[transferIndex],
      formValues,
      edit: false,
      isInvalid: false,
    };

    const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];

    setTransfers(updatedTransfers); // Update the transfers state
    // if (formType === "propertyTransfer" || formType === "departure") {
    //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
    // }
    bookings = filterVehicleRoomsNoSet(bookings);
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
  } else {
    console.log('SAFARI ERROR');
    console.log('tuuid: ' + tuuid);
    console.log('formType: ' + formType);
  }

  onClose();
  e.target.reset();
};

// ! NOT USED
// const handleFormSubmitTown = (e, transfers, setTransfers, setIsModalOpen, bookings, setBookings, properties) => {
//   e.preventDefault();

//   const formData = new FormData(e.target);
//   const tuuid = formData.get('tuuid');
//   const formType = formData.get('formType');

//   const formValues = {
//     type: 'town',
//   };

//   console.log('tuuid handleFormSubmitTown: ' + tuuid);

//   const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

//   if (transferIndex !== -1) {
//     const updatedTransfer = {
//       ...transfers[transferIndex],
//       formValues,
//       edit: false,
//     };

//     const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];
//     //
//     setTransfers(updatedTransfers); // Update the transfers state
//     // if (formType === "propertyTransfer" || formType === "departure") {
//     //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
//     // }
//     bookings = filterVehicleRoomsNoSet(bookings);
//     updatedTransfers.forEach(async (item) => {
//       await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
//       console.log(item.tuuid);
//     });
//   } else {
//     console.log(formType + ' ERROR');
//     console.log('tuuid: ' + tuuid);
//     console.log('formType: ' + formType);
//   }

//   setIsModalOpen(false);
//   e.target.reset();
// };

// ! USED - CHECKED
// USED IN INTER FOR OWN ARRANGEMENTS / ownArrangements
const handleFormSubmitOwnArrangements = async (e, transfers, setTransfers, onClose, bookings, setBookings, properties, customLocations, airports) => {
  e.preventDefault();

  console.log('handleFormSubmitOwnArrangements 1');
  const formData = new FormData(e.target);
  const formType = formData.get('formType');
  const tuuid = formData.get('tuuid');

  const airportTransfer = formData.get('airportTransfer');

  const originAirportName = formData.get('originAirportName');
  const destinationAirportName = formData.get('destinationAirportName');
  const originAirportUid = formData.get('originAirportUid');
  const destinationAirportUid = formData.get('destinationAirportUid');
  const originAirportCode = formData.get('originAirportCode');
  const destinationAirportCode = formData.get('destinationAirportCode');
  const destinationLocationUid = formData.get('destinationLocationUid');
  const originLocationUid = formData.get('originLocationUid');
  const fltnum = formData.get('fltnum');
  const airlineUid = formData.get('airlineUid');
  const airlineName = formData.get('airlineName');
  const timeDeparture = formData.get('timeDeparture');
  const timeArrival = formData.get('timeArrival');
  const supplierName = formData.get('supplierName');
  const supplierUid = formData.get('supplierUid');
  let arrivalTime = 'Select time';
  let departureTime = 'Select time';

  const addLater = formData.get('addLater');

  if (formType === 'propertyTransfer') {
    arrivalTime = formData.get('arrivalTime');
    departureTime = formData.get('departureTime');
    // return;
    console.log('addLater: ' + addLater);
    if (
      (!arrivalTime || arrivalTime === undefined || arrivalTime === 'Select time' || !departureTime || departureTime === undefined || departureTime === 'Select time') &&
      addLater === 'false' &&
      airportTransfer === 'false'
    ) {
      // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
      return toast.error(
        // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
        `You must select what time you will depart, arrive and a supplier or select to add details later.`,
      );
    }
  }


  

  const includeDepartureTransfer = e.target.elements.includeDepartureTransfer ? e.target.elements.includeDepartureTransfer.checked : null;
  const includeArrivalTransfer = e.target.elements.includeArrivalTransfer ? e.target.elements.includeArrivalTransfer.checked : null;
  //const addDetailsLater = e.target.elements.addFlightDetailsLater && e.target.elements.addFlightDetailsLater.checked;
  const addDetailsLater = e.target.elements.addFlightDetailsLater ? e.target.elements.addFlightDetailsLater.checked : false;
  console.log('addDetailsLater debug: ' + addDetailsLater);
  console.log('timeDeparture: ' + timeDeparture);
  console.log('timeArrival: ' + timeArrival);
  //const capitalizedFormType = formType.charAt(0).toUpperCase() + formType.slice(1);
  //alert(airline);
  if (
    airportTransfer === 'true' &&
    !addDetailsLater &&
    (fltnum === '' || airlineUid === null || timeDeparture === 'Select time' || timeArrival === 'Select time' || timeDeparture === 'TBD' || timeArrival === 'TBD')
  ) {
    return toast.error(`You must enter a Flight Number, Airline, Departure time and Arrival time or select 'Add flight number, departure time and arrival time later'`);
  }

  const packedLunch = e.target.elements.packedLunchCheck.checked;
  const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;

  // PACKED LUNCH MODIFICATION
  const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  const booking = findBookingById(bookings, transfers[transferIndex].uuid);
  const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);

  

  // arrival and departure transfers

  let departureTransferSupplierName;
  let departureTransferSupplierUid;
  let departureTransferUnits;
  let departureTransferUnitPrice;
  let departureTransferSaleTotal;

  const currentBooking = findBookingById(bookings, transfers[transferIndex].uuid);
  const currentBookingIndex = bookings.findIndex((booking) => booking.id === currentBooking.id);
  const currentPropUid = currentBooking.propUid;

  let nextBooking = null;
  let nextPropUid = null;
  if (currentBookingIndex !== bookings.length - 1) {
    nextBooking = bookings[currentBookingIndex + 1];
    nextPropUid = nextBooking.propUid;
  }

  const [totalAd, totalCh] = getTotalAdCh(currentBooking);

  const adults = totalAd;
  const children = totalCh;
  const infants = 0;

  console.log('handleFormSubmitOwnArrangements includeArrivalTransfer', includeArrivalTransfer);
  console.log('handleFormSubmitOwnArrangements includeDepartureTransfer', includeDepartureTransfer);

  // departure transfer
  if (includeDepartureTransfer !== false) {
    if (properties[getPropObj(currentPropUid, properties)].handlesOwnAirportTransfer) {
      departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
      departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
      departureTransferUnits = 1;
      departureTransferUnitPrice = 0;
      departureTransferSaleTotal = 0;
      console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(currentPropUid, properties)].name);
    } else {
      // let propUid = nextPropUid;
      let origin;
      let destination;
      if (transfers[transferIndex].objType === 'departure') {
        console.log('rates: departure');
        const originObject = findCustomLocation(customLocations, currentPropUid);
        origin = originObject.parkId;
        //const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(originAirportCode));
        const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(originAirportCode));
        destination = destinationObject.park.id;
        console.log('origin departure: ' + origin);
        console.log('destination departure: ' + destination);

        //const transferRoute = await loadTransferRouteTownTransfer(origin, destination);
        const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
        const rates = transferRoute.rates;
        //const rateObject = findTransferRate(rates, transfers[transferIndex].endDay);
        const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, currentBooking, 'departure');
        if (transferRoute !== null && rateObject !== null) {


          const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
          const transferUnitPrice = rateObject.stoPrice;

          departureTransferSupplierUid = rateObject.supplier.id;
          departureTransferSupplierName = rateObject.supplier.name;
          departureTransferUnits = transferUnits;
          departureTransferUnitPrice = transferUnitPrice;
          departureTransferSaleTotal = transferUnits * transferUnitPrice;
        } else if (currentBooking.rateName === 'fullBoardNomad') {
          departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
          departureTransferSupplierName = 'Nomad Tanzania';
          departureTransferUnits = 1;
          departureTransferUnitPrice = 0;
          departureTransferSaleTotal = 0;
        } else {
          departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
          departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
          departureTransferUnits = 1;
          departureTransferUnitPrice = 0;
          departureTransferSaleTotal = 0;
        }
        // const transferDate = transfers[transferIndex].endDay;
        // console.log('rates: departure');
        // console.log('transfer date: ' + transferDate);
        // console.log(JSON.stringify(transferDate, undefined, 4));
        // console.log('origin: ' + origin);
        // console.log('destination: ' + destination);
      } else {
        console.log('rates: departure property');
        const originObject = findCustomLocation(customLocations, currentPropUid);
        origin = originObject.parkId;
        // const destinationObject = findCustomLocation(customLocations, nextPropUid);
        // destination = destinationObject.parkId;
        //const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(originAirportCode));
        const destinationObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(originAirportCode));
        console.log('originAirportCode property: ' + originAirportCode);
        destination = destinationObject.park.id;

        console.log('origin departure property: ' + origin);
        console.log('destination departure property: ' + destination);

        console.log('destinationObject:', JSON.stringify(destinationObject, null, 2));

        // CHECK ALL CODE!
        //const transferRoute = await loadTransferRouteTownTransfer(origin, destination);
        const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
        console.log('transfer route maxcapacity 1', JSON.stringify(transferRoute, null, 2));
        const rates = transferRoute.rates;
        //const rateObject = findTransferRate(rates, transfers[transferIndex].endDay);
        const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, nextBooking, 'departure');
        console.log('raterObject departure', JSON.stringify(rateObject, null, 2));
        if (transferRoute !== null && rateObject !== null) {
          const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
          const transferUnitPrice = rateObject.stoPrice;

          departureTransferSupplierUid = rateObject.supplier.id;
          departureTransferSupplierName = rateObject.supplier.name;
          departureTransferUnits = transferUnits;
          departureTransferUnitPrice = transferUnitPrice;
          departureTransferSaleTotal = transferUnits * transferUnitPrice;
        } else if (currentBooking.rateName === 'fullBoardNomad') {
          departureTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
          departureTransferSupplierName = 'Nomad Tanzania';
          departureTransferUnits = 1;
          departureTransferUnitPrice = 0;
          departureTransferSaleTotal = 0;
        } else {
          departureTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
          departureTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
          departureTransferUnits = 1;
          departureTransferUnitPrice = 0;
          departureTransferSaleTotal = 0;
        }
        // const transferDate = transfers[transferIndex].endDay;
        // console.log('rates: property departure');
        // console.log('transfer date: ' + transferDate);
        // console.log(JSON.stringify(transferRoute, undefined, 4));
        // console.log('origin: ' + origin);
        // console.log('destination: ' + destination);
      }
    }
  }

  // arrival transfer
  // const includeArrivalTransfer = e.target.elements.includeArrivalTransfer ? e.target.elements.includeArrivalTransfer.checked : null;
  let arrivalTransferSupplierName;
  let arrivalTransferSupplierUid;
  let arrivalTransferUnits;
  let arrivalTransferUnitPrice;
  let arrivalTransferSaleTotal;

  if (includeArrivalTransfer !== false) {
    let propUid = nextPropUid;
    if (transfers[transferIndex].objType === 'arrival') {
      propUid = currentPropUid;
    }

    if (properties[getPropObj(propUid, properties)].handlesOwnAirportTransfer) {
      arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
      arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
      arrivalTransferUnits = 1;
      arrivalTransferUnitPrice = 0;
      arrivalTransferSaleTotal = 0;
      console.log('rates: handlesOwnAirportTransfer ', properties[getPropObj(propUid, properties)].name);
    } else {
      let origin;
      let destination;
      if (transfers[transferIndex].objType === 'arrival') {
        console.log('rates: arrival');
        //const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(destinationAirportCode));
        const originObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(destinationAirportCode));
        origin = originObject.park.id;
        const destinationObject = findCustomLocation(customLocations, currentPropUid);
        destination = destinationObject.parkId;

        //const transferRoute = await loadTransferRouteTownTransfer(origin, destination);
        const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);

        // console.log('transferRoute: arrival');
        // console.log(JSON.stringify(transferRoute, undefined, 4));
        const rates = transferRoute.rates;
        const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].startDay, transfers[transferIndex], properties, currentBooking, 'arrival');
        console.log('raterObject departure', JSON.stringify(rateObject, null, 2));
        if (transferRoute !== null && rateObject !== null) {

          //const rateObject = findTransferRate(rates, transfers[transferIndex].startDay);
          const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
          const transferUnitPrice = rateObject.stoPrice;

          arrivalTransferSupplierUid = rateObject.supplier.id;
          arrivalTransferSupplierName = rateObject.supplier.name;
          arrivalTransferUnits = transferUnits;
          arrivalTransferUnitPrice = transferUnitPrice;
          arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
        } else if (currentBooking.rateName === 'fullBoardNomad') {
          arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
          arrivalTransferSupplierName = 'Nomad Tanzania';
          arrivalTransferUnits = 1;
          arrivalTransferUnitPrice = 0;
          arrivalTransferSaleTotal = 0;
        } else {
          arrivalTransferSupplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
          arrivalTransferSupplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
          arrivalTransferUnits = 1;
          arrivalTransferUnitPrice = 0;
          arrivalTransferSaleTotal = 0;
        }
        const transferDate = transfers[transferIndex].startDay;
        console.log('rates: arrival');
        console.log('transfer date: ', transferDate);
        console.log(JSON.stringify(transferRoute, undefined, 4));
        console.log('origin: ' + origin);
        console.log('destination: ' + destination);
      } else {
        console.log('rates: arrival property');
        // const originObject = findCustomLocation(customLocations, currentPropUid);
        // origin = originObject.parkId;
        //const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(destinationAirportCode));
        const arrivalObject = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(destinationAirportCode));
        console.log('destinationAirportCode property: ' + destinationAirportCode);
        origin = arrivalObject.park.id;

        console.log('arrivalObject arrival property:', JSON.stringify(arrivalObject, null, 2));


        const destinationObject = findCustomLocation(customLocations, nextPropUid);
        destination = destinationObject.parkId;

        console.log('destinationObject arrival property:', JSON.stringify(destinationObject, null, 2));

        console.log('origin: ' + origin);
        console.log('destination: ' + destination);

        // CHECK ALL CODE!
        //const transferRoute = await loadTransferRouteTownTransfer(origin, destination);
        const transferRoute = await loadTransferRouteAirportTransfers(origin, destination);
        console.log('transferRoute: inter property');
        console.log(JSON.stringify(transferRoute, undefined, 4));
        const rates = transferRoute.rates;
        //const rateObject = findTransferRate(rates, transfers[transferIndex].endDay);
        const rateObject = findTransferRateAirportTransfers(rates, transfers[transferIndex].endDay, transfers[transferIndex], properties, nextBooking, 'arrival');
        console.log('raterObject arrival', JSON.stringify(rateObject, null, 2));
        if (transferRoute !== null && rateObject !== null) {

          const transferUnits = calculateRateTransferUnits(adults, children, infants, rateObject.maxCapacity);
          const transferUnitPrice = rateObject.stoPrice;

          arrivalTransferSupplierUid = rateObject.supplier.id;
          arrivalTransferSupplierName = rateObject.supplier.name;
          arrivalTransferUnits = transferUnits;
          arrivalTransferUnitPrice = transferUnitPrice;
          arrivalTransferSaleTotal = transferUnits * transferUnitPrice;
        } else if (nextBooking.rateName === 'fullBoardNomad') {
          arrivalTransferSupplierUid = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
          arrivalTransferSupplierName = 'Nomad Tanzania';
          arrivalTransferUnits = 1;
          arrivalTransferUnitPrice = 0;
          arrivalTransferSaleTotal = 0;
        } else {
          console.log('transferRoute: inter property NULL', origin, destination);
          arrivalTransferSupplierUid = properties[getPropObj(propUid, properties)].supplier.id;
          arrivalTransferSupplierName = properties[getPropObj(propUid, properties)].supplier.name;
          arrivalTransferUnits = 1;
          arrivalTransferUnitPrice = 0;
          arrivalTransferSaleTotal = 0;
        }
        // const transferDate = transfers[transferIndex].startDay;
        // console.log('rates: arrival property');
        // console.log('transfer date: ', transferDate);
        // console.log(JSON.stringify(transferRoute, undefined, 4));
        // console.log('origin: ' + origin);
        // console.log('destination: ' + destination);
      }
    }
  }



    // VIP start
    let vipObject;
    let vipObjectArrival;
    let vipObjectDeparture;
    let includeArrivalTransferVip = false;
    let includeDepartureTransferVip = false;
  
    let arrivalSupplierNameVip;
    let arrivalSupplierUidVip;
    let arrivalUnitsVip;
    let arrivalPerGroupPriceVip;
    let arrivalPerPersonPriceVip;
    let arrivalSaleTotalVip;
  
    let departureSupplierNameVip;
    let departureSupplierUidVip;
    let departureUnitsVip;
    let departurePerGroupPriceVip;
    let departurePerPersonPriceVip;
    let departureSaleTotalVip;
  
  
  
    const vipObjectJSON = formData.get('vipObject');
    vipObject = vipObjectJSON ? JSON.parse(vipObjectJSON) : null;
  
    const vipObjectArrivalJSON = formData.get('vipObjectArrival');
    vipObjectArrival = vipObjectArrivalJSON ? JSON.parse(vipObjectArrivalJSON) : null;
  
    const vipObjectDepartureJSON = formData.get('vipObjectDeparture');
    vipObjectDeparture = vipObjectDepartureJSON ? JSON.parse(vipObjectDepartureJSON) : null;
  
    if (transferIndex !== -1) {
    
        includeArrivalTransferVip =
          formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
        
        console.log('includeArrivalTransferVip: ' + includeArrivalTransferVip);
        
          if (includeArrivalTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
          arrivalSupplierNameVip = vipObjectArrival.supplier.displayName;
          arrivalSupplierUidVip = vipObjectArrival.supplier.id;
          arrivalUnitsVip = adults + children + infants;
          arrivalPerGroupPriceVip = vipObjectArrival.supplier.perGroupPrice;
          arrivalPerPersonPriceVip = vipObjectArrival.supplier.perPersonPrice;
          arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
        }
        includeDepartureTransferVip =
          formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
        
        console.log('includeDepartureTransferVip: ' + includeDepartureTransferVip);

        if (includeDepartureTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));    
          departureSupplierNameVip = vipObjectDeparture.supplier.displayName;
          departureSupplierUidVip = vipObjectDeparture.supplier.id;
          departureUnitsVip = adults + children + infants;
          departurePerGroupPriceVip = vipObjectDeparture.supplier.perGroupPrice;
          departurePerPersonPriceVip = vipObjectDeparture.supplier.perPersonPrice;
          departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
        }
    }
    // VIP end

  let formValues;

  formValues = {
    type: 'ownArrangements',
    airportTransfer: airportTransfer === 'true' ? true : false,
    ...(airportTransfer === 'true' ? { originAirportName } : {}),
    ...(airportTransfer === 'true' ? { destinationAirportName } : {}),
    ...(airportTransfer === 'true' ? { originAirportUid } : {}),
    ...(airportTransfer === 'true' ? { destinationAirportUid } : {}),
    ...(airportTransfer === 'true' ? { originAirportCode } : {}),
    ...(airportTransfer === 'true' ? { destinationAirportCode } : {}),
    ...(airportTransfer === 'true' ? { originLocationUid } : {}),
    ...(airportTransfer === 'true' ? { destinationLocationUid } : {}),

    ...(airportTransfer === 'true' && fltnum !== '' ? { fltnum } : {}),
    ...(airportTransfer === 'true' && timeDeparture !== 'Select time' ? { timeDeparture } : { timeDeparture: 'TBD' }),
    ...(airportTransfer === 'true' && timeArrival !== 'Select time' ? { timeArrival } : { timeArrival: 'TBD' }),
    ...(airportTransfer === 'true' && airlineUid !== null ? { airlineUid } : { airlineUid: 'TBD' }),
    ...(airportTransfer === 'true' && airlineName !== null ? { airlineName } : { airlineName: 'TBD' }),
    // ...(airportTransfer === 'true' && includeDepartureTransfer !== null ? { includeDepartureTransfer } : {}),
    // ...(airportTransfer === 'true' && includeArrivalTransfer !== null ? { includeArrivalTransfer } : {}),
    ...(addDetailsLater !== false ? { addDetailsLater } : {}),
    packedLunch,
    packedLunchChargeable,
    packedLunchUnits: processPackedLunchResults.units,
    packedLunchUnitPrice: processPackedLunchResults.unitPrice,
    packedLunchSaleTotal: processPackedLunchResults.saleTotal,
    ...(includeDepartureTransfer !== false
      ? {
          includeDepartureTransfer,
          departureTransferSupplierName,
          departureTransferSupplierUid,
          departureTransferUnits, // figure out calculation
          departureTransferUnitPrice,
          departureTransferSaleTotal,
        }
      : {}),
    ...(includeArrivalTransfer !== false
      ? {
          includeArrivalTransfer,
          arrivalTransferSupplierName,
          arrivalTransferSupplierUid,
          arrivalTransferUnits, // figure out calculation
          arrivalTransferUnitPrice,
          arrivalTransferSaleTotal,
        }
      : {}),
    ...(includeArrivalTransferVip !== false
      ? {
          includeArrivalVip : includeArrivalTransferVip,
          arrivalSupplierNameVip,
          arrivalSupplierUidVip,
          arrivalUnitsVip,
          arrivalPerGroupPriceVip,
          arrivalPerPersonPriceVip,
          arrivalSaleTotalVip,
          arrivalAirportCodeVip: vipObjectArrival.airportCode,
          vuuid: uuidv4(),
        }
      : {}),
    ...(includeDepartureTransferVip !== false
      ? {
          includeDepartureVip: includeDepartureTransferVip,
          departureSupplierNameVip,
          departureSupplierUidVip,
          departureUnitsVip,
          departurePerGroupPriceVip,
          departurePerPersonPriceVip,
          departureSaleTotalVip,
          departureAirportCodeVip: vipObjectDeparture.airportCode,
          vuuid: uuidv4(),
        }
      : {}),
    adults,
    children,
    infants,
    totalAllPax: adults + children + infants,
    ...(airportTransfer === 'false'
      ? addLater === 'false'
        ? {
            arrivalTime,
            departureTime,
            supplierName,
            supplierUid,
            addLater: false,
          }
        : {
            arrivalTime,
            departureTime,
            supplierName,
            supplierUid,
            addLater: true,
          }
      : {}),
    // ...(addLater === 'false' && airportTransfer === 'false'
    //   ? {
    //       arrivalTime,
    //       departureTime,
    //       supplierName,
    //       supplierUid,
    //       addLater: false,
    //     }
    //   : { addLater: true }),
    // ...(arrivalTime && arrivalTime !== 'Select time' ? { arrivalTime } : {}),
    // ...(departureTime && departureTime !== 'Select time' ? { departureTime } : {}),
    // ...(addLater !== false ? { addLater } : {}),
  };

  console.log('handleFormSubmitOwnArrangements formType ', formType);
  console.log('handleFormSubmitOwnArrangements formType ', tuuid);
  // const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  console.log('handleFormSubmitOwnArrangements 2');
  if (transferIndex !== -1) {
    const updatedTransfer = {
      ...transfers[transferIndex],
      formValues,
      edit: false,
      isInvalid: false,
    };

    const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];
    console.log('handleFormSubmitOwnArrangements 3');
    setTransfers(updatedTransfers); // Update the transfers state
    // if (formType === "propertyTransfer" || formType === "departure") {
    //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
    // }
    bookings = filterVehicleRoomsNoSet(bookings);
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
  } else {
    console.log('No transfers ERROR');
    console.log('tuuid: ' + tuuid);
    console.log('formType: ' + formType);
  }

  onClose();
  e.target.reset();
};

// ! USED - CHECKED
// USED IN SINGLE FOR ROAD TRANSFERS / pickupLocation
const handleFormSubmitLocation = async (e, transfers, setTransfers, onClose, bookings, setBookings, properties) => {
  e.preventDefault();

  const formData = new FormData(e.target);
  const formType = formData.get('formType');
  const locationUid = formData.get('locationUid');
  const locationName = formData.get('locationName');
  const parkUid = formData.get('parkUid');
  //const subLocation = formData.get("subLocation");
  const tuuid = formData.get('tuuid');
  //const time = formData.get("time");
  ///  const time = timeRef.current.value;

  const fltnum = formData.get('fltnum');
  const time = formData.get('time') === '' ? 'TBD' : formData.get('time');
  const locationType = formData.get('locationType');
  const totalPax = Number(formData.get('totalPax'));

  const airport = formData.get('airport') !== 'airport' ? false : true;

  console.log('totalPax: ' + totalPax);

  console.log('time XXX: ' + time);

  const originUid = formData.get('originUid');
  console.log('originUid: ' + originUid);
  const destinationUid = formData.get('destinationUid');
  console.log('destinationUid: ' + destinationUid);

  // Transfer Route variables
  const supplierJson = formData.get('supplier');
  const supplier = JSON.parse(supplierJson);

  const stoPriceCost = formData.get('stoPriceCost');
  const netRackCost = formData.get('netRackCost');
  const saleTotalForm = Number(formData.get('saleTotal'));
  const maxCapacityForm = formData.get('maxCapacity');
  const rateIdForm = formData.get('rateId');
  const totalUnitsForm = formData.get('totalUnits');
  const transferRouteUid = formData.get('transferRouteUid');
  const transferType = formData.get('transferType');
  const distanceMins = formData.get('distanceMins');
  const gameDriveTime = formData.get('gameDriveTime');
  const transferRouteName = formData.get('transferRouteName');
  const rateType = formData.get('rateType');

  const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  //const locationRoute = await loadTransferRoute(originUid, destinationUid);

  let units = 0;
  let maxCapacity = 0;
  let saleTotal = 0;
  let supplierName;
  let supplierUid;
  let rackUnitCost = 0;
  let netUnitCost = 0;


    // VIP start
    let vipObject;
    let includeArrivalTransferVip = false;
    let includeDepartureTransferVip = false;
  
    let arrivalSupplierNameVip;
    let arrivalSupplierUidVip;
    let arrivalUnitsVip;
    let arrivalPerGroupPriceVip;
    let arrivalPerPersonPriceVip;
    let arrivalSaleTotalVip;
  
    let departureSupplierNameVip;
    let departureSupplierUidVip;
    let departureUnitsVip;
    let departurePerGroupPriceVip;
    let departurePerPersonPriceVip;
    let departureSaleTotalVip;

    let airportCodeVip = null;
  
  
    const vipObjectJSON = formData.get('vipObject');
    vipObject = vipObjectJSON ? JSON.parse(vipObjectJSON) : null;
    console.log('vipObjectJSON: ' + vipObjectJSON);
    console.log('vipObject: ' + JSON.stringify(vipObject, null, 2));
    if (transferIndex !== -1 && vipObject) {
      airportCodeVip = formData.get('airportCode');
      if (transfers[transferIndex].objType === 'arrival') {
        includeArrivalTransferVip =
          formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
        if (includeArrivalTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
          arrivalSupplierNameVip = vipObject.supplier.displayName;
          arrivalSupplierUidVip = vipObject.supplier.id;
          arrivalUnitsVip = totalPax;
          arrivalPerGroupPriceVip = vipObject.supplier.perGroupPrice;
          arrivalPerPersonPriceVip = vipObject.supplier.perPersonPrice;
          arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
        }
      } else if (transfers[transferIndex].objType === 'departure') {
        includeDepartureTransferVip =
          formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
        if (includeDepartureTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
          departureSupplierNameVip = vipObject.supplier.displayName;
          departureSupplierUidVip = vipObject.supplier.id;
          departureUnitsVip = totalPax;
          departurePerGroupPriceVip = vipObject.supplier.perGroupPrice;
          departurePerPersonPriceVip = vipObject.supplier.perPersonPrice;
          departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
        }
      }
    }
    // VIP end

  if (transferType === 'townTransfer') {
    const currentBooking = findBookingById(bookings, transfers[transferIndex].uuid);
    //const currentBookingIndex = bookings.findIndex((booking) => booking.id === currentBooking.id);
    //const currentPropUid = currentBooking.propUid;
    // if (airport && properties[getPropObj(currentPropUid, properties)].handlesOwnAirportTransfer) {
    //   units = 1;
    //   maxCapacity = 1;
    //   saleTotal = 0;
    //   supplierName = properties[getPropObj(currentPropUid, properties)].supplier.name;
    //   supplierUid = properties[getPropObj(currentPropUid, properties)].supplier.id;
    //   rackUnitCost = 0;
    //   netUnitCost = 0;
    // } else {
    // new rates
    // const rates = locationRoute.rates;
    // const rateObject = findTransferRate(rates, transfers[transferIndex].endDay);
    // const transferUnits = calculateRateTransferUnitsPax(totalPax, rateObject.maxCapacity);
    // const transferUnitPrice = rateObject.stoPrice;
    units = totalUnitsForm;
    maxCapacity = maxCapacityForm;
    saleTotal = saleTotalForm;
    supplierName = supplier.name;
    supplierUid = supplier.id;
    rackUnitCost = netRackCost;
    netUnitCost = stoPriceCost;
    console.log('calculated rates: townTransfer');
    // }
  } else {
    units = totalUnitsForm;
    maxCapacity = maxCapacityForm;
    saleTotal = saleTotalForm;
    supplierName = supplier.name;
    supplierUid = supplier.id;
    rackUnitCost = netRackCost;
    netUnitCost = stoPriceCost;
    console.log('calculated rates: other');
  }

  console.log('flightRoute:');
  //console.log(JSON.stringify(locationRoute, undefined, 4));

  //const addLater = e.target.elements.addLater.checked;

  const packedLunch = e.target.elements.packedLunchCheck.checked;
  const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;

  const airlineUidOwnArrangements = formData.get('airlineUidOwnArrangements');
  const airlineNameOwnArrangements = formData.get('airlineNameOwnArrangements');
  const fltNum = formData.get('fltNum');
  const dateFlightOwnArrangements = formData.get('dateFlightOwnArrangements');
  const timeFlightOwnArrangements = formData.get('timeFlightOwnArrangements');
  let detailsNow = formData.get('detailsNow') === false || formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;

  // const addFlightDetails = formData.get('addFlightDetails');
  //const addFlightDetailsLater = formData.get('addFlightDetailsLater') === 'false' ? false : true;

  // PACKED LUNCH MODIFICATION

  const booking = findBookingById(bookings, transfers[transferIndex].uuid);
  const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);

  const capitalizedFormType = formType.charAt(0).toUpperCase() + formType.slice(1);
  // alert('time: ' + time);
  // alert('addLater: ' + addLater);
  //let addLater = false;
  let addFlightDetailsLater = false;
  // alert('time: ' + time);
  // alert('detailsNow: ' + detailsNow);
  // alert('airport: ' + airport);
  if (time === 'Select time' && !airport) {
    //return toast.error(`You must enter an '${capitalizedFormType} time' or select ${formType === 'departure' ? "'Add drop off time later'" : "'Add arrival time later'"}`);
    addFlightDetailsLater = true;
  } else if (detailsNow && transferType === '') {
    //return toast.error(`You must enter an '${capitalizedFormType} time' or select ${formType === 'departure' ? "'Add drop off time later'" : "'Add arrival time later'"}`);
    addFlightDetailsLater = true;
  }

  if (airport && (airlineUidOwnArrangements === '' || fltNum === '' || timeFlightOwnArrangements === 'Select time')) {
    // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
    // return toast.error(
    //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
    //   `You must select all flight detail or tick to add later`,
    // );
    addFlightDetailsLater = true;
  }
  // if (!addLater && time === 'Select time' && !airport) {
  //   return toast.error(`You must enter an '${capitalizedFormType} time' or select ${formType === 'departure' ? "'Add drop off time later'" : "'Add arrival time later'"}`);
  // }

  // if (!addLater && transferType === '') {
  //   return toast.error(`You must enter an '${capitalizedFormType} time' or select ${formType === 'departure' ? "'Add drop off time later'" : "'Add arrival time later'"}`);
  // }

  // if (airport && !addFlightDetailsLater && (airlineUidOwnArrangements === '' || fltNum === '' || timeFlightOwnArrangements === 'Select time')) {
  //   // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
  //   return toast.error(
  //     // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
  //     `You must select all flight detail or tick to add later`,
  //   );
  // }

  //if (!locationUid || locationUid === 'false') return toast.error(`An error occurred, please try again or try another location`);
  //return toast.error(`An error occurred, please try again or try another location `, ` ${locationUid}`);

  const formValues = {
    locationName,
    locationUid,
    parkUid,
    originUid,
    destinationUid,
    //subLocation,
    time,
    type: 'location',
    //addLater,
    ...(locationType === 'airport' && fltnum !== '' ? { fltnum } : {}),
    transferType: transferType,
    gameDriveTime: gameDriveTime,
    distanceMins: distanceMins,
    rackUnitCost: rackUnitCost,
    netUnitCost: netUnitCost,
    stoPriceCost: netUnitCost,
    units: units,
    maxCapacity: Number(maxCapacity),
    saleTotal: saleTotal,
    route: transferRouteName,
    supplierName: supplierName,
    supplierUid: supplierUid,
    locationType,
    transferRoute: transferRouteUid,
    packedLunch,
    packedLunchChargeable,
    packedLunchUnits: processPackedLunchResults.units,
    packedLunchUnitPrice: processPackedLunchResults.unitPrice,
    packedLunchSaleTotal: processPackedLunchResults.saleTotal,
    airport,
    airlineUid: airlineUidOwnArrangements,
    airlineName: airlineNameOwnArrangements,
    fltNum,
    dateFlight: dateFlightOwnArrangements,
    timeFlight: timeFlightOwnArrangements,
    addFlightDetailsLater,
    transferRouteUid: transferRouteUid,
    rateId: rateIdForm,
    rateType: rateType,
    detailsNow,
    ...(includeDepartureTransferVip !== false
      ? {
        includeDepartureVip: includeDepartureTransferVip,
          departureSupplierNameVip,
          departureSupplierUidVip,
          departureUnitsVip, // figure out calculation
          departurePerGroupPriceVip,
          departurePerPersonPriceVip,
          departureSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
    ...(includeArrivalTransferVip !== false
      ? {
        includeArrivalVip: includeArrivalTransferVip,
          arrivalSupplierNameVip,
          arrivalSupplierUidVip,
          arrivalUnitsVip, // figure out calculation
          arrivalPerGroupPriceVip,
          arrivalPerPersonPriceVip,
          arrivalSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
      airportCodeVip,
  };

  //const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);

  if (transferIndex !== -1) {
    const updatedTransfer = {
      ...transfers[transferIndex],
      formValues,
      edit: false,
      isInvalid: false,
    };

    const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];

    // Reset ArrivalTransfers
    if (formType === 'arrival') {
      const arrivalTransferIndex = updatedTransfers.findIndex((transfer) => transfer.objType === 'arrivalTransfer');

      if (arrivalTransferIndex !== -1) {
        const { formValues, ...rest } = updatedTransfers[arrivalTransferIndex];
        const modifiedTransfers = [...updatedTransfers.slice(0, arrivalTransferIndex), rest, ...updatedTransfers.slice(arrivalTransferIndex + 1)];

        setTransfers(modifiedTransfers); // Update the transfers state
      } else {
        setTransfers(updatedTransfers);
      }
    } else {
      setTransfers(updatedTransfers);
    }
    // if (formType === "propertyTransfer" || formType === "departure") {
    //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
    // }
    bookings = filterVehicleRoomsNoSet(bookings);
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
  } else {
    console.log(formType + ' ERROR');
    console.log('tuuid: ' + tuuid);
    console.log('formType: ' + formType);
  }

  onClose();
  e.target.reset();
  console.log(formType + ' ERROR');
};

// ! USED - CHECKED
// USED IN SINGLE FOR OWN ARRANGEMENTS / arrivalCamp
const handleFormSubmitOwnArrangementsSingle = (e, transfers, setTransfers, onClose, bookings, setBookings, properties) => {
  e.preventDefault();

  const formData = new FormData(e.target);

  const formType = formData.get('formType');
  const arrive = formData.get('arrive');
  //const depart = formData.get("departure");
  const transferType = formData.get('transferType');
  const tuuid = formData.get('tuuid');
  //new
  const locationUid = formData.get('locationUid');
  const locationName = formData.get('locationName');
  //const airport = formData.get('airport') === 'false' ? false : true;
  const airport = formData.get('airport') !== 'airport' ? false : true;
  const supplierUid = formData.get('supplierUid');
  const supplierName = formData.get('supplierName');
  const timeOwnArrangements = formData.get('timeOwnArrangements');
  //const dateOwnArrangements = formData.get('dateOwnArrangements');

  const airlineUidOwnArrangements = formData.get('airlineUidOwnArrangements');
  const airlineNameOwnArrangements = formData.get('airlineNameOwnArrangements');
  const fltNum = formData.get('fltNum');
  const dateFlightOwnArrangements = formData.get('dateFlightOwnArrangements');
  const timeFlightOwnArrangements = formData.get('timeFlightOwnArrangements');

  // const addFlightDetails = formData.get('addFlightDetails');
  // const addFlightDetailsLater = formData.get('addFlightDetailsLater') === 'false' ? false : true;
  // const addLaterOwnArrangements = formData.get('addLaterOwnArrangements') === 'false' ? false : true;
  let addFlightDetailsLater = false;
  //let addLaterOwnArrangements = false;

  const transferIndex = transfers.findIndex((transfer) => transfer.objType === formType && transfer.tuuid === tuuid);
  let detailsNow = formData.get('detailsNow') === false || formData.get('detailsNow') === 'false' || formData.get('detailsNow') === null ? false : true;

  const totalPax = Number(formData.get('totalPax'));



    // VIP start
    let vipObject;
    let includeArrivalTransferVip = false;
    let includeDepartureTransferVip = false;
  
    let arrivalSupplierNameVip;
    let arrivalSupplierUidVip;
    let arrivalUnitsVip;
    let arrivalPerGroupPriceVip;
    let arrivalPerPersonPriceVip;
    let arrivalSaleTotalVip;
  
    let departureSupplierNameVip;
    let departureSupplierUidVip;
    let departureUnitsVip;
    let departurePerGroupPriceVip;
    let departurePerPersonPriceVip;
    let departureSaleTotalVip;

    let airportCodeVip = null;
  
  
    const vipObjectJSON = formData.get('vipObject');
    vipObject = vipObjectJSON ? JSON.parse(vipObjectJSON) : null;
    console.log('vipObjectJSON: ' + vipObjectJSON);
    console.log('vipObject: ' + JSON.stringify(vipObject, null, 2));
    if (transferIndex !== -1 && vipObject) {
      airportCodeVip = formData.get('airportCode');
      if (transfers[transferIndex].objType === 'arrival') {
        includeArrivalTransferVip =
          formData.get('includeArrivalTransferVip') === 'false' || formData.get('includeArrivalTransferVip') === null || formData.get('includeArrivalTransferVip') === '' ? false : true;
        if (includeArrivalTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
          arrivalSupplierNameVip = vipObject.supplier.displayName;
          arrivalSupplierUidVip = vipObject.supplier.id;
          arrivalUnitsVip = totalPax;
          arrivalPerGroupPriceVip = vipObject.supplier.perGroupPrice;
          arrivalPerPersonPriceVip = vipObject.supplier.perPersonPrice;
          arrivalSaleTotalVip = arrivalPerGroupPriceVip + (arrivalUnitsVip * arrivalPerPersonPriceVip);
        }
      } else if (transfers[transferIndex].objType === 'departure') {
        includeDepartureTransferVip =
          formData.get('includeDepartureTransferVip') === 'false' || formData.get('includeDepartureTransferVip') === null || formData.get('includeDepartureTransferVip') === '' ? false : true;
        if (includeDepartureTransferVip) {
          //alert('vipObject: ' + JSON.stringify(vipObject, null, 2));
          departureSupplierNameVip = vipObject.supplier.displayName;
          departureSupplierUidVip = vipObject.supplier.id;
          departureUnitsVip = totalPax;
          departurePerGroupPriceVip = vipObject.supplier.perGroupPrice;
          departurePerPersonPriceVip = vipObject.supplier.perPersonPrice;
          departureSaleTotalVip = departurePerGroupPriceVip + (departureUnitsVip * departurePerPersonPriceVip);
        }
      }
    }
    // VIP end


  if ((supplierUid === '' || supplierName === '' || timeOwnArrangements === 'Select time') && !airport) {
    //return toast.error(`You must select a Supplier and enter an ${formType} time`);
    addFlightDetailsLater = true;
  }

  if (airport && (airlineUidOwnArrangements === '' || fltNum === '' || timeFlightOwnArrangements === 'Select time')) {
    // alert('You must select an Airline, enter a Flight number and enter an Arrival time or select "Add Airline, Flight number and Arrival time later"');
    // return toast.error(
    //   // `You must select an Airline, enter a Flight number and enter an ${formType} time or select 'Add Airline, Flight number, Flight date and ${transfers[transferIndex].objType} time later'`,
    //   `You must select all flight detail or tick to add later`,
    // );
    addFlightDetailsLater = true;
  }

  // if (arrive === '') {
  //   return toast.error(`You must enter an Arrival time`);
  // }


    // Add null checks for packed lunch elements
    const packedLunch = e.target.elements.packedLunchCheck ? e.target.elements.packedLunchCheck.checked : false;
    const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck ? e.target.elements.packedLunchChargeableCheck.checked : false;
    
  // const packedLunch = e.target.elements.packedLunchCheck.checked;
  // const packedLunchChargeable = e.target.elements.packedLunchChargeableCheck.checked;

  // PACKED LUNCH MODIFICATION
  const booking = findBookingById(bookings, transfers[transferIndex].uuid);
  const processPackedLunchResults = processPackedLunch(properties, booking, packedLunchChargeable, packedLunch);
  const formValues = {
    [transferType === 'arrival' ? 'arrive' : 'depart']: transferType === 'arrival' ? arrive : arrive,
    type: 'camp',
    packedLunch,
    packedLunchChargeable,
    packedLunchUnits: processPackedLunchResults.units,
    packedLunchUnitPrice: processPackedLunchResults.unitPrice,
    packedLunchSaleTotal: processPackedLunchResults.saleTotal,
    locationUid,
    locationName: locationName === 'Search by airport, property or other' ? '' : locationName,
    airport,
    supplierUid,
    supplierName,
    timeOwnArrangements: timeOwnArrangements === 'Select time' ? 'TBC' : timeOwnArrangements,
    //dateOwnArrangements,
    airlineUidOwnArrangements,
    airlineNameOwnArrangements,
    fltNum,
    dateFlightOwnArrangements,
    timeFlightOwnArrangements,
    addFlightDetailsLater,
    //addLaterOwnArrangements,
    detailsNow,
    ...(includeDepartureTransferVip !== false
      ? {
        includeDepartureVip: includeDepartureTransferVip,
          departureSupplierNameVip,
          departureSupplierUidVip,
          departureUnitsVip, // figure out calculation
          departurePerGroupPriceVip,
          departurePerPersonPriceVip,
          departureSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
    ...(includeArrivalTransferVip !== false
      ? {
        includeArrivalVip: includeArrivalTransferVip,
          arrivalSupplierNameVip,
          arrivalSupplierUidVip,
          arrivalUnitsVip, // figure out calculation
          arrivalPerGroupPriceVip,
          arrivalPerPersonPriceVip,
          arrivalSaleTotalVip,
          vuuid: uuidv4(),
        }
      : {}),
      airportCodeVip,
  };

  //const transferIndex = transfers.findIndex((transfer) => transfer.objType === transferType && transfer.tuuid === tuuid);

  if (transferIndex !== -1) {
    const updatedTransfer = {
      ...transfers[transferIndex],
      formValues,
      edit: false,
      isInvalid: false,
    };

    const updatedTransfers = [...transfers.slice(0, transferIndex), updatedTransfer, ...transfers.slice(transferIndex + 1)];

    // Reset ArrivalTransfers
    if (formType === 'arrival') {
      const arrivalTransferIndex = updatedTransfers.findIndex((transfer) => transfer.objType === 'arrivalTransfer');

      if (arrivalTransferIndex !== -1) {
        const { formValues, ...rest } = updatedTransfers[arrivalTransferIndex];
        const modifiedTransfers = [...updatedTransfers.slice(0, arrivalTransferIndex), rest, ...updatedTransfers.slice(arrivalTransferIndex + 1)];

        setTransfers(modifiedTransfers); // Update the transfers state
      } else {
        setTransfers(updatedTransfers);
      }
    } else {
      setTransfers(updatedTransfers);
    }
    // if (formType === "propertyTransfer" || formType === "departure") {
    //   calculatePropertyVehicles(bookings, setBookings, updatedTransfers, tuuid, properties);
    // }
    //clear vehicles from bookings
    bookings = filterVehicleRoomsNoSet(bookings);

    console.log('handleFormSubmitCamp 1');
    console.log(JSON.stringify(bookings, undefined, 4));
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(bookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
  } else {
    console.log(formType + ' ERROR');
    console.log('tuuid: ' + tuuid);
    console.log('formType: ' + formType);
  }

  onClose();
  e.target.reset();
  console.log(formType + ' ERROR');
};

// ! FORM FUNCTIONS

function getTotalAdCh(booking) {
  const totals = booking.rooms
    .filter((room) => room.roomType !== 'Vehicle')
    .reduce(
      (acc, room) => {
        acc.totalAd += room.ad;
        acc.totalCh += room.ch;
        return acc;
      },
      { totalAd: 0, totalCh: 0 },
    );

  return [totals.totalAd, totals.totalCh];
}

// TRANSFER FORMS SINGLE ******************************************************
function handleOptionClick(event, setSelectedOption, setSelectedContent, setPackedLunch, setPackedLunchChargeable) {
  const target = event.currentTarget.getAttribute('data-target');
  setSelectedOption(target);
  setSelectedContent(target);
  setPackedLunch(false);
  setPackedLunchChargeable(true);
}

const closeForm = (e, tuuid, transfers, setTransfers, onClose) => {
  if (e !== null) {
    e.preventDefault();
  }

  const updatedTransfers = transfers.map((transfer) => {
    if (transfer.tuuid === tuuid) {
      return {
        ...transfer,
        edit: false,
        isInvalid: false,
      };
    }
    return transfer;
  });

  setTransfers(updatedTransfers);
  onClose();
};

// TRANSFER FORMS INTER **********************************************

function handleOptionClickTransfer(event, setSelectedContent, setResetPackedLunch) {
  if (event.currentTarget) {
    const target = event.currentTarget.getAttribute('data-target');
    setResetPackedLunch(true);
    setSelectedContent(target);
  } else if (event) {
    setResetPackedLunch(true);
    setSelectedContent('flightTransfer');
  } else {
    console.error('currentTarget is null');
  }
}

export {
  handleOptionClick,
  handleOptionClickTransfer,
  handleFormSubmitLocation,
  handleFormSubmitOwnArrangementsSingle,
  handleFormSubmitAirport,
  handleFormSubmitSafari,
  //handleFormSubmitTown,
  handleFormSubmitOwnArrangements,
  closeForm,
};
