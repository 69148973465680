import React from 'react';
import { TableCellsIcon, Squares2X2Icon } from '@heroicons/react/24/outline';

function FilterBar({ 
  selectedPark, 
  selectedMonth, 
  setSelectedPark, 
  setSelectedMonth, 
  parkOptions, 
  monthOptions,
  view,
  setView 
}) {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
      <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
        <div className="flex flex-1 items-center space-x-4">
          <select
            value={selectedPark}
            onChange={(e) => setSelectedPark(e.target.value)}
            className="form-select w-full sm:w-48 text-sm border-gray-300 rounded-md focus:ring-nomadBlue focus:border-nomadBlue"
          >
            {parkOptions.map((park) => (
              <option key={park} value={park}>{park}</option>
            ))}
          </select>

          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="form-select w-full sm:w-48 text-sm border-gray-300 rounded-md focus:ring-nomadBlue focus:border-nomadBlue"
          >
            {monthOptions.map((month) => (
              <option key={month} value={month}>{month}</option>
            ))}
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <button
            onClick={() => setView('grid')}
            className={`p-2 rounded-md ${
              view === 'grid' 
                ? 'bg-nomadBlue-700 text-white' 
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <Squares2X2Icon className="h-5 w-5" />
          </button>
          <button
            onClick={() => setView('table')}
            className={`p-2 rounded-md ${
              view === 'table' 
                ? 'bg-nomadBlue-700 text-white' 
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <TableCellsIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FilterBar;