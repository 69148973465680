import { Timestamp, addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';

import Select from 'react-select';
import { db } from '../../src/db/firebase.config';
import { getPropObj } from './generalFunctions';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const getSafariTransfers = async (properties, transfer, nextItem, transfers, airports, locations, customLocations) => {
  console.log('transfer propUid');
  console.log(JSON.stringify(transfer, undefined, 4));
  const firstPropId = transfer.propUid;
  const secondPropId = nextItem.propUid;
  const endDay = transfer.endDay;

  console.log('getSafariTransfers:1 ');
  console.log('firstPropId: ', firstPropId);
  console.log('secondPropId: ', secondPropId);

  console.log('endDay: ', endDay);

  // check for arrivial flights
  // first property
  let firstPropertyParkId = false;
  let firstPropertyUId = false;

  const firstProperty = properties.find((property) => property.uid === firstPropId);

  const firstPropertyCustomLocations = firstProperty.mobileLocation;
  console.log('secondPropertyUId FIX:');
  console.log(JSON.stringify(firstProperty, undefined, 4));

  // second property
  let secondPropertyParkId = false;
  let secondPropertyUId = false;
  const secondProperty = properties.find((property) => property.uid === secondPropId);

  const secondPropertyCustomLocations = secondProperty.mobileLocation;
  console.log('secondPropertyUId FIX: ' + secondPropertyUId);

  console.log('TRANSFER TYPE: ' + transfer.objType);
  if (transfer.formValues && transfer.formValues.type === 'location') {
    console.log('LOCATION');
    if (transfer.objType === 'arrival') {
      console.log('MATCHED TRANSFER: LOCATION ARRIVAL');
      const location = locations.find((location) => location.uid === transfer.formValues.locationUid);
      firstPropertyParkId = location.park;
      firstPropertyUId = firstProperty.uid;
      secondPropertyParkId = firstProperty.park.id;
      secondPropertyUId = firstProperty.uid;
    } else if (transfer.objType === 'departure') {
      console.log('MATCHED TRANSFER: LOCATION DEPARTURE');
      const location = locations.find((location) => location.uid === nextItem.formValues.locationUid);
      firstPropertyParkId = firstProperty.park.id;
      firstPropertyUId = firstProperty.uid;
      secondPropertyParkId = location.park;
      secondPropertyUId = location.uid;
    }
  } else if (transfer.formValues && transfer.formValues.type === 'safari') {
    console.log('MATCHED TRANSFER: SAFARI');

    firstPropertyParkId = firstProperty.park.id;
    firstPropertyUId = firstProperty.uid;
    secondPropertyParkId = secondProperty.park.id;
    secondPropertyUId = secondProperty.uid;
  } else if (transfer.formValues) {
    console.log('MATCHED TRANSFER: NONE');
    firstPropertyParkId = firstProperty.park.id;
    firstPropertyUId = firstProperty.uid;
    secondPropertyParkId = secondProperty.park.id;
    secondPropertyUId = secondProperty.uid;
  } else {
    firstPropertyParkId = firstProperty.park.id;
    firstPropertyUId = firstProperty.uid;
    secondPropertyParkId = secondProperty.park.id;
    secondPropertyUId = secondProperty.uid;
  }

  //let matchFound = false;

  if (firstPropertyCustomLocations) {
    //const resultFirst = await findCustomLocation(firstPropertyUId, endDay);
    const resultFirst = customLocations.find((location) => location.propUid === firstPropertyUId && location.uuid === transfer.uuid);

    console.log('firstPropertyUId ' + firstPropertyUId);
    console.log('transfer.uuid ' + transfer.uuid);
    console.log('first property customLocations');
    console.log(JSON.stringify(customLocations, undefined, 4));
    console.log('resultFirst');
    console.log(JSON.stringify(resultFirst, undefined, 4));
    firstPropertyParkId = resultFirst.parkId;
  }

  if (secondPropertyCustomLocations) {
    //const resultSecond = await findCustomLocation(secondPropertyUId, endDay);
    const resultSecond = customLocations.find((location) => location.propUid === secondPropertyUId && location.uuid === nextItem.uuid);

    console.log('secondPropertyCustomLocations ' + secondPropertyUId);
    secondPropertyParkId = resultSecond.parkId;
    console.log('second property customLocations');

    console.log('secondPropertyUId xxxxxxxxxx ' + secondPropertyParkId);
  }

  if ((firstPropertyParkId === false && secondPropertyParkId !== false) || (firstPropertyParkId !== false && secondPropertyParkId === false)) {
    //return false;
    return { matchFound: false, matchData: ' - ' };
  } else {
    const result = await findTransfer(firstPropertyParkId, secondPropertyParkId);
    if (!result.matchFound) {
      return { matchFound: false, matchData: ' - ' };
    } else if (result.matchFound) {
      return {
        matchFound: true,
        matchData: result.matchData,
      };
    }
  }
};

const getTownTransfers = async (transfer, transfers, airports, properties, nextItem) => {
  // TOWN TRANSFERS - START
  let firstPropertyParkId;

  // first property
  const firstProperty = properties.find((property) => property.uid === transfer.propUid);
  firstPropertyParkId = firstProperty.park.id;

  //check airport location
  const matchingTransfer = transfers.find(
    (transfer) => transfer.objType === 'arrival' && transfer.formValues && transfer.formValues.type === 'flight' && airports.some((airport) => airport.uid === transfer.formValues.airportUid),
  );

  let airportParkId;
  if (matchingTransfer) {
    // Find the matching airport and set the park id
    const airport = airports.find((airport) => airport.uid === matchingTransfer.formValues.airportUid);
    airportParkId = airport.park.id;

    console.log('MATCHED ARRIVAL TRANSFER');
  }

  // second property
  let secondPropertyParkId;
  const secondProperty = properties.find((property) => property.uid === nextItem.propUid);
  secondPropertyParkId = secondProperty.park.id;

  let showTownTransfer;
  if (
    ((matchingTransfer ? airportParkId : firstPropertyParkId) === 'd03eeb65-41c0-4eb7-9550-378a3c48c7c1' ||
      (matchingTransfer ? airportParkId : firstPropertyParkId) === 'd65469af-4dff-4fbc-8de0-069b8eb56782' ||
      (matchingTransfer ? airportParkId : firstPropertyParkId) === '11a86b51-8f61-4d8b-979b-87f404f5086d') &&
    (secondPropertyParkId === 'd03eeb65-41c0-4eb7-9550-378a3c48c7c1' ||
      secondPropertyParkId === 'd65469af-4dff-4fbc-8de0-069b8eb56782' ||
      secondPropertyParkId === '11a86b51-8f61-4d8b-979b-87f404f5086d')
  ) {
    //showTownTransfer = true;
    return true;
  } else if (
    (matchingTransfer ? airportParkId : firstPropertyParkId) === secondPropertyParkId &&
    ((matchingTransfer ? airportParkId : firstPropertyParkId) === '82b50b59-c58f-4438-856d-bf3bd1a052eb' ||
      (matchingTransfer ? airportParkId : firstPropertyParkId) === 'ce7809b0-7680-44bb-ad7b-5fbcc0270e30')
  ) {
    //showTownTransfer = true;
    return true;
  } else {
    return false;
    //showTownTransfer = false;
  }
};

const getFlightTransfers = async (properties, transfer, nextItem, transfers, airports, locations, customLocations) => {
  const firstPropId = transfer.propUid;
  const secondPropId = nextItem.propUid;
  const endDay = transfer.endDay;
  console.log('getAirportTransfers: ');
  console.log('firstPropId: ', firstPropId);
  console.log('secondPropId: ', secondPropId);

  console.log('endDay: ', endDay);

  // check for arrivial flights
  // first property
  let firstPropertyAirportId;
  let firstPropertyUId;

  const firstProperty = properties.find((property) => property.uid === firstPropId);
  firstPropertyAirportId = firstProperty.airport.id;
  firstPropertyUId = firstProperty.uid;
  const firstPropertyCustomLocations = firstProperty.mobileLocation;

  const matchingTransfer = transfers.find((transfer) => {
    if (transfer.objType === 'arrival' && transfer.formValues) {
      if (transfer.formValues.type === 'flight' && airports.some((airport) => airport.uid === transfer.formValues.airportUid)) {
        return true;
      } else if (transfer.formValues.type === 'location' && locations.some((location) => location.uid === transfer.formValues.locationUid)) {
        return true;
      }
    }
    return false;
  });

  if (matchingTransfer) {
    if (matchingTransfer.formValues.type === 'flight') {
      // Find the matching airport and set the park id
      const airport = airports.find((airport) => airport.uid === matchingTransfer.formValues.airportUid);
      firstPropertyAirportId = airport.uid;
      firstPropertyUId = airport.uid;
      console.log('MATCHED ARRIVAL TRANSFER AIRPORT');
    }
  } else {
    // Set the park id based on the second property's park id
    console.log('NOT MATCHED ARRIVAL TRANSFER');
    firstPropertyAirportId = firstProperty.airport.id;
    firstPropertyUId = firstProperty.uid;
  }

  // second property
  let secondPropertyAirportId;
  let secondPropertyUId;
  const secondProperty = properties.find((property) => property.uid === secondPropId);
  secondPropertyAirportId = secondProperty.airport.id;
  secondPropertyUId = secondProperty.uid;
  const secondPropertyCustomLocations = secondProperty.mobileLocation;

  let flightsFound = false;

  if (firstPropertyCustomLocations) {
    //const resultFirst = await findCustomLocation(firstPropertyUId, endDay);
    const resultFirst = customLocations.find((location) => location.propUid === firstPropertyUId && location.uuid === transfer.uuid);

    console.log('firstPropertyUId ' + firstPropertyUId);
    firstPropertyAirportId = resultFirst.airportId;
  }

  if (secondPropertyCustomLocations) {
    //const resultSecond = await findCustomLocation(secondPropertyUId, endDay);
    const resultSecond = customLocations.find((location) => location.propUid === secondPropertyUId && location.uuid === nextItem.uuid);
    console.log('secondPropertyCustomLocations ' + secondPropertyUId);
    secondPropertyAirportId = resultSecond.airportId;
    console.log('secondPropertyUId xxxxxxxxxx ' + secondPropertyAirportId);
  }

  console.log('FLIGHTS FOUND RESULTS 1');
  console.log('firstPropertyAirportId ' + firstPropertyAirportId);
  console.log('secondPropertyAirportId ' + secondPropertyAirportId);

  if ((firstPropertyAirportId === false && secondPropertyAirportId !== false) || (firstPropertyAirportId !== false && secondPropertyAirportId === false)) {
    //return false;
    return {
      flightsFound: false,
      estimatedTimeMins: 0,
      originUid: false,
      destinationUid: false,
    };
  } else {
    console.log('firstPropertyAirportId ' + firstPropertyAirportId);
    console.log('secondPropertyAirportId ' + secondPropertyAirportId);
    const result = await findFlightTransfer(firstPropertyAirportId, secondPropertyAirportId, airports);

    console.log('firstPropertyAirportId ' + firstPropertyAirportId);
    console.log('secondPropertyAirportId ' + secondPropertyAirportId);
    if (!result.flightsFound) {
      return {
        flightsFound: false,
        estimatedTimeMins: 0,
        originUid: false,
        destinationUid: false,
        scheduled: null,
        chartered: null,
        customFlight: 'null',
        uid: null,
      };
    } else if (result.flightsFound) {
      return {
        flightsFound: true,
        estimatedTimeMins: result.estimatedTimeMins,
        originUid: firstPropertyAirportId,
        destinationUid: secondPropertyAirportId,
        scheduled: result.scheduled,
        chartered: result.chartered,
        customFlight: result.customFlight,
        uid: result.uid,
      };
    }
  }
};

//closureDates check
function checkClosure(closureDates, startDay, endDay) {
  // Convert startDay and endDay to Moment objects
  const startDate = moment(startDay, "YYYY-MM-DD");
  const endDate = moment(endDay, "YYYY-MM-DD");

  console.log(`Checking closure for startDay: ${startDay}, endDay: ${endDay}`);
  console.log('closureDates');
  console.log(JSON.stringify(closureDates, undefined, 4));

  for (const closure of closureDates) {
      // Convert Firestore timestamps to Moment objects
      // const periodStartDate = moment(closure.periodStartDate.toDate());
      // const periodEndDate = moment(closure.periodEndDate.toDate());
      // Convert timestamp seconds to moment objects
      // const periodStartDate = moment.unix(closure.periodStartDate.seconds ? closure.periodStartDate.seconds : closure.periodStartDate);
      // const periodEndDate = moment.unix(closure.periodEndDate.seconds ? closure.periodEndDate.seconds : closure.periodEndDate);
      const periodStartDate = closure.periodStartDate.seconds 
    ? moment.unix(closure.periodStartDate.seconds)
    : moment(closure.periodStartDate);

    const periodEndDate = closure.periodEndDate.seconds
    ? moment.unix(closure.periodEndDate.seconds)
    : moment(closure.periodEndDate);

      console.log(`Checking closure period: ${periodStartDate.format()} to ${periodEndDate.format()}`);
      console.log(`Closure reason: ${closure.reason}`);
      console.log(`Indefinitely closed: ${closure.indefinitelyClosed}`);
      console.log(`Subsequent years also: ${closure.subsequentYearsAlso}`);

      if (closure.indefinitelyClosed) {
          // Check if startDay or endDay is after periodStartDate
          if (startDate.isSameOrAfter(periodStartDate) || endDate.isSameOrAfter(periodStartDate)) {
              console.log("Match found: Indefinitely closed and date is after periodStartDate");
              return { closed: true, reason: closure.reason };
          }
      } else if (closure.subsequentYearsAlso) {
          // Handle repeated closures across years
          const startYear = startDate.year();
          const endYear = endDate.year();

          console.log(`Subsequent years active. Checking across years ${startYear} to ${endYear}`);

          for (let year = startYear; year <= endYear; year++) {
              const periodStartThisYear = moment(periodStartDate).year(year);
              const periodEndThisYear = moment(periodEndDate).year(year);

              console.log(`Checking year ${year}: ${periodStartThisYear.format()} to ${periodEndThisYear.format()}`);

              if (
                  (startDate.isBetween(periodStartThisYear, periodEndThisYear, null, "[]")) ||
                  (endDate.isBetween(periodStartThisYear, periodEndThisYear, null, "[]"))
              ) {
                  console.log("Match found: Within period for a subsequent year");
                  return { closed: true, reason: closure.reason };
              }
          }
      } else {
          // Regular closure period check
          if (
              (startDate.isBetween(periodStartDate, periodEndDate, null, "[]")) ||
              (endDate.isBetween(periodStartDate, periodEndDate, null, "[]"))
          ) {
              console.log("Match found: Within regular period");
              return { closed: true, reason: closure.reason };
          }
      }
  }

  // Default return if no closures match
  console.log("No match found. Returning closed: false");
  return { closed: false, reason: null };
}

//build customLocations
async function buildCustomLocations(bookings, properties) {
  console.log('buildCustomLocations 2024');
  var customLocations = [];
  let customDetails;
  for (let i = 0; i < bookings.length; i++) {
    const property = properties.find((prop) => prop.uid === bookings[i].propUid);
    const uuid = bookings[i].id;
    const propUid = bookings[i].propUid;
    const startDay = bookings[i].startDay;
    const endDay = bookings[i].endDay;
    console.log('buildCustomLocations');
    console.log('customLocations ', customLocations);
    console.log(JSON.stringify(customLocations, undefined, 4));
    console.log('propUid ', propUid);
    if (property.mobileLocation) {
      customDetails = await findCustomLocationPeriod(propUid, startDay, endDay, properties);
      //customLocations.push([propUid, uuid, customDetails]);
      const customDetailsWithIds = {
        ...customDetails, // copy existing properties from customDetails
        propUid: propUid, // add propUid
        bid: bookings[i].id,
        name: property.name,
        uuid: uuid, // add uuid
        closed: !customDetails.matchFound,
      };
      customLocations.push(customDetailsWithIds);
      console.log('IMPORTANT 1');
      console.log(JSON.stringify(customDetailsWithIds, undefined, 4));
    } else {
      // add logic for checking if camp is closed here
      let closed = false;
      let reason = '';
      if(property.closureDates !== undefined)
      {
       let result = checkClosure(property.closureDates, startDay, endDay);
        console.log('result closureDates');
        console.log(JSON.stringify(result, undefined, 4));
        closed = result.closed;
        reason = result.reason ? result.reason : '';
      } else {
        console.log('result closureDates 1');
      }
      customDetails = {
        propUid: propUid,
        bid: bookings[i].id,
        name: property.name,
        uuid: uuid,
        parkId: property.park.id,
        parentParkId: property.park.parentParkId, // new parent park id
        airportId: property.airport.id,
        locationId: property.park.id,
        parkName: property.park.name,
        airportDistance: property.airport.distance,
        safariPortalCode: property.safariPortalCode,
        closed: closed,
        ...(closed && { reason }),

      };
      customLocations.push(customDetails);
      console.log('IMPORTANT 2');
      console.log(JSON.stringify(customDetails, undefined, 4));
    }
  }
  console.log('customLocations x');
  console.log(JSON.stringify(customLocations, undefined, 4));
  return customLocations;
}


async function findCustomLocationPeriodProperty(propertyUid, startDay, endDay, hitProperty) {
  console.log('findCustomLocationPeriodProperty');
  console.log('propertyUid: ', propertyUid);
  console.log('startDay: ', startDay);
  console.log('endDay: ', endDay);
  console.log('hitProperty:');
  console.log(JSON.stringify(hitProperty, undefined, 4)); 

  let matchFound = false;
  let parkId = false;
  let parentParkId = false;
  let parkName = false;
  let airportId = false;
  let locationId = false;
  let airportDistance = false;
  let safariPortalCode = false;

  try {
    // Find the property that matches the provided propertyUid
    const property = hitProperty;

    if (property && property.customLocations.length > 0) {
      // Convert endDay to a JavaScript Date object
      const startDayDate = new Date(startDay);
      const endDayDate = new Date(endDay);
      //const endDayDate = new Date(`${endDay}T12:00:00`);

      // Find a customLocation that matches the period
      const customLocation = property.customLocations.find((location) => {
        // const periodStartDate = new Date(location.periodStartDate.seconds * 1000);
        // const periodEndDate = new Date(location.periodEndDate.seconds * 1000);
        const periodStartDate = new Date(location.periodStartDate.seconds ? location.periodStartDate.seconds * 1000 : location.periodStartDate);
        const periodEndDate = new Date(location.periodEndDate.seconds ? location.periodEndDate.seconds * 1000 : location.periodEndDate);
        console.log('periodStartDate: ', location.periodStartDate);
        console.log('periodEndDate: ', location.periodEndDate);
        console.log('periodStartDate: ', periodStartDate);
        console.log('periodEndDate: ', periodEndDate);

        //return endDayDate >= periodStartDate && endDayDate <= periodEndDate; // Previously only checked endDay  
        return startDayDate >= periodStartDate && endDayDate <= periodEndDate;
        
      });

      if (customLocation) {
        matchFound = true;
        parkId = customLocation.parkId;
        airportId = customLocation.airportId;
        locationId = customLocation.parkId; // you may want to adjust this based on your logic
        parentParkId = customLocation.parentParkId;
        parkName = customLocation.parkName;
        airportDistance = customLocation.airportDistance;
        safariPortalCode = customLocation.safariPortalCode;
        console.log('Found a match!!!!!!!!!');
        console.log('Found a match!!!!!!!!! ************ ' + propertyUid);
        console.log('Date ************ ' + endDayDate);
        console.log('customLocation 2024');
        console.log(JSON.stringify(customLocation, null, 2));
      }
    }
  } catch (error) {
    console.log(error);
  }

  return {
    matchFound: matchFound,
    parkId: parkId,
    airportId: airportId,
    locationId: locationId,
    parentParkId: parentParkId,
    parkName: parkName,
    airportDistance: airportDistance,
    safariPortalCode: safariPortalCode,
  };
}

async function findCustomLocationPeriod(propertyUid, startDay, endDay, properties) {
  console.log('findCustomLocationPeriod');
  console.log('propertyUid: ', propertyUid);
  console.log('startDay: ', startDay);
  console.log('endDay: ', endDay);
  console.log('properties.length: ', properties.length);
  let matchFound = false;
  let parkId = false;
  let parentParkId = false;
  let parkName = false;
  let airportId = false;
  let locationId = false;
  let airportDistance = false;
  let safariPortalCode = false;

  try {
    // Find the property that matches the provided propertyUid
    const property = properties.find((prop) => prop.uid === propertyUid);

    if (property && property.customLocations.length > 0) {
      // Convert endDay to a JavaScript Date object
      const startDayDate = new Date(startDay);
      const endDayDate = new Date(endDay);
      //const endDayDate = new Date(`${endDay}T12:00:00`);

      // Find a customLocation that matches the period
      const customLocation = property.customLocations.find((location) => {
        // const periodStartDate = new Date(location.periodStartDate.seconds * 1000);
        // const periodEndDate = new Date(location.periodEndDate.seconds * 1000);
        const periodStartDate = new Date(location.periodStartDate.seconds ? location.periodStartDate.seconds * 1000 : location.periodStartDate);
        const periodEndDate = new Date(location.periodEndDate.seconds ? location.periodEndDate.seconds * 1000 : location.periodEndDate);
        console.log('periodStartDate: ', location.periodStartDate);
        console.log('periodEndDate: ', location.periodEndDate);
        console.log('periodStartDate: ', periodStartDate);
        console.log('periodEndDate: ', periodEndDate);

        //return endDayDate >= periodStartDate && endDayDate <= periodEndDate; // Previously only checked endDay  
        return startDayDate >= periodStartDate && endDayDate <= periodEndDate;
        
      });

      if (customLocation) {
        matchFound = true;
        parkId = customLocation.parkId;
        airportId = customLocation.airportId;
        locationId = customLocation.parkId; // you may want to adjust this based on your logic
        parentParkId = customLocation.parentParkId;
        parkName = customLocation.parkName;
        airportDistance = customLocation.airportDistance;
        safariPortalCode = customLocation.safariPortalCode;
        console.log('Found a match!!!!!!!!!');
        console.log('Found a match!!!!!!!!! ************ ' + propertyUid);
        console.log('Date ************ ' + endDayDate);
        console.log('customLocation 2024');
        console.log(JSON.stringify(customLocation, null, 2));
      }
    }
  } catch (error) {
    console.log(error);
  }

  return {
    matchFound: matchFound,
    parkId: parkId,
    airportId: airportId,
    locationId: locationId,
    parentParkId: parentParkId,
    parkName: parkName,
    airportDistance: airportDistance,
    safariPortalCode: safariPortalCode,
  };
}

async function logDocumentsWithoutSuppliers() {
  console.log('logDocumentsWithoutSuppliers');
  const collectionName = 'transferRoutes'; // Replace with your actual collection name

  try {
    // Get reference to the collection
    const collectionRef = collection(db, collectionName);

    // Execute the query to fetch all documents
    const querySnapshot = await getDocs(collectionRef);

    // Filter documents without a 'suppliers' field
    const docsWithoutSuppliers = querySnapshot.docs.filter((doc) => doc.data().suppliers === undefined);

    if (docsWithoutSuppliers.length === 0) {
      console.log('No documents found without suppliers field.');
      return;
    }
    console.log('Documents without suppliers field:');
    // Log each document that meets the criteria
    docsWithoutSuppliers.forEach((doc) => {
      console.log(doc.id);
    });
  } catch (error) {
    console.error('Error fetching documents: ', error);
  }
}

async function findTransfer(firstPropertyParkId, secondPropertyParkId) {
  //logDocumentsWithoutSuppliers();

  const fetchName2 = 'transferRoutes';

  try {
    // Get reference
    const fetchRef = collection(db, fetchName2);

    // Create separate queries for 'safariTransfer' and 'townTransfer'
    const safariTransferQuery = query(
      fetchRef,
      where('originPark.id', '==', firstPropertyParkId),
      where('destinationPark.id', '==', secondPropertyParkId),
      where('active', '==', true),
      where('type', '==', 'safariTransfer'),
    );

    const townTransferQuery = query(
      fetchRef,
      where('originPark.id', '==', firstPropertyParkId),
      where('destinationPark.id', '==', secondPropertyParkId),
      where('active', '==', true),
      where('type', '==', 'townTransfer'),
    );

    // Execute both queries
    const [safariTransferSnapshot, townTransferSnapshot] = await Promise.all([getDocs(safariTransferQuery), getDocs(townTransferQuery)]);

    // Initialize variable to hold match data
    let matchFound = false;
    let matchData = null;

    // Process safariTransferSnapshot
    safariTransferSnapshot.forEach((doc) => {
      const data = doc.data();
      matchFound = true;
      matchData = data;
    });

    // Process townTransferSnapshot only if safariTransferSnapshot didn't yield a result
    if (!matchFound) {
      townTransferSnapshot.forEach((doc) => {
        const data = doc.data();
        matchFound = true;
        matchData = data;
      });
    }

    // Now, matchData will contain the first found match
    if (matchFound) {
      console.log('Match found for firstPropertyParkId: ', firstPropertyParkId);
      console.log('Match found for secondPropertyParkId: ', secondPropertyParkId);
      return {
        matchFound: true,
        matchData: matchData,
      };
    } else {
      return { matchFound: false, matchData: null };
    }
  } catch (error) {
    console.log(error);
    return { matchFound: false, matchData: 'Error' };
  }
}


function getAlternativeCodes(airportId, airports) {
  console.log('getAlternativeCodes');
  console.log('airportId: ', airportId);
  const airport = airports.find((a) => a.uid === airportId);
  console.log('airport');
  console.log(JSON.stringify(airport, undefined, 4));

  if (airport && airport.alternative) {
    const altAirport = airports.find((a) => a.alternativeAirports && a.codes.includes(airport.code));
    if (altAirport) {
      console.log('altAirport');
      console.log(JSON.stringify(altAirport, undefined, 4));
      console.log(JSON.stringify(altAirport.codes, undefined, 4));
      return altAirport.codes;
    }
  }

  return airport ? airport.codes : [];
}


// Helper function to retrieve airport's uid based on its code
function getUidByCode(code, airports) {
  const airport = airports.find((a) => a.codes.includes(code));
  return airport ? airport.uid : null;
}

async function findFlightTransfer(firstPropertyAirportId, secondPropertyAirportId, airports) {
  const firstPropertyCodes = getAlternativeCodes(firstPropertyAirportId, airports);
  const secondPropertyCodes = getAlternativeCodes(secondPropertyAirportId, airports);

  for (let originCode of firstPropertyCodes) {
    for (let destCode of secondPropertyCodes) {
      // Fetch uids for the origin and destination based on their codes
      const originUid = getUidByCode(originCode, airports);
      const destUid = getUidByCode(destCode, airports);

      // If both uids are found, then query the database
      if (originUid && destUid) {
        const result = await queryDatabaseForFlight(originUid, destUid);
        if (result && result.flightsFound) {
          return result;
        }
      }
    }
  }

  // If no matches found
  return {
    flightsFound: false,
    estimatedTimeMins: false,
    scheduled: null,
    chartered: null,
    customFlight: null,
    uid: null,
  };
}

async function queryDatabaseForFlight(originCode, destinationCode) {
  console.log('queryDatabaseForFlight');
  console.log('originCode: ', originCode);
  console.log('destinationCode: ', destinationCode);

  const fetchName2 = 'flightRoutes';

  try {
    const fetchRef = collection(db, fetchName2);

    const q = query(fetchRef, where('origin.id', '==', originCode), where('destination.id', '==', destinationCode), where('active', '==', true), where('scheduled', '==', true));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();

      return {
        flightsFound: true,
        estimatedTimeMins: data.estimatedTimeMins,
        scheduled: data.scheduled,
        chartered: data.chartered,
        customFlight: data.customFlight,
        uid: doc.id,
      };
    }
  } catch (error) {
    console.log(error);
  }

  return {
    flightsFound: false,
    estimatedTimeMins: 'Error',
    scheduled: null,
    chartered: null,
    customFlight: null,
    uid: null,
  };
}

async function findMatchingFlightRoutes(airPortId) {
  const fetchName2 = 'flightRoutes';

  try {
    // Define an array to store fetched data
    const fetchedData = [];

    // Get reference
    const fetchRef = collection(db, fetchName2);
    const q = query(fetchRef, where('origin.id', '==', airPortId), where('active', '==', true));

    // Execute query
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      // Push the data into the fetchedData array
      fetchedData.push({
        ...doc.data(),
      });
    });

    return fetchedData;
  } catch (error) {
    console.log(error);
    // toast.error("Could not fetch " + fetchName);
  }
}

// Get users
const fetchName = 'airports';
const getAirports = async () => {
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef);

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];
    console.log(`querySnapshot.docs.length ${querySnapshot.docs.length}`);
    querySnapshot.forEach((doc) => {
      return fetch.push({
        ...doc.data(),
      });
    });
    var jsonResult = {
      result: fetch,
    };
    console.log('REDUX: getAirports');
    //console.log(JSON.stringify(jsonResult, undefined, 4));
    return { jsonResult };
  } catch (error) {
    console.log(error);
  }
};

function getAirportCode(properties, propUid, code) {
  const property = properties.find((property) => property.uid === propUid && property.airport.code === code);
  return property ? property.airport.name : null;
}

function getAirportCodeFromUid(airports, uid) {
  const airport = airports.find((airport) => airport.uid === uid);
  return airport ? airport.code : null;
}

function getAirportName(airports, code) {
  const airport = airports.find((ap) => ap.aerocrsCodes && ap.aerocrsCodes.includes(code));
  //const airport = airports.find((airport) => airport.code === code);
  return airport ? airport.name : null;
}

function getAirportDistance(properties, propUid) {
  const property = properties.find((property) => property.uid === propUid);
  return property ? property.airport.distance : null;
}
const getAirportProperties = (uid, properties) => {
  return properties.find((property) => property.uid === uid);
};

async function getAirportDetails(airports, properties, transfer, customLocations) {
  const propUid = transfer.propUid;
  const endDay = transfer.endDay;
  const property = properties.find((property) => property.uid === propUid);
  let airport;
  let airportId;

  if (!property.mobileLocation && property.airport) {
    airport = airports.find((airport) => airport.uid === property.airport.id);
    airportId = airport.uid;
  } else {
    //const result = await findCustomLocation(property.uid, endDay);
    const result = customLocations.find((location) => location.propUid === property.uid && location.uuid === transfer.uuid);

    if (result.matchFound && result.airportId && result.airportId !== '') {
      airport = airports.find((airport) => airport.uid === result.airportId);
      airportId = result.airportId;
    } else return false;
  }

  const airportDetails = airports.find((airport) => airport.uid === airportId) || false;

  return airportDetails;
}

async function getParkDetails(locations, properties, transfer, customLocations) {
  const propUid = transfer.propUid;
  const endDay = transfer.endDay;

  const property = properties.find((property) => property.uid === propUid);
  let location;
  let locationId;

  if (!property.mobileLocation && property.park) {
    location = locations.find((location) => location.uid === property.park.id);
    locationId = location.uid;
  } else {
    //const result = await findCustomLocation(property.uid, endDay);
    const result = customLocations.find((location) => location.propUid === property.uid && location.uuid === transfer.uuid);

    if (result.matchFound && result.parkId && result.parkId !== '') {
      location = locations.find((location) => location.uid === result.parkId);
      locationId = result.parkId;
    } else return false;
  }

  const locationDetails = locations.find((location) => location.uid === locationId) || false;

  return locationDetails;
}

async function getLocationDetails(locations, properties, transfer, customLocations) {
  const propUid = transfer.propUid;
  const endDay = transfer.endDay;

  const property = properties.find((property) => property.uid === propUid);
  let location;
  let locationId;

  if (!property.mobileLocation && property.park) {
    location = locations.find((location) => location.uid === property.park.id);
    locationId = location.uid;
  } else {
    //const result = await findCustomLocation(property.uid, endDay);
    const result = customLocations.find((location) => location.propUid === property.uid && location.uuid === transfer.uuid);

    if (result.matchFound && result.parkId && result.parkId !== '') {
      location = locations.find((location) => location.uid === result.parkId);
      locationId = result.parkId;
    } else return false;
  }

  const locationDetails = locations.find((location) => location.uid === locationId) || false;

  return locationDetails;
}

// TRANSFERS START
async function availableTransfers(transfer, newTransfers, index, properties, objType, setTransfers, airports, transfers, locations, customLocations) {
  console.log('getSafariTransfers:2 ' + index);
  console.log(JSON.stringify(transfer, undefined, 4));

  // Find the index of the next propertyTransfer object
  const nextPropertyTransferIndex = newTransfers.findIndex((nextTransfer, nextIndex) => nextIndex > index && nextTransfer.objType === 'propertyTransfer');

  console.log('getSafariTransfers:3 ' + index);
  console.log(JSON.stringify(nextPropertyTransferIndex, undefined, 4));

  // Get the nextItem from the transfers array using the found index
  const nextItem = newTransfers[nextPropertyTransferIndex];
  // console.log("nextPropertyTransferIndex", nextPropertyTransferIndex);
  // console.log("transfer.propUid", transfer.propUid);
  // console.log("nextItem");
  // console.log(JSON.stringify(nextItem, undefined, 4));
  //console.log("nextItem.propUid", nextItem.propUid);
  // if (nextItem) {
  //   const { matchFound, distanceMins } = await getSafariTransfers(properties, transfer.propUid, nextItem.propUid, transfer.endDay, transfers, airports, locations);
  //   // Rest of the code...
  // }
  const { matchFound, matchData } = await getSafariTransfers(properties, transfer, nextItem, transfers, airports, locations, customLocations);

  //const showTownTransfer = await getTownTransfers(transfer, transfers, airports, properties, nextItem);

  const { flightsFound, estimatedTimeMins, originUid, destinationUid, scheduled, chartered, customFlight, uid } = await getFlightTransfers(
    properties,
    transfer,
    nextItem,
    transfers,
    airports,
    locations,
    customLocations,
  );

  console.log('FLIGHTS FOUND RESULTS 2');
  console.log('flightsFound ' + flightsFound ? 'true' : 'false');
  console.log('estimatedTimeMins ' + estimatedTimeMins);

  console.log('matchData');
  console.log(JSON.stringify(matchData, undefined, 4));
  // Add the routeTransfer object to the current transfer

  const updatedTransfer = {
    ...transfer,
    routeTransfer: {
      available: matchFound,
      distanceMins: !matchFound ? 0 : matchData.distanceMins,
      gameDriveTime: !matchFound ? ' - ' : matchData.gameDriveTime,
      transferType: !matchFound ? ' - ' : matchData.type,
      supplierId: !matchFound ? ' - ' : matchData.suppliers[0].id,
      supplierName: !matchFound ? ' - ' : matchData.suppliers[0].name === '' ? null : matchData.suppliers[0].name,
      //supplierName: "Test Supplier",
      maxCapacity: !matchFound ? 0 : matchData.maxCapacity,
      netUnitCost: !matchFound ? 0 : matchData.netUnitCost,
      netRackCost: !matchFound ? 0 : matchData.rackUnitCost,
      transferRouteUid: !matchFound ? null : matchData.uid,
    },
    // townTransfer: {
    //   available: showTownTransfer,
    // },
    flightTransfer: {
      available: flightsFound,
      estimatedTimeMins: estimatedTimeMins,
      originUid: originUid,
      destinationUid: destinationUid,
      scheduled: scheduled,
      chartered: chartered,
      customFlight: customFlight,
      uid: uid,
    },
  };

  // const updatedTransfer = {
  // 	...transfer,
  // 	routeTransfer: {
  // 		available: matchFound,
  // 		distanceMins: !matchFound ? 0 : matchData.distanceMins,
  // 		gameDriveTime: !matchFound ? " - " : matchData.gameDriveTime,
  // 		transferType: !matchFound ? " - " : matchData.type,
  // 		supplier: !matchFound ? " - " : matchData.suppliers[0],
  // 		maxCapacity: !matchFound ? 0 : matchData.maxCapacity,
  // 		netUnitCost: !matchFound ? 0 : matchData.netUnitCost,
  // 		netRackCost: !matchFound ? 0 : matchData.rackUnitCost,
  // 	},
  // 	// townTransfer: {
  // 	//   available: showTownTransfer,
  // 	// },
  // 	flightTransfer: {
  // 		available: flightsFound,
  // 		estimatedTimeMins: estimatedTimeMins,
  // 		originUid: originUid,
  // 		destinationUid: destinationUid,
  // 	},
  // };

  // Replace the current transfer with the updatedTransfer in the newTransfers array
  newTransfers[index] = updatedTransfer;
}

// SAFARI VEHICLES END

const getOriginPropertyDetails = (transfer, transfers, properties, airports) => {
  const arrivalTransfer = transfers.find((transfer) => transfer.objType === 'arrival');

  if (arrivalTransfer && arrivalTransfer.formValues.type === 'flight') {
    const airportUid = arrivalTransfer.formValues.airportUid;
    const airport = airports.find((airport) => airport.uid === airportUid);
    if (airport) {
      const airportCode = airport.code;
      const airportProperties = getAirportProperties(arrivalTransfer.propUid, properties);
      return {
        code: airportCode,
        property: airportProperties,
      };
    }
  }
  // else if (arrivalTransfer && arrivalTransfer.formValues.type === "location")  {

  // }

  // If no arrival transfer is found, return an appropriate default value or handle the situation accordingly
  return null;
};

const findSecondToLastPropertyTransferWithFormValues = (transfers) => {
  for (let i = transfers.length - 2; i >= 0; i--) {
    if (transfers[i].objType === 'propertyTransfer' && transfers[i].formValues) {
      return transfers[i];
    }
  }
  return null;
};

// NEW
async function createTransfersArray(bookings, createTransferObject, currentTransfers) {
  let transfers = [];

  if (bookings.length > 0) {
    const firstBooking = bookings[0];
    const lastBooking = bookings[bookings.length - 1];

    // check for arrival transfers
    let arrivalTuuid = uuidv4();
    if (currentTransfers.length > 0) {
      const arrivalTransfer = currentTransfers.find((transfer) => transfer.uuid === firstBooking.id && transfer.objType === 'arrival');
      // Check if a transfer is found, otherwise return new uuid
      arrivalTuuid = arrivalTransfer?.tuuid || uuidv4();
    }

    transfers.push(createTransferObject('arrival', firstBooking, arrivalTuuid));
    //transfers.push(createTransferObject("arrivalTransfer", firstBooking));

    bookings.forEach((booking, index) => {
      // check for property transfers
      let propertyTuuid = uuidv4();
      if (currentTransfers.length > 0) {
        const propertyTransfer = currentTransfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'propertyTransfer');
        // Check if a transfer is found, otherwise return new uuid
        propertyTuuid = propertyTransfer?.tuuid || uuidv4();
      }
      transfers.push(createTransferObject('propertyTransfer', booking, propertyTuuid));
    });

    // check for departure transfers
    let departureTuuid = uuidv4();
    if (currentTransfers.length > 0) {
      const departureTransfer = currentTransfers.find((transfer) => transfer.uuid === lastBooking.id && transfer.objType === 'departure');
      // Check if a transfer is found, otherwise return new uuid
      departureTuuid = departureTransfer?.tuuid || uuidv4();
    }

    //transfers.push(createTransferObject("departureTransfer", lastBooking));
    transfers.push(createTransferObject('departure', lastBooking, departureTuuid));
  }

  return transfers;
}

function createTransferObject(objType, booking, transferUuid) {
  const { propUid, startDay, endDay, id, rooms } = booking;
  const edit = false;
  const uuid = id;
  const tuuid = transferUuid;
  let totalAd = 0,
    totalCh = 0,
    totalChi = 0,
    totalInf = 0;
  let selectedAges = [];
  let selectedAgesAdults = [];

  rooms.forEach((room) => {
    totalAd += room.ad;
    totalCh += room.ch;
    totalChi += room.chi;
    totalInf += room.inf;

    // Check if room.selectedAges exists and has length before concatenating
    if (room.selectedAges && room.selectedAges.length > 0) {
      selectedAges = selectedAges.concat(room.selectedAges);
    }
    // Check if room.selectedAgesAdults exists and has length before concatenating
    if (room.selectedAgesAdults && room.selectedAgesAdults.length > 0) {
      selectedAgesAdults = selectedAgesAdults.concat(room.selectedAgesAdults);
    }
  });

  return {
    objType,
    propUid,
    startDay,
    endDay,
    uuid,
    tuuid,
    totalAd,
    totalCh,
    totalChi,
    totalInf,
    edit,
    selectedAges,
    selectedAgesAdults,
  };
}


function getAirportCodes(airports, flightCode) {
  //const airport = airports.find((ap) => ap.code === flightCode);
  const airport = airports.find((ap) => ap.aerocrsCodes && ap.aerocrsCodes.includes(flightCode));
  console.log('CHECK flightCode:', flightCode);

  let airportCodes = airport ? [...airport.codes] : ['Not found']; // Using spread syntax to clone the array

  console.log('Initial airportCodes:', airportCodes);

  // If alternativeAirports is true, then filter the codes
  if (airport && airport.alternative) {
    console.log('Filtering because airport.alternative is true');

    const filteredAirports = airports.filter((ap) => ap.alternativeAirports === true);
    console.log('Airports with alternativeAirports=true:', filteredAirports);

    const allCodesFromFilteredAirports = filteredAirports.flatMap((ap) => ap.codes);
    console.log('All codes from filtered airports:', allCodesFromFilteredAirports);

    // const filteredCodes = allCodesFromFilteredAirports.filter((code) =>
    // 	airport.codes.includes(code)
    // );
    // console.log("Filtered codes:", filteredCodes);

    // If filteredCodes is empty, revert back to original codes
    airportCodes = allCodesFromFilteredAirports.length ? allCodesFromFilteredAirports : airport.codes;
  }

  return airportCodes;
}

function getAeroCrsCode(airports, code) {
  const airport = airports.find((ap) => ap.aerocrsCodes && ap.aerocrsCodes.includes(code));
  return airport ? airport.code : 'null';
}

function findTransferRate(rates, transferDate) {
  console.log('findTransferRate: ', JSON.stringify(rates, null, 2));
  // Convert transferDate to a Unix timestamp
  const transferTimestamp = moment(transferDate, 'YYYY-MM-DD').unix();

  // Initialize variables to track the rate with the highest stoPrice
  let highestStoPriceRate = null;
  let highestStoPrice = 0;

  // for (let rate of rates) {
  //   const validFromTimestamp = rate.validFrom.seconds;
  //   const validUntilTimestamp = rate.validUntil.seconds;

  //   // Update the highest stoPrice rate if this rate's stoPrice is the highest so far
  //   if (rate.stoPrice > highestStoPrice) {
  //     highestStoPrice = rate.stoPrice;
  //     highestStoPriceRate = rate;
  //   }

  //   console.log('highestStoPriceRate: ', highestStoPriceRate);

  //   // Check if transferDate falls within the valid range
  //   if (transferTimestamp >= validFromTimestamp && transferTimestamp <= validUntilTimestamp) {
  //     return rate;
  //   }
  // }
  //const availableAnyRates = rates.filter((rate) => rate.type === 'availableAny');

  for (let rate of rates) {
    const validFromTimestamp = rate.validFrom.seconds;
    const validUntilTimestamp = rate.validUntil.seconds;

    // Update the highest stoPrice rate if this rate's stoPrice is the highest so far
    if (rate.stoPrice > highestStoPrice) {
      highestStoPrice = rate.stoPrice;
      highestStoPriceRate = rate;
    }

    console.log('highestStoPriceRate 1: ', highestStoPriceRate);

    // Check if transferDate falls within the valid range
    if (transferTimestamp >= validFromTimestamp && transferTimestamp <= validUntilTimestamp) {
      console.log('findTransferRate: rate found', JSON.stringify(rate, null, 2));
      return rate;
    }
  }

  // Return the rate with the highest stoPrice if no rate was found in the valid range
  console.log('highestStoPriceRate 2: ', highestStoPriceRate);
  return highestStoPriceRate;
}

//Sort the possible rates in priority order.
//availableAny, propertyAirportTransfer, sameSupplierOnly, nomadGuideVehicle,
function sortAirportTransferRates(rates) {
  const typeOrder = {
    availableAny: 1,
    propertyAirportTransfer: 2,
    sameSupplierOnly: 3,
    nomadGuideVehicle: 4,
  };

  return rates.sort((a, b) => {
    const typeA = typeOrder[a.type] || 999;
    const typeB = typeOrder[b.type] || 999;

    if (typeA !== typeB) {
      return typeA - typeB;
    }

    // If types are the same, sort by supplier name
    return a.supplier.name.localeCompare(b.supplier.name);
  });
}

function findTransferRateAirportTransfers(rates, transferDate, transfer, properties, booking, arrivalDeparture) {
  console.log('findTransferRateAirportTransfers: ', JSON.stringify(rates, null, 2));
  // Convert transferDate to a Unix timestamp
  const transferTimestamp = moment(transferDate, 'YYYY-MM-DD').unix();

  let ratesMatchingDate = [];
  let ratesFilter = [];

  // get all rates matching date
  for (let rate of rates) {
    const validFromTimestamp = rate.validFrom.seconds;
    const validUntilTimestamp = rate.validUntil.seconds;

    // Check if transferDate falls within the valid range
    if (transferTimestamp >= validFromTimestamp && transferTimestamp <= validUntilTimestamp) {
      console.log('findTransferRateAirportTransfers: rate found', JSON.stringify(rate, null, 2));
      ratesMatchingDate.push(rate);
    }
  }

  // if no rates matching date, return null
  if (ratesMatchingDate.length === 0) {
    console.log('findTransferRateAirportTransfers: no rates matching date');
    return null; // return null;
  }

  // add new logic here based on ratesMatchingDate or return highestStoPriceRate/null
  // add logic for fullBoardNomad and supplierId ded3a3ed-aeaf-4495-9069-7754a649de67 = Nomad Tanzania
  for (let rate of ratesMatchingDate) {
    console.log('findTransferRateAirportTransfers: booking', JSON.stringify(booking, null, 2));
    const supplierUid = booking.supplierUid;
    if (booking.propUid === rate.property?.id && rate.type === 'propertyAirportTransfer') {
      ratesFilter.push(rate);
    } else if ((supplierUid === 'ded3a3ed-aeaf-4495-9069-7754a649de67' || booking.rateName === 'fullBoardNomad') && rate.type === 'nomadGuideVehicle') {
      ratesFilter.push(rate);
    } else if (rate.type === 'availableAny') {
      ratesFilter.push(rate);
    } else if (supplierUid === rate.supplier.id && rate.type === 'sameSupplierOnly') {
      ratesFilter.push(rate);
    }
  }

  // if no rates matching ratesFilter, return null
  if (ratesFilter.length === 0) {
    console.log('findTransferRateAirportTransfers: no rates matching ratesFilter');
    return null;
  }

  const sortedRates = sortAirportTransferRates(ratesFilter);

  // if nomadGuideVehicle rate exists in sortedRates, choose the first one
  if (sortedRates.some((rate) => rate.type === 'nomadGuideVehicle')) {
    const nomadGuideVehicleRate = sortedRates.find((rate) => rate.type === 'nomadGuideVehicle');
    return nomadGuideVehicleRate;
  } else if (properties[getPropObj(booking.propUid, properties)].handlesOwnAirportTransfer) {
    //If properties[getPropObj(booking.propUid, properties)].handlesOwnAirportTransfer- choose the first item in sortedRates where type is either "propertyAirportTransfer" or "sameSupplierOnly" ELSE  return null
    const propertyAirportTransferRate = sortedRates.find((rate) => rate.type === 'propertyAirportTransfer' || rate.type === 'sameSupplierOnly');
    return propertyAirportTransferRate ? propertyAirportTransferRate : null;
  } else {
    return sortedRates[0]; // ! check that we don't want to pick the property with 'availableAny' not the first in the array
  }
}

function findTransferRatesAll(rates, transferDate) {
  // Convert transferDate to a Unix timestamp
  const transferTimestamp = moment(transferDate, 'YYYY-MM-DD').unix();

  // Initialize an array to store valid rates
  let validRates = [];

  for (let rate of rates) {
    const validFromTimestamp = rate.validFrom.seconds;
    const validUntilTimestamp = rate.validUntil.seconds;

    // Check if transferDate falls within the valid range
    if (transferTimestamp >= validFromTimestamp && transferTimestamp <= validUntilTimestamp) {
      validRates.push(rate);
    }
  }

  // Return the array of valid rates
  return validRates;
}

function calculateRateTransferUnits(adults, children, infants, maxCapacity) {
  const totalPeople = adults + children + infants;
  const groups = Math.ceil(totalPeople / maxCapacity);
  return groups;
}

function calculateRateTransferUnitsPax(pax, maxCapacity) {
  const totalPeople = pax;
  const groups = Math.ceil(totalPeople / maxCapacity);
  return groups;
}

function airportTransferTotals(transfer) {
  let total = 0;
  if (transfer.formValues.includeDepartureTransfer && transfer.formValues.includeArrivalTransfer) {
    total = transfer.formValues.departureTransferSaleTotal + transfer.formValues.arrivalTransferSaleTotal;
  } else if (transfer.formValues.includeDepartureTransfer && !transfer.formValues.includeArrivalTransfer) {
    total = transfer.formValues.departureTransferSaleTotal;
  } else if (!transfer.formValues.includeDepartureTransfer && transfer.formValues.includeArrivalTransfer) {
    total = transfer.formValues.arrivalTransferSaleTotal;
  }

  if (transfer.formValues.includeDepartureVip && transfer.formValues.includeArrivalVip) {
    total += transfer.formValues.departureSaleTotalVip + transfer.formValues.arrivalSaleTotalVip;
  } else if (transfer.formValues.includeDepartureVip && !transfer.formValues.includeArrivalVip) {
    total += transfer.formValues.departureSaleTotalVip;
  } else if (!transfer.formValues.includeDepartureVip && transfer.formValues.includeArrivalVip) {
    total += transfer.formValues.arrivalSaleTotalVip;
  }

  return total;
}

const arrivalDepartureTimeOptions = [
  { value: 'Before Breakfast', label: 'Before Breakfast' },
  { value: 'Before Lunch', label: 'Before Lunch' },
  { value: 'After lunch', label: 'After lunch' },
  { value: 'Before Dinner', label: 'Before Dinner' },
  { value: 'After dinner time', label: 'After dinner time' },
];

function filterTransferRouteRatesSingle(transferRouteRates, properties, matchingBooking, selectedLocation) {
  // Get supplier IDs from properties based on matching bookings
  const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  const propertyId1 = matchingBooking.propUid;
  //const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;
  const selectedRate1 = matchingBooking.rateName;

  // Check if either selected rate is "fullBoardNomad"
  const isFullBoardNomad = selectedRate1 === 'fullBoardNomad';
  const nomadSupplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  const isNomadGamePackage = selectedRate1 === 'gamePackage' && supplierId1 === nomadSupplierId;
  // Use a Set to keep track of added supplier IDs
  const isSelectedLocationAirport = selectedLocation && selectedLocation.type === 'airport';

  const addedSupplierIds = new Set();
  const filteredRates = [];

  for (let rate of transferRouteRates) {
    if (rate.active) {
      // Check if if rate is airport transfer and that the property matches the rate property
      const isAirportTransfer = rate.type === 'propertyAirportTransfer';
      const airportPropertyMatch = rate.property && rate.property.id === propertyId1;

      if (isFullBoardNomad || isNomadGamePackage) {
        // If fullBoardNomad, include "Safari Vehicle" rates and rates meeting other conditions
        if (rate.type === 'nomadGuideVehicle' || (rate.supplier.id === supplierId1 && !isAirportTransfer) || rate.availableAll || (isAirportTransfer && isSelectedLocationAirport && airportPropertyMatch)) {
          filteredRates.push(rate);
          addedSupplierIds.add(rate.supplier.id);
        }
      } else {
        // For non-fullBoardNomad, exclude "Safari Vehicle" rates and handle propertyAirportTransfer separately
        if (
          (rate.type !== 'nomadGuideVehicle' && rate.type !== 'propertyAirportTransfer' && (rate.supplier.id === supplierId1 || rate.availableAll)) ||
          (isAirportTransfer && airportPropertyMatch && isSelectedLocationAirport)
        ) {
          filteredRates.push(rate);
          addedSupplierIds.add(rate.supplier.id);
        }
      }
    }
  }

  return filteredRates;
}

function filterTransferRouteRates(transferRouteRates, properties, matchingBooking, matchingBookingNext) {
  //HPFUNCTION
  // Get supplier IDs from properties based on matching bookings
  const supplierId1 = properties[getPropObj(matchingBooking.propUid, properties)].supplier.id;
  const supplierId2 = properties[getPropObj(matchingBookingNext.propUid, properties)].supplier.id;
  const selectedRate1 = matchingBooking.rateName;
  const selectedRate2 = matchingBookingNext.rateName;

  // Check if either selected rate is "fullBoardNomad"
  const isFullBoardNomad = selectedRate1 === 'fullBoardNomad' || selectedRate2 === 'fullBoardNomad';
  const nomadSupplierId = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  const isNomadGamePackage = (selectedRate1 === 'gamePackage' && supplierId1 === nomadSupplierId) || (selectedRate2 === 'gamePackage' && supplierId2 === nomadSupplierId);
  // Use a Set to keep track of added supplier IDs
  const addedSupplierIds = new Set();
  const filteredRates = [];

  for (let rate of transferRouteRates) {
    if (rate.active) {
      if (isFullBoardNomad || isNomadGamePackage) {
        // If fullBoardNomad, include "Safari Vehicle" rates and rates meeting other conditions
        if (rate.type !== 'propertyAirportTransfer' && (rate.type === 'nomadGuideVehicle' || rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2 || rate.availableAll)) {
          filteredRates.push(rate);
          addedSupplierIds.add(rate.supplier.id);
        }
      } else {
        // For non-fullBoardNomad, exclude "Safari Vehicle" rates
        if (rate.type !== 'nomadGuideVehicle' && rate.type !== 'propertyAirportTransfer' && (rate.supplier.id === supplierId1 || rate.supplier.id === supplierId2 || rate.availableAll)) {
          filteredRates.push(rate);
          addedSupplierIds.add(rate.supplier.id);
        }
      }
    }
  }

  return filteredRates;
}


function processVip(airports, airportCode, transfer, arrivalDeparture) {
  console.log('processVip')
  //const airportCode = transfer.objType === 'arrival' || transfer.objType === 'propertyTransfer' ? selectedFlight.tocode : selectedFlight.fromcode
  const airport = airports.find((airport) => airport.code === airportCode);
  console.log('airportCode ', airportCode); 
  console.log('airport ', JSON.stringify(airport, null, 2));
  const transferDate = transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay;

  if (!airport?.airportServices || !Array.isArray(airport.airportServices)) {
    console.log('No airport services found');
    return null;
  }

  const transferMoment = moment(transferDate);
  console.log('Transfer date:', transferMoment.format());
  console.log('arrivalDeparture:', arrivalDeparture);

  const matchingServices = airport.airportServices.filter(service => {
    if (!service.active) {
      console.log('Service not active');
      return false;
    }

    const validFrom = moment(service.validFrom);
    const validUntil = moment(service.validUntil);

    console.log('Service dates:', validFrom.format(), 'to', validUntil.format());
    console.log('Is transfer date between?', transferMoment.isBetween(validFrom, validUntil, null, '[]'));

    const dateMatches = transferMoment.isBetween(validFrom, validUntil, null, '[]');
    
    const arrivalDepartureMatches = 
      (arrivalDeparture === 'arrival' && service.arrival) ||
      (arrivalDeparture === 'departure' && service.departure);

    console.log('Arrival/Departure matches:', arrivalDepartureMatches);

    return dateMatches && arrivalDepartureMatches;
  });

  if (matchingServices.length > 0) {
    console.log('Matching airport service(s) found:', matchingServices.length);
    // Return the first matching service, or you could implement some logic to choose the best one
    return matchingServices[0];
  }

  console.log('No matching airport service found');
  return null;
}

function hideOwnArrangements(advancedMode) {
  if (advancedMode) {
    return false;
  } else {
    return true;
  }
}

export {
  getSafariTransfers,
  findTransfer,
  //findCustomLocation,
  getAirportCode,
  getAirportName,
  getAirportProperties,
  availableTransfers,
  getAirportDistance,
  getOriginPropertyDetails,
  findSecondToLastPropertyTransferWithFormValues,
  getAirportDetails,
  getParkDetails,
  buildCustomLocations,
  getLocationDetails,
  findMatchingFlightRoutes,
  getAirportCodeFromUid,
  createTransferObject,
  createTransfersArray,
  getAirportCodes,
  getAeroCrsCode,
  findTransferRate,
  calculateRateTransferUnits,
  airportTransferTotals,
  calculateRateTransferUnitsPax,
  arrivalDepartureTimeOptions,
  findTransferRatesAll,
  filterTransferRouteRatesSingle,
  filterTransferRouteRates,
  findTransferRateAirportTransfers,
  processVip,
  hideOwnArrangements,
  findCustomLocationPeriod,
  findCustomLocationPeriodProperty,
  checkClosure,
};
