import React, { useEffect, useState, Fragment } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../../db/firebase.config';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../../components/ui/Dropdown';
import { EllipsisHorizontalIcon, ClockIcon, InformationCircleIcon, CheckCircleIcon, CalendarIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition, Checkbox } from '@headlessui/react';
import { format, parseISO, isBefore } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DocumentMinusIcon } from '@heroicons/react/24/outline';
import InfoBox from '../../../../components/InfoBox';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import { toast } from 'react-toastify';
import { isAfter, startOfDay } from 'date-fns';
import { RESERVATION_STAGES, getReservationStageLabel } from '../../../../constants/lookups';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

function SupplierReservations({ itineraryId, icon: Icon, itineraryStatus, dateExpiry, supplierReservations }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingReservation, setEditingReservation] = useState(null);
  const [newExpiryDate, setNewExpiryDate] = useState(null);
  const [newReservationStage, setNewReservationStage] = useState('');
  const [unableToConfirm, setUnableToConfirm] = useState(false);
  const [remindDate, setRemindDate] = useState(null);
  const [unableToConfirmDetails, setUnableToConfirmDetails] = useState('');

  //Handle delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingReservation, setDeletingReservation] = useState(null);
  //Filter out deleted reservations
  const activeReservations = supplierReservations.filter((reservation) => !reservation.deleted);
  const nonFeaturedCount = activeReservations.filter((r) => !r.featuredInItinerary).length;

  console.log('All supplier reservations:', supplierReservations.length);
  console.log('Active reservations:', activeReservations.length);
  console.log('Non-featured count:', nonFeaturedCount);
  console.log('Reservations data:', {
    all: supplierReservations,
    active: activeReservations,
    nonFeatured: activeReservations.filter((r) => !r.featuredInItinerary),
  });

  const [error, setError] = useState('');

  const shouldShowProvisionalRequired = () => {
    return itineraryStatus === 'pendingProvisional' || itineraryStatus === 'provisional';
  };

  const shouldShowExpiryWarning = () => {
    if (!['pendingProvisional', 'provisional'].includes(itineraryStatus)) return false;
    if (!newExpiryDate || !dateExpiry) return false;

    const expiryUtc = utcToZonedTime(new Date(Date.UTC(newExpiryDate.getUTCFullYear(), newExpiryDate.getUTCMonth(), newExpiryDate.getUTCDate(), 23, 59, 59, 999)), 'UTC');
    return isBefore(expiryUtc, dateExpiry.toDate());
  };

  const formatCurrency = (value) => {
    return parseFloat(value || 0).toFixed(2);
  };

  const handleEdit = (reservation) => {
    if (reservation.supplierUid === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
      toast.info("This supplier's reservation status is automatically updated based on the itinerary status.");
      return;
    }

    setEditingReservation(reservation);
    setNewExpiryDate(reservation.dateProvisionalExpiry ? utcToZonedTime(reservation.dateProvisionalExpiry.toDate(), 'UTC') : null);
    setNewReservationStage(reservation.reservationStage);
    setUnableToConfirm(reservation.unableToConfirm || false);
    setRemindDate(reservation.remindDate ? parseISO(reservation.remindDate) : null);
    setUnableToConfirmDetails(reservation.unableToConfirmDetails || '');
    setIsEditModalOpen(true);
  };

  const handleUpdate = async () => {
    if (!editingReservation) return;

    // Validate expiry date for Provisional status
    if (newReservationStage === 'provisional') {
      if (!newExpiryDate) {
        setError('Expiry date is required for Provisional status');
        return;
      }

      if (!isAfter(newExpiryDate, startOfDay(new Date()))) {
        setError('Expiry date cannot be in the past');
        return;
      }
    }

    setError('');

    try {
      const reservationRef = doc(db, 'itineraries', itineraryId, 'supplierReservations', editingReservation.id);

      let updateData = {
        reservationStage: newReservationStage,
        unableToConfirm: newReservationStage === 'confirmed' ? unableToConfirm : false,
        remindDate: unableToConfirm && remindDate ? format(remindDate, 'yyyy-MM-dd') : null,
        unableToConfirmDetails: unableToConfirm ? unableToConfirmDetails : '',
      };

      if (newReservationStage === 'provisional' && newExpiryDate) {
        // Set expiry to 11:59:59.999 PM UTC on the selected day
        const expiryTimestamp = Timestamp.fromMillis(Date.UTC(newExpiryDate.getUTCFullYear(), newExpiryDate.getUTCMonth(), newExpiryDate.getUTCDate(), 23, 59, 59, 999));
        updateData.dateProvisionalExpiry = expiryTimestamp;
      } else {
        updateData.dateProvisionalExpiry = null;
      }

      await updateDoc(reservationRef, updateData);

      setIsEditModalOpen(false);
      toast.success('Reservation updated successfully');
    } catch (error) {
      console.error('Error updating reservation:', error);
      toast.error('Failed to update reservation');
    }
  };

  const handleDelete = async (reservation) => {
    try {
      const reservationRef = doc(db, 'itineraries', itineraryId, 'supplierReservations', reservation.id);
      await updateDoc(reservationRef, {
        deleted: true,
      });
      toast.success('Supplier reservation removed successfully');
    } catch (error) {
      console.error('Error deleting reservation:', error);
      toast.error('Failed to remove supplier reservation');
    }
  };

  const formatExpiryDate = (date) => {
    if (!date) return 'N/A';
    const utcDate = utcToZonedTime(date instanceof Timestamp ? date.toDate() : date, 'UTC');
    return format(utcDate, "dd MMM yyyy 'at' HH:mm 'UTC'", { timeZone: 'UTC' });
  };

  const getReservationStageStyle = (stage) => {
    switch (stage) {
      case 'new':
        return { bgColor: 'bg-gray-100', textColor: 'text-gray-700', Icon: InformationCircleIcon };
      case 'provisionalRequested':
        return { bgColor: 'bg-yellow-100', textColor: 'text-yellow-700', Icon: CalendarIcon };
      case 'provisional':
        return { bgColor: 'bg-blue-100', textColor: 'text-blue-700', Icon: ClockIcon };
      case 'confirmationRequested':
        return { bgColor: 'bg-orange-100', textColor: 'text-orange-700', Icon: CalendarIcon };
      case 'confirmed':
        return { bgColor: 'bg-green-100', textColor: 'text-green-700', Icon: CheckCircleIcon };
      case 'cancelled':
      case 'rejected':
        return { bgColor: 'bg-red-100', textColor: 'text-red-700', Icon: InformationCircleIcon };
      default:
        return { bgColor: 'bg-gray-100', textColor: 'text-gray-700', Icon: InformationCircleIcon };
    }
  };

  return (
    <div className="space-y-4">
      {/* Title Section */}
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">Supplier Reservations</h3>
      </div>

      {/* Info Box Section */}
      {nonFeaturedCount > 0 && (
        <InfoBox
          icon={ExclamationTriangleIcon}
          text={`You have ${nonFeaturedCount} supplier${nonFeaturedCount > 1 ? 's' : ''} that no longer appear in the itinerary. Please review and remove if they are no longer needed.`}
          bgColor="bg-amber-50"
          textColor="text-amber-700"
        />
      )}

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        {activeReservations.length === 0 ? (
          <div className="text-center py-4 text-gray-500">No supplier reservations found.</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Supplier Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Res Stage
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Expiry Date
                  </th>
                  {shouldShowProvisionalRequired() && (
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Provisional Required
                    </th>
                  )}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {activeReservations.map((reservation) => {
                  const { bgColor, textColor, Icon: StageIcon } = getReservationStageStyle(reservation.reservationStage);
                  return (
                    <tr key={reservation.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center space-x-2">
                          <div className="text-sm font-medium text-gray-900">{reservation.supplierName}</div>
                          {!reservation.featuredInItinerary && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-700 whitespace-nowrap">
                              <DocumentMinusIcon className="mr-1 h-3.5 w-3.5" />
                              Not Featured
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full ${bgColor} ${textColor}`}>
                          <StageIcon className="w-4 h-4 mr-1" />
                          {getReservationStageLabel(reservation.reservationStage)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{reservation.dateProvisionalExpiry ? formatExpiryDate(reservation.dateProvisionalExpiry) : 'N/A'}</div>
                      </td>
                      {shouldShowProvisionalRequired() && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center">
                            {reservation.provisionalRequired ? <CheckIcon className="h-5 w-5 text-green-500" /> : <XMarkIcon className="h-5 w-5 text-red-500" />}
                          </div>
                        </td>
                      )}
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Dropdown>
                          <DropdownButton plain aria-label="More options">
                            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" />
                          </DropdownButton>
                          <DropdownMenu anchor="bottom end">
                            <DropdownItem onClick={() => handleEdit(reservation)}>Edit</DropdownItem>
                            {!reservation.featuredInItinerary && (
                              <DropdownItem
                                onClick={() => {
                                  setDeletingReservation(reservation);
                                  setIsDeleteModalOpen(true);
                                }}
                                className="text-red-600 hover:text-red-700 hover:bg-red-50"
                              >
                                Remove
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Transition appear show={isEditModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsEditModalOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Edit Supplier Reservation
                  </Dialog.Title>
                  <div className="mt-2 space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Reservation Stage</label>
                      <select
                        value={newReservationStage}
                        onChange={(e) => {
                          setNewReservationStage(e.target.value);
                          if (e.target.value !== 'provisional') {
                            setNewExpiryDate(null);
                          }
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      >
                        {Object.entries(RESERVATION_STAGES).map(([value, label]) => {
                          // For cancelled itineraries, only show cancelled and confirmed options
                          if (itineraryStatus === 'cancelled') {
                            return ['cancelled', 'confirmed'].includes(value) ? (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ) : null;
                          }

                          // Normal filtering for other statuses
                          if (value === 'cancelled' && !['pendingConfirmation', 'confirmed', 'pendingCancellation', 'cancelled'].includes(itineraryStatus)) {
                            return null;
                          }
                          if ((value === 'confirmationRequested' || value === 'confirmed') && !['pendingConfirmation', 'confirmed'].includes(itineraryStatus)) {
                            return null;
                          }
                          return (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {newReservationStage === 'provisional' && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Expiry Date <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                          <DatePicker
                            selected={newExpiryDate}
                            onChange={(date) => setNewExpiryDate(utcToZonedTime(date, 'UTC'))}
                            dateFormat="dd MMM yyyy"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            popperClassName="absolute z-50"
                            popperPlacement="bottom-start"
                            popperModifiers={[
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 8],
                                },
                              },
                              {
                                name: 'preventOverflow',
                                options: {
                                  rootBoundary: 'viewport',
                                  tether: false,
                                  altAxis: true,
                                },
                              },
                            ]}
                          />
                        </div>
                        {newExpiryDate && (
                          <>
                            <p className="mt-1 text-sm text-gray-500">
                              Expiry will be set to:{' '}
                              {format(
                                utcToZonedTime(new Date(Date.UTC(newExpiryDate.getUTCFullYear(), newExpiryDate.getUTCMonth(), newExpiryDate.getUTCDate(), 23, 59, 59, 999)), 'UTC'),
                                "dd MMM yyyy 'at' HH:mm 'UTC (GMT)'",
                                { timeZone: 'UTC' },
                              )}
                            </p>
                            {shouldShowExpiryWarning() && (
                              <p className="mt-2 text-sm text-amber-600">
                                This reservation will now become the closest provisional to expire. The booking expiry date will be updated from{' '}
                                {format(dateExpiry.toDate(), "dd MMM yyyy 'at' HH:mm 'UTC'")} to{' '}
                                {format(
                                  utcToZonedTime(new Date(Date.UTC(newExpiryDate.getUTCFullYear(), newExpiryDate.getUTCMonth(), newExpiryDate.getUTCDate(), 23, 59, 59, 999)), 'UTC'),
                                  "dd MMM yyyy 'at' HH:mm 'UTC'",
                                )}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {newReservationStage === 'confirmed' && (
                      <>
                        <div className="flex items-center">
                          <Checkbox
                            checked={unableToConfirm}
                            onChange={setUnableToConfirm}
                            className={`${unableToConfirm ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                          >
                            <span className="sr-only">Unable to confirm all services</span>
                            <span className={`${unableToConfirm ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                          </Checkbox>
                          <span className="ml-3 text-sm font-medium text-gray-700">Unable to confirm all services</span>
                        </div>

                        {unableToConfirm && (
                          <>
                            <div className="relative">
                              <label className="block text-sm font-medium text-gray-700">Remind me on</label>
                              <DatePicker
                                selected={remindDate}
                                onChange={(date) => setRemindDate(date)}
                                dateFormat="dd MMM yyyy"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                popperClassName="absolute z-50"
                                popperPlacement="bottom-start"
                                popperModifiers={[
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, 8],
                                    },
                                  },
                                  {
                                    name: 'preventOverflow',
                                    options: {
                                      rootBoundary: 'viewport',
                                      tether: false,
                                      altAxis: true,
                                    },
                                  },
                                ]}
                              />
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700">Details</label>
                              <textarea
                                value={unableToConfirmDetails}
                                onChange={(e) => setUnableToConfirmDetails(e.target.value)}
                                rows={4}
                                className="mt-1 pb-8 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsEditModalOpen(false)} color="dark" />
                    <ButtonPrimarySmall text="Update" onClick={handleUpdate} color="dark" />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Modal for deleting */}
      <Transition appear show={isDeleteModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDeleteModalOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Remove Supplier Reservation
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Are you sure you want to remove this supplier reservation? This action cannot be undone.</p>
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <ButtonOutlineSmall text="Cancel" onClick={() => setIsDeleteModalOpen(false)} color="dark" />
                    <ButtonPrimarySmall
                      text="Remove"
                      onClick={() => {
                        handleDelete(deletingReservation);
                        setIsDeleteModalOpen(false);
                      }}
                      color="dark"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

// Tab name
SupplierReservations.displayName = 'Supplier Reservations';

export default SupplierReservations;
