import React from 'react';

function MobileLocationsTable({ customLocations }) {
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle Firestore Timestamp object
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    }
    
    // Handle Unix timestamp in milliseconds
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const currentDate = new Date();
  const futureLocations = customLocations
    .filter((location) => {
      const endDate = location.periodEndDate.toDate 
        ? location.periodEndDate.toDate() 
        : new Date(location.periodEndDate);
      return endDate > currentDate;
    })
    .sort((a, b) => {
      const aDate = a.periodStartDate.toDate 
        ? a.periodStartDate.toDate() 
        : new Date(a.periodStartDate);
      const bDate = b.periodStartDate.toDate 
        ? b.periodStartDate.toDate() 
        : new Date(b.periodStartDate);
      return aDate - bDate;
    });

  return (
    <div className="bg-white rounded-lg border border-[#f0ece7] flex flex-col p-6 gap-2">
      <div className="flex justify-between items-center">
        <h3 className="text-[#162716] text-lg font-semibold leading-[27px]">Mobile Locations</h3>
      </div>
      {futureLocations.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-[#f4f3f2] rounded-lg">
                <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Period</th>
                <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Location</th>
                <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Closest Airport</th>
              </tr>
            </thead>
            <tbody>
              {futureLocations.map((location, index) => (
                <tr key={index} className="border-b border-[#f0ece7]">
                  <td className="p-4 text-gray-600 text-sm">
                    {formatDate(location.periodStartDate)} - {formatDate(location.periodEndDate)}
                  </td>
                  <td className="p-4 text-gray-600 text-sm">{location.parkName}</td>
                  <td className="p-4 text-gray-600 text-sm">{location.airportName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-600 text-sm">No upcoming mobile locations scheduled.</p>
      )}
    </div>
  );
}

export default MobileLocationsTable;