import moment from 'moment';
import { resRequestRatesFilter } from './platformFunctions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const handlePaste = (
  position = 'bottom',
  itemId,
  customFinalCut,
  customFinalItems,
  searchId,
  pickupDropoff,
  setCustomFinalItems,
  setCustomFinalCut,
  parentDate,
  departure,
  vip,
  arrivalDeparture
) => {
  if (customFinalCut) {
    console.log('customFinalCut', JSON.stringify(customFinalCut, null, 2));
    // Find the index of the item to be pasted
    const cutIndex = customFinalItems.findIndex((item) => item.uuid === customFinalCut.uuid);

    if (cutIndex !== -1) {
      // Remove the item to be pasted from its previous position
      const updatedItems = [...customFinalItems];
      updatedItems.splice(cutIndex, 1);

      // Generate a new UUID for the parent item
      const newParentUuid = uuidv4();

      // Update fields including dates and parent/child relationships
      let updatedFields = customFinalCut.fields.map(field => {
        const newField = {
          ...field,
          uuid: field.parentUuid ? uuidv4() : newParentUuid, // New UUID for child items
        };

        // Update dates if parentDate is provided
        if (parentDate !== false) {
          newField.date = parentDate;
        }

        // Update parentUuid for child items to reference the new parent UUID
        if (field.parentUuid) {
          newField.parentUuid = newParentUuid;
        }

        return newField;
      });

      const pastedItem = {
        ...customFinalCut,
        id: searchId,
        uuid: newParentUuid,
        collapsed: true,
        pickupDropoff: pickupDropoff,
        departure: departure,
        fields: updatedFields,
        ...(vip === true ? { vip: true } : { vip: false }),
        ...(arrivalDeparture !== undefined ? { arrivalDeparture } : {}),
      };

      // Insert the item at the appropriate position
      if (position === 'above') {
        const currentIndex = updatedItems.findIndex((item) => item.uuid === itemId);
        if (currentIndex !== -1) {
          updatedItems.splice(currentIndex, 0, pastedItem);
        } else {
          updatedItems.unshift(pastedItem);
        }
      } else if (position === 'top') {
        updatedItems.unshift(pastedItem);
      } else {
        updatedItems.push(pastedItem);
      }

      // Set the state with the updatedItems array
      setCustomFinalItems(updatedItems);
      setCustomFinalCut(null);
    }
  }
};

const handlePasteOriginal = (position = 'bottom', itemId, customFinalCut, customFinalItems, searchId, pickupDropoff, setCustomFinalItems, setCustomFinalCut, parentDate, departure, vip, arrivalDeparture) => {
  if (customFinalCut) {
    console.log('customFinalCut', JSON.stringify(customFinalCut, null, 2));
    // Find the index of the item to be pasted
    const cutIndex = customFinalItems.findIndex((item) => item.uuid === customFinalCut.uuid);

    if (cutIndex !== -1) {
      // Remove the item to be pasted from its previous position
      const updatedItems = [...customFinalItems];
      updatedItems.splice(cutIndex, 1);

      // Update the date in each 'fields' object to 'parentDate'
      let updatedFields = customFinalCut.fields;
      parentDate = false;
      if (parentDate !== false) {
        updatedFields = customFinalCut.fields.map((field) => ({
          ...field,
          date: parentDate, // Replace 'date' with 'parentDate'
        }));
      }
      console.log('vip paste', vip);
      const pastedItem = {
        ...customFinalCut,

        id: searchId, // Use the searchId of the current page
        uuid: uuidv4(), // Generate a new UUID for the pasted item
        collapsed: true, // Set the collapsed field to true by default
        //...(pickupDropoff !== false ? { pickupDropoff: pickupDropoff } : {}),
        pickupDropoff: pickupDropoff,
        departure: departure,
        fields: updatedFields,
        //...(departure ? { departure: departure } : {}),
        // ...(pickupDropoff === 'pickup' || pickupDropoff === 'dropoff' ? { pickupDropoff: pickupDropoff } : {}),
        // Other fields can remain the same or be modified as needed
        ...(vip === true ? { vip: true } : { vip: false }),
        ...(arrivalDeparture !== undefined ? { arrivalDeparture } : {}),
      };

      if (position === 'above') {
        const currentIndex = updatedItems.findIndex((item) => item.uuid === itemId);

        if (currentIndex !== -1) {
          updatedItems.splice(currentIndex, 0, pastedItem);
        } else {
          // If the item with the specified uuid is not found, add it to the top by default
          updatedItems.unshift(pastedItem);
        }
      } else if (position === 'top') {
        updatedItems.unshift(pastedItem);
      } else {
        updatedItems.push(pastedItem);
      }

      // Set the state with the updatedItems array (pasted item removed and new item added)
      setCustomFinalItems(updatedItems);
      setCustomFinalCut(null); //  clear the cut item after pasting
    }
  }
};

const deleteItem = (itemId, customFinalItems, setCustomFinalItems) => {
  const updatedItems = customFinalItems.filter((item) => item.uuid !== itemId);
  setCustomFinalItems(updatedItems);
};

const handleCut = (item, isDeparture, setCustomFinalCut) => {
  setCustomFinalCut({ ...item, isDeparture });
};

const addItem = (position, itemId, searchId, pickupDropoff, handleOpenSlideOut, setAddFinalItemDetails, departure, vip, arrivalDeparture) => {
  //
  //alert(arrivalDeparture);
  const addItemDetails = {
    position: position,
    itemId: itemId,
    searchId: searchId,
    uuid: uuidv4(),
    ...(pickupDropoff ? { pickupDropoff: pickupDropoff } : {}),
    ...(departure ? { departure: departure } : {}),
    ...(vip ? { vip: vip } : {}),
    ...(arrivalDeparture !== undefined ? { arrivalDeparture } : {}),
  };
  setAddFinalItemDetails(addItemDetails);
  handleOpenSlideOut();
};

// CUSTOM FINAL PAGE DATA

const handleCheckUnallocated = (customFinalItems, setCustomFinalItems, bookings, transfers, activitiesData) => {
  const flattenActivities = (activitiesData) => {
    return activitiesData.reduce((acc, item) => {
      const flattenedActivities = item.activities.flat().map((activity) => ({
        ...activity,
        parent: item, // Here we store the entire item as 'parent'
      }));
      return [...acc, ...flattenedActivities];
    }, []);
  };

  let hasUnallocatedChanges = false; // Flag to check if we made any changes

  customFinalItems.forEach((item, index) => {
    const existsInBookings = bookings.some((booking) => booking.id === item.id);
    const existsInTransfers = transfers.some(
      (transfer) =>
        (transfer.tuuid === item.id && !item.pickupDropoff) ||
        (transfer.tuuid === item.id && item.pickupDropoff === 'dropoff' && transfer.formValues?.includeDepartureTransfer === true) ||
        (transfer.tuuid === item.id && item.pickupDropoff === 'pickup' && transfer.formValues?.includeArrivalTransfer === true),
    );

    // Flatten the activities to include their parent data
    const flatActivities = flattenActivities(activitiesData);
    const existsInActivities = flatActivities.some((activity) => activity.uuid === item.id);

    // Check for departure transfers where formValues is defined
    const existsMismatchedDepartureItem = item.departure && transfers.some((transfer) => transfer.objType === 'departure' && transfer.formValues && transfer.tuuid !== item.id);

    const existsMismatchedDepartureTransferItem = item.departure && transfers.some((transfer) => transfer.objType === 'departure' && !transfer.formValues && transfer.tuuid === item.id);
    const existsMismatchedArrivalTransfer = transfers.some((transfer) => transfer.objType === 'arrival' && !transfer.formValues && transfer.tuuid === item.id);
    const existsMismatchedPropertyTransfer = transfers.some((transfer) => transfer.objType === 'propertyTransfer' && !transfer.formValues && transfer.tuuid === item.id);
    const existsMismatchedDepartureTransfer = transfers.some((transfer) => transfer.objType === 'departure' && !transfer.formValues && transfer.tuuid === item.id);

    // Update the item's id to "unallocated" if it doesn't exist in bookings, transfers, or activities,
    // or if there's a mismatched departure transfer
    if (
      (!existsInBookings && !existsInTransfers && !existsInActivities) ||
      existsMismatchedDepartureItem ||
      existsMismatchedDepartureTransferItem ||
      existsMismatchedArrivalTransfer ||
      existsMismatchedPropertyTransfer ||
      existsMismatchedDepartureTransfer
    ) {
      customFinalItems[index].id = 'unallocated';
      hasUnallocatedChanges = true; // We have unallocated items
    }
  });

  // If we made changes to the array, update the state to trigger a re-render
  if (hasUnallocatedChanges) {
    setCustomFinalItems([...customFinalItems]);
  }
};

// const makeAllUnallocated = (customFinalItems, setCustomFinalItems) => {
//   customFinalItems.forEach((item, index) => {
//     customFinalItems[index].id = 'unallocated';
//   });
//   setCustomFinalItems([...customFinalItems]);
// };

function checkUnallocatedItems(customFinalItems) {
  // Iterate over the array and check if any item has an id of 'unallocated'
  const hasUnallocated = customFinalItems.some((item) => item.id === 'unallocated');

  // If there's an unallocated item, show a toast error message
  if (hasUnallocated) {
    //toast.error("Move or delete items from 'Unallocated items' before proceeding.");
    return true; // Indicate that unallocated items were found
  }

  return false; // No unallocated items found
}

function setAddToFalse(customFinalItems) {
  return customFinalItems.map((item) => ({
    ...item,
    add: false,
  }));
}

// Function for calculating the discount for Hornbill bookings on early access
function hornbillDiscount(bookings, properties) {
  const nomadTanzaniaBookings = bookings.filter((booking) => booking.supplierUid === 'ded3a3ed-aeaf-4495-9069-7754a649de67');
  console.log("nomadTanzaniaBookings", nomadTanzaniaBookings)
  const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
  console.log("totalNomadTanzaniaDays", totalNomadTanzaniaDays)
  if (totalNomadTanzaniaDays > 4) {
    let discount;
    let startDay = bookings[0].startDay; // "2024-12-15"
    const startDate = moment(startDay, 'YYYY-MM-DD');
    const comparisonDate = moment('2025-05-01', 'YYYY-MM-DD');
    
    if (startDate.isBefore(comparisonDate)) {
      discount = 700;
    } else {
      discount = 250;
    }
    return discount;
  } else {
    return false;
  }
}

export { handlePaste, handleCut, deleteItem, addItem, handleCheckUnallocated, checkUnallocatedItems, setAddToFalse, hornbillDiscount };
