import { Timestamp, doc, serverTimestamp, updateDoc, where, query, collection, getDocs } from 'firebase/firestore';

import axios from 'axios';
import { db } from '../../../../db/firebase.config'; // Ensure this import is correct for your project
import { generateExtensionEmail } from '../utils/emailTemplates';

export const ExtendProvisionalStatus = async (booking, newExpiryDate, userUid, user, sendNotificationEmail, copySelfEmail, consultant) => {
  const { resReqReservationId, uid } = booking;

  // Convert input date to UTC and set to start of day
  const inputDateUTC = new Date(Date.UTC(newExpiryDate.getUTCFullYear(), newExpiryDate.getUTCMonth(), newExpiryDate.getUTCDate()));

  console.log('Input newExpiryDate (local):', newExpiryDate);
  console.log('Input newExpiryDate (UTC start of day):', inputDateUTC);

  try {
    if (resReqReservationId) {
      // ResReq update logic
      const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
      const url = '/api/resreq';
      const resReqDate = inputDateUTC.toISOString().split('T')[0];

      const data = JSON.stringify({
        method: 'rv_update_status',
        params: [
          {
            bridge_username: '',
            bridge_password: '',
            link_id: link_id,
          },
          resReqReservationId,
          '20',
          resReqDate,
        ],
        id: 1,
      });
 

      console.log('Date sent to ResRequest:', resReqDate);

      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      console.log('ResReq response:', response.data);

      if (response.data.id !== 1 || response.data.error !== null || response.data.result !== true) {
        console.error('Unexpected ResReq response:', response.data);
        throw new Error('ResReq update failed');
      }
    } else {
      console.log('Skipping ResReq update as resReqReservationId does not exist');
    }

    // 2. Update Firestore
        //  // Removed Because Now we directly update the supplier reservation
    // const bookingRef = doc(db, 'itineraries', uid);
    try {
      console.log('Attempting to update Firestore document:', uid);

      // Set expiry to end of UTC day (23:59:59.999)
      const expiryTimestamp = Timestamp.fromMillis(Date.UTC(inputDateUTC.getUTCFullYear(), inputDateUTC.getUTCMonth(), inputDateUTC.getUTCDate(), 23, 59, 59, 999));

         // Update ResReq supplier reservation if it exists
         const supplierReservationsRef = collection(db, 'itineraries', uid, 'supplierReservations');
         const q = query(supplierReservationsRef, where('supplierUid', '==', 'ded3a3ed-aeaf-4495-9069-7754a649de67'));
         const querySnapshot = await getDocs(q);
   
         if (!querySnapshot.empty) {
           const resReqDoc = querySnapshot.docs[0];
           await updateDoc(doc(supplierReservationsRef, resReqDoc.id), {
             dateProvisionalExpiry: expiryTimestamp
           });
           console.log('Updated ResReq supplier reservation expiry date');
         }

      console.log('Firestore update successful');
    } catch (error) {
      console.error('Error updating Firestore:', error);
      throw error;
    }

    if (sendNotificationEmail) {
      await sendExtensionEmail(booking, newExpiryDate, user, copySelfEmail, consultant);
    }

    return true;
  } catch (error) {
    console.error('Error updating ResReq or sending emails::', error);
    throw error;
  }
};

const sendExtensionEmail = async (booking, newExpiryDate, user, copySelfEmail, consultant) => {
  const isInternalUser = user.internalUser;
  const recipientEmail = isInternalUser ? consultant.email : user.email;
  const recipientFullName = isInternalUser ? consultant.fullName : `${user.firstName} ${user.lastName}`;
  const recipientFirstName = isInternalUser ? consultant.firstName : `${user.firstName}`;
  const emailData = {
    from: 'Notifications <noreply@hornbill.app>',
    to: recipientEmail,
    subject: `Ref:${booking.code} - Itinerary Expiry Date Extended for ${booking.clientName}`,
    html: generateExtensionEmail({
      booking,
      newExpiryDate,
      recipient: recipientFirstName,
      isCustomer: isInternalUser,
    }),
  };

  if (isInternalUser && copySelfEmail) {
    emailData.cc = user.email;
  }

  try {
    const response = await axios.post('/api/resend', emailData);
    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};
