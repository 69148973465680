import React, { useEffect, useState } from "react";
import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion';
import { GlobeAltIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import Sidebar from "../../../partials/Sidebar";
import TravelInfoTab from "./components/TravelInfoTab";
import { fetchTravelInfoData } from "../functions/propertyFunctions";
import InfoBox from "../../../components/InfoBox";

function TravelInfo() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [travelInfo, setTravelInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTravelInfo = async () => {
      try {
        const data = await fetchTravelInfoData("Tanzania");
        setTravelInfo(data);
      } catch (error) {
        console.error("Error fetching travel info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTravelInfo();
  }, []);

  const tabs = travelInfo ? travelInfo.sections.map((section) => ({
    name: section.tabTitle,
    component: <TravelInfoTab tabData={section} />,
  })) : [];

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
          >
            <div className="mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue mb-4">
                Travel Information
              </h1>
              <div className="flex items-center space-x-2 text-gray-600">
                <GlobeAltIcon className="h-5 w-5" />
                <span>Essential travel information and guidelines for Tanzania</span>
              </div>
            </div>

            <div className="mb-6">
              <InfoBox
                icon={DocumentTextIcon}
                text="Find comprehensive information about visas, health requirements, and travel recommendations."
                bgColor="bg-blue-50"
                textColor="text-nomadBlue-700"
                fontSize="text-md"
              />
            </div>

            <div className="bg-white rounded-xl shadow-sm">
              {isLoading ? (
                <div className="flex items-center justify-center h-64">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-nomadBlue"></div>
                </div>
              ) : tabs.length > 0 ? (
                <Tab.Group>
                <Tab.List className="flex space-x-1 p-1 bg-nomadBlue-100 rounded-t-xl border-b">
  {tabs.map((tab) => (
    <Tab
      key={tab.name}
      className={({ selected }) =>
        `w-full py-4 text-sm font-medium leading-5 rounded-lg transition-all duration-200
        ${selected 
          ? 'bg-nomadBlue-600 text-white shadow-md' 
          : 'text-nomadBlue-700 hover:bg-nomadBlue-200 hover:text-nomadBlue-800'
        }`
      }
    >
      {tab.name}
    </Tab>
  ))}
</Tab.List>
                  <Tab.Panels className="p-6">
                    {tabs.map((tab, idx) => (
                      <Tab.Panel key={idx}>
                        {tab.component}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              ) : (
                <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                  <DocumentTextIcon className="h-12 w-12 mb-4" />
                  <p>No travel information available.</p>
                </div>
              )}
            </div>
          </motion.div>
        </main>
      </div>
    </div>
  );
}

export default TravelInfo;