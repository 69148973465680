import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { differenceInDays, format } from 'date-fns';

import ClosuresDisplay from '../pages/portal/propertyLibrary/components/ClosuresDisplay';
import MobileLocationsTable from '../pages/portal/propertyLibrary/components/MobileLocationsTable';
import { XMarkIcon } from '@heroicons/react/24/outline';

// Helper function to format numbers with comma-separated thousands
const formatNumber = (num) => {
  return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default function PricingSummaryModal({ isOpen, closeModal, propertyInfo, property }) {
  //console.log("property readable json", JSON.stringify(property, null, 2));
  const hasPrices = propertyInfo?.prices && propertyInfo.prices.length > 0;

  const totalPrice = useMemo(() => {
    if (!hasPrices) return 0;
    return propertyInfo.prices.reduce((sum, item) => sum + item.saletotal, 0);
  }, [hasPrices, propertyInfo?.prices]);

  const nights = useMemo(() => {
    if (!propertyInfo?.startDay || !propertyInfo?.endDay) return 0;
    return differenceInDays(new Date(propertyInfo.endDay), new Date(propertyInfo.startDay));
  }, [propertyInfo?.startDay, propertyInfo?.endDay]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <TransitionChild as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="max-h-[80vh] overflow-y-auto">
                  <div className="sticky top-0 bg-white z-10 border-b">
                    <div className="px-6 py-4 flex justify-between items-start">
                      <div>
                        <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                          {propertyInfo?.propName}
                        </DialogTitle>
                        <p className="mt-1 text-sm text-gray-500">Locations and Closures</p>
                      </div>
                      <button onClick={closeModal} className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="p-6">
                    {!property.mobileLocation ?
                      <ClosuresDisplay closureDates={property.closureDates} />
                    : <MobileLocationsTable customLocations={property.customLocations} />}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
