import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import { formatPrice, getSupplierName } from '../functions/generalFunctions';

import { GiJeep } from 'react-icons/gi';
import { MdConnectingAirports } from 'react-icons/md';
import Select from 'react-select'; // assuming you're using react-select
import { TbTransfer } from 'react-icons/tb';
import ViewCompDay from './ViewCompDay';
import ViewCompHeader from './ViewCompHeader';
import ViewCompTableHeader from './ViewCompTableHeader';
import ViewCompTableRow from './ViewCompTableRow';
import ViewFoundItemCard from './ViewFoundItem';
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toggleFinalTransferCollapse } from '../functions/finalPageFunctions';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewItineraryTransferItem = ({
  item,
  index,
  suppliers,
  finalPageTransferData,
  setFinalPageTransferData,
  customFinalItems,
  setCustomFinalItems,
  transfers,
  properties,
  firstDay,
  bookingMaster,
  manage,
  supplierReservations,
  hideStatusPill,
}) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  // redux airports
  const { allAirports } = useSelector((state) => state.airports);
  var airportsShort = allAirports.result;
  let tempAirports = JSON.parse(JSON.stringify(airportsShort));
  const [airports, setAirports] = useState(tempAirports);

  // custom item
  let searchId = item.tuuid;

  console.log('item transfer', JSON.stringify(item, null, 2));

  console.log('supplierReservations1112', JSON.stringify(supplierReservations, null, 2));

  const foundItems = customFinalItems.filter((search) => search.id === searchId && !search.departure && (search.pickupDropoff === undefined || search.pickupDropoff === false));
  const foundItemsDeparture = customFinalItems.filter((search) => search.id === searchId && search.departure && (search.pickupDropoff === undefined || search.pickupDropoff === false));

  const pickupDropoff = false;

  // HEADER FOR CARD
  // function getTransferByTuuid(transfers, tuuidToFind) {
  //   // Find the transfer in the transfers array where the tuuid matches tuuidToFind
  //   const foundTransfer = transfers.find((transfer) => transfer.tuuid === tuuidToFind);

  //   // Return the found transfer object or null if not found
  //   return foundTransfer || null;
  // }
  // const transfer = getTransferByTuuid(transfers, item.tuuid);

  function getTransferAndNextIfNotDeparture(transfers, tuuidToFind) {
    // Find the index of the transfer in the transfers array where the tuuid matches tuuidToFind
    const index = transfers.findIndex((transfer) => transfer.tuuid === tuuidToFind);

    // Initialize an object to hold the current and next transfer if applicable
    const result = {
      currentTransfer: null,
      nextTransfer: null,
    };

    // If the transfer was found (index !== -1), set the current transfer
    if (index !== -1) {
      result.currentTransfer = transfers[index];

      // Check if there is a next transfer and if its objType is not 'departure'
      if (index + 1 < transfers.length && transfers[index + 1].objType !== 'departure') {
        result.nextTransfer = transfers[index + 1];
      }
    }

    // Return the result object
    return result;
  }

  // DEAL WITH HEADER
  const transfersResult = getTransferAndNextIfNotDeparture(transfers, item.tuuid);

  const transfer = transfersResult.currentTransfer;
  const nextTransfer = transfersResult.nextTransfer;

  let transferMessage;
  let transferTime = false;
  // ARRIVAL
  if (item.objType === 'arrival') {
    if (transfer.formValues?.transferType && (transfer.formValues.transferType === 'townTransfer' || transfer.formValues.transferType === 'safariTransfer')) {
      transferMessage = 'Road transfer from ' + transfer.formValues.locationName + ' to ' + properties[getPropObj(item.propUid, properties)].name;
    } else if (transfer.formValues.type === 'bookFlight') {
      let airportTo;
      let airportFrom;
      let airportConnecting;
      if (transfer.formValues.flightType === 'Multiple') {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        airportConnecting = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetails.tocode +
          ') via ' +
          airportConnecting.name +
          ' (' +
          airportConnecting.code +
          ')';
          transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetailsSecond.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");
      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
        transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetails.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");
      }
      // const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
      // const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
      //transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
    } else if (transfer.formValues.type === 'camp') {
      transferMessage = 'Arrival at ' + properties[getPropObj(item.propUid, properties)].name + ' by own arrangements at ' + transfer.formValues.timeOwnArrangements;
    } else {
      transferMessage = `Arrival to ${item.destinationName}`;
    }
    // PROPERTY TRANSFER
  } else if (item.objType === 'propertyTransfer') {
    if (transfer.formValues?.transferType === 'townTransfer' || transfer.formValues?.transferType === 'safariTransfer') {
      transferMessage = 'Road transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + properties[getPropObj(nextTransfer.propUid, properties)].name;
    } else if (transfer.formValues.type === 'flight') {
      let airportTo;
      let airportFrom;
      let airportConnecting;
      if (transfer.formValues.flightType === 'Multiple') {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        airportConnecting = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetails.tocode +
          ') via ' +
          airportConnecting.name +
          ' (' +
          airportConnecting.code +
          ')';
          transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetailsSecond.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");

      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
        transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetails.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");
      }
      // const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
      // const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
      // transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
    } else if (transfer.formValues.type === 'ownArrangements') {
      if (transfer.formValues.airportTransfer) {
        const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.destinationAirportCode));
        const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.originAirportCode));
        transferMessage =
          'Flight from ' + airportFrom.name + ' (' + transfer.formValues.originAirportCode + ') to ' + airportTo.name + ' (' + transfer.formValues.destinationAirportCode + ') - Own Arrangement';
      } else {
        transferMessage = 'Transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + properties[getPropObj(nextTransfer.propUid, properties)].name + ' - Own Arrangement';
      }
    } else {
      transferMessage = `Transfer | ${item.originName} to ${item.destinationName}`;
    }
    // DEPARTURE
  } else if (item.objType === 'departure') {
    if (transfer.formValues?.transferType && (transfer.formValues.transferType === 'townTransfer' || transfer.formValues.transferType === 'safariTransfer')) {
      transferMessage = 'Road transfer from ' + properties[getPropObj(item.propUid, properties)].name + ' to ' + transfer.formValues.locationName;
    } else if (transfer.formValues.type === 'bookFlight') {
      let airportTo;
      let airportFrom;
      let airportConnecting;
      if (transfer.formValues.flightType === 'Multiple') {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        airportConnecting = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetailsSecond.fromcode));
        transferMessage =
          'Flight from ' +
          airportFrom.name +
          ' (' +
          transfer.formValues.flightDetails.fromcode +
          ') to ' +
          airportTo.name +
          ' (' +
          transfer.formValues.flightDetails.tocode +
          ') via ' +
          airportConnecting.name +
          ' (' +
          airportConnecting.code +
          ')';
          transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetailsSecond.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");
      } else {
        airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
        airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
        transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
        transferTime = moment(transfer.formValues.flightDetails.STD, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm") + " - " + moment(transfer.formValues.flightDetails.STA, "YYYY/MM/DD HH:mm:ss.SSS").format("HH:mm");
      }
      // const airportTo = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.tocode));
      // const airportFrom = airports.find((airport) => airport.aerocrsCodes && airport.aerocrsCodes.includes(transfer.formValues.flightDetails.fromcode));
      //transferMessage = 'Flight from ' + airportFrom.name + ' (' + transfer.formValues.flightDetails.fromcode + ') to ' + airportTo.name + ' (' + transfer.formValues.flightDetails.tocode + ')';
    } else if (transfer.formValues.type === 'camp') {
      transferMessage = 'Departure from ' + properties[getPropObj(item.propUid, properties)].name + ' by own arrangements at ' + transfer.formValues.timeOwnArrangements;
    } else {
      transferMessage = `Departure from ${item.originName}`;
    }
  }

  // DAY
  const firstDayMoment = moment(firstDay, 'YYYY-MM-DD');
  const dayMoment = moment(item.endDay, 'YYYY-MM-DD');

  let day = 1;
  if (item.objType === 'arrival') {
    day = firstDay === item.startDay ? 1 : dayMoment.diff(firstDayMoment, 'days');
  } else {
    day = firstDay === item.endDay ? 1 : dayMoment.diff(firstDayMoment, 'days') + 1;
  }

  let parentDate = item.objType === 'arrival' ? item.startDay : item.endDay;

  let feeTypeIcon;
  if (item.objType === 'arrival' && transfer.formValues.type === 'bookFlight') {
    feeTypeIcon = <MdConnectingAirports size={30} />;
  } else if (item.objType === 'arrival' && transfer.formValues.type !== 'bookFlight') {
    feeTypeIcon = <GiJeep size={30} />;
  } else if (item.objType === 'propertyTransfer' && transfer.formValues.type === 'flight') {
    feeTypeIcon = <MdConnectingAirports size={30} />;
  } else if (item.objType === 'propertyTransfer' && transfer.formValues.type === 'ownArrangements' && transfer.formValues.airportTransfer) {
    feeTypeIcon = <MdConnectingAirports size={30} />;
  } else if (item.objType === 'propertyTransfer' && transfer.formValues.type === 'ownArrangements' && !transfer.formValues.airportTransfer) {
    feeTypeIcon = <GiJeep size={30} />;
  } else if (item.objType === 'propertyTransfer' && transfer.formValues.type !== 'ownArrangements' && transfer.formValues.type !== 'flight') {
    feeTypeIcon = <GiJeep size={30} />;
  } else if (item.objType === 'departure' && transfer.formValues.type === 'bookFlight') {
    feeTypeIcon = <MdConnectingAirports size={30} />;
  } else if (item.objType === 'departure' && transfer.formValues.type !== 'bookFlight') {
    feeTypeIcon = <GiJeep size={30} />;
  } else {
    feeTypeIcon = <TbTransfer />;
  }

  let supplierName;
  if (item.objType === 'arrival') {
    supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);
  } else if (item.objType === 'propertyTransfer') {
    supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);
  } else if (item.objType === 'departure') {
    supplierName = getSupplierName(item.transferItems[0].supplier, suppliers);
  }

  let date;
  if (item.objType === 'arrival') {
    date = moment(item.startDay).format('Do MMM YYYY');
  } else if (item.objType === 'propertyTransfer') {
    date = moment(item.endDay).format('Do MMM YYYY');
  } else if (item.objType === 'departure') {
    date = moment(item.endDay).format('Do MMM YYYY');
  }

  return (
    <>
      {/* found items */}
      {foundItems.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
            hideStatusPill={hideStatusPill}
          />
        </div>
      ))}

      {/* <div className="bg-gray-50"> */}

      <div key={uuidv4()} style={{ zIndex: 1000 - index, WebkitTransform: 'translateZ(0)' }} className={`my-10`}>
        {/* DAY */}
        <div className="bg-white rounded-[15px] border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
          {/* HEADER */}
          <ViewCompHeader
          day={day} 
            item={item}
            feeTypeIcon={feeTypeIcon}
            description={transferMessage}
            transferTime={transferTime}
            supplierName={supplierName}
            supplierUid={item.transferItems[0].supplier}
            date={date}
            total={item.transferItems.reduce((totals, currentItem) => totals + currentItem.total, 0)}
            collapse={
              <FiChevronsUp size={30} className="cursor-pointer text-zinc-300" onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, false, setFinalPageTransferData, false)} />
            }
            expand={
              <FiChevronsDown
                size={30}
                className=" cursor-pointer text-zinc-300"
                onClick={() => toggleFinalTransferCollapse(item.tuuid, finalPageTransferData, false, setFinalPageTransferData, false)}
              />
            }
            bookingMaster={bookingMaster}
            manage={manage}
            supplierReservations={supplierReservations}
            hideStatusPill={hideStatusPill}
          />
          {/* ROW TITLES */}
          {!item.collapsed && <ViewCompTableHeader />}

          {/* ROW ITEMS*/}
          <div className={`${!item.collapsed ? 'pb-5' : ''}`}>
            {!item.collapsed &&
              item.transferItems
                .sort((a, b) => (a.edit === b.edit ? 0 : a.edit ? 1 : -1))
                .map(
                  (transferItem, itemIndex) =>
                    item.editTransferItem !== transferItem.uuid && (
                      <div key={uuidv4()} className="">
                        <ViewCompTableRow
                          index={itemIndex}
                          lengthCheck={item.transferItems}
                          description={transferItem.description}
                          supplierName={getSupplierName(transferItem.supplier, suppliers)}
                          units={transferItem.units}
                          pax={transferItem.pax}
                          net={transferItem.net}
                          total={transferItem.total}
                        />
                      </div>
                    ),
                )}
          </div>
        </div>
      </div>

      {/* found items final */}
      {foundItemsDeparture.map((foundItem, index) => (
        <div
          key={foundItem.uuid}
          className={`brand-text-grey-v2 mb-7`}
          style={{
            WebkitTransform: 'translateZ(0)' /* Safari */,
          }}
        >
          <ViewFoundItemCard
            key={foundItem.uuid}
            foundItem={foundItem}
            customFinalItems={customFinalItems}
            setCustomFinalItems={setCustomFinalItems}
            searchId={searchId}
            pickupDropoff={pickupDropoff}
            formatPrice={formatPrice}
            firstDay={firstDay}
            bookingMaster={bookingMaster}
            hideStatusPill={hideStatusPill}
          />
        </div>
      ))}
    </>
  );
};

export default ViewItineraryTransferItem;
