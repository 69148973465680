import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon, ArrowDownTrayIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { IoMdCheckmark } from 'react-icons/io';

function ImagePreviewModal({ image, isOpen, onClose }) {
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const downloadSizes = {
    'Original': {
      url: image?.imageUrl,
      info: 'Full resolution image'
    },
    'Large': {
      url: `${image?.imageUrl}?w=1920&fit=max`,
      info: '1920px width, perfect for presentations'
    },
    'Medium': {
      url: `${image?.imageUrl}?w=1280&fit=max`,
      info: '1280px width, ideal for web use'
    },
    'Small': {
      url: `${image?.imageUrl}?w=800&fit=max`,
      info: '800px width, great for email and documents'
    },
  };

  const handleDownload = async (url, size) => {
    try {
      setDownloadStatus('downloading');
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const fileName = `${image?.title}-${size}.${image?.imageUrl.split('.').pop()}`;
      
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      setDownloadStatus('success');
      setTimeout(() => {
        setDownloadStatus(null);
        setShowDownloadOptions(false);
      }, 2000);
    } catch (error) {
      console.error('Download failed:', error);
      setDownloadStatus('error');
      setTimeout(() => setDownloadStatus(null), 3000);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative max-w-7xl w-full bg-white rounded-2xl overflow-hidden shadow-2xl"
            onClick={e => e.stopPropagation()}
          >
            {/* Top Bar */}
            <div className="absolute top-0 left-0 right-0 flex justify-between items-center px-6 py-4 bg-gradient-to-b from-black/50 to-transparent z-10">
              <div className="flex items-center space-x-4">
                <h3 className="text-lg font-medium text-white">{image?.title}</h3>
                <button
                  onClick={() => setShowInfo(!showInfo)}
                  className="text-white/80 hover:text-white transition-colors"
                >
                  <InformationCircleIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="flex items-center space-x-3">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDownloadOptions(!showDownloadOptions);
                  }}
                  className="flex items-center space-x-2 px-4 py-2 bg-nomadBlue-600 text-white rounded-lg hover:bg-nomadBlue-700 transition-colors"
                >
                  <ArrowDownTrayIcon className="h-5 w-5" />
                  <span>Download</span>
                </motion.button>
                <button
                  onClick={onClose}
                  className="text-white/80 hover:text-white transition-colors"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
            </div>

            {/* Main Image */}
            <div className="relative h-[80vh]">
              <img
                src={image?.previewUrl?.full}
                alt={image?.title}
                className="w-full h-full object-contain"
              />
            </div>

            {/* Download Options Dropdown */}
            <AnimatePresence>
              {showDownloadOptions && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute top-16 right-6 w-72 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden"
                >
                  <div className="p-3 bg-gray-50 border-b border-gray-200">
                    <h4 className="text-sm font-medium text-gray-700">Select Download Size</h4>
                  </div>
                  <div className="py-2">
                    {Object.entries(downloadSizes).map(([size, { url, info }]) => (
                      <button
                        key={size}
                        onClick={() => handleDownload(url, size)}
                        className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center justify-between group transition-colors"
                      >
                        <div>
                          <span className="text-sm font-medium text-gray-700 group-hover:text-nomadBlue-600">
                            {size}
                          </span>
                          <p className="text-xs text-gray-500">{info}</p>
                        </div>
                        <ArrowDownTrayIcon className="h-4 w-4 text-gray-400 group-hover:text-nomadBlue-600" />
                      </button>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Info Panel */}
            <AnimatePresence>
              {showInfo && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="absolute left-6 bottom-6 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg p-4 max-w-md"
                >
                  <h4 className="font-medium text-gray-900 mb-2">Image Details</h4>
                  <div className="space-y-2 text-sm">
                    <p className="text-gray-600">{image?.description}</p>
                    <div className="flex items-center space-x-4 text-gray-500">
                      <span>{image?.dimensions?.width} × {image?.dimensions?.height}px</span>
                      <span>{(image?.size / (1024 * 1024)).toFixed(2)} MB</span>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Download Status */}
            <AnimatePresence>
              {downloadStatus && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  className="fixed bottom-6 left-1/2 transform -translate-x-1/2"
                >
                  <div className={`px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2 ${
                    downloadStatus === 'downloading' ? 'bg-blue-50 text-blue-700' :
                    downloadStatus === 'success' ? 'bg-green-50 text-green-700' :
                    'bg-red-50 text-red-700'
                  }`}>
                    {downloadStatus === 'downloading' && <span>Downloading...</span>}
                    {downloadStatus === 'success' && (
                      <>
                        <IoMdCheckmark className="h-5 w-5" />
                        <span>Download complete</span>
                      </>
                    )}
                    {downloadStatus === 'error' && <span>Download failed. Please try again.</span>}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ImagePreviewModal;