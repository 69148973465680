import { IoMdCheckmark, IoMdCloseCircleOutline, IoMdWarning } from 'react-icons/io';
import { MdAccessTime, MdAirplanemodeActive, MdLocalAirport, MdLocationOn, MdPerson, MdRestaurant, MdWarning } from 'react-icons/md';
import React, { useState } from 'react';
import { airportTransferTotals, getAeroCrsCode, getAirportCodeFromUid, getAirportDistance, getAirportName, getAirportProperties } from '../functions/transferFunctions';
import { calculatePropertyVehicles, filterVehicleRoomsNoSet } from '../functions/vehicleFunctions';
import { capitalizeFirstLetter, formatPrice, getPropObj } from '../functions/generalFunctions';

import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import { FaCar } from 'react-icons/fa';
import { GiElephant } from 'react-icons/gi';
import InfoBox from './InfoBox';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import TransferPricingSummaryModal from './TransferPricingSummaryModal';
import moment from 'moment';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const TransferCard = ({
  index,
  transfer,
  transferNext,
  properties,
  transfers,
  setTransfers,
  TbTransferIn,
  MdOutlineModeEdit,
  IoMdWarning,
  SlPlane,
  MdAccessTime,
  MdDateRange,
  GiJeep,
  CgUnavailable,
  FaCampground,
  MdOutlineLocationOn,
  BiTransfer,
  setIsModalOpen,
  airports,
  bookings,
  setBookings,
  handleOpenSlideOutTransferSingle,
  handleOpenSlideOutTransferSingleDeparture,
  handleOpenSlideOutTransferProperty,
  setSelectedTransferTuuid,
  booking,
  setSelectedBooking,
  setSelectedTransferSingleArrivalCurrent,
  setSelectedTransferSingleDepartureCurrent,
  setSelectedTransferCurrent,
  setSelectedTransferNext,
  setSelectedTransferPrev,
  // originDetails,
  // destinationDetails,
}) => {
  console.log('transfer', JSON.stringify(transfer, null, 2));
  console.log('transferNext', JSON.stringify(transferNext, null, 2));
  console.log('transfers', JSON.stringify(transfers, null, 2));
  // const [flightResults, setFlightResults] = useState(null);
  // const [loading, setLoading] = useState(true);

  // const [originDetails, setOriginDetails] = useState(getAirportProperties(transfer.propUid, properties));
  // const [destinationDetails, setDestinationDetails] = useState(transfer.objType !== 'departure' && getAirportProperties(transferNext.propUid, properties));

  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  // ! if (!transferNext) return null; ADD THIS TO AVOID ERROR AND GET MORE STRANGE RESULTS

  // Function to generate sale total of each transfer card/type
  const calculateSaleTotal = (transfer) => {
    if (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') {
      return formatPrice((airportTransferTotals(transfer) || 0) + transfer.formValues.saleTotal + (transfer.formValues.packedLunchSaleTotal || 0));
    } else if (transfer.formValues.type === 'safari' || transfer.formValues.type === 'location') {
      return formatPrice((airportTransferTotals(transfer) || 0) +transfer.formValues.saleTotal + (transfer.formValues.packedLunch && (transfer.formValues.packedLunchSaleTotal || 0)));
    } else if (transfer.formValues.type === 'camp' || transfer.formValues.type === 'ownArrangements') {
      return formatPrice((airportTransferTotals(transfer) || 0) + transfer.formValues.packedLunchSaleTotal || 0);
    }
    return '0';
  };

  // Generate the transfer card title
  const generateTransferTitle = (transfer, transferNext, properties, airports) => {
    const { formValues, objType } = transfer;

    switch (formValues.type) {
      case 'flight':
      case 'bookFlight':
        return `Flight from ${getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode))} to ${getAirportName(
          airports,
          formValues.flightDetailsSecond ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode,
        )}`;
      case 'safari':
        return `Drive to ${properties[getPropObj(transferNext.propUid, properties)].name}`;
      case 'town':
        return `Town transfer ${
          objType === 'arrivalTransfer' || objType === 'departure'
            ? `To ${properties[getPropObj(transferNext.propUid, properties)].name}`
            : `to ${properties[getPropObj(transferNext.propUid, properties)].name}`
        }`;
      case 'location':
        return objType === 'arrival' ? `Drive to ${properties[getPropObj(transfer.propUid, properties)].name}` : `Drive from ${properties[getPropObj(transfer.propUid, properties)].name}`;
      case 'camp':
        return `${objType === 'departure' ? 'Departure from' : 'Arrival at'} property - ${properties[getPropObj(transfer.propUid, properties)].name}`;
      case 'ownArrangements':
        return 'Own arrangement';
      default:
        return `${formValues.type} Transfer`;
    }
  };

  // Generate the transfer subtitle
  const generateTransferSubtitle = (transfer, transferNext, properties, airports) => {
    const { formValues, objType } = transfer;

    switch (formValues.type) {
      case 'flight':
      case 'bookFlight':
        return (
          <div className="flex flex-col font-normal mt-0 items-start">
            <p className="text-base mb-1 brand-text-grey-v2">
              {getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode))} ({formValues.flightDetails.fromcode}) {'>'}{' '}
              {getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.tocode))} ({formValues.flightDetails.tocode})
              {formValues.flightDetailsSecond && ' > ' + getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetailsSecond.tocode))}{' '}
              {formValues.flightDetailsSecond && '(' + formValues.flightDetailsSecond.tocode + ')'}
            </p>
          </div>
        );

      case 'town':
        return (
          <>
            <div className="py-1 text-base font-normal brand-text-grey-v2">
              {objType === 'arrivalTransfer' ? getTransferArrivalOrigin(transfers) : properties[getPropObj(transfer.propUid, properties)].name} {'>'}{' '}
              {properties[getPropObj(transferNext.propUid, properties)].name}
            </div>
          </>
        );

      case 'safari':
        return (
          <>
            <div className="py-1 text-base font-normal brand-text-grey-v2">
              {objType === 'arrivalTransfer' ? getTransferArrivalOrigin(transfers) : properties[getPropObj(transfer.propUid, properties)].name} {'>'}{' '}
              {properties[getPropObj(transferNext.propUid, properties)].name}
            </div>
          </>
        );

      case 'location':
        return (
          <>
            <div className="py-1 text-sm font-normal brand-text-grey-v2">
              {objType === 'arrival'
                ? formValues.locationName + ' > ' + properties[getPropObj(transfer.propUid, properties)].name
                : properties[getPropObj(transfer.propUid, properties)].name + ' > ' + formValues.locationName}{' '}
              - {formValues.transferType === 'safariTransfer' ? 'by Nomad Tanzania (guide vehicle)' : 'by ' + formValues.supplierName}
            </div>
          </>
        );

      case 'camp':
        return (
          <>
            <div className="py-1 text-sm font-normal brand-text-grey-v2">
              {capitalizeFirstLetter(objType)} {objType === 'arrival' ? 'from' : 'at'} {formValues.locationName} | {objType === 'arrival' ? moment(transfer.startDay).format('Do MMM YYYY') : moment(transfer.endDay).format('Do MMM YYYY')} @{' '}
              {formValues.timeOwnArrangements}
            </div>
          </>
        );

      case 'ownArrangements':
        return formValues.airportTransfer ? (
          <div className="py-1 text-sm font-normal brand-text-grey-v2">
            Flight from {formValues.originAirportName} ({getAirportCodeFromUid(airports, formValues.originAirportUid)}) to {formValues.destinationAirportName} (
            {getAirportCodeFromUid(airports, formValues.destinationAirportUid)})
          </div>
        ) : (
          <div className="py-1 text-sm font-normal brand-text-grey-v2">Make your own way to the next property</div>
        );

      default:
        return null;
    }
  };

  // Function to generate info box to display the arrival info
  const renderItineraryBoundary = (transfer, airports, properties) => {
    if (transfer.objType !== 'arrival' && transfer.objType !== 'departure') return null;

    const isArrival = transfer.objType === 'arrival';
    const isRoadTransfer = transfer.formValues.type === 'location';
    const { formValues } = transfer;

    let startEndPoint = '';

    switch (formValues.type) {
      case 'bookFlight':
        const airportCode = isArrival ? formValues.flightDetails.fromcode : formValues.flightDetails.tocode;
        startEndPoint = `${getAirportName(airports, getAeroCrsCode(airports, airportCode))} airport`;
        break;
      case 'location':
        startEndPoint = formValues.locationName;
        break;
      case 'camp':
        startEndPoint = `${properties[getPropObj(transfer.propUid, properties)].name}`;
        break;
      default:
        startEndPoint = 'your selected location';
    }

    const boundaryMessage = `Services ${isArrival ? 'begin' : 'end'} at ${startEndPoint}. ${
      isRoadTransfer ? '' : isArrival ? 'Guests will arrive there by own arrangement.' : 'Onward travel from here is by own arrangement'
    }`;
    let guestMessage = '';
    // handle the case for flight arrivals/departures (only bookflight & road transfers with flight)
    if (formValues.type === 'bookFlight' || (formValues.type === 'location' && formValues.locationType === 'airport')) {
      if (formValues.connectionType === 'flight' || formValues.type === 'location') {
        const airlineName = formValues.airlineNameFlight || '';
        const flightCode = formValues.fltNumFlight || '';
        const time = formValues.timeFlight && formValues.timeFlight !== 'Select time' ? formValues.timeFlight : '';
        const date = formValues.dateFlight ? moment(formValues.dateFlight).format('Do MMM YYYY') : '';

        guestMessage = `Guests ${isArrival ? 'arriving' : 'continuing journey'} by flight -`;

        if (airlineName) guestMessage += ` ${airlineName}`;
        if (flightCode) guestMessage += ` ${flightCode}`;
        if (time) {
          if (isArrival) {
            guestMessage += ` arriving at ${startEndPoint} ${time}`;
          } else {
            guestMessage += ` departing from ${startEndPoint} ${time}`;
          }
        }
        if (date && time) guestMessage += ` on ${date}`;

        if (formValues.addFlightDetailsLater) {
          guestMessage += ' (Awaiting confirmed details)';
        }
      } else if (formValues.connectionType === 'road') {
        const locationName = formValues.locationName && formValues.locationName !== 'Search by airport, property or other' ? formValues.locationName : '';
        const supplierName = formValues.supplierName || '';

        guestMessage = `Guests ${isArrival ? 'arriving' : 'continuing journey'} by road transfer -`;

        if (locationName) {
          if (isArrival) {
            guestMessage += ` from ${locationName}`;
          } else {
            guestMessage += ` to ${locationName}`;
          }
        }

        if (supplierName) guestMessage += ` with ${supplierName}`;

        if (formValues.addFlightDetailsLater) {
          guestMessage += ' (Awaiting confirmed details)';
        }
      }
    } else if (formValues.type === 'ownArrangements') {
      guestMessage = isArrival ? 'Guests making their own arrangements to arrive.' : 'Guests continuing journey by own arrangement.';
    }

    return (
      <>
        <InfoBox icon={MdLocationOn} text={boundaryMessage} />
        {guestMessage && <InfoBox icon={MdPerson} text={guestMessage} />}
      </>
    );
  };

  // Function to generate the display of journey time
  const renderJourneyTime = (transfer, properties, transferNext) => {
    if (!transfer || !transfer.formValues) return null;

    const { type, flightDetails, transferType, gameDriveTime, distanceMins, includeDepartureTransfer } = transfer.formValues;

    let journeyTimeText;

    switch (type) {
      case 'flight':
      case 'bookFlight':
        let staFlight = flightDetails.STA;
        if (transfer.formValues.flightDetailsSecond) {
          staFlight = transfer.formValues.flightDetailsSecond.STA;
        }

        const estimatedTime = getTimeDifference(
          flightDetails.STD,
          staFlight,
          getAirportDistance(properties, transfer.propUid),
          type === 'bookFlight' || !transferNext ? 0 : getAirportDistance(properties, transferNext.propUid),
        );
        journeyTimeText = `Estimated ${estimatedTime} total journey time ${includeDepartureTransfer ? '(airport transfer included)' : ''}`;
        break;

      case 'safari':
        journeyTimeText =
          transferType === 'safariTransfer' ? `Estimated ${gameDriveTime} – including game drive. Lunch is included` : `Estimated transfer time: ${distanceMins} mins – depending on local traffic`;
        break;

      case 'town':
        journeyTimeText = 'Transfer time will depend on local traffic';
        break;

      case 'location':
        journeyTimeText =
          transferType === 'safariTransfer' ? `Estimated ${gameDriveTime} – including game drive. Lunch is included` : `Estimated transfer time: ${distanceMins} mins – depending on local traffic`;
        break;

      case 'camp':
      case 'ownArrangements':
      default:
        return null;
    }

    if (!journeyTimeText) return null;

    return (
      <div className="flex items-center space-x-2 text-sm mb-1 text-nomadGray-600">
        <MdAccessTime className="text-nomadEvergreen-600" />
        <p>{journeyTimeText}</p>
      </div>
    );
  };

  const renderTransferInfo = (transfer, transferNext, properties, airports) => {
    if (!transfer || !transfer.formValues) return null;

    const transferType = transfer.formValues.type;
    const transferSteps = [];
    const notIncluded = [];

    const departureProperty = properties[getPropObj(transfer.propUid, properties)]?.name || 'Departure';
    const arrivalProperty = transferNext ? properties[getPropObj(transferNext.propUid, properties)]?.name : 'Arrival';

    // FLIGHTS ONLY
    // Departure Transfer (for flights only)
    if (transferType === 'flight' || transferType === 'bookFlight') {
      const originAirport = getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.fromcode));
      const intermediateAirport = getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetails.tocode));
      const finalDestinationAirport = transfer.formValues.flightDetailsSecond ? getAirportName(airports, getAeroCrsCode(airports, transfer.formValues.flightDetailsSecond.tocode)) : null;

      // Departure Transfer (for flights only)
      if (transfer.formValues.includeDepartureTransfer) {
        transferSteps.push(
          <div key="departTransfer" className="flex items-center space-x-2 text-sm mb-1">
            <FaCar className="text-nomadEvergreen-600" />
            <span>
              Airport Transfer from {departureProperty} to {originAirport}
            </span>
          </div>,
        );
      } else if (transfer.objType !== 'arrival') {
        notIncluded.push('Departure airport transfer');
      }

      // First Flight (for flights only)
      transferSteps.push(
        <div key="flight1" className="flex items-center space-x-2 text-sm mb-1">
          <MdAirplanemodeActive className="text-nomadEvergreen-600" />
          <span>
            Flight from {originAirport} to {intermediateAirport}
          </span>
        </div>,
      );

      // Second Flight (if applicable and for flights only)
      if (finalDestinationAirport) {
        transferSteps.push(
          <div key="flight2" className="flex items-center space-x-2 text-sm mb-1">
            <MdAirplanemodeActive className="text-nomadEvergreen-600" />
            <span>
              Flight from {intermediateAirport} to {finalDestinationAirport}
            </span>
          </div>,
        );
      }

      // Arrival Transfer (for flights only)
      if (transfer.formValues.includeArrivalTransfer) {
        transferSteps.push(
          <div key="arrivalTransfer" className="flex items-center space-x-2 text-sm mb-1">
            <FaCar className="text-nomadEvergreen-600" />
            <span>
              Airport Transfer from {finalDestinationAirport || intermediateAirport} to {arrivalProperty}
            </span>
          </div>,
        );
      } else if (transfer.objType !== 'departure') {
        notIncluded.push('Arrival airport transfer');
      }
    }

    // ROAD TRANSFERS ONLY
    // Transfer journey (for road only)
    if (transferType === 'safari' || transferType === 'town') {
      transferSteps.push(
        <div key="roadTransfer" className="flex items-center space-x-2 text-sm mb-1">
          <FaCar className="text-nomadEvergreen-600" />
          <span>
            Transfer from {departureProperty} to {arrivalProperty}
          </span>
        </div>,
      );
      // condition for game viewing (for road only)
      if (transfer.formValues.rateType === 'nomadGuideVehicle' || (transferType === 'safari' && transfer.formValues.supplierUid === 'ded3a3ed-aeaf-4495-9069-7754a649de67')) {
        transferSteps.push(
          <div key="gameViewing" className="flex items-center space-x-2 text-sm mb-1">
            <GiElephant className="text-nomadEvergreen-600" />
            <span>Game viewing en route with Nomad Guide</span>
          </div>,
        );
      }
    }
    // Picnics
    if (transfer.formValues?.packedLunch) {
      const packedLunchProperty = properties[getPropObj(transfer.propUid, properties)]?.name || 'Departure property';
      transferSteps.push(
        <div key="picnic" className="flex items-center space-x-2 text-sm mb-1">
          <MdRestaurant className="text-nomadEvergreen-600" />
          <span>Packed lunch from {packedLunchProperty}</span>
        </div>,
      );
    }

    // Only return content if there are inclusions or exclusions to show
    if (transferSteps.length > 0 || notIncluded.length > 0) {
      return (
        <div className="">
          {transferSteps.length > 0 && (
            <>
              <h3 className="text-base font-semibold mb-1">Inclusions</h3>
              {transferSteps}
            </>
          )}
          {notIncluded.length > 0 && (
            <div className="mt-4">
              <h4 className="text-sm font-semibold text-orange-600 flex items-center">
                <MdWarning className="mr-1" /> Not Included:
              </h4>
              <ul className="list-disc list-inside text-sm text-gray-600 mt-1">
                {notIncluded.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }

    // If there are no inclusions or exclusions, return null
    return null;
  };

  function getTimeDifference(startDateTime, endDateTime, prop1, prop2) {
    const startMoment = moment(startDateTime);
    const endMoment = moment(endDateTime);

    let duration = moment.duration(endMoment.diff(startMoment));

    // add prop1 and prop2 minutes to the duration
    duration = duration.add(prop1, 'minutes').add(prop2, 'minutes');

    const hours = Math.floor(duration.asHours()); // use asHours to get total hours
    const minutes = duration.minutes();

    return `${hours} hours and ${minutes} minutes`;
  }

  function getTransferArrivalOrigin(transfers) {
    const arrivalData = transfers.find((transfer) => transfer.objType === 'arrival');

    if (arrivalData.formValues.type === 'flight') {
      return `${arrivalData.formValues.airportName} airport`;
    } else if (arrivalData.formValues.type === 'location') {
      return `${arrivalData.formValues.subLocation}, ${arrivalData.formValues.locationName}`;
    }
  }

  const handleConfirm = (variable) => {
    console.log('Action confirmed with variable:', variable);
    const updatedTransfers = transfers.map((transferUpdate) => {
      if (transferUpdate.tuuid === transfer.tuuid) {
        // Spread to create a new object, then delete the formValues property from this new object
        const { formValues, ...rest } = transferUpdate;
        return {
          ...rest,
          isInvalid: false,
        };
      }
      return transferUpdate;
    });

    setTransfers(updatedTransfers);
    const updatedBookings = filterVehicleRoomsNoSet(bookings);
    updatedTransfers.forEach(async (item) => {
      await calculatePropertyVehicles(updatedBookings, setBookings, updatedTransfers, item.tuuid, properties);
      console.log(item.tuuid);
    });
    toast.dismiss();
  };

  const CustomToastWithConfirm = ({ closeToast, variable, handleConfirm }) => (
    <div className="flex justify-between items-center bg-white p-4 rounded max-w-md">
      <div className="text-black">
        <p>Click to delete transfer</p>
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevents the click from closing the toast
          handleConfirm(variable);
          closeToast();
        }}
        className="ml-4 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
      >
        Delete
      </button>
    </div>
  );

  // Function to show the custom toast with Tailwind CSS
  const showToastWithConfirm = (variable) => {
    toast.warning((t) => <CustomToastWithConfirm variable={variable} handleConfirm={handleConfirm} closeToast={() => toast.dismiss(t.id)} />, {
      position: 'top-right',
      autoClose: true, // Remains false to not auto close
      closeOnClick: true, // Allows toast to close when other parts are clicked
      pauseOnHover: true,
      draggable: true,
      className: 'flex p-0 overflow-hidden cursor-pointer', // Apply Tailwind classes as needed
    });
  };

  const transferTitle = generateTransferTitle(transfer, transferNext, properties, airports);

  const isInvalid = transfer.isInvalid;

  return (
    <div key={uuidv4()} className="">
      <div
        className={`${transfer.objType === 'propertyTransfer' ? 'mx-0' : 'mx-5'} 
      bg-white border rounded-xl mb-5 drop-shadow-md border-slate-200 overflow-hidden
      ${isInvalid ? 'border-red-500 bg-red-50 pt-0' : 'pt-4'}`}
      >
        {isInvalid && (
          <div className="bg-red-100 border-b border-red-500 text-red-700 px-4 py-2 mb-2 flex justify-between items-center">
            <div>
              <p className="font-bold">This transfer is no longer valid due to property changes.</p>
              <p>Please delete this transfer and select a new one.</p>
            </div>
            <ButtonOutlineSmall
              text="Delete"
              onClick={() => {
                handleConfirm(transfer.tuuid);
              }}
              color="dark"
              className=""
            />
          </div>
        )}{' '}
        <div className="px-4 pb-4 relative">
          <div className="flex items-start">
            {/* Left column - Icon */}
            <div className="flex-shrink-0 mr-4">
              <div className={`relative rounded-full ${transfer.formValues.type === 'ownArrangements' ? 'bg-nomadBlue-200' : 'bg-nomadBlue-200'} w-10 h-10 flex items-center justify-center`}>
                {transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? (
                  <SlPlane className="inline-block text-nomadBlue-500 w-5 h-5" />
                ) : transfer.formValues && transfer.formValues.type === 'town' ? (
                  <TbTransferIn className="inline-block text-nomadBlue-500 w-5 h-5" />
                ) : transfer.formValues && transfer.formValues.type === 'safari' ? (
                  <GiJeep className="inline-block text-nomadBlue-500 w-5 h-5" />
                ) : transfer.formValues && transfer.formValues.type === 'location' ? (
                  <MdOutlineLocationOn className="inline-nomadBlue-500 text-black w-5 h-5" />
                ) : transfer.formValues && transfer.formValues.type === 'camp' ? (
                  <FaCampground className="inline-block text-nomadBlue-500 w-5 h-5" />
                ) : transfer.formValues && transfer.formValues.type === 'ownArrangements' ? (
                  <BiTransfer className="inline-block text-nomadBlue-500 w-5 h-5" />
                ) : null}
              </div>
            </div>
            {/* Middle column - Title, description, and other details */}
            <div key={uuidv4()} className="flex-grow min-w-0">
              <div className="text-xl font-bold  truncate  pb-1">
                {transferTitle}
                {generateTransferSubtitle(transfer, transferNext, properties, airports)}
              </div>
              {/* Render Journey time */}
              {renderJourneyTime(transfer, properties, transferNext)}
              {/* Render info boxes */}
              {renderItineraryBoundary(transfer, airports, properties)}
              {/* renderTransferInfo block*/}
              {transfer.formValues && <div className="mt-4">{renderTransferInfo(transfer, transferNext, properties, airports)}</div>}
            </div>
            {/* Right column - Edit and Delete icons */}
            {!isInvalid && (
              <div className="flex-shrink-0 ml-auto">
                <div className="flex">
                  <MdOutlineModeEdit
                    size={20}
                    className="ml-auto brand-text-grey-v2 mr-2 cursor-pointer"
                    onClick={() => {
                      const updatedTransfers = transfers.map((transferUpdate) => {
                        console.log('transferUpdate.tuuid: ' + transferUpdate.tuuid);
                        console.log('transfer.tuuid: ' + transfer.tuuid);
                        if (transferUpdate.tuuid === transfer.tuuid) {
                          console.log('transferUpdate.tuuid TC:', transferUpdate.tuuid);
                          console.log('transfer.tuuid TC:', transfer.tuuid);
                          console.log('EDIT CLICKED');
                          setSelectedTransferTuuid(transfer.tuuid);
                          setSelectedBooking(booking);
                          return {
                            ...transferUpdate,
                            edit: true,
                          };
                        }

                        console.log('Edit');
                        return transferUpdate;
                      });
                      setTransfers(updatedTransfers);
                      if (transfer.objType === 'arrival') {
                        setSelectedTransferSingleArrivalCurrent(updatedTransfers.find((transfer) => transfer.objType === 'arrival'));
                        handleOpenSlideOutTransferSingle();
                      } else if (transfer.objType === 'departure') {
                        setSelectedTransferSingleDepartureCurrent(updatedTransfers.find((transfer) => transfer.objType === 'departure'));
                        handleOpenSlideOutTransferSingleDeparture();
                      } else if (transfer.objType === 'propertyTransfer') {
                        setSelectedTransferCurrent(
                          updatedTransfers.find((transferSearch) => transferSearch.objType === 'propertyTransfer' && transferSearch.tuuid === transfer.tuuid && transferSearch.edit === true),
                        );
                        const currentTransferIndex = updatedTransfers.findIndex(
                          (transferSearch) => transferSearch.objType === 'propertyTransfer' && transferSearch.tuuid === transfer.tuuid && transferSearch.edit === true,
                        );

                        if (currentTransferIndex !== -1) {
                          const nextTransferIndex = (currentTransferIndex + 1) % updatedTransfers.length;
                          const prevTransferIndex = (currentTransferIndex - 1 + updatedTransfers.length) % updatedTransfers.length;

                          setSelectedTransferNext(updatedTransfers[nextTransferIndex]);
                          setSelectedTransferPrev(updatedTransfers[prevTransferIndex]);
                        } else {
                          setSelectedTransferNext(null);
                          setSelectedTransferPrev(null);
                        }
                        handleOpenSlideOutTransferProperty();
                      }

                      // setIsModalOpen(true);
                    }}
                  />
                  <IoMdCloseCircleOutline
                    size={20}
                    className="brand-text-grey-v2 mr-5 cursor-pointer"
                    onClick={() => {
                      // const updatedTransfers = transfers.map((transferUpdate) => {
                      //   if (transferUpdate.tuuid === transfer.tuuid) {
                      //     // Spread to create a new object, then delete the formValues property from this new object
                      //     const { formValues, ...rest } = transferUpdate;
                      //     return {
                      //       ...rest,
                      //     };
                      //   }
                      //   return transferUpdate;
                      // });
                      // setTransfers(updatedTransfers);
                      showToastWithConfirm(transfer.tuuid);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Bottom strip - Info */}
        <div className={`${isInvalid ? 'bg-red-100' : 'bg-nomadBlue-100'} rounded-b-xl`}>
          <div className="pl-16 py-2 flex justify-between items-center">
            <div className="flex items-center text-sm text-nomadGray-600 space-x-2">
              {}
              <MdDateRange className="inline-block ml-2 w-4 h-4 brand-text-grey-v2" />{' '}
              {transfer.formValues && (transfer.formValues.type === 'flight' || transfer.formValues.type === 'bookFlight') ? (
                transfer.objType === 'arrival' || transfer.objType === 'departure' ? (
                  <>
                    <div className="pl-1 text-sm font-normal truncate brand-text-grey-v2">
                      {moment(transfer.objType === 'arrival' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}
                    </div>
                    <div className="pl-1 text-sm font-normal truncate brand-text-grey-v2">
                      {transfer.formValues.time !== null && transfer.formValues.time !== '' && '@ '}
                      {/* {transfer.objType === "arrival" &&
            transfer.formValues.fltnum &&
            transfer.formValues.fltnum !== "" &&
            " → Flight number: " + transfer.formValues.fltnum}
          {transfer.objType === "departure" &&
            transfer.formValues.type !== "bookFlight" &&
            transfer.formValues.fltnum &&
            transfer.formValues.fltnum !== "" &&
            " → Flight number: " + transfer.formValues.fltnum} */}
                      {moment(transfer.formValues.flightDetails.STD).format('HH:mm')} {' > '}
                      {moment(transfer.formValues.flightDetailsSecond ? transfer.formValues.flightDetailsSecond.STA : transfer.formValues.flightDetails.STA).format('HH:mm')} → {transfer.formValues.flightDetailsSecond ? `(Incl. connecting flight)` : `Flight number: ${transfer.formValues.flightDetails.fltnum}`}
                      {/* {transfer.formValues.time === null || transfer.formValues.time === "" ? " > No time selected" : ""} */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                      {moment(transfer.objType === 'arrival' || transfer.objType === 'arrivalTransfer' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}
                    </div>
                    <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                      {'@ ' + moment(transfer.formValues.flightDetails.STD).format('HH:mm')} {'>'} {moment(transfer.formValues.flightDetailsSecond ? transfer.formValues.flightDetailsSecond.STA : transfer.formValues.flightDetails.STA).format('HH:mm')}
                      {transfer.formValues.flightDetails.fltnum !== '' && transfer.formValues.flightDetailsSecond ? ` → (Incl. connecting flight)` : ` → Flight number: ${transfer.formValues.flightDetails.fltnum}`}
                    </div>
                  </>
                )
              ) : transfer.formValues && transfer.formValues.type === 'town' ? (
                <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                  {moment(transfer.objType === 'arrival' || transfer.objType === 'arrivalTransfer' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}
                </div>
              ) : transfer.formValues && transfer.formValues.type === 'safari' ? (
                <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                  {moment(transfer.objType === 'arrival' || transfer.objType === 'arrivalTransfer' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}
                </div>
              ) : transfer.formValues && transfer.formValues.type === 'location' ? (
                <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                  {moment(transfer.objType === 'arrival' || transfer.objType === 'arrivalTransfer' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}{' '}
                  {transfer.objType === 'arrival' && '- Pick up'} @ {transfer.formValues.time !== 'Select time' ? transfer.formValues.time : 'TBD'}
                </div>
              ) : transfer.formValues && transfer.formValues.type === 'camp' ? (
                <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                  {moment(transfer.objType === 'departure' ? transfer.endDay : transfer.startDay).format('Do MMM YYYY')}
                  {/* @{' '}
      {transfer.objType === 'departure' ? transfer.formValues.timeOwnArrangements : transfer.formValues.timeOwnArrangements} */}
                  {}
                </div>
              ) : transfer.formValues && transfer.formValues.type === 'ownArrangements' ? (
                <div className="pl-2 text-sm font-normal truncate brand-text-grey-v2">
                  {moment(transfer.objType === 'arrival' || transfer.objType === 'arrivalTransfer' ? transfer.startDay : transfer.endDay).format('ddd, Do MMM YYYY')}
                  {transfer.formValues.airportTransfer && transfer.formValues.timeDeparture && transfer.formValues.timeDeparture !== '' && ' @ ' + transfer.formValues.timeDeparture}
                  {transfer.formValues.fltnum && transfer.formValues.fltnum !== '' && ' → Flight number: ' + transfer.formValues.fltnum}
                  {transfer.formValues.airportTransfer && transfer.formValues.timeArrival && transfer.formValues.timeArrival !== '' && ' - Arrival @ ' + transfer.formValues.timeArrival}
                </div>
              ) : null}
            </div>
            <div className="pr-4 text-sm text-nomadGray-600 flex items-center">
              Total: <span className="text-lg pl-2 text-black font-bold">${calculateSaleTotal(transfer)}</span>
              <button onClick={() => setIsPricingModalOpen(true)} className="ml-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <TransferPricingSummaryModal
              isOpen={isPricingModalOpen}
              closeModal={() => setIsPricingModalOpen(false)}
              transferInfo={transfer}
              transferTitle={transferTitle}
              properties={properties}
              airports={airports}
              transferNext={transferNext}
            />{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferCard;
