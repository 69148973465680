import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropertyContent from '../pages/portal/propertyLibrary/components/PropertyContent';
import { fetchPropertyData } from '../pages/portal/functions/propertyFunctions';

function FullScreenPropertyModal({ isOpen, onClose, propertyUid }) {
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadPropertyData() {
      if (!isOpen) return;
      try {
        setLoading(true);
        const data = await fetchPropertyData(propertyUid);
        setPropertyData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    loadPropertyData();
  }, [isOpen, propertyUid]);

  if (!isOpen) return null;

  const modalContent = (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
        onClick={onClose}
      >
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="relative top-20 mx-auto w-full max-w-7xl shadow-lg rounded-xl bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-100">
            <h3 className="text-xl font-medium text-nomadBlue-800">Property Details</h3>
            <button 
              onClick={onClose} 
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Close modal"
            >
              <XMarkIcon className="w-6 h-6 text-gray-500" />
            </button>
          </div>

          {error ? (
            <div className="p-6 text-center">
              <div className="bg-red-50 border border-red-200 rounded-xl p-8">
                <h3 className="text-lg font-medium text-red-800 mb-2">Unable to load property</h3>
                <p className="text-red-600">{error.message}</p>
              </div>
            </div>
          ) : (
            <PropertyContent 
              propertyData={propertyData} 
              isLoading={loading} 
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
}

export default FullScreenPropertyModal;