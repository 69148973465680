/* eslint-disable default-case */
import React, { useState, useMemo } from 'react';
import { XMarkIcon, ExclamationTriangleIcon, ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, BanknotesIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';
import { toast } from 'react-toastify';

import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { updateItineraryStatusCancel } from '../../../../functions/itineraryFunctions';
import { generateInvoice } from '../../../../functions/viewItineraryFunctions'; 
import { db } from '../../../../db/firebase.config';
import { formatPrice } from '../../../../functions/generalFunctions';

const HIGH_VALUE_THRESHOLD = 10000;

const CHARGE_TYPES = {
  100: {
    label: '100% Charge',
    description: 'Full booking amount retained',
    icon: BanknotesIcon,
    color: 'text-red-600',
    bgColor: 'bg-red-50',
  },
  10: {
    label: '10% Fee',
    description: '10% cancellation fee',
    icon: BanknotesIcon,
    color: 'text-orange-600',
    bgColor: 'bg-orange-50',
  },
  deposit: {
    label: 'Deposit Only',
    description: 'Retain initial deposit',
    icon: BanknotesIcon,
    color: 'text-yellow-600',
    bgColor: 'bg-yellow-50',
  },
  none: {
    label: 'No Charge',
    description: 'Full refund to customer',
    icon: BanknotesIcon,
    color: 'text-green-600',
    bgColor: 'bg-green-50',
  },
  custom: {
    label: 'Custom Charges',
    description: 'Specify charges per item',
    icon: BanknotesIcon,
    color: 'text-blue-600',
    bgColor: 'bg-blue-50',
  },
};

const STEPS = {
  INFO: 0,
  CHARGES: 1,
  REVIEW: 2,
};

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { label: 'Cancellation Details', description: 'Reason and summary' },
    { label: 'Charge Selection', description: 'Define cancellation fees' },
    { label: 'Review & Confirm', description: 'Final verification' },
  ];

  return (
    <div className="mb-8">
      <div className="relative">
        <div className="absolute top-5 left-1 right-1 h-0.5 bg-gray-200">
          <div className="absolute top-0 left-0 h-full bg-nomadBlue transition-all duration-300" style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }} />
        </div>
        <div className="relative flex justify-between">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center relative z-10 
                  ${index <= currentStep ? 'bg-nomadBlue-400 text-white' : 'bg-white border-2 border-gray-300 text-gray-500'}`}
              >
                {index < currentStep ? '✓' : index + 1}
              </div>
              <div className="mt-2 text-center">
                <div className={`text-sm font-medium ${index <= currentStep ? 'text-nomadBlue' : 'text-gray-500'}`}>{step.label}</div>
                <div className="text-xs text-gray-500 mt-1">{step.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const InitialWarning = ({ bookingCode }) => (
  <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 mb-6">
    <div className="flex">
      <InformationCircleIcon className="h-6 w-6 text-amber-400 mt-0.5" />
      <div className="ml-3">
        <h3 className="text-sm font-medium text-amber-800">Important Notice</h3>
        <p className="text-sm text-amber-700 mt-1">You are about to cancel booking #{bookingCode}. This action:</p>
        <ul className="list-disc ml-4 mt-2 text-sm text-amber-700">
          <li>Cannot be undone once confirmed</li>
          <li>Will notify all relevant parties</li>
          <li>May incur cancellation fees</li>
        </ul>
      </div>
    </div>
  </div>
);

const HighValueWarning = ({ amount }) => (
  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
    <div className="flex">
      <ExclamationTriangleIcon className="h-6 w-6 text-red-400" />
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">High-Value Booking Alert</h3>
        <p className="text-sm text-red-700 mt-1">This booking value exceeds {formatPrice(HIGH_VALUE_THRESHOLD)}. Additional approval may be required.</p>
      </div>
    </div>
  </div>
);

const ChargeTypeSelector = ({ selected, onChange }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
    {Object.entries(CHARGE_TYPES).map(([value, { label, description, icon: Icon, color, bgColor }]) => (
      <button
        key={value}
        onClick={() => onChange(value)}
        className={`flex items-start p-4 border rounded-lg transition-all ${selected === value ? `border-2 ${color} ${bgColor}` : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50'}`}
      >
        <Icon className={`h-6 w-6 ${selected === value ? color : 'text-gray-400'}`} />
        <div className="ml-3 text-left">
          <p className={`font-medium ${selected === value ? color : 'text-gray-900'}`}>{label}</p>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </button>
    ))}
  </div>
);

// component for charge breakdown table
const ChargeBreakdownTable = ({ items, chargeType, onCustomUpdate }) => {
  const isCustom = chargeType === 'custom';

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
            <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Original</th>
            <th className="px-4 py-2 text-right">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">{isCustom ? 'Charge' : 'New Amount'}</div>
            </th>
            {!isCustom && <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Difference</th>}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {items.map((item, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="px-4 py-2 text-sm text-gray-900">{item.details}</td>
              <td className="px-4 py-2 text-sm text-gray-900 text-right">{formatPrice(item.saleTotalFinal)}</td>
              <td className="px-4 py-2">
                {isCustom ? (
                  <div className="flex justify-end">
                    <input
                      type="number"
                      value={item.cancelledTotal}
                      onChange={(e) => onCustomUpdate(index, e.target.value)}
                      className="block w-32 rounded-md border-gray-300 text-right shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
                    />
                  </div>
                ) : (
                  <div className="text-right text-sm text-gray-900">{formatPrice(item.cancelledTotal)}</div>
                )}
              </td>
              {!isCustom && (
                <td className={`px-4 py-2 text-sm text-right ${item.saleTotalFinal - item.cancelledTotal > 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {formatPrice(item.saleTotalFinal - item.cancelledTotal)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CancellationProcess = ({ booking, onSuccess, onError, onClose }) => {
  const [currentStep, setCurrentStep] = useState(STEPS.INFO);
  const [formData, setFormData] = useState({
    reason: '',
    summary: '',
    chargeType: '',
    asPerTerms: true,
    approvedBy: '',
    customCharges:
      booking.jsonData?.map((item) => ({
        ...item,
        cancelledTotal: item.saleTotalFinal,
      })) || [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showBreakdown, setShowBreakdown] = useState(false);

  // utility to round the values before updating firestore
  const roundToTwoDecimals = (num) => Math.round((Number(num) || 0) * 100) / 100;

  const cancellationDetails = useMemo(() => {
    if (!formData.chargeType) return null;

    let updatedItems;
    if (formData.chargeType === 'custom') {
      updatedItems = formData.customCharges;
    } else {
      updatedItems =
        booking.jsonData?.map((item) => {
          let cancelledTotal = 0;
          switch (formData.chargeType) {
            case '100':
              cancelledTotal = roundToTwoDecimals(item.saleTotalFinal);
              break;
            case '10':
              cancelledTotal = roundToTwoDecimals(item.saleTotalFinal * 0.1);
              break;
            case 'deposit':
              cancelledTotal = roundToTwoDecimals(item.saleDeposit);
              break;
            case 'none':
              cancelledTotal = 0;
              break;
            default:
              cancelledTotal = roundToTwoDecimals(item.saleTotalFinal);
          }
          return { ...item, cancelledTotal };
        }) || [];
    }

    const totalCancellationPrice = roundToTwoDecimals(updatedItems.reduce((sum, item) => sum + (item.cancelledTotal || 0), 0));
    return {
      items: updatedItems,
      originalTotal: roundToTwoDecimals(booking.totalPrice),
      newTotal: totalCancellationPrice,
      difference: roundToTwoDecimals(booking.totalPrice - totalCancellationPrice),
    };
  }, [formData.chargeType, formData.customCharges, booking]);

  const handleCustomChargeUpdate = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      customCharges: prev.customCharges.map((item, i) => (i === index ? { ...item, cancelledTotal: roundToTwoDecimals(value) } : item)),
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (!formData.reason || !formData.summary || !formData.chargeType) {
        throw new Error('Please fill in all required fields');
      }

      const cancellationData = {
        reason: formData.reason,
        summary: formData.summary,
        chargeType: formData.chargeType,
        asPerTerms: formData.asPerTerms,
        approvedBy: formData.approvedBy || null,
        timestamp: new Date(),
        totalPriceOriginal: booking.totalPrice,
        totalPrice: cancellationDetails.newTotal,
      };

    // Update ResRequest to cancelled  if resReqReservationId exists
    if (booking.resReqReservationId) {
      await updateItineraryStatusCancel(booking.resReqReservationId);
    }

        // Update supplier reservation if exists
        const supplierReservationsRef = collection(db, 'itineraries', booking.uid, 'supplierReservations');
        const q = query(supplierReservationsRef, where('supplierUid', '==', 'ded3a3ed-aeaf-4495-9069-7754a649de67'));
        const querySnapshot = await getDocs(q);
        
        // Update matching supplier reservations
        const supplierUpdates = querySnapshot.docs.map(doc => 
          updateDoc(doc.ref, { reservationStage: 'cancelled' })
        );
        await Promise.all(supplierUpdates);


      const docRef = doc(db, 'itineraries', booking.uid);
      await updateDoc(docRef, {
        cancellation: cancellationData,
        status: 'cancelled',
        dateModified: new Date(),
        jsonData: cancellationDetails.items,
        totalPrice: cancellationDetails.newTotal,
        totalPriceOriginal: booking.totalPrice,
      });

       // Regenerate the invoice
    await generateInvoice(booking.uid);

      onSuccess(cancellationData);
      toast.success('Booking cancelled successfully');
    } catch (error) {
      onError(error);
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.INFO:
        return (
          <div className="space-y-6">
            <InitialWarning bookingCode={booking.code} />
            {booking.totalPrice > HIGH_VALUE_THRESHOLD && <HighValueWarning amount={booking.totalPrice} />}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Reason for Cancellation*</label>
              <select
                value={formData.reason}
                onChange={(e) => setFormData((prev) => ({ ...prev, reason: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
              >
                <option value="">Select a reason</option>
                <option value="health">Client sickness/health</option>
                <option value="duplicate">Duplicate booking/Confirmation Error</option>
                <option value="failed_balance">Failed balance payment</option>
                <option value="failed_deposit">Failed deposit payment</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Additional Details*</label>
              <textarea
                value={formData.summary}
                onChange={(e) => setFormData((prev) => ({ ...prev, summary: e.target.value }))}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-nomadBlue focus:ring-nomadBlue sm:text-sm"
                placeholder="Please provide detailed reason for cancellation..."
              />
            </div>
          </div>
        );

      case STEPS.CHARGES:
        return (
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Select Charge Type</h3>
              <p className="text-sm text-gray-500 mb-4">Choose how you would like to handle the cancellation charges</p>

              <ChargeTypeSelector selected={formData.chargeType} onChange={(value) => setFormData((prev) => ({ ...prev, chargeType: value }))} />
              {formData.chargeType && (
                <p className="mt-2 text-sm text-gray-600">
                  {formData.chargeType === 'custom' ? 'Enter your desired charge amount for each item below' : 'Amounts below are automatically calculated based on the selected charge type'}
                </p>
              )}
            </div>

            {formData.chargeType && cancellationDetails && (
              <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex items-center justify-between mb-4">
                  <h4 className="text-sm font-medium text-gray-900">Charge Breakdown</h4>
                  <button onClick={() => setShowBreakdown(!showBreakdown)} className="text-sm text-nomadBlue hover:text-nomadBlue-600 font-medium flex items-center">
                    {showBreakdown ? 'Hide Details' : 'Show Details'}
                    <ChevronDownIcon className={`ml-1 h-5 w-5 transform transition-transform ${showBreakdown ? 'rotate-180' : ''}`} />
                  </button>
                </div>

                {showBreakdown && (
                  <div className="mb-4">
                    <ChargeBreakdownTable items={cancellationDetails.items} chargeType={formData.chargeType} onCustomUpdate={handleCustomChargeUpdate} />
                  </div>
                )}

                
<div className="space-y-2">
  <div className="flex justify-between text-sm">
    <span className="text-gray-500">Original Total:</span>
    <span className="font-medium">{formatPrice(cancellationDetails.originalTotal)}</span>
  </div>
  <div className="flex justify-between text-sm">
    <span className="text-gray-500 font-bold">Cancellation Charge:</span>
    <span className="font-medium text-nomadBlue">{formatPrice(cancellationDetails.newTotal)}</span>
  </div>
  <div className="flex justify-between text-sm pt-2 border-t">
    <span className="text-gray-500 ">Difference:</span>
    <span className={`font-medium ${cancellationDetails.difference > 0 ? 'text-green-600' : 'text-red-600'}`}>
      {formatPrice(cancellationDetails.difference)}
    </span>
  </div>
  <div className="flex justify-between text-sm border-t pt-2">
    <span className="text-gray-500">Total Paid to Date:</span>
    <span className="font-medium">{formatPrice(Number(booking.totalPaid) || 0)}</span>
  </div>
  <div className="flex justify-between text-sm">
    <span className="text-gray-500 font-bold">
      {Number(booking.totalPaid) > cancellationDetails.newTotal ? 'Total Refund Due:' : 'Total Outstanding:'}
    </span>
    <span className={`font-medium ${Number(booking.totalPaid) > cancellationDetails.newTotal ? 'text-red-600' : 'text-green-600'}`}>
      {formatPrice(Math.abs((Number(booking.totalPaid) || 0) - cancellationDetails.newTotal))}
    </span>
  </div>
</div>
              </div>
            )}

            <div className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="termsCheckbox"
                  checked={formData.asPerTerms}
                  onChange={(e) => setFormData((prev) => ({ ...prev, asPerTerms: e.target.checked }))}
                  className="h-4 w-4 text-nomadBlue focus:ring-nomadBlue border-gray-300 rounded"
                />
                <label htmlFor="termsCheckbox" className="ml-2 text-sm text-gray-700">
                  Charged as per terms and conditions
                </label>
              </div>
            </div>

            {!formData.asPerTerms && (
              <div className="bg-amber-50 p-4 rounded-lg border border-amber-200">
                <label className="block text-sm font-medium text-amber-800 mb-1">Approval Required*</label>
                <select
                  value={formData.approvedBy}
                  onChange={(e) => setFormData((prev) => ({ ...prev, approvedBy: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-amber-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                >
                  <option value="">Select who approved this exception</option>
                  <option value="manager1">John Smith - Manager</option>
                  <option value="manager2">Jane Doe - Senior Manager</option>
                </select>
              </div>
            )}
          </div>
        );

      case STEPS.REVIEW:
        return (
          <div className="space-y-6">
          <div className="bg-gray-50 rounded-lg p-6 border border-gray-200">
            <h4 className="text-lg font-medium text-gray-900 mb-4">Cancellation Summary</h4>
            <dl className="space-y-4">
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Booking Reference:</dt>
                <dd className="text-sm font-medium text-gray-900">#{booking.code}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Reason:</dt>
                <dd className="text-sm font-medium text-gray-900">{formData.reason}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Charge Type:</dt>
                <dd className="text-sm font-medium text-gray-900">{CHARGE_TYPES[formData.chargeType]?.label}</dd>
              </div>
              <div className="pt-4 border-t space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500">Original Total:</span>
                  <span className="font-medium">{formatPrice(cancellationDetails.originalTotal)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500">Final Charge:</span>
                  <span className="font-medium text-nomadBlue">{formatPrice(cancellationDetails.newTotal)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500">Difference:</span>
                  <span className={`font-medium ${cancellationDetails.difference > 0 ? 'text-green-600' : 'text-red-600'}`}>
                    {formatPrice(cancellationDetails.difference)}
                  </span>
                </div>
                <div className="flex justify-between text-sm border-t pt-2">
                  <span className="text-gray-500">Total Paid to Date:</span>
                  <span className="font-medium">{formatPrice(Number(booking.totalPaid) || 0)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500 font-bold">
                    {Number(booking.totalPaid) > cancellationDetails.newTotal ? 'Total Refund Due:' : 'Total Outstanding:'}
                  </span>
                  <span className={`font-medium ${Number(booking.totalPaid) > cancellationDetails.newTotal ? 'text-red-600' : 'text-green-600'}`}>
                    {formatPrice(Math.abs((Number(booking.totalPaid) || 0) - cancellationDetails.newTotal))}
                  </span>
                </div>
              </div>
            </dl>
          </div>

          <div className="bg-amber-50 rounded-lg p-4 border border-amber-200 mb-4">
  <div className="flex">
    <ExclamationTriangleIcon className="h-5 w-5 text-amber-400 mt-0.5" />
    <div className="ml-3">
      <h3 className="text-sm font-medium text-amber-800">Confirm Cancellation</h3>
      <p className="text-sm text-amber-700 mt-1">This action cannot be undone. Nomad Services will be released from ResRequest and the booking will be permanently cancelled.</p>
    </div>
  </div>
</div>

<div className="bg-blue-50 rounded-lg p-4 border border-blue-200">
  <div className="flex">
    <InformationCircleIcon className="h-5 w-5 text-blue-400 mt-0.5" />
    <div className="ml-3">
      <h3 className="text-sm font-medium text-blue-800">Next Steps</h3>
      <p className="text-sm text-blue-700 mt-1">After cancellation, please ensure you:
  <ul className="mt-1 ml-4 list-disc">
    <li>Notify all suppliers and update all supplier reservations to "Cancelled" status.</li>
    <li>Upload the final supplier invoices to Creatio for processing and approval by Finance team.</li>
  </ul>
</p>
    </div>
  </div>
</div>
          </div>
        );
    }
  };

  const canProceed = () => {
    switch (currentStep) {
      case STEPS.INFO:
        return formData.reason && formData.summary;
      case STEPS.CHARGES:
        return formData.chargeType && (formData.asPerTerms || (!formData.asPerTerms && formData.approvedBy));
      case STEPS.REVIEW:
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="bg-white rounded-lg p-6 relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors p-1 hover:bg-gray-100 rounded-full" aria-label="Close">
          <XMarkIcon className="h-6 w-6" />
        </button>

        <h2 className="text-2xl font-medium text-gray-900 mb-6">Cancel Booking</h2>

        <StepIndicator currentStep={currentStep} />

        {renderStepContent()}

        <div className="flex justify-between mt-8 pt-6 border-t border-gray-200">
          <div>{currentStep > STEPS.INFO && <ButtonOutlineSmall text="Back" onClick={() => setCurrentStep((prev) => prev - 1)} color="dark" icon={<ArrowLeftIcon className="h-4 w-4 mr-2" />} />}</div>
          <div className="flex space-x-4">
            <ButtonOutlineSmall text="Cancel" onClick={onClose} color="dark" />
            {currentStep < STEPS.REVIEW ? (
              <ButtonPrimarySmall
                text="Continue"
                onClick={() => setCurrentStep((prev) => prev + 1)}
                color="dark"
                disabled={!canProceed()}
                icon={<ArrowRightIcon className="h-4 w-4 ml-2" />}
                iconPosition="right"
              />
            ) : (
              <ButtonPrimarySmall text="Confirm Cancellation" onClick={handleSubmit} color="dark" isLoading={isSubmitting} disabled={isSubmitting} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationProcess;
