import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';
import Sidebar from '../../../partials/Sidebar';
import { fetchSeasonalGuideData } from '../functions/propertyFunctions';
import SeasonalGuideGrid from './components/SeasonalGuideGrid';
import FilterBar from './components/FilterBar';
import InfoBox from "../../../components/InfoBox";


function SeasonalGuides() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [seasonalGuides, setSeasonalGuides] = useState([]);
  const [selectedPark, setSelectedPark] = useState('All Parks');  // Changed default
  const [selectedMonth, setSelectedMonth] = useState('All Months'); // Changed default
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('grid');

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const guides = await fetchSeasonalGuideData();
        setSeasonalGuides(guides);
      } catch (error) {
        console.error('Error fetching seasonal guides:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGuides();
  }, []);

  // Fixed filtering logic
  const filteredData = seasonalGuides
    .filter(guide => selectedPark === 'All Parks' || guide.name === selectedPark)
    .map(guide => ({
      ...guide,
      months: guide.months.filter(month => 
        selectedMonth === 'All Months' || month.month === selectedMonth
      )
    }))
    .filter(guide => guide.months.length > 0);

  // Get unique options for filters
  const parkOptions = ['All Parks', ...new Set(seasonalGuides.map(guide => guide.name))];
  const monthOptions = [
    'All Months',
    ...new Set(seasonalGuides.flatMap(guide => guide.months.map(month => month.month)))
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
          >
            <div className="mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue mb-4">
                Seasonal Park Guide
              </h1>
              <div className="flex items-center space-x-2 text-gray-600">
                <MapPinIcon className="h-5 w-5" />
                <span>Explore seasonal information for each national park</span>
              </div>
            </div>

            <div className="mb-6">
              <InfoBox
                icon={CalendarIcon}
                text="Discover the best times to visit each park, including wildlife migrations, weather patterns, and seasonal activities."
                bgColor="bg-white"
                textColor="text-nomadBlue-700"
                fontSize="text-md"
                className="shadow-sm border border-nomadGray-400"
              />
            </div>

            <FilterBar
              selectedPark={selectedPark}
              selectedMonth={selectedMonth}
              setSelectedPark={setSelectedPark}
              setSelectedMonth={setSelectedMonth}
              parkOptions={parkOptions}
              monthOptions={monthOptions}
              view={view}
              setView={setView}
            />

            <div className="mt-6">
              {isLoading ? (
                <div className="flex items-center justify-center h-64">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-nomadBlue"></div>
                </div>
              ) : filteredData.length === 0 ? (
                <div className="text-center py-12">
                  <p className="text-gray-500">No data available for the selected filters.</p>
                </div>
              ) : (
                <SeasonalGuideGrid parkData={filteredData} view={view} />
              )}
            </div>
          </motion.div>
        </main>
      </div>
    </div>
  );
}

export default SeasonalGuides;