import 'react-toastify/dist/ReactToastify.css';

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { checkClosure, findCustomLocationPeriodProperty } from '../functions/transferFunctions';

import { BiDollar } from 'react-icons/bi';
import BookedDirect from './BookedDirect';
import CustomModalBookDirect from './CustomModalBookDirect';
import FullScreenPropertyModal from './FullScreenPropertyModal';
import HTMLContent from './HTMLContent';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { RiCloseLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import { deeShadow } from '../css/additional-styles/custom';
import { getPropObj } from '../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// The PropertyItem component takes in five props: hit, properties, setLoadingData, setCallMakeRooms, and SetGetPropertiesAndStock
export function PropertyItem({ hit, properties, setLoadingData, setCallMakeRooms, SetGetPropertiesAndStock, customLocations, setFetchCustom, onOpenPropertyModal, bookings, switchId, switchStartDate, switchEndDate, startDate }) {
  const { user } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // deal with roomPax

  // const maxChildAge = hit.maxChildAge;

  // const roomPaxMerged = roomPax.reduce(
  //   (acc, room) => {
  //     acc.adults += room.adults;
  //     acc.children += room.children;
  //     acc.childAges = [...acc.childAges, ...room.childAges];
  //     return acc;
  //   },
  //   { adults: 0, children: 0, childAges: [] },
  // );

  // hit.roomPaxMerged = roomPaxMerged;

  // const maxChildAge = hit.maxChildAge;

  // const roomPaxMerged = roomPax.reduce(
  //   (acc, room) => {
  //     acc.adults += room.adults;
  //     room.childAges.forEach((age) => {
  //       if (age > maxChildAge) {
  //         // Count as an adult
  //         acc.adults += 1;
  //       } else {
  //         // Count as a child and add to childAges
  //         acc.children += 1;
  //         acc.childAges.push(age);
  //       }
  //     });
  //     return acc;
  //   },
  //   { adults: 0, children: 0, childAges: [] },
  // );

  // // Sort childAges in descending order
  // roomPaxMerged.childAges.sort((a, b) => b - a);

  // hit.roomPaxMerged = roomPaxMerged;

  // console.log("hit data");
  // console.log(JSON.stringify(hit, undefined, 4));

  // Declare a state variable called showModal and set its initial value to false
  // const [showModal, setShowModal] = useState(false);
  // // Declare a state variable called open and set its initial value to false
  // const [open, setOpen] = useState(false);
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Declare a function called AddProperty that takes in two arguments: stock and num
  function AddProperty(stock, num) {
    // edit the property pax

    // If the value of stock is truthy, then push the hit object into the result array of the properties object

    if (stock) {
      const fetch = properties;
      const property = hit;
      // Filter out inactive rooms
      property.rooms = property.rooms.filter((room) => room.active);
      if (user.internalUser === false) {
        property.rooms = property.rooms.filter((room) => !room.hideForExternalUsers);
      }

      fetch.push(property);
    }
    setFetchCustom(true);

    // Call the SetGetPropertiesAndStock function with the value of stock as an argument
    SetGetPropertiesAndStock(stock);

    // Call the setCallMakeRooms function with the value of num as an argument
    setCallMakeRooms(num);
    console.log('add');
  }

  // Declare a variable called modalId and set its value to the result of calling the uuidv4 function
  const modalId = uuidv4();

  const [modalOpen, setModalOpen] = useState(false);
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };

  const modalClicked = () => {
    // if(campClosed){
    //   toast.error('Camp location closed');
    //   return;
    // }
    setLoadingData(true);
    if (hit.uid === 'a145bd57-f79b-4ca1-8a05-3be74fc63eaa') {
      setLoadingData(false);
      console.log(modalOpen);

      // Added the return keyword here
      return setModalOpen(true);
    }

    //setLoadingData(true);
    // If the length of the result array of the properties object is equal to 0, then call the AddProperty function with true and 0 as arguments
    if (properties.length === 0) {
      AddProperty(true, 0);
      console.log('first');

      return null;
    } else {
      // Find the index of the hit object in the result array of the properties object
      const matchingObjectIndex = properties.findIndex((item) => item.uid === hit.uid);

      // If the matchingObjectIndex is equal to -1, then the hit object was not found in the result array
      // In this case, call the AddProperty function with true and the length of the result array as arguments
      if (matchingObjectIndex === -1) {
        AddProperty(true, properties.length);
        console.log('next');

        return null;
      } else {
        // If the hit object was found in the result array, then call the AddProperty function with false and the matchingObjectIndex as arguments
        console.log(matchingObjectIndex);
        console.log('matched 1');
        AddProperty(false, matchingObjectIndex);
        return null;
      }
    }
  };

  const openPropertyModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenPropertyModal = () => {
    if (onOpenPropertyModal) {
      onOpenPropertyModal(hit.uid);
    }
  };


  const [locationDisplay, setLocationDisplay] = useState({ displayText: '', titleText: '' });
  const [campClosed, setCampClosed] = useState(false);

// Add this useEffect
useEffect(() => {
  async function fetchLocationData() {
    //const property = properties[getPropObj(hit.uid, properties)];
    
    let checkCamp = false;
    let checkDayStart;
    let checkDayEnd;
    if (switchId) {
      checkDayStart = moment(switchStartDate).format('YYYY-MM-DD');
      checkDayEnd = moment(switchEndDate).format('YYYY-MM-DD');
    } else {
      // Add safety checks for bookings array
      const lastBooking = bookings && bookings.length > 0 ? bookings[bookings.length - 1] : null;
      
      checkDayStart = lastBooking?.endDay 
        ? moment(lastBooking.endDay).add(1, 'days').format('YYYY-MM-DD') 
        : moment(startDate).format('YYYY-MM-DD');
        
      checkDayEnd = lastBooking?.endDay 
        ? moment(lastBooking.endDay).add(1, 'days').format('YYYY-MM-DD') 
        : moment(startDate).format('YYYY-MM-DD');
    }
    if (hit.mobileLocation) {
      checkCamp = await findCustomLocationPeriodProperty(hit.uid, checkDayStart, checkDayEnd, hit);
      console.log('checkCamp', checkCamp);
      console.log('checkDayStart', checkDayStart);
      console.log('checkDayEnd', checkDayEnd);
    }

    //const customLocation = customLocations.find((location) => location.propUid === hit.uid);
    const customLocation = checkCamp;
    let displayText;
    let titleText;

    if (hit.mobileLocation && customLocation) {
      const park = hit.customLocations.find((park) => park.parkId === customLocation.parkId);
      displayText = park ? park.parkName : 'Camp location closed';
      titleText = displayText;
      setCampClosed(park ? false : true);
    } else if(hit.closureDates !== undefined) {
      const result = checkClosure(hit.closureDates, checkDayStart, checkDayEnd);
      if(result.closed){
        displayText = 'Camp location closed';
        titleText = displayText;
        setCampClosed(true);
      } else {
        displayText = hit.park.name;
        titleText = hit.park.name;
        setCampClosed(false);
      }
    }
    else {
      displayText = hit.park.name;
      titleText = hit.park.name;
      setCampClosed(false);
    }

    setLocationDisplay({ displayText, titleText });
  }

  fetchLocationData();
}, [hit, properties, customLocations, bookings]); // Add any other dependencies as needed

  // Return a div element
  return (
    <div>
      <>
        {modalOpen && (
          <div className="z-[1000]">
            <div className="fixed left-0 top-0 h-[50px] w-full bg-white bg-opacity-90 z-[999]"></div>
            <div className="fixed top-50 h-full w-full bg-white bg-opacity-90 z-[999]"></div>
            <CustomModalBookDirect isOpen={modalOpen} onClose={() => setModalOpen(false)}>
              <div className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center z-[999]">
                {/* <div className="fixed top-0 left-1/2 transform -translate-x-1/2 p-5 bg-white rounded-lg z-[1000]" style={deeShadow}> */}

                <div className="absolute top-0 left-0 p-5 left-[250px] top-1/2  transform -translate-y-1/2 bg-white rounded-lg z-[999]" style={deeShadow}>
                  <p className="text-sm font-semibold pb-2">Please select property</p>
                  <p className="text-sm font-normal mb-5">Select a property that will be booked direct</p>
                  <div className="text-xs pb-2">
                    {/* Uncomment the below line once you confirm the modal is working */}

                    <BookedDirect
                      hit={hit}
                      deeShadow={deeShadow}
                      properties={properties}
                      SetGetPropertiesAndStock={SetGetPropertiesAndStock}
                      setCallMakeRooms={setCallMakeRooms}
                      setFetchCustom={setFetchCustom}
                    />
                  </div>

                  <div className="flex mt-5 justify-start">
                    <div>
                      <button
                        className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                        onClick={async () => {
                          setModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModalBookDirect>
          </div>
        )}
      </>
      <div key={uuidv4()} id={hit.uid + '_divId'} className="bg-white overflow-hidden drop-shadow rounded-md w-56 h-56 z-[25] relative">
        {/* PROPERTY ROW 1 */}
        <div
  className="cursor-pointer relative"
  onClick={() => {
    // Nomad Tanzania properties and "Accommodation Booked Direct" can be added to the properties object
    return modalClicked();
  }}
>
  <img className="object-cover mb-0.5 h-[115px] w-56 rounded-t-md" src={`${hit.heroImageUrl}/320x200`} alt={hit.name} />
  <div 
    className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-xs py-1 px-2 rounded cursor-pointer hover:bg-opacity-75 transition-all"
    onClick={(e) => {
      e.stopPropagation(); // Prevent the parent onClick from firing
      handleOpenPropertyModal();
    }}
  >
    More Info
  </div>
</div>
        {/* PROPERTY ROW 2 */}
        <div className="mx-2 flex">
          {/* Property name */}
          <div
            className="w-11/12 cursor-pointer"
            onClick={() => {
              return modalClicked();
              // if (process.env.REACT_APP_ENV_CODE !== "l") {
              //   return modalClicked();
              // } else if (hit.supplier.id === "ded3a3ed-aeaf-4495-9069-7754a649de67" || hit.supplier.id === "8ae5bed6-e15e-4e79-8ab0-e934c2645abc") {
              //   return modalClicked();
              // } else {
              //   return toast.error('You can only add Nomad Tanzania properties or "Accommodation Booked Direct"');
              // }
            }}
          >
            {/* Display the name of the hit object */}
            <div className="text-sm font-semibold truncate mt-1.5" title={hit.name}>
              {hit.name}
            </div>
            <div className="">
              {/* Display the name of the hit object */}
              {/* <div
  							className="text-xs font-normal leading-tight truncate brand-text-grey-2-v2 pt-1"
  							title={hit.park.name}>
  							{hit.park.name}
  						</div> */}
              {/* {(() => {
                const result = (async () => {

                  const property = properties[getPropObj(hit.uid, properties)];

                  // New code Dec 2024 - start
                  let checkCamp = false;
                  if (hit.mobileLocation) { 
                  const checkDay = moment(bookings[bookings.length-1].endDay).add(1, 'days').format('YYYY-MM-DD');
                   checkCamp = await findCustomLocationPeriodProperty(hit.uid, checkDay, checkDay, hit);
                  console.log('checkCamp');
                  console.log(checkCamp);
                  console.log(JSON.stringify(checkCamp, undefined, 4)); 
                  console.log('checkDay');
                  console.log(checkDay);
                  }
                  // New code Dec 2024 - end
                  const customLocation = customLocations.find((location) => location.propUid === hit.uid);
                  let displayText;
                  let titleText;

                  if (hit.mobileLocation && customLocation) {
                    const park = property.customLocations.find((park) => park.parkId === customLocation.parkId);
                    //titleText = displayText;
                    displayText = park ? park.parkName : 'Camp location closed'; // Not found - WIP
                    titleText = displayText;
                  } else {
                    displayText = hit.park.name;
                    titleText = hit.park.name;
                  }

                  return {
                    displayText,
                    titleText,
                  };
                })();

                return (
                  <div className="text-xs font-normal leading-tight truncate brand-text-grey-2-v2 pt-1" title={result.titleText}>
                    {result.displayText}
                  </div>
                );
              })()} */}
              <div 
  className="text-xs font-normal leading-tight truncate brand-text-grey-2-v2 pt-1" 
  title={locationDisplay.titleText}
>
  {locationDisplay.displayText}
</div>
              <div className="text-xs font-normal leading-tight truncate brand-text-grey-2-v2 pt-1.5">{hit.supplier.name}</div>
              {hit.platformResRequest?.active === true && (
                <div className="absolute bottom-2 left-2 right-0">
                  <div className="w-fit h-5 rounded border border-nomadEvergreen-700 px-3 py-0.5 flex items-center justify-center">
                    <div className="text-center text-nomadEvergreen-700 text-xs font-semibold truncate">Live Availability</div>
                  </div>
                </div>
              )}
              {hit.platformKarani?.active === true && user.internalUser === true && (
                <div className="absolute bottom-2 right-2">
                  <BiDollar className="text-nomadEvergreen-700" />
                </div>
              )}
            </div>
          </div>
         
        </div>
      </div>
    
    </div>
  );
}
