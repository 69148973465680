import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../css/additional-styles/stepper.scss';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import '../../../css/autocomplete-theme-classic/index.css';
import '../../../css/instantSearch/index.css';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import '../../../css/manageBookingsPagination.css';

import { Configure, Hits, InstantSearch, Pagination, connectPagination, connectSearchBox, connectStateResults } from 'react-instantsearch-dom';
import { LuFilter, LuRefreshCw } from 'react-icons/lu';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';

import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import ConnectedPagination from '../../../components/CustomPagination';
import KanbanBoard from './components/KanbanBoard';
import { ManageBookingHeader } from '../../../components/ManageBookingHeader';
import { ManageBookingItem } from '../../../components/ManageBookingItem';
import ManageBookingsSearchBox from '../../../components/ManageBookingsSearchBox';
import ManageBookingsSlideOut from '../../../components/ManageBookingsSlideOut';
import Sidebar from '../../../partials/Sidebar';
import StatusFilters from './components/ItineraryFilterDropdown';
import ViewToggle from './components/ViewToggle';
import algoliasearch from 'algoliasearch';
import { deeShadow } from '../../../css/additional-styles/custom';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { utcToZonedTime } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;

const searchClient = algoliasearch(appId, apiKey);

export const INSTANT_SEARCH_INDEX_NAME = 'agents';
export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTES = ['name'];

const VirtualSearchBox = connectSearchBox(() => null);

function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }
  const { configure, ...rest } = searchState;
  return `${location.pathname}${createURL(rest)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

const handleChangePropertiesSearch = (event) => {
  // console.log(event.currentTarget.value);
};

function ManageItineraries() {
  const { user } = useSelector((state) => state.auth);
  const { selectedCompany } = useSelector((state) => state.companies);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState('list');
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [selectedHit, setSelectedHit] = useState(null);
  const [laneKeys, setLaneKeys] = useState({});
  const [refreshingLanes, setRefreshingLanes] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchState, setSearchState] = useState(() => urlToSearchState(window.location));
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [hitsPerPage, setHitsPerPage] = useState(view === 'list' ? 10 : 50);
  const [showAllItineraries, setShowAllItineraries] = useState(false);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialFilters = generateFilters();
    setSearchState(prevState => ({
      ...urlToSearchState(window.location),
      configure: {
        ...prevState?.configure,
        filters: initialFilters
      }
    }));
    setIsInitialized(true);
  }, [user, selectedCompany]); 


  useEffect(() => {
    setHitsPerPage(view === 'list' ? 10 : 20);
  }, [view]);

  const handleCloseSlideOut = () => {
    setIsSlideOutOpen(false);
    setSelectedItinerary(null);
    setSelectedHit(null);
  };

  const [statuses, setStatuses] = useState({
    draft: true,
    provisional: true,
    confirmed: true,
    unknown: true,
    expired: true,
    cancelled: true,
    quotation: true,
    pendingProvisional: true,
    pendingConfirmation: true,
    pendingCancellation: true,
  });

  const handleStatusChange = (status) => {
    setStatuses((prevStatuses) => {
      const newStatuses = {
        ...prevStatuses,
        [status]: !prevStatuses[status],
      };

      setSearchState((prevSearchState) => ({
        ...prevSearchState,
        configure: {
          ...prevSearchState.configure,
          filters: generateFilters(newStatuses),
        },
      }));

      return newStatuses;
    });
  };

  const generateFilters = (currentStatuses = statuses) => {
    const selectedStatuses = Object.entries(currentStatuses)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    if (selectedStatuses.length === 0) return '';

    let statusFilters = selectedStatuses.map((status) => `status:${status}`).join(' OR ');
    let filters = `(${statusFilters})`;

    if (user.internalUser) {
      if (!showAllItineraries) {
        // filters = `user.uid:${user.uid} AND (${filters})`;
        filters = `(user.uid:${user.uid} OR bookingOwner.uid:${user.uid}) AND (${filters})`;
      }
    } else {
      let agentFilter = '';
      if (selectedCompany && selectedCompany.uid) {
        agentFilter = `agent.uid:${selectedCompany.uid}`;
      } else {
        console.error('No selected company found for non-internal user');
        agentFilter = `user.uid:${user.uid}`;
      }

      const mojoFilter = 'NOT mojoItinerary:true';

      if (!showAllItineraries) {
        filters = `(user.uid:${user.uid} OR consultant.uid:${user.consultant?.uid}) AND ${agentFilter} AND ${mojoFilter} AND (${filters})`;
      } else {
        filters = `${agentFilter} AND ${mojoFilter} AND (${filters})`;
      }
    }

    return filters;
  };

  const filters = generateFilters();

  const refreshLane = useCallback(
    (laneId) => {
      setRefreshingLanes((prev) => ({ ...prev, [laneId]: true }));

      setTimeout(() => {
        setLaneKeys((prevKeys) => ({
          ...prevKeys,
          [laneId]: (prevKeys[laneId] || 0) + 1,
        }));
        searchClient.clearCache();

        setTimeout(() => {
          setRefreshingLanes((prev) => ({ ...prev, [laneId]: false }));
        }, 500);
      }, 3000);
    },
    [searchClient],
  );

  const handleRefresh = () => {
    setIsRefreshing(true);

    const allLanes = ['quotation', 'hidden', 'pendingProvisional', 'provisional', 'pendingConfirmation', 'confirmed', 'awaitingTravelDesk'];

    // Set all lanes to refreshing state
    setRefreshingLanes((prevState) => {
      const newState = { ...prevState };
      allLanes.forEach((lane) => {
        newState[lane] = true;
      });
      return newState;
    });

    searchClient.clearCache();

    setTimeout(() => {
      setSearchState((prevState) => ({ ...prevState, refresh: Date.now() }));

      setLaneKeys((prevKeys) => {
        const newKeys = { ...prevKeys };
        allLanes.forEach((lane) => {
          newKeys[lane] = (prevKeys[lane] || 0) + 1;
        });
        return newKeys;
      });

      // Reset refreshing states after a short delay
      setTimeout(() => {
        setRefreshingLanes({});
        setIsRefreshing(false);
      }, 500);
    }, 3000);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto">
        <main className="flex-grow px-5 py-8">
          <h1 className="brand-text-color-800 font-normal font-roca text-4xl mb-2">Manage itineraries</h1>
          {isLoading ? (
            <CircleSpinnerOverlay loading={isLoading} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
          ) : (
            <div className="flex flex-col h-[calc(100vh-12rem)] min-h-[500px]">
              {isLoading || !isInitialized ? (
  <CircleSpinnerOverlay loading={true} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Loading data..." />
) : (
              <InstantSearch indexName="itineraries" searchClient={searchClient} searchState={searchState} onSearchStateChange={setSearchState}>
                <Configure filters={filters} hitsPerPage={hitsPerPage} />
                <div className="bg-white rounded-md shadow p-5 mb-5">
                  <div className="flex flex-wrap items-center justify-between gap-4">
                    <div className="flex-grow flex items-center space-x-4">
                      <ManageBookingsSearchBox onChange={handleChangePropertiesSearch} />
                      <div className="relative" ref={dropdownRef}>
                        <button className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                          <LuFilter className="text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-5 h-5" />
                        </button>
                        {isOpen && (
                          <div style={deeShadow} className="absolute left-0 mt-2 bg-white rounded-lg w-[250px] h-[340px] z-50">
                            <StatusFilters statuses={statuses} onStatusChange={handleStatusChange} />
                          </div>
                        )}
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="showAllItineraries"
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          checked={!showAllItineraries}
                          onChange={() => setShowAllItineraries(!showAllItineraries)}
                        />
                        <label htmlFor="showAllItineraries" className="ml-2 text-base font-medium text-gray-900">
                          Show only my itineraries
                        </label>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button onClick={handleRefresh} className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer" disabled={isRefreshing}>
                        <LuRefreshCw className={`text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
                      </button>
                      <ViewToggle view={view} setView={setView} />
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md shadow p-5 mb-5">
                  {view === 'kanban' ? (
                    <KanbanBoard
                      searchClient={searchClient}
                      user={user}
                      indexName="itineraries"
                      filters={filters}
                      onQuickView={(hit) => {
                        setSelectedItinerary(hit.objectID);
                        setSelectedHit(hit);
                        setIsSlideOutOpen(true);
                      }}
                      laneKeys={laneKeys}
                      refreshingLanes={refreshingLanes}
                      refreshLane={refreshLane}
                    />
                  ) : (
                    <>
                      {isRefreshing ? (
                        <CircleSpinnerOverlay loading={true} color="rgba(0,0,0,0.5)" overlayColor="rgba(255,255,255,0.5)" message="Refreshing list..." />
                      ) : (
                        <>
                          <ManageBookingHeader />
                          <Hits
                            className="ais-Hits-list-manageBooking w-full"
                            hitComponent={(props) => (
                              <ManageBookingItem
                                {...props}
                                setSelectedItinerary={setSelectedItinerary}
                                setSelectedHit={setSelectedHit}
                                selectedItinerary={selectedItinerary}
                                isOpen={isOpen}
                                setIsLoading={setIsLoading}
                                userUid={user.uid}
                                user={user}
                                setIsSlideOutOpen={setIsSlideOutOpen}
                              />
                            )}
                          />
                          {/* <Hits
                            className="ais-Hits-list-manageBooking w-full"
                            hitComponent={({ hit }) => (
                              <ManageBookingItem
                                uid={hit.uid}
                                setSelectedItinerary={setSelectedItinerary}
                                setSelectedHit={setSelectedHit}
                                selectedItinerary={selectedItinerary}
                                isOpen={isOpen}
                                setIsLoading={setIsLoading}
                                userUid={user.uid}
                                user={user}
                                setIsSlideOutOpen={setIsSlideOutOpen}
                              />
                            )}
                          /> */}
                          <div className="mt-4">
                            <ConnectedPagination />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <VirtualSearchBox />
              </InstantSearch>
              )}
            </div>
          )}
        </main>
      </div>
      <ManageBookingsSlideOut isOpen={isSlideOutOpen} onClose={handleCloseSlideOut} selectedHit={selectedHit} user={user} />
    </div>
  );
}

export default ManageItineraries;
