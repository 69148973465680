import 'react-confirm-alert/src/react-confirm-alert.css';

import { Cog6ToothIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu } from '../components/ui/Dropdown';
import { FiCalendar, FiChevronDown, FiChevronUp, FiEdit, FiExternalLink, FiFilePlus, FiHelpCircle, FiHome, FiLogOut, FiMessageCircle, FiShield, FiUser, FiZap } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Sidebar, SidebarBody, SidebarFooter, SidebarHeader, SidebarHeading, SidebarItem, SidebarLabel, SidebarSection, SidebarSpacer } from '../components/Sidebar';
import { TbLayoutSidebarLeftExpand, TbLayoutSidebarRightExpand } from 'react-icons/tb';
import { fetchCompanies, updateSelectedCompany } from '../features/companies/companiesSlice';
import { logout, reset } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from '../components/Avatar';
import ButtonOutlineSmall from '../components/Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from '../components/Buttons/ButtonPrimarySmall';
import { Badge } from '../components/ui/Badge';
import { clearSelectedCompany } from '../features/companies/companiesSlice';
import { confirmAlert } from 'react-confirm-alert';
import { deeShadow } from '../css/additional-styles/custom';
import packageJson from '../../package.json';

function CustomSidebar({ setSidebarOpen }) {
  const [sidebarOpen, setSidebarOpenState] = useState(() => {
    const storedSidebarOpen = localStorage.getItem('sidebarOpen');
    return storedSidebarOpen ? JSON.parse(storedSidebarOpen) : false;
  });

  const toggleSidebar = () => {
    const newSidebarOpen = !sidebarOpen;
    setSidebarOpenState(newSidebarOpen);
    setSidebarOpen(newSidebarOpen);
    localStorage.setItem('sidebarOpen', JSON.stringify(newSidebarOpen));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { companies, selectedCompany, selectedCompanyPermissions } = useSelector((state) => state.companies);

  useEffect(() => {
    if (user && user.consultant.companyId) {
      dispatch(
        fetchCompanies({
          userUid: user.uid,
          userCompanyId: user.consultant.companyId,
        }),
      );
    } else {
      console.log('User or companyId is missing:', user);
    }
  }, [dispatch, user]);

  const handleCompanyChange = (company) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="p-5 bg-white rounded-lg" style={deeShadow}>
            <p className="text-sm font-semibold pb-2">Confirm Brand Change</p>
            <p className="text-sm pb-2">Are you sure you want to change the brand to {company.name}?</p>
            <p className="text-sm pb-2">You'll navigate back to the homescreen on confirmation.</p>
            <div className="flex mt-5 justify-end">
              <ButtonOutlineSmall text="Cancel" onClick={onClose} className="mr-2" />
              <ButtonPrimarySmall
                text="Confirm"
                onClick={() => {
                  dispatch(
                    updateSelectedCompany({
                      updatedCompanyId: company.uid,
                      userUid: user.uid,
                    }),
                  );
                  onClose();
                  navigate('/'); // Navigate to the home screen
                }}
              />
            </div>
          </div>
        );
      },
    });
  };

  const onLogout = () => {
    // RP -> Clear companies on logout - START
    dispatch(clearSelectedCompany());
    // RP -> Clear companies on logout - END
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  return (
    <Sidebar className={`${!sidebarOpen ? 'w-16' : 'w-64'} transition-width duration-300`}>
      <SidebarHeader>
        <Dropdown>
          <DropdownButton as={SidebarItem} className="lg:mb-2.5">
            <Avatar src={selectedCompany && selectedCompany.avatarUrl ? selectedCompany.avatarUrl : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} />
            {sidebarOpen && (
              <span className="min-w-0 max-w-32">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{selectedCompany?.name || 'Select Company'}</span>
              </span>
            )}
            {sidebarOpen && <FiChevronDown className="w-5 h-5" />}
          </DropdownButton>
          <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
            <DropdownItem as={Link} to="/settings/account">
              <Cog8ToothIcon />
              <DropdownLabel>Settings</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            {companies.map((company) => (
              <DropdownItem key={company.uid} onClick={() => handleCompanyChange(company)}>
                <Avatar slot="icon" src={company.avatarUrl ? company.avatarUrl : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} />
                <DropdownLabel>{company.name}</DropdownLabel>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          <SidebarItem href="/" current={location.pathname === '/'}>
            <FiHome className="w-5 h-5" />
            {sidebarOpen && <SidebarLabel>Home</SidebarLabel>}
          </SidebarItem>
          <SidebarItem href="/itinerary/create/createItinerary" current={location.pathname === '/itinerary/create/createItinerary'}>
            <FiFilePlus className="w-5 h-5" />
            {sidebarOpen && <SidebarLabel>Create Itinerary</SidebarLabel>}
          </SidebarItem>
          <SidebarItem href="/availability/checkAvailability" current={location.pathname === '/availability/checkAvailability'}>
            <FiCalendar className="w-5 h-5" />
            {sidebarOpen && <SidebarLabel>Check Availability</SidebarLabel>}
          </SidebarItem>
          <SidebarItem href="/itinerary/manage/manageItineraries" current={location.pathname === '/itinerary/manage/manageItineraries'}>
            <FiEdit className="w-5 h-5" />
            {sidebarOpen && <SidebarLabel>Manage Itineraries</SidebarLabel>}
          </SidebarItem>
        </SidebarSection>
        {sidebarOpen && selectedCompany && selectedCompanyPermissions.accessLevel && selectedCompanyPermissions.accessLevel !== 'limited' && (
          <>
            <SidebarSection className="max-lg:hidden">
              <SidebarHeading>Product Info</SidebarHeading>
              <SidebarItem href="/propertyLibrary" current={location.pathname === '/propertyLibrary'}>
                Property Library
              </SidebarItem>
              <SidebarItem href="/travelInfo" current={location.pathname === '/travelInfo'}>
                Travel Info
              </SidebarItem>
              <SidebarItem href="/seasonalGuides" current={location.pathname === '/seasonalGuides'}>
                Seasonal Guides
              </SidebarItem>
            </SidebarSection>



    {/* Add the new Internal Management section with user.internalUser check */}
    {user.internalUser && (
      <SidebarSection className="max-lg:hidden">
        <SidebarHeading>Booking Management</SidebarHeading>
        <SidebarItem href="/management/suppliers" current={location.pathname === '/management/suppliers'}>
          Suppliers
        </SidebarItem>
      </SidebarSection>
    )}


            <SidebarSection className="max-lg:hidden">
              <SidebarHeading>Nomad Info</SidebarHeading>
              <SidebarItem href="/nomadCamps" current={location.pathname === '/nomadCamps'}>
                Nomad Camps
              </SidebarItem>
              <SidebarItem href="/mediaLibrary" current={location.pathname === '/mediaLibrary'}>
                <div className="flex items-center justify-between w-full">
                  <span>Media Studio</span>
               
                </div>
              </SidebarItem>
              <SidebarItem href="/#" current={location.pathname === '/#'}>
                <div className="flex items-center justify-between w-full">
                  <span>Agent News</span>
                  <Badge variant="warning" size="sm">Coming Soon</Badge>
                </div>
              </SidebarItem>
            </SidebarSection>
          </>
        )}
        <SidebarSpacer />
        <SidebarSection>
          <SidebarItem href="https://help.hornbill.app/en/" target="_blank" rel="noopener noreferrer">
            <FiHelpCircle className="w-5 h-5" />
            {sidebarOpen && (
              <>
                <SidebarLabel>Help Center</SidebarLabel>
                <FiExternalLink />
              </>
            )}
          </SidebarItem>
          {/*    <SidebarItem href="/changelog">
            <FiZap className="w-5 h-5" />
            {!isCollapsed && <SidebarLabel>Changelog</SidebarLabel>}
          </SidebarItem> */}
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden">
        {user && (
          <Dropdown>
            <DropdownButton as={SidebarItem}>
              {sidebarOpen ? (
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={user && user.avatarUrl ? user.avatarUrl : ''} initials={user ? `${user.firstName[0]}${user.lastName[0]}` : ''} className="size-10" square alt="" />
                  <span className="min-w-0 max-w-28">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{user && user.firstName}</span>
                    <span className="block truncate text-xs font-normal text-zinc-500 dark:text-white">{user ? user.email : ''}</span>
                  </span>
                </span>
              ) : (
                <Cog6ToothIcon className="w-6 h-6" />
              )}
              {sidebarOpen && <FiChevronUp className="w-5 h-5" />}
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="top start">
              <DropdownItem as={Link} to="/settings/account">
                <FiUser className="mr-2" />
                <DropdownLabel>My Settings</DropdownLabel>
              </DropdownItem>
              {/*
               <DropdownDivider />
              <DropdownItem href="/privacy-policy">
                <FiShield className="mr-2"  />
                <DropdownLabel>Privacy policy</DropdownLabel>
              </DropdownItem>
              <DropdownItem href="/share-feedback">
                <FiMessageCircle className="mr-2"  />
                <DropdownLabel>Share feedback</DropdownLabel>
              </DropdownItem> 
            */}
              <DropdownDivider />
              <DropdownItem onClick={onLogout}>
                <FiLogOut className="mr-2" />
                <DropdownLabel>Sign Out</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
        <div className="flex justify-between items-center text-xs text-zinc-400">
          {sidebarOpen && (
            <div>
              Version: {packageJson.version} ({process.env.REACT_APP_ENV_CODE})
            </div>
          )}
          <button className="p-1 text-zinc-400 hover:text-zinc-500 focus:outline-none" onClick={toggleSidebar}>
            {!sidebarOpen ? <TbLayoutSidebarLeftExpand className="w-6 h-5" /> : <TbLayoutSidebarRightExpand className="w-5 h-5" />}
          </button>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}

export default CustomSidebar;
