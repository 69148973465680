import React, { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import ViewTransferItem from '../../../../components/ViewTransferItem';
import ViewDropPickup from '../../../../components/ViewDropPickup';
import ViewPageComp from '../../../../components/ViewPageComp';
import ViewActivity from '../../../../components/ViewActivity';
import { collapseExpandAllItems } from '../../../../functions/finalPageFunctions';
import { formatFireStoreDate } from '../../../../functions/generalFunctions';
import { formatPrice } from '../../../../functions/generalFunctions';
import { BanknotesIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { loadProperties } from '../../../../functions/loadDataFunctions';
import { getPropObj } from '../../../../functions/generalFunctions';
import { rateLabelMap } from '../../../../functions/itineraryFunctions';
const DetailedItinerary = ({
  booking,
  bookingMaster,
  icon: Icon,
  supplierReservations,
  user,
}) => {
  const [finalPageData, setFinalPageData] = useState([]);
  const [finalPageTransferData, setFinalPageTransferData] = useState([]);
  const [customFinalItems, setCustomFinalItems] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [finalCollapse, setFinalCollapse] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [airports, setAirports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { allSuppliers } = useSelector((state) => state.suppliers);
  const { allAirports } = useSelector((state) => state.airports);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      
      if (allSuppliers && allSuppliers.result) {
        setSuppliers(JSON.parse(JSON.stringify(allSuppliers.result)));
      }
      
      if (allAirports && allAirports.result) {
        setAirports(JSON.parse(JSON.stringify(allAirports.result)));
      }
      
      if (booking && booking.itineraryData) {
        const loadedProperties = [];
        for (let i = 0; i < booking.itineraryData.length; i++) {
          const property = await loadProperties(booking.itineraryData[i].propUid, loadedProperties);
          if (property) {
            loadedProperties.push(property);
          }
        }
        setProperties(loadedProperties);
      }
      
      setIsLoading(false);
    }
    
    loadData();
  }, [allSuppliers, allAirports, booking]);

  useEffect(() => {
    if (booking) {
      setBookings(booking.itineraryData || []);
      setTransfers(booking.transfersData || []);
      
      if (booking.customFinalItems) {
        setCustomFinalItems(booking.customFinalItems);
      }
      
      if (booking.activitiesData) {
        const originalActivitiesData = booking.activitiesData.map((activityData) => {
          const { activities, ...rest } = activityData;
          const originalActivities = activities.map((activity) => {
            return activity.values;
          });
          return { ...rest, activities: originalActivities };
        });
        setActivitiesData(originalActivitiesData);
      }
      
      setFinalPageData(booking.calculatedPricing || []);
      setFinalPageTransferData(booking.transfersPricing || []);
      
      const formattedDate = booking.dateStart.seconds 
        ? formatFireStoreDate(booking.dateStart.seconds, booking.dateStart.nanoseconds) 
        : booking.dateStart;
      const formattedStart = moment.utc(formattedDate).subtract(0, 'days').format('YYYY-MM-DD');
      setDateStart(formattedStart);
    }
  }, [booking]);

  const flattenActivities = (activitiesData) => {
    return activitiesData.reduce((acc, item) => {
      const flattenedActivities = item.activities.flat().map((activity) => ({ ...activity, parent: item }));
      return [...acc, ...flattenedActivities];
    }, []);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or use a spinner component
  }


  // SHOW CANCELLATION DETAILS IF THE ITINEARY IS CANCELLED 
  if (booking.status === 'cancelled' && booking.cancellation) {
    return (
      <div className="space-y-6 p-6 bg-white rounded-lg">
        {/* Cancellation Notice */}
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex">
            <InformationCircleIcon className="h-6 w-6 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Booking Cancelled</h3>
              <p className="text-sm text-red-700 mt-1">
                Cancelled on {moment(booking.cancellation.timestamp.toDate()).format('MMMM Do, YYYY')}
              </p>
              {user.internalUser && (
                <>
                  <p className="text-sm text-red-700">Reason: {booking.cancellation.reason}</p>
                  {booking.cancellation.summary && (
                    <p className="text-sm text-red-700 mt-2">Details: {booking.cancellation.summary}</p>
                  )}
                  {!booking.cancellation.asPerTerms && booking.cancellation.approvedBy && (
                    <p className="text-sm text-red-700 mt-2">
                      Exception approved by: {booking.cancellation.approvedBy}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Financial Summary */}
        <div className="bg-gray-50 rounded-lg p-6 border border-gray-200">
          <div className="flex items-center mb-4">
            <BanknotesIcon className="h-5 w-5 text-gray-400 mr-2" />
            <h4 className="text-lg font-medium text-gray-900">Cancellation Charges</h4>
          </div>

          {/* Charges Table */}
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                  <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Original Amount</th>
                  <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Cancellation Charge</th>
                  <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Difference</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {booking.jsonData?.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm text-gray-900">{item.details}</td>
                    <td className="px-4 py-2 text-sm text-gray-900 text-right">
                      {formatPrice(item.saleTotalFinal)}
                    </td>
                    <td className="px-4 py-2 text-sm text-red-600 text-right">
                      {formatPrice(item.cancelledTotal)}
                    </td>
                    <td className={`px-4 py-2 text-sm text-right ${
                      item.saleTotalFinal - item.cancelledTotal > 0 
                        ? 'text-green-600' 
                        : 'text-red-600'
                    }`}>
                      {formatPrice(item.saleTotalFinal - item.cancelledTotal)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <td className="px-4 py-3 text-sm font-medium text-gray-900">Total</td>
                  <td className="px-4 py-3 text-sm font-medium text-gray-900 text-right">
                    {formatPrice(booking.totalPriceOriginal)}
                  </td>
                  <td className="px-4 py-3 text-sm font-medium text-red-600 text-right">
                    {formatPrice(booking.totalPrice)}
                  </td>
                  <td className="px-4 py-3 text-sm font-medium text-green-600 text-right">
                    {formatPrice(booking.totalPriceOriginal - booking.totalPrice)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* Additional Info */}
          {!booking.cancellation.asPerTerms && booking.cancellation.approvedBy && (
            <div className="mt-4 text-sm text-gray-500">
              Exception approved by: {booking.cancellation.approvedBy}
            </div>
          )}
        </div>
      </div>
    );
  }


  return (
    <div>
      <div className={`pt-0 grid grid-cols-12 mb-0`}>
        <div className="col-span-12">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Detailed Itinerary</h2>
            <div className="flex text-sm font-normal">
              <div>
                <div className="ml-5 text-black">
                  <button
                    onClick={() =>
                      collapseExpandAllItems(
                        finalPageData,
                        setFinalPageData,
                        finalPageTransferData,
                        setFinalPageTransferData,
                        customFinalItems,
                        setCustomFinalItems,
                        setFinalCollapse,
                        false,
                      )
                    }
                    className="flex items-center"
                  >
                    <span className="mr-2">Expand all</span>
                    <IoIosArrowDown size={20} />
                  </button>
                </div>
              </div>
              <div>
                <div className="ml-5 text-black">
                  <button
                    onClick={() =>
                      collapseExpandAllItems(
                        finalPageData,
                        setFinalPageData,
                        finalPageTransferData,
                        setFinalPageTransferData,
                        customFinalItems,
                        setCustomFinalItems,
                        setFinalCollapse,
                        true,
                      )
                    }
                    className="flex items-center"
                  >
                    <span className="mr-2">Collapse all</span>
                    <IoIosArrowUp size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {!isLoading && airports.length > 0 && suppliers.length > 0 && properties.length > 0 && bookings.map((booking, bi) => {
        const firstDay = dateStart;
        let arrivalTransfer = false;
        if (bi === 0) {
          arrivalTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'arrival' && transfer.formValues);
          if (arrivalTransfer === undefined) {
            arrivalTransfer = false;
          }
        }

        let departureTransfer = false;
        if (bi === bookings.length - 1) {
          departureTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'departure' && transfer.formValues);
          if (departureTransfer === undefined) {
            departureTransfer = false;
          }
        }

        let propertyTransfer = false;
        if (bi >= 0 && bi < bookings.length - 1) {
          propertyTransfer = transfers.find((transfer) => transfer.uuid === booking.id && transfer.objType === 'propertyTransfer' && transfer.formValues);
          if (propertyTransfer === undefined) {
            propertyTransfer = false;
          }
        }

        return (
          <div key={uuidv4()} className="mx-0">
            {/* ARRIVAL TRANSFER */}
            {arrivalTransfer &&
              finalPageTransferData
                .filter((item) => item.uuid === booking.id && item.objType === 'arrival')
                .map((item, index) => (
                  <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                    <ViewTransferItem
                      item={item}
                      index={index}
                      suppliers={suppliers}
                      finalPageTransferData={finalPageTransferData}
                      setFinalPageTransferData={setFinalPageTransferData}
                      customFinalItems={customFinalItems}
                      setCustomFinalItems={setCustomFinalItems}
                      transfers={transfers}
                      properties={properties}
                      firstDay={firstDay}
                      bookingMaster={bookingMaster}
                      supplierReservations={supplierReservations}
                    />
                  </div>
                ))}

            {/* ARRIVAL PICKUP */}
            {arrivalTransfer && arrivalTransfer?.formValues?.includeArrivalTransfer && (
              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                <ViewDropPickup
                  item={arrivalTransfer}
                  bookings={bookings}
                  booking={booking}
                  airports={airports}
                  pickupDropoff={'pickup'}
                  customFinalItems={customFinalItems}
                  setCustomFinalItems={setCustomFinalItems}
                  properties={properties}
                  transfers={transfers}
                  shouldCollapse={finalCollapse}
                  firstDay={firstDay}
                  bookingMaster={bookingMaster}
                  supplierReservations={supplierReservations}
                />
              </div>
            )}

            {/* PROPERTY */}
            {finalPageData
              .filter((item) => item.uuid === booking.id)
              .map((item, index) => (
                <ViewPageComp
                  key={item.uuid}
                  item={item}
                  index={index}
                  finalPageData={finalPageData}
                  setFinalPageData={setFinalPageData}
                  properties={properties}
                  getPropObj={getPropObj}
                  rateLabelMap={rateLabelMap}
                  formatPrice={formatPrice}
                  suppliers={suppliers}
                  customFinalItems={customFinalItems}
                  setCustomFinalItems={setCustomFinalItems}
                  bookngs={bookings}
                  finalItem={bookings.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                  firstDay={firstDay}
                  bookingMaster={bookingMaster}
                  supplierReservations={supplierReservations}
                />
              ))}

            {/* ACTIVITIES */}
            {flattenActivities(activitiesData)
              .filter((activity) => activity.parent.id === booking.id)
              .map((activity, index) => (
                <div key={activity.uuid} style={{ zIndex: 1000 - index }}>
                  <ViewActivity
                    item={activity}
                    index={index}
                    bookings={bookings}
                    booking={booking}
                    activitiesData={activitiesData}
                    customFinalItems={customFinalItems}
                    setCustomFinalItems={setCustomFinalItems}
                    shouldCollapse={finalCollapse}
                    firstDay={firstDay}
                    finalItem={bookings.length === bi + 1 && !departureTransfer?.formValues ? true : false}
                    bookingMaster={bookingMaster}
                    supplierReservations={supplierReservations}
                  />
                </div>
              ))}

            {/* PROPERTY TRANSFER DROPOFF */}
            {propertyTransfer && propertyTransfer?.formValues?.includeDepartureTransfer && propertyTransfer.objType === 'propertyTransfer' && (
              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                <ViewDropPickup
                  item={propertyTransfer}
                  bookings={bookings}
                  booking={booking}
                  airports={airports}
                  pickupDropoff={'dropoff'}
                  customFinalItems={customFinalItems}
                  setCustomFinalItems={setCustomFinalItems}
                  properties={properties}
                  transfers={transfers}
                  shouldCollapse={finalCollapse}
                  firstDay={firstDay}
                  bookingMaster={bookingMaster}
                  supplierReservations={supplierReservations}
                />
              </div>
            )}

            {/* INTER PROPERTY TRANSFER */}
            {propertyTransfer &&
              finalPageTransferData
                .filter((item) => item.uuid === booking.id && item.objType === 'propertyTransfer')
                .map((item, index) => (
                  <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                    <ViewTransferItem
                      item={item}
                      index={index}
                      suppliers={suppliers}
                      finalPageTransferData={finalPageTransferData}
                      setFinalPageTransferData={setFinalPageTransferData}
                      customFinalItems={customFinalItems}
                      setCustomFinalItems={setCustomFinalItems}
                      transfers={transfers}
                      properties={properties}
                      firstDay={firstDay}
                      bookingMaster={bookingMaster}
                      supplierReservations={supplierReservations}
                    />
                  </div>
                ))}

            {/* PROPERTY TRANSFER PICKUP */}
            {propertyTransfer && propertyTransfer?.formValues?.includeArrivalTransfer && propertyTransfer.objType === 'propertyTransfer' && (
              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                <ViewDropPickup
                  item={propertyTransfer}
                  bookings={bookings}
                  booking={booking}
                  airports={airports}
                  pickupDropoff={'pickup'}
                  customFinalItems={customFinalItems}
                  setCustomFinalItems={setCustomFinalItems}
                  properties={properties}
                  transfers={transfers}
                  shouldCollapse={finalCollapse}
                  firstDay={firstDay}
                  bookingMaster={bookingMaster}
                  supplierReservations={supplierReservations}
                />
              </div>
            )}

            {/* DEPARTURE DROPOFF */}
            {departureTransfer && departureTransfer?.formValues?.includeDepartureTransfer && (
              <div key={uuidv4()} style={{ zIndex: 1000 }}>
                <ViewDropPickup
                  item={departureTransfer}
                  bookings={bookings}
                  booking={booking}
                  airports={airports}
                  pickupDropoff={'dropoff'}
                  customFinalItems={customFinalItems}
                  setCustomFinalItems={setCustomFinalItems}
                  properties={properties}
                  transfers={transfers}
                  shouldCollapse={finalCollapse}
                  firstDay={firstDay}
                  bookingMaster={bookingMaster}
                  supplierReservations={supplierReservations}
                />
              </div>
            )}

            {/* DEPARTURE TRANSFER */}
            {departureTransfer &&
              finalPageTransferData
                .filter((item) => item.uuid === booking.id && item.objType === 'departure')
                .map((item, index) => (
                  <div key={uuidv4()} style={{ zIndex: 1000 - index }}>
                    <ViewTransferItem
                      item={item}
                      index={index}
                      suppliers={suppliers}
                      finalPageTransferData={finalPageTransferData}
                      setFinalPageTransferData={setFinalPageTransferData}
                      customFinalItems={customFinalItems}
                      setCustomFinalItems={setCustomFinalItems}
                      transfers={transfers}
                      properties={properties}
                      firstDay={firstDay}
                      bookingMaster={bookingMaster}
                      departureButtons={false}
                      supplierReservations={supplierReservations}
                    />
                  </div>
                ))}
          </div>
        );
      })}
    </div>
  );
};

DetailedItinerary.displayName = 'Detailed Itinerary'; 

export default DetailedItinerary;