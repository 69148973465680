import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for react-confirm-alert
import ButtonPrimarySmall from '../../../../components/Buttons/ButtonPrimarySmall';
import ButtonOutlineSmall from '../../../../components/Buttons/ButtonOutlineSmall';
import { IoIosDocument, IoMdCheckmark, IoMdCheckmarkCircle } from 'react-icons/io';
import { format, isBefore, parseISO } from 'date-fns';
import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { Checkbox, Dialog, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from '@headlessui/react';
import ConfirmationProcess from './ConfirmationProcess';
import CancellationProcess from './CancellationProcess';
import { Timestamp } from 'firebase/firestore';

import {
  cancelRequestPendingConfirmation,
  cancelRequestPendingProvisional,
  createLogEntry,
  modifiedDetails,
  releaseHold,
  requestCancellation,
  requestConfirmation,
  convertToQuotation,
  reinstateProvisional,
} from '../functions/sendDataFunctions';
import { updateItineraryStatusCancel, updateItineraryStatusConfirmation, updateItineraryStatusReleaseHold } from '../../../../functions/itineraryFunctions';
import { getTermsAndConditionsUrl } from '../../../../constants/documentUrls';

import { confirmAlert } from 'react-confirm-alert';
import { countUsedGuests } from '../../../../functions/guestsFunctions';
import { deeShadow } from '../../../../css/additional-styles/custom';
import { formatPrice } from '../../../../functions/generalFunctions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const ViewMainHeader = ({ booking, guests, setIsSlideOutOpen, bookingMaster, supplierReservations }) => {
  // USER
  const { user } = useSelector((state) => state.auth);
  const { selectedCompanyNomadCompany } = useSelector((state) => state.companies);
  const navigate = useNavigate();
  const status = booking.status;
  // Terms and conditions
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState('');

  // Modal States
  const [isConverting, setIsConverting] = useState(false);
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
  const [isReinstateModalOpen, setIsReinstateModalOpen] = useState(false);
  const [isConfirmationProcessOpen, setIsConfirmationProcessOpen] = useState(false);
  const [isCancellationProcessOpen, setIsCancellationProcessOpen] = useState(false);

  // Helper function to see if user is able to re-instate a provisonal
  const canReinstate = useCallback(() => {
    if (!user.internalUser) return false;
    return supplierReservations.some((res) => res.supplierUid === 'ded3a3ed-aeaf-4495-9069-7754a649de67' && res.reservationStage === 'provisional' && res.dateProvisionalExpiry?.toDate() > new Date());
  }, [user.internalUser, supplierReservations]);

// helper function to check provisional requirement and is able to re-instate a provisional
const checkProvisionalRequirements = useCallback(() => {
  const provisionalSuppliers = supplierReservations.filter(res => 
    res.provisionalRequired && !res.deleted
  );

  const invalidProvisionals = provisionalSuppliers.filter(res => 
    res.reservationStage !== 'provisional' || 
    !res.dateProvisionalExpiry?.toDate() || 
    res.dateProvisionalExpiry.toDate() <= new Date()
  );

  return {
    canReinstate: invalidProvisionals.length === 0,
    invalidSuppliers: invalidProvisionals
  };
}, [supplierReservations]);


  useEffect(() => {
    setTermsAndConditionsUrl(getTermsAndConditionsUrl(selectedCompanyNomadCompany));
  }, [selectedCompanyNomadCompany]);


  // HANDLE CONFIRMATION PROCESS
  const handleOpenConfirmationProcess = useCallback(() => {
    setIsConfirmationProcessOpen(true);
  }, []);

  const handleConfirmationSuccess = useCallback(() => {
    setIsConfirmationProcessOpen(false);
    // Any additional logic after successful confirmation
  }, []);

  const handleConfirmationError = useCallback((error) => {
    console.error('Confirmation process error:', error);
    toast.error(`Confirmation process failed: ${error.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }, []);

  const handleCloseConfirmationProcess = useCallback(() => {
    setIsConfirmationProcessOpen(false);
  }, []);

    // HANDLE CANCELLATION PROCESS
    const handleOpenCancellationProcess = useCallback(() => {
      setIsCancellationProcessOpen(true);
    }, []);
  
    const handleCancellationSuccess = useCallback((cancellationData) => {
      setIsCancellationProcessOpen(false);
      handleCreateLog('statusChange', booking.uid, booking.code, 'pendingCancellation > cancelled');
    }, [booking.uid, booking.code]);
  
    const handleCancellationError = useCallback((error) => {
      console.error('Cancellation process error:', error);
      toast.error(`Cancellation process failed: ${error.message}`);
    }, []);

  const [supplierCounts, setSupplierCounts] = useState({
    provisional: 0,
    pendingProvisional: 0,
    confirmed: 0,
    pendingConfirmation: 0,
  });

  const activeReservations = useMemo(() => {
    const filtered = supplierReservations.filter((reservation) => !reservation.deleted);
    console.log('Active Reservations:', filtered);
    return filtered;
  }, [supplierReservations]);

  useEffect(() => {
    const counts = {
      provisional: 0, // provisionals with provisionalRequired
      pendingProvisional: 0, // non-provisionals with provisionalRequired
      confirmed: 0, // confirmed reservations
      pendingConfirmation: 0, // all non-confirmed reservations
    };

    console.log('Starting count calculation...');
    activeReservations.forEach((reservation) => {
      console.log('Processing reservation:', reservation);

      // Handle provisional counts
      if (reservation.provisionalRequired) {
        if (reservation.reservationStage === 'provisional') {
          counts.provisional++;
          console.log('Added provisional');
        } else {
          counts.pendingProvisional++;
          console.log('Added pendingProvisional');
        }
      }

      // Handle confirmation counts
      if (reservation.reservationStage === 'confirmed') {
        counts.confirmed++;
        console.log('Added confirmed');
      } else {
        counts.pendingConfirmation++;
        console.log('Added pendingConfirmation');
      }
    });

    console.log('Final counts:', counts);
    setSupplierCounts(counts);
  }, [activeReservations]);

  // Monitor counts changes
  useEffect(() => {
    console.log('Supplier counts updated:', supplierCounts);
  }, [supplierCounts]);

  // Add this to see when the counts change
  useEffect(() => {
    console.log('Supplier counts updated:', supplierCounts);
  }, [supplierCounts]);

  // guests
  const guestsConfirmed = guests.filter((guest) => guest.detailsComplete).length;
  const guestsTotal = countUsedGuests(bookingMaster, guests);
  let guestsTask = 1;
  let guestsTickColor = 'text-neutral-200';
  if (guestsConfirmed === guestsTotal) {
    guestsTickColor = 'text-nomadEvergreen-600';
    guestsTask = 0;
  }

  //deposit
  let depositTitle;
  let depositText;
  let depositTickColor = 'text-neutral-200';
  let depositTask = 1;

  //cancelled
  let cancelledTickColor = 'text-neutral-200';
  if (booking.totalOutstanding === 0) {
    cancelledTickColor = 'brand-bg-color-v2';
  }

  if (booking.totalDeposit === 0) {
    depositTitle = 'Pay Deposit';
    depositText = 'No deposit is due for this booking';
  } else if (booking.totalDeposit > 0 && booking.totalPaid === 0) {
    depositTitle = 'Pay Deposit';
    depositText = `Pay deposit of $${formatPrice(booking.totalDeposit)} before ${moment(booking.depositDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.totalDeposit > 0 && booking.totalPaid > 0 && booking.depositPaid === false) {
    depositTitle = 'Pay Deposit';
    depositText = `You've paid $${formatPrice(booking.totalPaid)} of your $${formatPrice(booking.totalDeposit)} deposit due before ${moment(booking.depositDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.depositPaid === true) {
    depositTitle = 'Deposit Paid';
    depositText = `We've received your deposit of $${formatPrice(booking.totalDeposit)}, thank you`;
    depositTickColor = 'text-nomadEvergreen-600';
    depositTask = 0;
  } else {
    depositTitle = 'Pay Deposit';
    depositText = `Unable to view. Please contact your consultant for details`;
  }

  //balance
  let balanceTitle;
  let balanceText;
  let balanceTickColor = 'text-neutral-200';
  let balanceTask = 1;

  if (booking.totalPaid === booking.totalPrice) {
    balanceTitle = 'Balance Paid';
    balanceText = `We've received full payment for this booking, thank you`;
    balanceTickColor = 'text-nomadEvergreen-600';
    balanceTask = 0;
  } else if (booking.depositPaid === false) {
    balanceTitle = 'Pay Balance';
    balanceText = `Pay total of $${formatPrice(booking.totalPrice - booking.totalDeposit)} before ${moment(booking.balanceDueDate).format('MMMM Do, YYYY')}`;
  } else if (booking.depositPaid === true) {
    balanceTitle = 'Balance Paid';
    // handle the case where there is an error displaying this value
    const outstandingAmount = formatPrice(booking.totalOutstanding);
    balanceText =
      outstandingAmount !== 'NaN'
        ? `You have $${outstandingAmount} to be paid before ${moment(booking.balanceDueDate).format('MMMM Do, YYYY')}`
        : "We can't display this right now. Please contact our team.";
  } else {
    balanceTitle = 'Pay Balance';
    balanceText = `Unable to view. Please contact your consultant for details`;
  }

  let headerBgColor;
  let headerTextColor;
  let headerIconColor;
  let headerIconBgColor;
  let headerText;
  let headerSubText;
  let btns;
  let cancelledTab;
  let guestsTitle;
  let guestsText;
  let supplierCount;
  let supplierDate;
  let supplierCount1;

  const handleCreateLog = async (eventType, itineraryUid, itineraryCode, action) => {
    try {
      const result = await createLogEntry(eventType, user, itineraryUid, itineraryCode, action);
      if (result.success) {
        console.log(result.message);
        // Perform any additional actions on success
      } else {
        console.error(result.message);
        // Handle the error case
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle any errors that occurred during the function execution
    }
  };

  if (status === 'confirmed') {
    const isCompletedBooking = moment().diff(moment(booking.dateStart), 'days') > 14;

    if (isCompletedBooking) {
      // COMPLETED BOOKING
      headerBgColor = 'bg-nomadEvergreen-300';
      headerTextColor = 'text-black';
      headerIconColor = 'text-nomadEvergreen-600';
      headerIconBgColor = 'bg-nomadEvergreen-200';
      headerText = 'This booking has been completed';
      headerSubText = 'Please contact your consultant if you have any questions.';
      btns = null; // Remove the cancel button for completed bookings
    } else {
      // CONFIRMED
      headerBgColor = 'bg-nomadEvergreen-300';
      headerTextColor = 'text-black';
      headerIconColor = 'text-nomadEvergreen-600';
      headerIconBgColor = 'bg-nomadEvergreen-200';
      headerText = 'All services are booked and your trip is confirmed!';
      headerSubText = `You have ${guestsTask + balanceTask + depositTask} task${guestsTask + balanceTask + depositTask > 1 ? 's' : ''} to complete before departure`;
      btns = (
        <div className="flex items-center mr-5">
          <div>
            <ButtonOutlineSmall
              text="Request Cancellation"
              color="dark"
              onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                        <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                          <p className="text-sm font-semibold pb-2">Are you sure you want to cancel this booking?</p>
                          <p className="text-sm font-normal pb-2">This will cancel all confirmed services.</p>
                          <p className="text-sm font-normal pb-2">Cancellation charges may apply as per your agent agreement.</p>
                          <p className="text-sm font-normal pb-2">You will receive a confirmation email once your booking is successfully cancelled. </p>
                          <div className="flex mt-5 justify-end gap-4">
                            <ButtonOutlineSmall
                              onClick={async () => {
                                onClose();
                              }}
                              text="Back"
                              color="dark"
                            />
                            <ButtonPrimarySmall
                              color="dark"
                              onClick={async () => {
                                onClose(); // Close the confirmation dialog first
                                let statusResponse = true;
                                if (statusResponse) {
                                  const response = await requestCancellation(booking.uid);
                                  await handleCreateLog('statusChange', booking.uid, booking.code, 'confirmed > pendingCancellation');

                                  if (response.success) {
                                    await modifiedDetails(booking.uid, user, 'pendingCancellation');
                                    toast.success('Booking Cancelled');
                                  } else {
                                    toast.error(response.message);
                                  }
                                }
                              }}
                              text="Cancel Booking" 
                            />
                          </div>
                        </div>
                      </div>
                    );
                  },
                  closeOnEscape: true,
                  closeOnClickOutside: true,
                });
              }}
            />
          </div>
        </div>
      );
    }
    // Do not allow edit of guests for external users within 2 weeks of start date
    const isWithinTwoWeeks = moment(booking.dateStart).diff(moment(), 'days') <= 14;
    const canEditGuests = user.internalUser || !isWithinTwoWeeks;

    guestsTitle = 'Add Guest Information';
    guestsText = (
      <div>
        <span
          className="text-nomadEvergreen-600 underline cursor-pointer"
          onClick={() => {
            if (canEditGuests) {
              setIsSlideOutOpen(true);
            } else {
              toast.error("You're unable to edit guests within 2 weeks of the start date. Please contact our team if you wish to make a change.");
            }
          }}
        >
          {guestsConfirmed === guestsTotal ? guestsConfirmed : guestsConfirmed} guest{guestsConfirmed > 1 || guestsConfirmed === guestsTotal ? 's' : ''} confirmed
        </span>{' '}
        out of {guestsTotal} total guests.
      </div>
    );
  } else if (status === 'quotation') {
    // QUOTATION
    headerBgColor = 'bg-[#F0ECE7]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#C5BDB4]';
    headerIconBgColor = 'bg-[#F4F3F2]';
    headerText = 'No services held.';
    headerSubText = 'You can edit this itinerary if you wish to progress.';
    btns =
      user.internalUser === false && booking.user.internalUser === true ? null : (
        <div className="flex items-center mr-5">
          <div>
            <ButtonPrimarySmall
              text="Edit itinerary"
              color="dark"
              onClick={() => {
                navigate(`/itinerary/edit/editItinerary/${booking.uid}`, {
                  replace: true,
                });
              }}
            />
          </div>
        </div>
      );
  } else if (status === 'draft') {
    // QUOTATION
    headerBgColor = 'bg-[#F0ECE7]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#C5BDB4]';
    headerIconBgColor = 'bg-[#F4F3F2]';
    headerText = 'No services held.';
    headerSubText = 'You can edit this itinerary if you wish to progress.';
    btns =
      user.internalUser === false && booking.user.internalUser === true ? null : (
        <div className="flex items-center mr-5">
          <div>
            <ButtonPrimarySmall
              text="Edit itinerary"
              color="dark"
              onClick={() => {
                navigate(`/itinerary/edit/editItinerary/${booking.uid}`, {
                  replace: true,
                });
              }}
            />
          </div>
        </div>
      );
  } else if (status === 'pendingCancellation') {
    // PENDING CANCELLATION
    headerBgColor = 'bg-[#FEE2DD]';
    headerTextColor = 'text-nomadPrimary-900';
    headerIconColor = 'text-[#FC735B]';
    headerIconBgColor = 'bg-[#FFC7BD]';
    headerText = 'Pending Cancellation';
    headerSubText = 'Our team are reviewing your cancellation request and will be in touch soon.';

    // Add button for internal users
    btns = user.internalUser ? (
      <div className="flex items-center mr-5">
        <ButtonPrimarySmall 
          text="Process Cancellation" 
          color="dark" 
          onClick={handleOpenCancellationProcess} 
        />
      </div>
    ) : null;
  } else if (status === 'provisional') {
    // PROVISIONAL
    headerBgColor = 'bg-[#D4EBFF]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#3699FF]';
    headerIconBgColor = 'bg-[#D0E3F6]';
    headerText = 'Your Itinerary is in Provisional State';
    headerSubText = `${supplierCounts.provisional} supplier${supplierCounts.provisional !== 1 ? 's' : ''} held on provisional status, all other services will be booked on confirmation. ${
      booking.dateExpiry ? `Your closest provisional expires on ${moment(booking.dateExpiry.toDate()).utc().format('MMMM Do, YYYY [at] HH:mm [UTC]')}` : 'No expiry date available'
    }`;
    btns = (
      <div className="flex items-center mr-5">
        <div className="mr-5">
          <ButtonOutlineSmall
            text="Release Hold"
            color="dark"
            onClick={() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                        <p className="text-sm font-semibold pb-2">Release All Provisional Holds?</p>
                        <p className="text-sm font-normal pb-2">Do you want to release all provisional holds for this booking?</p>
                        <p className="text-sm font-normal pb-2">This action will release all held services.</p>
                        <p className="text-sm font-normal pb-2">The itinerary will then revert to 'Quotation' status.</p>
                        <div className="flex mt-5 justify-end gap-4">
                          <ButtonOutlineSmall onClick={onClose} text="Back" color="dark" />
                          <ButtonPrimarySmall
                            onClick={async () => {
                              onClose(); // Close the confirmation dialog first
                              let statusResponse = true;
                              if (booking.resReqReservationId !== null && booking.resReqReservationId !== undefined && booking.resReqReservationId !== '') {
                                statusResponse = await updateItineraryStatusReleaseHold(booking.resReqReservationId);
                              }

                              if (statusResponse) {
                                await handleCreateLog('statusChange', booking.uid, booking.code, 'provisional > quotation');
                                const response = await releaseHold(booking.uid);

                                if (response.success) {
                                  await modifiedDetails(booking.uid, user, 'quotation');
                                  toast.success('Action Completed. All provisional services have been released');
                                } else {
                                  toast.error(response.message);
                                }
                              }
                            }}
                            text="Release Provisional Holds"
                            color="dark"
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
              });
            }}
          />
        </div>
        <div>
          <ButtonPrimarySmall
            text="Request Confirmation"
            onClick={() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                        <p className="text-sm font-semibold pb-2">Proceed with Itinerary Confirmation</p>
                        <p className="text-sm font-normal pb-2">Ready to secure your safari?</p>

                        <ul className="list-disc pl-5 mb-3 text-sm">
                          <li className="mb-2">All Nomad Tanzania properties will be secured immediately</li>
                          <li className="mb-2">Your expert consultant will review your itinerary and may suggest and final improvements.</li>
                          {!booking.nomadOnlyItinerary && <li className="mb-2">Our team will send confirmation requests to other properties in your booking</li>}
                          <li className="mb-2">You'll receive your invoice and confirmation details once everything is finalised</li>
                        </ul>

                        <p className="text-sm font-normal pb-4">
                          By confirming, you agree to our{' '}
                          <a href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">
                            Booking Terms and Conditions
                          </a>
                        </p>

                        <div className="flex mt-5 justify-end gap-4">
                          <ButtonOutlineSmall onClick={onClose} text="Back" />
                          <ButtonPrimarySmall
                            onClick={async () => {
                              onClose(); // Close the confirmation dialog first
                              let statusResponse = true;
                              if (booking.resReqReservationId !== null && booking.resReqReservationId !== undefined && booking.resReqReservationId !== '') {
                                statusResponse = await updateItineraryStatusConfirmation(booking.resReqReservationId);
                              }

                              if (statusResponse) {
                                const response = await requestConfirmation(booking.uid);
                                if (response.success) {
                                  await handleCreateLog('statusChange', booking.uid, booking.code, 'provisional > pendingConfirmation');
                                  await modifiedDetails(booking.uid, user, 'pendingConfirmation');
                                  toast.success('Action Completed. Confirmation Requests have been sent.');
                                } else {
                                  toast.error(response.message);
                                }
                              } else {
                                toast.error('Error. Confirmation Requests have not been sent, please contact support.');
                              }
                            }}
                            text="Confirm Itinerary"
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
              });
            }}
          />
        </div>
      </div>
    );
  } else if (status === 'pendingProvisional') {
    // PENDING PROVISIONAL

    headerBgColor = 'bg-[#D4EBFF]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#3699FF]';
    headerIconBgColor = 'bg-[#D0E3F6]';
    headerText = 'Pending Provisional: Awaiting supplier responses';
    const totalProvisionalRequired = supplierCounts.provisional + supplierCounts.pendingProvisional;

    if (totalProvisionalRequired === 0) {
      headerSubText = 'No suppliers require provisional status for this booking.';
    } else if (supplierCounts.pendingProvisional === 0) {
      headerSubText = `All ${totalProvisionalRequired} supplier${totalProvisionalRequired !== 1 ? 's have' : ' has'} confirmed provisional status.`;
    } else {
      let pendingText = `Waiting for ${supplierCounts.pendingProvisional} supplier${supplierCounts.pendingProvisional !== 1 ? 's' : ''} to confirm provisional status.`;

      headerSubText = `${pendingText} (${supplierCounts.provisional}/${totalProvisionalRequired} confirmed)`;
    }
    btns = (
      <div className="flex items-center mr-5">
        <div className="">
          <ButtonOutlineSmall
            text="Cancel Pending Requests"
            color="dark"
            onClick={() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                        <p className="text-sm font-semibold pb-2">Cancel Provisional Requests</p>
                        <p className="text-sm font-normal pb-2">This will cancel any pending requests and release all holds.</p>
                        <p className="text-sm font-normal pb-2">The itinerary will then revert to 'Quotation' status.</p>
                        <div className="flex mt-5 justify-end gap-4">
                          <ButtonOutlineSmall onClick={onClose} text="Back" color="dark" />
                          <ButtonPrimarySmall
                            onClick={async () => {
                              onClose(); // Close the confirmation dialog first
                              let statusResponse = true;
                              if (booking.resReqReservationId !== null && booking.resReqReservationId !== undefined && booking.resReqReservationId !== '') {
                                statusResponse = await updateItineraryStatusReleaseHold(booking.resReqReservationId);
                              }

                              if (statusResponse) {
                                const response = await cancelRequestPendingProvisional(booking.uid);
                                await handleCreateLog('statusChange', booking.uid, booking.code, 'pendingProvisional > quotation');
                                if (response.success) {
                                  await modifiedDetails(booking.uid, user, 'quotation');
                                  toast.success('Action Completed. Requests cancelled and provisionals released');
                                } else {
                                  toast.error(response.message);
                                }
                              } else {
                                toast.error('Error releasing holds. Please contact support.');
                              }
                            }}
                            color="dark"
                            text="Cancel Requests"
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
              });
            }}
          />
        </div>
      </div>
    );
  } else if (status === 'pendingConfirmation') {
    // PENDING CONFIRMATION
    headerBgColor = 'bg-[#FCEEC1]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#FFBB38]';
    headerIconBgColor = 'bg-[#F2D77F]';
    headerText = 'Pending Confirmation';
    headerSubText = `${supplierCounts.confirmed} supplier${supplierCounts.confirmed !== 1 ? 's' : ''} confirmed, pending confirmation from ${supplierCounts.pendingConfirmation} more supplier${
      supplierCounts.pendingConfirmation !== 1 ? 's' : ''
    }.`;
    btns = (
      <div className="flex items-center mr-5 gap-4">
        <div className="">
          <ButtonOutlineSmall
            text="Cancel Request"
            color="dark"
            onClick={() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui-overlay" onClick={(e) => e.stopPropagation()}>
                      <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                        <p className="text-sm font-semibold pb-2">Cancel booking and withdraw pending confirmation requests.</p>
                        <p className="text-sm font-normal pb-2">This will cancel all confirmed services and any pending requests.</p>
                        <p className="text-sm font-normal pb-2">Note: Cancellation charges may apply for services already confirmed.</p>
                        <p className="text-sm font-normal pb-2">You will receive a confirmation email once your booking is successfully cancelled.</p>
                        <div className="flex mt-5 justify-end gap-4">
                          <ButtonOutlineSmall onClick={onClose} text="Back" color="dark" />
                          <ButtonPrimarySmall
                            onClick={async () => {
                              onClose(); // Close the confirmation dialog first
                              const response = await cancelRequestPendingConfirmation(booking.uid);
                              await handleCreateLog('statusChange', booking.uid, booking.code, 'pendingConfirmation > quotation');
                              if (response.success) {
                                await modifiedDetails(booking.uid, user, 'quotation');
                                toast.error('Requests cancelled and provisionals released.');
                              } else {
                                toast.error(response.message);
                              }
                            }}
                            text="Cancel Booking and Withdraw Requests"
                            color="dark"
                          />
                        </div>
                      </div>
                    </div>
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
              });
            }}
          />
        </div>
        {user.internalUser && <ButtonPrimarySmall text="Begin Confirmation" onClick={handleOpenConfirmationProcess} color="dark" />}
      </div>
    );
  } else if (status === 'cancelled') {
    // CANCELLED
    headerBgColor = 'bg-red-50';  
    headerTextColor = 'text-gray-900';    
    headerIconColor = 'text-red-400';     
    headerIconBgColor = 'bg-red-100';      
    headerText = 'Your Itinerary Has Been Cancelled';
    headerSubText = 'All services booked for this reservation have been canceled.';

    // *** TO DO - UPDATE PAY CANCELLING BUTTON BELOW THAT SHOWS IF OUTSTANDING IS DUE 
    // cancelledTab = (
    //   <div className="mt-3 px-3 py-2 bg-stone-50 rounded-[15px] justify-center items-center inline-flex mr-5">
    //     <div className="flex items-center text-center text-nomadGray-600 text-base font-normal font-['Inter'] leading-[18px]">
    //       {' '}
    //       <div className={`inline-flex items-center justify-center w-6 h-6 rounded-full ${cancelledTickColor}`}>
    //         <IoMdCheckmark className="text-white" />
    //       </div>
    //       <span className="ml-2 text-nomadGray-600 text-base font-normal font-['Inter'] leading-[18px]">Pay Cancelation Invoice</span>
    //     </div>
    //   </div>
    // );
  } else if (status === 'expired') {
    // EXPIRED
    headerBgColor = 'bg-[#E6E1F0]';
    headerTextColor = 'text-gray-900';
    headerIconColor = 'text-[#6B5A9E]';
    headerIconBgColor = 'bg-[#F2F0F7]';
    headerText = 'Your Itinerary Has Expired.';
    headerSubText = 'You can convert this itinerary back to quotation status to make changes or attempt to reinstate on provisional.';
    btns = (
      <div className="flex items-center gap-4 mr-5">
        {canReinstate() && <ButtonOutlineSmall text="Reinstate Provisional" color="dark" onClick={() => setIsReinstateModalOpen(true)} />}
        <ButtonPrimarySmall text="Convert to Quotation" color="dark" onClick={() => setIsQuotationModalOpen(true)} />
      </div>
    );
  } else {
    headerBgColor = 'bg-[#F0ECE7]';
    headerTextColor = 'text-black';
    headerIconColor = 'text-[#C5BDB4]';
    headerIconBgColor = 'bg-[#F4F3F2]';
    // Text
    headerText = 'Status unknown, please contact support.';
  }

  return (
    <>
      {/* main items */}
      <div key={uuidv4()} style={{ zIndex: 1000, WebkitTransform: 'translateZ(0)' }} className="mb-5 mt-5">
        <div className={status === 'confirmed' ? 'bg-white rounded-[15px] shadow p-3 sm:p-5' : ''}>
          {/* HEADER */}
          <div className={`rounded-[15px] ${headerBgColor}`}>
            <div key={uuidv4()} className="flex flex-col md:flex-row items-start md:items-center justify-between p-3 sm:p-5 space-y-4 md:space-y-0">
              <div className="flex items-start space-x-4 lg:w-[68%]">
                <div className={`flex-shrink-0 w-[46px] h-[46px] ${headerIconBgColor} rounded-lg flex items-center justify-center`}>
                  <IoIosDocument size={30} className={`${headerIconColor}`} />
                </div>
                <div className="flex-grow">
                  <div className={`text-xl font-semibold leading-tight ${headerTextColor}`}>{headerText}</div>
                  <div className="text-gray-600 text-base leading-[18.20px] pt-2">{headerSubText}</div>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-start md:justify-end space-x-0 space-y-2 md:space-x-4 md:space-y-0">
                {cancelledTab}
                {btns}
              </div>
            </div>
          </div>
          {status === 'confirmed' && (
            <div className="bg-white mt-4">
              <div key={uuidv4()} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="flex items-start space-x-3">
                  <div className="flex-shrink-0 w-[46px] h-[46px] flex justify-center items-start">
                    <IoMdCheckmarkCircle size={30} className={`${depositTickColor}`} />
                  </div>
                  <div>
                    <div className="text-neutral-800 text-base font-semibold">{depositTitle}</div>
                    <div className="text-nomadGray-600 text-sm font-normal">{depositText}</div>
                  </div>
                </div>
                <div className="flex items-start space-x-3">
                  <div className="flex-shrink-0 w-[46px] h-[46px] flex justify-center items-start">
                    <IoMdCheckmarkCircle size={30} className={`${balanceTickColor}`} />
                  </div>
                  <div>
                    <div className="text-neutral-800 text-base font-semibold">{balanceTitle}</div>
                    <div className="text-nomadGray-600 text-sm font-normal">{balanceText}</div>
                  </div>
                </div>
                <div className="flex items-start space-x-3">
                  <div className="flex-shrink-0 w-[46px] h-[46px] flex justify-center items-start">
                    <IoMdCheckmarkCircle size={30} className={`${guestsTickColor}`} />
                  </div>
                  <div>
                    <div className="text-neutral-800 text-base font-semibold">{guestsTitle}</div>
                    <div className="text-nomadGray-600 text-sm font-normal">{guestsText}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ***MODALS FOR THE CLICK OF EACH BUTTON OPTION*** */}

      {/*  CONFIRMATION MODAL */}
      <Transition appear show={isConfirmationProcessOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseConfirmationProcess}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {isConfirmationProcessOpen && (
                    <ConfirmationProcess
                      booking={booking}
                      supplierReservations={activeReservations}
                      onSuccess={handleConfirmationSuccess}
                      onError={handleConfirmationError}
                      onClose={handleCloseConfirmationProcess}
                      user={user}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/*  CONVERT TO QUOTATION MODAL */}
      <Transition appear show={isQuotationModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsQuotationModalOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Convert to Quotation Status?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">This will:</p>
                    <ul className="list-disc pl-5 mt-2 text-sm text-gray-500">
                      <li>Reset all supplier reservation stages to 'new'</li>
                      <li>Allow you to make changes to the itinerary</li>
                    </ul>
                  </div>

                  <div className="mt-6 flex justify-end gap-4">
                    <ButtonOutlineSmall onClick={() => setIsQuotationModalOpen(false)} text="Back" color="dark" disabled={isConverting} />
                    <ButtonPrimarySmall
                      onClick={async () => {
                        try {
                          setIsConverting(true);
                          // First update ResRequest
                          if (booking.resReqReservationId) {
                            await updateItineraryStatusReleaseHold(booking.resReqReservationId);
                          }

                          // Then update Firestore
                          const response = await convertToQuotation(booking.uid);
                          if (response.success) {
                            await handleCreateLog('statusChange', booking.uid, booking.code, 'expired > quotation');
                            await modifiedDetails(booking.uid, user, 'quotation');
                            toast.success('Successfully converted to quotation status');
                          } else {
                            toast.error(response.message);
                          }
                          setIsQuotationModalOpen(false);
                        } catch (error) {
                          toast.error('Error updating status. Please try again.');
                          console.error(error);
                        } finally {
                          setIsConverting(false);
                        }
                      }}
                      text="Convert to Quotation"
                      color="dark"
                      isLoading={isConverting}
                      disabled={isConverting}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      
      {/*  REINSTATE PROVISIONAL MODAL */}
      <Transition appear show={isReinstateModalOpen} as={Fragment}>
  <Dialog as="div" className="relative z-50" onClose={() => setIsReinstateModalOpen(false)}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
    Reinstate Provisional Status?
  </Dialog.Title>
  
  {checkProvisionalRequirements().canReinstate ? (
    <>
      <div className="mt-2">
        <p className="text-sm text-gray-500">This will:</p>
        <ul className="list-disc pl-5 mt-2 text-sm text-gray-500">
          <li>Reinstate the itinerary to provisional status</li>
          <li>Maintain existing provisional holds</li>
        </ul>
      </div>

      <div className="mt-6 flex justify-end gap-4">
        <ButtonOutlineSmall
          onClick={() => setIsReinstateModalOpen(false)}
          text="Back"
          color="dark"
          disabled={isConverting}
        />
        <ButtonPrimarySmall
          onClick={async () => {
            try {
              setIsConverting(true);
              const response = await reinstateProvisional(booking.uid);
              if (response.success) {
                await handleCreateLog('statusChange', booking.uid, booking.code, 'expired > provisional');
                await modifiedDetails(booking.uid, user, 'provisional');
                toast.success('Successfully reinstated provisional status');
              } else {
                toast.error(response.message);
              }
              setIsReinstateModalOpen(false);
            } catch (error) {
              toast.error('Error updating status. Please try again.');
              console.error(error);
            } finally {
              setIsConverting(false);
            }
          }}
          text="Reinstate Provisional"
          color="dark"
          isLoading={isConverting}
          disabled={isConverting}
        />
      </div>
    </>
  ) : (
    <>
      <div className="mt-2">
        <div className="mb-4 p-4 bg-amber-50 border border-amber-200 rounded-lg">
          <p className="text-amber-800 font-medium mb-2">Unable to Reinstate Provisional Status</p>
          <p className="text-sm text-gray-600 mb-2">
            The following suppliers require valid provisional holds:
          </p>
          <ul className="list-disc pl-5 text-sm text-gray-600">
            {checkProvisionalRequirements().invalidSuppliers.map((supplier, index) => (
              <li key={index}>
                {supplier.supplierName || 'Unnamed Supplier'}: 
                {!supplier.dateProvisionalExpiry 
                  ? ' No expiry date set'
                  : supplier.dateProvisionalExpiry.toDate() <= new Date()
                    ? ' Provisional has expired'
                    : ' Not in provisional status'
                }
              </li>
            ))}
          </ul>
        </div>
        <p className="text-sm text-gray-500">
          Please ensure all required suppliers have valid provisional holds before reinstating.
        </p>
      </div>

      <div className="mt-6 flex justify-end">
        <ButtonOutlineSmall
          onClick={() => setIsReinstateModalOpen(false)}
          text="Close"
          color="dark"
        />
      </div>
    </>
  )}
</Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

  {/*  CANCELLATION MODAL */}
<Transition appear show={isCancellationProcessOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsCancellationProcessOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <CancellationProcess
                    booking={booking}
                    onSuccess={handleCancellationSuccess}
                    onError={handleCancellationError}
                    onClose={() => setIsCancellationProcessOpen(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewMainHeader;
