import React from 'react';
import { Squares2X2Icon, ListBulletIcon } from '@heroicons/react/24/outline';

function FilterBar({ view, setView, selectedCategory, setSelectedCategory }) {
 // const categories = ['All', 'Kuro', 'Wildlife', 'Camps', 'Activities', 'People'];
 const categories = ['All'];

  return (
    <div className="border-b border-gray-100 px-6 py-3 flex justify-between items-center bg-gray-50">
      <div className="flex space-x-2">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${selectedCategory === category 
                ? 'bg-nomadBlue-600 text-white' 
                : 'text-gray-600 hover:bg-gray-200'
              }`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="flex space-x-2">
        <button
          onClick={() => setView('grid')}
          className={`p-2 rounded-lg transition-colors
            ${view === 'grid' 
              ? 'bg-nomadBlue-600 text-white' 
              : 'text-gray-600 hover:bg-gray-200'
            }`}
        >
          <Squares2X2Icon className="h-5 w-5" />
        </button>
        <button
          onClick={() => setView('list')}
          className={`p-2 rounded-lg transition-colors
            ${view === 'list' 
              ? 'bg-nomadBlue-600 text-white' 
              : 'text-gray-600 hover:bg-gray-200'
            }`}
        >
          <ListBulletIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}

export default FilterBar;