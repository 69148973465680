import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { getAeroCrsCode, getAirportName } from '../functions/transferFunctions';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { getPropObj } from '../functions/generalFunctions';

const formatNumber = (num) => {
  return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default function TransferPricingSummaryModal({ isOpen, closeModal, transferInfo, transferTitle, properties, airports, transferNext }) {
  const pricingDetails = useMemo(() => {
    const details = [];
    const { formValues } = transferInfo;
    console.log('Transfer Info:', JSON.stringify(transferInfo, null, 2));

    if (formValues.includeDepartureTransfer) {
      const departureProperty = properties[getPropObj(transferInfo.propUid, properties)]?.name || 'Departure';
      //const departureAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode));
      const departureAirport = formValues.flightDetails
        ? getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode))
        : getAirportName(airports, getAeroCrsCode(airports, formValues.originAirportCode));
      details.push({
        summary: `Airport Transfer from ${departureProperty} to ${departureAirport}`,
        total: formValues.departureTransferSaleTotal || 0,
        supplierName: formValues.departureTransferSupplierName || 'N/A',
      });
    }

    if (formValues.includeDepartureVip) {
      let departureAirport;
      if (formValues.type === 'flight' || formValues.type === 'bookFlight') {
        departureAirport = formValues.flightDetails
        ? getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode))
        : getAirportName(airports, getAeroCrsCode(airports, formValues.originAirportCode));
      } else {
        departureAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.airportCodeVip));
      }
      if(formValues.type === 'ownArrangements') {
        departureAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.departureAirportCodeVip));
      }
      details.push({
        // summary: `VIP departure from ${departureAirport}`,
        summary: `VIP meet and assist at ${departureAirport}`,
        total: formValues.departureSaleTotalVip || 0,
        supplierName: formValues.departureSupplierNameVip || 'N/A',
      });
    }

    if (formValues.type === 'flight' || formValues.type === 'bookFlight') {
      const fromAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.fromcode));
      const toAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.tocode));
      details.push({
        summary: `Flight from ${fromAirport} to ${toAirport}`,
        total: formValues.flightPricing.saleFare.total || 'N/A',
        supplierName: formValues.airlineName || 'N/A',
      });

      // Add second flight if it exists
      if (formValues.flightDetailsSecond) {
        const secondFromAirport = toAirport;
        const secondToAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetailsSecond.tocode));
        details.push({
          summary: `Flight from ${secondFromAirport} to ${secondToAirport}`,
          total: formValues.flightPricingSecond.saleFare.total || 'N/A',
          supplierName: formValues.airlineNameSecond || 'N/A',
        });
      }
    }

    if (formValues.includeArrivalVip) {
      let arrivalAirport;
      if (formValues.type === 'flight' || formValues.type === 'bookFlight') {
        arrivalAirport = formValues.flightDetails
        ? getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetailsSecond ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode))
        : getAirportName(airports, getAeroCrsCode(airports, formValues.destinationAirportCode));
      } else {
        arrivalAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.airportCodeVip));
      }
      if(formValues.type === 'ownArrangements') {
        arrivalAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.arrivalAirportCodeVip));
      }
      details.push({
        // summary: `VIP arrival at ${arrivalAirport}`,
        summary: `VIP meet and assist at ${arrivalAirport}`,
        total: formValues.arrivalSaleTotalVip || 0,
        supplierName: formValues.arrivalSupplierNameVip || 'N/A',
      });
    }

    if (formValues.includeArrivalTransfer) {
      const arrivalProperty = properties[getPropObj(transferNext.propUid, properties)]?.name || 'Arrival';
      //const arrivalAirport = getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetails.tocode));
      const arrivalAirport = formValues.flightDetails
        ? getAirportName(airports, getAeroCrsCode(airports, formValues.flightDetailsSecond ? formValues.flightDetailsSecond.tocode : formValues.flightDetails.tocode))
        : getAirportName(airports, getAeroCrsCode(airports, formValues.destinationAirportCode));
      details.push({
        summary: `Airport Transfer from ${arrivalAirport} to ${arrivalProperty}`,
        total: formValues.arrivalTransferSaleTotal || 0,
        supplierName: formValues.arrivalTransferSupplierName || 'N/A',
      });
    }



    if (formValues.type === 'safari' || formValues.type === 'town') {
      const departureProperty = properties[getPropObj(transferInfo.propUid, properties)]?.name || 'Departure';
      const arrivalProperty = properties[getPropObj(transferNext.propUid, properties)]?.name || 'Arrival';
      details.push({
        summary: `Transfer from ${departureProperty} to ${arrivalProperty}`,
        total: formValues.saleTotal || 0,
        supplierName: formValues.supplierName || 'N/A',
      });
    }

    if (formValues.type === 'location') {
      let departureProperty = transferInfo.objType === 'arrival' ? transferInfo.formValues.locationName : properties[getPropObj(transferInfo.propUid, properties)]?.name;
      let arrivalProperty = transferInfo.objType === 'arrival' ? properties[getPropObj(transferInfo.propUid, properties)]?.name : transferInfo.formValues.locationName;
      details.push({
        summary: `Transfer from ${departureProperty} to ${arrivalProperty}`,
        total: formValues.saleTotal || 0,
        supplierName: formValues.supplierName || 'N/A',
      });
    }

    if (formValues.packedLunch) {
      const packedLunchProperty = properties[getPropObj(transferInfo.propUid, properties)]?.name || 'Departure property';
      details.push({
        summary: `Packed lunch from ${packedLunchProperty}`,
        total: formValues.packedLunchSaleTotal || 0,
        supplierName: formValues.packedLunchSupplierName || 0,
      });
    }

    return details;
  }, [transferInfo, properties, airports]);

  const totalPrice = useMemo(() => {
    return pricingDetails.reduce((sum, item) => sum + item.total, 0);
  }, [pricingDetails]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="max-h-[80vh] overflow-y-auto">
                  <div className="sticky top-0 bg-white z-10 border-b">
                    <div className="px-6 py-4 flex justify-between items-start">
                      <div>
                        <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                          {transferTitle}
                        </Dialog.Title>
                        <p className="mt-1 text-sm text-gray-500">Pricing Summary</p>
                        {transferInfo?.startDay && <p className="mt-2 text-sm text-gray-600">{format(new Date(transferInfo.startDay), 'EEE, do MMM yyyy')}</p>}
                      </div>
                      <button onClick={closeModal} className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="p-6">
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2">
                              Summary
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                              Supplier
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {pricingDetails.map((item, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 text-sm font-medium text-gray-900 break-words">{item.summary}</td>
                              <td className="px-6 py-4 text-sm text-gray-500">{item.supplierName}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium text-right">${formatNumber(item.total)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="sticky bottom-0 bg-white z-10 p-6 border-t">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-semibold text-gray-900">Total Price:</span>
                    <span className="text-xl font-bold text-nomadBlue-700">${formatNumber(totalPrice)}</span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
