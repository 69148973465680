import '../../../css/instantSearch/index.css';
import '../../../css/manageBookingsPagination.css';
import { Configure, Hits, InstantSearch } from "react-instantsearch-dom";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { BuildingOfficeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import PropertyCard from "../components/PropertyCard";
import Sidebar from "../../../partials/Sidebar";
import algoliasearch from "algoliasearch";
import PropertyLibrarySearchBox from '../components/PropertyLibrarySearchBox';
import ConnectedPagination from '../../../components/CustomPagination';
import InfoBox from "../../../components/InfoBox";

// Algolia details
const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const PropertyHit = ({ hit }) => {
  const linkUrl = `/propertyLibrary/${hit.uid}`;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      className="col-span-1"
    >
      <Link key={hit.id} to={linkUrl}>
        <PropertyCard
          camp={{
            id: hit.uid,
            name: hit.name,
            supplier: hit.supplier.id,
            park: hit.park.name,
            description: hit.caption,
            imageUrl: hit.heroImageUrl,
          }}
        />
      </Link>
    </motion.div>
  );
};

function PropertyLibrary() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      <InstantSearch searchClient={searchClient} indexName="properties">
        <Configure filters="active:true AND tripAdvisorId.active:true" hitsPerPage={9} />
        
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
            >
              <div className="mb-8">
                <h1 className="text-4xl font-roca text-nomadBlue-800 mb-4">
                  Property Library
                </h1>
                <div className="flex items-center space-x-2 text-gray-600">
                  <BuildingOfficeIcon className="h-5 w-5" />
                  <span>Browse our curated collection of luxury properties and camps</span>
                </div>
              </div>

              <div className="mb-6">
                <InfoBox
                  icon={MagnifyingGlassIcon}
                  text="Search through our properties by name, location, or features. Use filters to refine your results."
                  bgColor="bg-white"
                  textColor="text-nomadBlue-700"
                  fontSize="text-md"
                  className="shadow-sm border border-nomadGray-400"
                />
              </div>

              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="border-b border-gray-100">
                  <div className="px-6 py-4">
                    <PropertyLibrarySearchBox />
                  </div>
                </div>

                <div className="p-6">
                  <motion.div 
                    initial="hidden"
                    animate="show"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: {
                          staggerChildren: 0.1
                        }
                      }
                    }}
                    className="hits-wrapper propertyLibrary"
                  >
                    <Hits hitComponent={PropertyHit} />
                  </motion.div>
                  
                  <div className="mt-8 flex justify-center">
                    <ConnectedPagination />
                  </div>
                </div>
              </div>
            </motion.div>
          </main>
        </div>
      </InstantSearch>
    </div>
  );
}

export default PropertyLibrary;