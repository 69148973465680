import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BuildingOfficeIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Sidebar from '../../../../partials/Sidebar';
import PropertyContent from '../components/PropertyContent';
import { fetchPropertyData } from '../../functions/propertyFunctions';
import InfoBox from "../../../../components/InfoBox";

function Property() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uid } = useParams();

  useEffect(() => {
    async function loadPropertyData() {
      try {
        setLoading(true);
        const data = await fetchPropertyData(uid);
        setPropertyData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (uid) {
      loadPropertyData();
    }
  }, [uid]);

  const ErrorState = ({ message }) => (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl mx-auto">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-red-50 border border-red-200 rounded-xl p-8 text-center"
      >
        <BuildingOfficeIcon className="h-12 w-12 text-red-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-red-800 mb-2">Unable to load property</h3>
        <p className="text-red-600">{message}</p>
        <Link 
          to="/propertyLibrary"
          className="inline-flex items-center mt-6 text-red-600 hover:text-red-800"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          Back to Property Library
        </Link>
      </motion.div>
    </div>
  );

  return (
    <div className="flex h-screen overflow-hidden bg-nomadGray-100">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
          >
            <div className="mb-6">
              <Link 
                to="/propertyLibrary"
                className="inline-flex items-center text-nomadBlue-600 hover:text-nomadBlue-800 mb-4"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />
                Back to Property Library
              </Link>
              
              {/* {!loading && !error && propertyData && (
                <InfoBox
                  icon={BuildingOfficeIcon}
                  text={`Viewing details for ${propertyData.camp.name} in ${propertyData.campDataFirestore.park.name}`}
                  bgColor="bg-nomadBlue-50"
                  textColor="text-nomadBlue-700"
                  fontSize="text-md"
                />
              )} */}
            </div>

            {error ? (
              <ErrorState message={error.message} />
            ) : (
              <PropertyContent 
                propertyData={propertyData} 
                isLoading={loading}
              />
            )}
          </motion.div>
        </main>
      </div>
    </div>
  );
}

export default Property;