import 'react-confirm-alert/src/react-confirm-alert.css';

import { addDoc, collection, doc, getDocs, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { calculatePaxStock, getAvailableVehicles, getVehiclesConfig } from './vehicleFunctions';
import { endOfDay, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';
import {
  getPropObj,
  getRoomType,
  getTotalFromActivities,
  getTotalFromBookings,
  getTotalFromBookingsForVehicles,
  getTotalFromCustomItems,
  getTotalFromFinalPageData,
  getTotalFromFinalPageTransferData,
  getTotalFromOtherArrangements,
  getTotalFromTransfers,
} from './generalFunctions';
import { propertyAccom, propertySelected, removeOne } from './availabilityFunctions';
import { resRequestCreate, resRequestDisplay, resRequestRates, resRequestRatesFilter } from './platformFunctions';

import _ from 'lodash';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { countGuides } from './paxFunctions';
import { db } from '../db/firebase.config';
import { loadAgentRates } from './loadDataFunctions';
import moment from 'moment';
import packageJson from '../../package.json';
import semver from 'semver';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { zonedTimeToUtc } from 'date-fns-tz';

function calculateRoomTotals(bookings, index) {
  const selectedBooking = bookings[index];
  let totalAd = 0;
  let totalCh = 0;
  let totalChi = 0;
  let totalInf = 0;

  selectedBooking.rooms.forEach((room) => {
    totalAd += room.ad;
    totalCh += room.ch;
    totalChi += room.chi;
    totalInf += room.inf;
  });

  return {
    totalAd,
    totalCh,
    totalChi,
    totalInf,
  };
}

function longStayCheck(totalNomadTanzaniaDays, checkDay, createVersionCheck, totalSafariNights, createEdit, createdDate) {
  let today;
  if (createEdit === 'edit') {
    today = moment(createdDate);
  } else {
    today = moment();
  }

  console.log('today longStayCheck', today);
  console.log('today longStayCheck moment', moment());
  console.log('createdDate longStayCheck', createdDate);
  console.log('Today (DD-MM-YYYY):', today.format('DD-MM-YYYY'));

  const checkDayMoment = moment(checkDay);
  const isWithin60Days = checkDayMoment.diff(today, 'days') <= 60;

  console.log('isWithin60Days', isWithin60Days);
  console.log('totalNomadTanzaniaDays', totalNomadTanzaniaDays);
  console.log('totalSafariNights', totalSafariNights);
  console.log('createVersionCheck', createVersionCheck);
  console.log('checkDay', checkDay);
  console.log('moment(checkDay)', moment(checkDay));
  console.log('moment("2024-09-01")', moment('2024-09-01'));
  console.log('moment(checkDay).isBefore(moment("2024-09-01"))', moment(checkDay).isBefore(moment('2024-09-01')));
  console.log('semver.gte(createVersionCheck, "2.3.30")', semver.gte(createVersionCheck, '2.3.30'));

  return (
    (semver.gte(createVersionCheck, '2.3.30') && moment(checkDay).isBefore(moment('2024-09-01'))) ||
    totalNomadTanzaniaDays > 4 ||
    (semver.gte(createVersionCheck, '2.4.30') && moment(checkDay).isBefore(moment('2025-04-30')) && totalSafariNights > 4 && totalNomadTanzaniaDays > 2) ||
    (semver.gte(createVersionCheck, '2.4.30') && isWithin60Days)
  );
}

function countSafariParkNights(bookings) {
  return bookings.reduce((totalDays, booking) => {
    if (booking.locationCategory === 'safariPark') {
      return totalDays + booking.days;
    }
    return totalDays;
  }, 0);
}

const saveItineraryWithGuests = async (db, itineraryObject, guests) => {
  // First, create the main itinerary document
  const itineraryDocRef = await addDoc(collection(db, 'itineraries'), itineraryObject);
  const itineraryId = itineraryDocRef.id;

  // Create a subcollection for guests
  const guestsCollectionRef = collection(db, 'itineraries', itineraryId, 'guests');

  // Function to add a guest to the subcollection
  const addGuest = async (guest, type) => {
    const guestData = {
      age: guest.age,
      uuid: guest.uuid,
      type: type,
    };

    // Use the guest's UUID as the document ID, or create a new one if it doesn't exist
    const guestId = guest.uuid || doc(guestsCollectionRef).id;
    await setDoc(doc(guestsCollectionRef, guestId), guestData);

    return guestId;
  };

  // Add adult guests
  for (const adult of guests.adultAges) {
    await addGuest(adult, 'adult');
  }

  // Add child guests
  for (const child of guests.childAges) {
    await addGuest(child, 'child');
  }

  // Update the main itinerary document with the total count of guests
  // await setDoc(
  //   doc(db, 'itineraries', itineraryId),
  //   {
  //     totalAdults: guests.adults,
  //     totalChildren: guests.children,
  //   },
  //   { merge: true },
  // );

  return itineraryId;
};

//Create a booking in resRequest
const createItineraryFunction = async (
  bookings,
  setBookingUid,
  agentRates,
  itineraryName,
  clientName,
  setIsLoading,
  properties,
  userName,
  userUid,
  userResRequestId,
  agentName,
  agentUid,
  agentResRequestId,
  consultantName,
  consultantUid,
  consultantResRequestId,
  agentRateId,
  activitiesDataTemp,
  transfers,
  jsonData,
  finalPageData,
  agentCommission,
  agentObject,
  setCallUpdateDates,
  deeShadow,
  setStep,
  newEnquiry,
  enquiryUid,
  userConsultantUid,
  timestampStarted,
  mojoItinerary,
  user,
  otherArrangementsData,
  quoteMode,
  finalPageTransferData,
  itineraryRenamed,
  roomPax,
  guests,
  customFinalItems,
  packageJsonVersion,
  createVersionCheck,
  isFamTrip,
  originatingAgent,
  advancedMode
) => {
  console.log('finalPageData');
  console.log(JSON.stringify(finalPageData, undefined, 4));
  // return setIsLoading(false);

    // getProperties
    const propNames = bookings ? bookings.map((booking) => booking.propName) : [];

    let propNameDisplay = '';
  
    if (propNames.length === 0) {
      propNameDisplay = '';
    } else if (propNames.length === 1) {
      propNameDisplay = propNames[0];
    } else if (propNames.length === 2) {
      propNameDisplay = propNames.join(', ');
    } else {
      propNameDisplay = propNames.slice(0, -1).join(', ') + ', ' + propNames[propNames.length - 1];
    }

  // Total nomad nights (long stay rate)
  const nomadTanzaniaBookings = bookings.filter((booking) => resRequestCreate(booking.propUid, properties));
  const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
  const totalSafariNights = countSafariParkNights(bookings);

  var accommodation = [];

  console.log('nomadTanzaniaBookings');
  console.log(JSON.stringify(nomadTanzaniaBookings, undefined, 4));

  // RESREQUESTCREATE === TRUE DON'T ADD  NORTHERN GUIDE VEHICLES
  nomadTanzaniaBookings.forEach((booking) => {
    const endDay = new Date(booking.endDay);
    const startDay = new Date(booking.startDay);
    let selectedRate;
    //let amountType;
    //if (totalNomadTanzaniaDays > 4 && moment(endDay).isAfter(moment('2023-05-31'))) {
    const today = moment();
    if (longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, 'create', today)) {
      selectedRate = agentRates.circuit[booking.rateName];
    } else {
      selectedRate = agentRates.standard[booking.rateName];
    }

    // Third party rates
    // Check if supplier is nomad or not

    if (properties[getPropObj(booking.propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
      //selectedRate = "11e7c8fe976efecbb0600cc47a969e7c";
      const rateObj = properties[getPropObj(booking.propUid, properties)].thirdPartyRates.find((rateObj) => rateObj.type === booking.rateName);
      if (rateObj) {
        selectedRate = rateObj.id;
      } else {
        return toast.error('Rate not found');
      }

      //amountType = "";
    }
    console.log(selectedRate);

    for (let x = 0; x < booking.rooms.length; x++) {
      if (!booking.rooms[x].northernGuideVehicleSupplement) {
        const ageCounts = processAgesRoom(booking.rooms[x]);
        let adNew = ageCounts.adNew;
        let chNew = ageCounts.chNew;
        let chiNew = ageCounts.chiNew;
        let infNew = 0;

        accommodation.push([
          booking.rooms[x].selectedRoom,
          booking.startDay,
          booking.endDay,
          [
            ['RS1', '1'],
            ['RS2', `${adNew}`],
            ['RS3', `${chNew}`],
            ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `${chiNew}`],
            ['11ed2692dae5ee328f91ac1f6b1b6a6e', `${infNew}`],
          ],
          '',
          selectedRate,
        ]);
      }
    }
  });

  // TEMP CODE
  console.log('ACCOMMODATION 1');
  console.log(JSON.stringify(accommodation, undefined, 4));

  // RESREQUESTCREATE === FALSE ADD NORTHERN GUIDE VEHICLES
  bookings.forEach((booking) => {
    let selectedRate = '11ed2a8f8ed73de68f91ac1f6b1b6a6e';

    for (let x = 0; x < booking.rooms.length; x++) {
      if (booking.rooms[x].northernGuideVehicleSupplement) {
        if (booking.startDay !== booking.endDay) {
          accommodation.push([
            booking.rooms[x].selectedRoom,
            booking.startDay,
            booking.endDay,
            [
              ['RS1', '1'],
              ['RS2', `0`],
              ['RS3', `0`],
              ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `0`],
              ['11ed2692dae5ee328f91ac1f6b1b6a6e', `0`],
            ],
            '',
            selectedRate,
          ]);
        }
      }
    }
  });

  // TEMP CODE
  //setIsLoading(false);
  console.log('ACCOMMODATION 2');
  console.log(JSON.stringify(accommodation, undefined, 4));

  // NEW CODE
  activitiesDataTemp.forEach((activity, index) => {
    // Check if the second element of the activities array exists
    if (activity.activities[1]) {
      // Find activities with type resRequestAccom
      const resRequestAccomActivities = activity.activities[1].filter((a) => a.type === 'resRequestAccom');

      // Iterate over each resRequestAccom activity
      resRequestAccomActivities.forEach((resRequestAccomActivity) => {
        // Extract the necessary data from the activity object
        const selectedRoom = resRequestAccomActivity.ids.resRequestRoom;
        const startDay = resRequestAccomActivity.selectedActivityDate;

        // Set end date
        const momentDate = moment(startDay, 'YYYY-MM-DD');

        // Add one day to the moment object
        const newMomentDate = momentDate.add(1, 'days');

        // Convert the moment object back to a string in the original format
        const newSelectedActivityDate = newMomentDate.format('YYYY-MM-DD');
        const endDay = newSelectedActivityDate;

        let selectedRate;
        const today = moment();
        if (longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, 'create', today)) {
          selectedRate = agentRates.circuit[bookings[index].rateName];
        } else {
          selectedRate = agentRates.standard[bookings[index].rateName];
        }

        //count total adults and children
        const totals = calculateRoomTotals(bookings, index);
        // Create the accommodation data structure and push it to the accommodation array
        const ageCounts = processAgesRooms(bookings, index);
        let adNew = ageCounts.adNew;
        let chNew = ageCounts.chNew;
        let chiNew = ageCounts.chiNew;
        let infNew = 0;
        const accommodationData = [
          selectedRoom,
          startDay,
          endDay,
          [
            ['RS1', resRequestAccomActivity.activityUnits.toString()],
            ['RS2', adNew.toString()],
            ['RS3', chNew.toString()],
            ['11ed2692eeb9680c8f91ac1f6b1b6a6e', chiNew.toString()],
            ['11ed2692dae5ee328f91ac1f6b1b6a6e', infNew.toString()],
          ],
          '',
          selectedRate,
        ];
        accommodation.push(accommodationData);
      });
    }
  });

  // TEMP CODE
  // setIsLoading(false);
  console.log('ACCOMMODATION 3');
  console.log(JSON.stringify(accommodation, undefined, 4));

  const transfersData = transfers;

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';
  // !console.log("nomadTanzaniaBookings.length" + nomadTanzaniaBookings.length);
  const itineraryData = bookings;

  const dateStart = bookings[0].startDay;
  const img = `${properties[getPropObj(bookings[0].propUid, properties)].heroImageUrl}/320x200`;

  const dateEnd = bookings[bookings.length - 1].endDay;

  let maxTotalGuests = 0;
  let bookingWithHighestTotalPax;

  // Find the booking with the highest total number of guests
  bookings.forEach((booking) => {
    const totalGuestsInBooking = booking.rooms.reduce((sum, room) => {
      return sum + room.ad + room.ch + room.chi + room.inf;
    }, 0);

    if (totalGuestsInBooking > maxTotalGuests) {
      maxTotalGuests = totalGuestsInBooking;
      bookingWithHighestTotalPax = booking;
    }
  });

  // Calculate the total adults and total children for the booking with the highest total number of guests
  const totalAdults = bookingWithHighestTotalPax ? bookingWithHighestTotalPax.rooms.reduce((sum, room) => sum + room.ad, 0) : 0;

  const totalChildren = bookingWithHighestTotalPax ? bookingWithHighestTotalPax.rooms.reduce((sum, room) => sum + room.ch + room.chi + room.inf, 0) : 0;


  // total guides
  const totalGuides = countGuides(guests);

  // ! MAKE totalPrice -> START
  let propertiesTotal;
  // finalPageData total
  if ((!user.internalUser && user.fullBoardOnly) || !user.betaTester || mojoItinerary === true) {
    const totalProperties = bookings.reduce((total, booking) => {
      let price = 0;
      if (booking.rooms.some((room) => room.rate && (room.rate.hasOwnProperty('payable') || room.rate.hasOwnProperty('nett')))) {
        price = booking.rooms
          .filter((room) => room.rate && (room.rate.hasOwnProperty('payable') || room.rate.hasOwnProperty('nett')))
          .reduce((price, room) => {
            return price + (room.rate.payable || room.rate.nett || 0);
          }, 0);
      }

      return total + price;
    }, 0);

    propertiesTotal = totalProperties;
  } else {
    const finalPageDataTotal = getTotalFromFinalPageData(finalPageData);

    propertiesTotal = finalPageDataTotal + getTotalFromBookings(bookings);
  }

  console.log('DEBUG TOTAL COST');
  console.log('propertiesTotal: ' + propertiesTotal);

  // transfers total
  let transfersTotal = 0;
  const transfersDataTotal = transfers.length > 0 && getTotalFromTransfers(transfers);
  console.log('transfersDataTotal: ' + transfersDataTotal);
  const finalPageTransferDataTotal = getTotalFromFinalPageTransferData(finalPageTransferData);
  console.log('transfersDataTotal total + final page transfers: ' + transfersDataTotal + finalPageTransferDataTotal);
  transfersTotal = transfersDataTotal + finalPageTransferDataTotal;

  const customItemsTotal = getTotalFromCustomItems(customFinalItems);

  // transfer vehicles total
  const bookingsForVehicles = getTotalFromBookingsForVehicles(bookings, properties);

  // activities total
  const activitiesDataTotal = getTotalFromActivities(activitiesDataTemp);
  console.log('activitiesDataTotal: ' + activitiesDataTotal);

  console.log('otherArrangementsData ', getTotalFromOtherArrangements(otherArrangementsData));

  const totalPrice = getTotalFromBookings(bookings) +
  getTotalFromActivities(activitiesDataTemp) +
  getTotalFromTransfers(transfers) +
  getTotalFromBookingsForVehicles(bookings, properties) +
  getTotalFromOtherArrangements(otherArrangementsData) +
  getTotalFromCustomItems(customFinalItems) +
  getTotalFromFinalPageData(finalPageData) +
  getTotalFromFinalPageTransferData(finalPageTransferData)

  const totalPriceOriginal = propertiesTotal + activitiesDataTotal + transfersTotal + getTotalFromOtherArrangements(otherArrangementsData) + bookingsForVehicles + customItemsTotal;

  console.log('totalPrice: ' + totalPrice);
  // ! MAKE totalPrice -> END

  // Flatten the activitiesDataTemp array
  const activitiesData = activitiesDataTemp.map((activityData) => {
    const { activities, ...rest } = activityData;
    const modifiedActivities = activities.map((activity) => {
      return { values: activity };
    });
    return { ...rest, activities: modifiedActivities };
  });
  // Store the flattened array as a single string in Firestore
  console.log('Flattened activities');

  console.log(JSON.stringify(activitiesData, undefined, 4));

  // calculate now, and difference between now and timestampStarted
  const now = new Date();
  let duration = moment(now).diff(moment(timestampStarted)); // Get the difference in milliseconds
  let milliseconds = duration;

  // check total length of accommodation rather than nomadTanzaniaBookings so that we add northern guide vehicles

  console.log('BOOKINGS NEW');
  console.log(JSON.stringify(itineraryData, undefined, 4));
  // setIsLoading(false);
  // return;

  if (accommodation.length > 0) {
    //if (nomadTanzaniaBookings.length > 0) {
    //console.log(JSON.stringify(accommodation, undefined, 4));

    // ResRequest API call - data

    // Ensure agentCommission is a string
    if (typeof agentCommission === 'number') {
      agentCommission = agentCommission.toString();
    }

    var data = JSON.stringify({
      method: 'rv_create',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        accommodation,
        '',
        quoteMode ? '0' : '20',
        itineraryName,
        '',
        'No notes',
        '',
        agentResRequestId,
        '',
        '',
        agentCommission,
        '',
        '',
        userResRequestId,
        '',
        agentResRequestId,
      ],
      id: 1,
    });

    // ResRequest API call - config
    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    console.log('data');
    console.log(data);
    console.log(JSON.parse(data, undefined, 4));
    console.log('userResRequestId: ' + userResRequestId);
    // ResRequest API call - send data
    axios(config)
      .then(async function (response) {
        console.log('response.status: ' + response.status);
        console.log(JSON.stringify(response.data, undefined, 4));
        console.log('createItinerary 1: ');

        if (response.status === 200) {
          if (response.data.error) {
            setIsLoading(false);

            if (response.data.error.faultCode === 800 && response.data.error.faultString === 'Not enough stock available.') {
              setCallUpdateDates(true);
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                      <p className="text-sm font-semibold pb-2">No Availability</p>
                      <p className="text-sm pb-2">One or more of the rooms you selected are now fully booked.</p>
                      <p className="text-sm pb-0">Click "Reload Stock" to update the availability and return</p>
                      <p className="text-sm pb-2">to the Properties page.</p>
                      <div className="flex mt-5 justify-end">
                        <div>
                          {/* <button
                                          className="ml-auto mr-5 bg-white font-normal text-base brand-text-color-v2 brand-btn-bg-color-v2-hover hover:text-white py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                                          onClick={() => {
                                            setRefreshBtn(false);
                        
                                            onClose();
                                          }}
                                        >
                                          Cancel
                                        </button> */}
                          <button
                            className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                            onClick={async () => {
                              setStep('bookings');
                              onClose();
                            }}
                          >
                            Reload stock
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                },
              });
            } else {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                      <p className="text-sm font-semibold pb-2">Error saving data to ResRequest</p>
                      <p className="text-sm pb-2">{response.data.error.faultString}</p>
                      <p className="text-sm pb-2"></p>
                      <div className="flex mt-5 justify-end">
                        <div>
                          <button
                            className="ml-auto brand-btn-bg-color-v2 text-white text-base brand-text-color-v2-hover hover:bg-white font-normal py-1 px-4 border brand-border-color-v2 rounded h-10 w-fit"
                            onClick={async () => {
                              //setStep('bookings');
                              onClose();
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                },
              });
              console.log(response.data.error.faultString);
            }

            //alert("Error: " + response.data.error.faultString);
          } else {
            console.log('createItinerary 2: ');
            const itineraryData = bookings;

            // Total nights
            const start = moment(dateStart, 'YY-MM-DD');
            const end = moment(dateEnd, 'YY-MM-DD');
            const totalNights = end.diff(start, 'days');

            // NEW CODE - START
            const itineraryObject = {
              resReqReservationId: response.data.result,
              itineraryData,
              activitiesData,
              itineraryName,
              itineraryRenamed,
              resRequestStatus: quoteMode ? '0' : '20',
              status: quoteMode ? 'draft' : 'draft',
              quoteMode: quoteMode,
              timestampStarted,
              timeToCreateMilliseconds: milliseconds,
              dateCreated: new Date(),
              dateStart: dateStart,
              dateEnd: dateEnd,
              dateStartTimestamp: moment.utc(dateStart).valueOf(),
              dateEndTimestamp: moment.utc(dateEnd).valueOf(),
              totalNights,
              clientName,
              agent: {
                name: agentName,
                uid: agentUid,
                resRequestId: agentResRequestId,
                rateId: agentRateId,
                commission: Number(agentCommission),
                commissionOffline: agentObject.rateBandOffline.commission,
                accountExecutive: agentObject.accountExecutive,
                seniorConsultant: agentObject.seniorConsultant,
                paymentTerms: agentObject.paymentTerms,
                accessLevel: agentObject.accessLevel,
                ...(agentObject.safariPortalCode && { safariPortalCode: agentObject.safariPortalCode }),
              },
              consultant: {
                name: consultantName,
                uid: consultantUid,
                resRequestId: consultantResRequestId,
              },
              user: {
                name: userName,
                uid: userUid,
                resRequestId: userResRequestId,
                consultantUid: userConsultantUid,
                internalUser: user.internalUser,
              },
              //propertyUids: propertyUids,
              img: img,
              totalAdults,
              totalChildren,
              totalPrice,
              transfersData,
              jsonData,
              calculatedPricing: finalPageData,
              transfersPricing: finalPageTransferData,
              otherArrangementsData,
              version: 1,
              newEnquiry,
              ...(enquiryUid && { enquiryUid: enquiryUid }),
              roomPax,
              guests: true,
              userVersion: 1,
              customFinalItems,
              createVersion: packageJsonVersion,
              isFamTrip,
              ...(originatingAgent !== '' && { originatingAgent }),
              hidden: user.internalUser ? true : false,
              totalGuides,
              advancedMode,
              hornbillDiscountAvailable: user.internalUser ? false : true,
              properties: propNameDisplay,
            };

            const setUndefinedToNull = (obj, path = '') => {
              console.log('Entered setUndefinedToNull with obj:', obj, 'and path:', path);
              for (const [key, value] of Object.entries(obj)) {
                const newPath = path ? `${path}.${key}` : key;

                if (value === undefined) {
                  console.error(`Undefined value found at path: ${newPath}. Setting to null.`);
                  obj[key] = null; // Use null instead of "null", unless you intend to make it a string
                } else if (typeof value === 'object' && value !== null) {
                  setUndefinedToNull(value, newPath);
                }
              }
            };

            const processEntireObject = (itineraryObjectData) => {
              console.log('Entered processEntireObject with itineraryObjectData:', itineraryObjectData);
              console.log('all addDoc json START');
              console.log(JSON.stringify(itineraryObjectData, undefined, 4));
              setUndefinedToNull(itineraryObjectData);
              console.log('Processed object to replace undefined with null:');
              console.log(JSON.stringify(itineraryObjectData, undefined, 4));
              console.log('all addDoc json END');
            };

            // Run the function to process the entire itineraryObject object
            processEntireObject(itineraryObject);

            // NEW CODE - END

            try {
              // New save with guests code
              //const docRef = await addDoc(collection(db, 'itineraries'), itineraryObject);

              const itineraryId = await saveItineraryWithGuests(db, itineraryObject, guests);
              const itineraryRef = doc(db, 'itineraries', itineraryId);

              console.log(`Itinerary saved with ID: ${itineraryId}`);

              //const addUid = doc(db, 'itineraries', docRef.id);
              //const addUid = doc(db, 'itineraries', itineraryId);
              console.log('createItinerary 3: ');
              // Set the "UID" field of the doc
              await updateDoc(itineraryRef, {
                //uid: docRef.id,
                uid: itineraryId,
              });
             

              // Create extras - start
              const promises = [];
              // ACTIVITY EXTRAS
              for (let index = 0; index < activitiesDataTemp.length; index++) {
                const property = activitiesDataTemp[index];
                property.activities.forEach((activityArr) => {
                  activityArr.forEach(async (activity) => {
                    if (activity.type === 'resRequestExtra') {
                      const resReqReservationId = response.data.result;
                      let extraId;
                      if (bookings[index].rateName.toLowerCase().includes('fullboard') || bookings[index].rateName.toLowerCase().includes('fullboardexclusive')) {
                        extraId = activity.ids.resRequestExtraFullBoard ? activity.ids.resRequestExtraFullBoard : activity.ids.resRequestExtra;
                      } else {
                        extraId = activity.ids.resRequestExtra;
                      }
                      const date = activity.selectedActivityDate;
                      const totals = calculateRoomTotals(bookings, index);
                      const totalChildren = totals.totalCh + totals.totalChi + totals.totalInf;
                      const propId = properties[getPropObj(property.propUid, properties)].platformResRequest.id;
                      console.log('*** totalChildren: ' + totalChildren);
                      promises.push(addExtra(resReqReservationId, extraId, date, totals.totalAd, totalChildren, activity.activityUnits, propId, date, ''));
                      console.log('propId: ' + propId);
                      console.log(`Property ${index} has an activity of type resRequestExtra`);
                    }
                  });
                });
              }
              console.log('EXTRAS 1');
              console.log(JSON.stringify(promises, undefined, 4));

              // NORTHERN GUIDE VEHICLE EXTRAS
              bookings.forEach((booking) => {
                const note = 'Nomad Northern Guide vehicle while at ' + booking.propName;

                for (let x = 0; x < booking.rooms.length; x++) {
                  if (booking.rooms[x].northernGuideVehicleSupplement) {
                    const resReqReservationId = response.data.result;
                    const start = moment(booking.startDay);
                    const end = moment(booking.endDay);
                    const totalDays = end.diff(start, 'days') + 1; // +1 to make it inclusive
                    promises.push(addExtra(resReqReservationId, booking.rooms[x].northernGuideVehicleResRequestId, booking.startDay, 0, 0, totalDays, '', booking.endDay, note));
                  }
                }
              });

              console.log('EXTRAS 2');
              console.log(JSON.stringify(promises, undefined, 4));

              Promise.all(promises).then(() => {
                //setBookingUid(docRef.id);
                setBookingUid(itineraryId);
              });

              console.log('createItinerary 4: ');
            } catch (error) {
              console.log(error);
            }
          }

          return response.data.result;
        }
      })
      .catch(function (error) {
        toast.error(error);
        setIsLoading(false);
        // !console.log(error);
      });
  } else {
    // No Nomad Tanzania bookings
    //console.log("transferData");
    //console.log(JSON.stringify(transfersData, undefined, 4));

    // Run the function to process the entire intineraryObject object

    // NEW CODE - START
    // Total nights
    const start = moment(dateStart, 'YYYY-MM-DD');
    const end = moment(dateEnd, 'YYYY-MM-DD');
    const totalNights = end.diff(start, 'days');

    const itineraryObject2 = {
      resReqReservationId: null,
      itineraryData,
      activitiesData,
      itineraryName,
      itineraryRenamed,
      resRequestStatus: '0',
      status: quoteMode ? 'draft' : 'draft',
      quoteMode: quoteMode,
      timestampStarted,
      timeToCreateMilliseconds: milliseconds,
      dateCreated: now,
      dateStart: dateStart,
      dateEnd: dateEnd,
      dateStartTimestamp: moment.utc(dateStart).valueOf(),
      dateEndTimestamp: moment.utc(dateEnd).valueOf(),
      totalNights,
      clientName,
      agent: {
        name: agentName,
        uid: agentUid,
        resRequestId: agentResRequestId,
        rateId: agentRateId,
        commission: Number(agentCommission),
        commissionOffline: agentObject.rateBandOffline.commission,
        accountExecutive: agentObject.accountExecutive,
        seniorConsultant: agentObject.seniorConsultant,
        paymentTerms: agentObject.paymentTerms,
        accessLevel: agentObject.accessLevel,
        ...(agentObject.safariPortalCode && { safariPortalCode: agentObject.safariPortalCode }),
      },
      consultant: {
        name: consultantName,
        uid: consultantUid,
        resRequestId: consultantResRequestId,
      },
      user: {
        name: userName,
        uid: userUid,
        resRequestId: userResRequestId,
        consultantUid: userConsultantUid,
        internalUser: user.internalUser,
      },
      //propertyUids: propertyUids,
      img: img,
      totalAdults,
      totalChildren,
      totalPrice,
      transfersData,
      jsonData,
      calculatedPricing: finalPageData,
      transfersPricing: finalPageTransferData,
      otherArrangementsData,
      version: 1,
      newEnquiry,
      ...(enquiryUid && { enquiryUid: enquiryUid }),
      roomPax,
      guests: true,
      userVersion: 1,
      customFinalItems,
      createVersion: packageJsonVersion,
      isFamTrip,
      ...(originatingAgent !== '' && { originatingAgent }),
      hidden: user.internalUser ? true : false,
      totalGuides,
      advancedMode,
      hornbillDiscountAvailable: user.internalUser ? false : true,
      properties: propNameDisplay,
    };

    const setUndefinedToNull = (obj, path = '') => {
      console.log('Entered setUndefinedToNull with obj:', obj, 'and path:', path);
      for (const [key, value] of Object.entries(obj)) {
        const newPath = path ? `${path}.${key}` : key;

        if (value === undefined) {
          console.error(`Undefined value found at path: ${newPath}. Setting to null.`);
          obj[key] = null; // Use null instead of "null", unless you intend to make it a string
        } else if (typeof value === 'object' && value !== null) {
          setUndefinedToNull(value, newPath);
        }
      }
    };

    const processEntireObject = (itineraryObjectData2) => {
      console.log('Entered processEntireObject with itineraryObjectData:', itineraryObjectData2);
      console.log('all addDoc json START');
      console.log(JSON.stringify(itineraryObjectData2, undefined, 4));
      setUndefinedToNull(itineraryObjectData2);
      console.log('Processed object to replace undefined with null:');
      console.log(JSON.stringify(itineraryObjectData2, undefined, 4));
      console.log('all addDoc json END');
    };

    processEntireObject(itineraryObject2);

    // NEW CODE - END
    const itineraryId = await saveItineraryWithGuests(db, itineraryObject2, guests);
    console.log(`Itinerary saved with ID: ${itineraryId}`);
    const itineraryRef = doc(db, 'itineraries', itineraryId);

    // const docRef = await addDoc(collection(db, 'itineraries'), itineraryObject2);
    // const addUid = doc(db, 'itineraries', docRef.id);

    // Set the "UID" field of the doc
    // await updateDoc(addUid, {
    //   uid: docRef.id,
    // });
    await updateDoc(itineraryRef, {
      uid: itineraryId,
    });
    setBookingUid(itineraryId);
    //setBookingUid(docRef.id);
    //bookingId = docRef.id;
  }
};

const addExtra = async (resReqReservationId, extraId, date, adults, children, activityUnits, propId, dateEnd, note) => {
  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_create_extra',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      extraId,
      '',
      date,
      propId,
      '',
      activityUnits.toString(),
      '',
      '',
      '',
      '',
      note,
      '',
      adults.toString(),
      children.toString(),
      dateEnd,
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log('response.status: ' + response.status);
    console.log(JSON.stringify(response.data, undefined, 4));

    if (response.status === 200) {
      if (response.data.error && response.data.result === null) {
        return Promise.reject(response.data.error.faultString);
      } else {
        console.log('extra added');
        return Promise.resolve(true);
      }

      //return Promise.resolve(response.data.result);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateItineraryStatusExpiry = async (resReqReservationId, code, text, uid, setShow, count, userUid, dateExpiry, reload, itineraryCode, provisionalExtended) => {
  console.log('dateExpiry');
  if (dateExpiry === null) {
    dateExpiry = new Date();
  }
  console.log(dateExpiry);
  console.log(moment(dateExpiry).format('YYYY-MM-DD'));
  // Set date to 12:00 midday UTC
  let newExpiryDate = moment.utc(dateExpiry).startOf('day').add(12, 'hours').toDate();

  console.log('newExpiryDate');
  console.log(newExpiryDate);

  if (resReqReservationId !== null && resReqReservationId !== undefined) {
    //if (resReqReservationId !== null) {
    const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
    const url = '/api/resreq';

    var data = JSON.stringify({
      method: 'rv_update_status',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
        code,
        code === '20' ? moment(dateExpiry).format('YYYY-MM-DD') : '',
        '',
        '',
      ],
      id: 1,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log('response.status: ' + response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error && response.data.result === null) {
            // setIsLoading(false);
            toast.error(response.data.error.faultString);
          } else {
            const addUid = doc(db, 'itineraries', uid);
            if (code === '30') {
              // Confirmed status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                //dateModified: new Date(),
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '90') {
              // Cancelled status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '20') {
              // Provisional status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
                dateExpiry: newExpiryDate,
                ...(provisionalExtended && { provisionalExtended: true }),
              });
            } else if (code === '0') {
              // Quotation status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
              });
            }

            if (setShow !== null) setShow(false);
            //setShow(false);
            //setIsLoading(false);
            toast.success('Booking updated');

            // setTimeout(() => {
            //   if (reload) {
            //     window.location.reload();
            //   }
            // }, 2000);

            return true;
          }

          return response.data.result;
        }
      })
      .catch(function (error) {
        // setIsLoading(false);
        toast.error(error);
        // !console.log(error);
      });
  } else {
    const addUid = doc(db, 'itineraries', uid);
    if (code === '30') {
      // Confirmed status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        //dateModified: new Date(),
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
      });
    } else if (code === '90') {
      // Cancelled status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
      });
    } else if (code === '20') {
      // Provisional status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
        dateExpiry: newExpiryDate,
        ...(provisionalExtended && { provisionalExtended: true }),
      });
    } else if (code === '0') {
      // Quotation status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
      });
    }
    setShow(false);
    // setIsLoading(false);
    toast.success('Booking updated');
    setTimeout(() => {
      if (reload) {
        window.location.reload();
      }
    }, 1500);
    return true;
  }
};

const updateItineraryStatus = async (resReqReservationId, code, text, uid, setShow, setIsLoading, count, userUid, dateExpiry, reload, itineraryCode, provisionalExtended) => {
  console.log('dateExpiry');
  if (dateExpiry === null) {
    dateExpiry = new Date().toISOString().split('T')[0];
  }
  console.log(dateExpiry);
  // Parse the date, ensuring it's treated as UTC
  let parsedDate = parseISO(dateExpiry);

  // Set to end of day in UTC
  let newExpiryDate = zonedTimeToUtc(endOfDay(parsedDate), 'UTC');

  console.log('newExpiryDate:', newExpiryDate.toISOString());

  if (resReqReservationId !== null && resReqReservationId !== undefined) {
    //if (resReqReservationId !== null) {
    const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
    const url = '/api/resreq';

    var data = JSON.stringify({
      method: 'rv_update_status',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
        code,
        code === '20' ? moment(dateExpiry).format('YYYY-MM-DD') : '',
        '',
        '',
      ],
      id: 1,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log('response.status: ' + response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error && response.data.result === null) {
            setIsLoading(false);
            toast.error(response.data.error.faultString);
          } else {
            const addUid = doc(db, 'itineraries', uid);
            if (code === '30') {
              // Confirmed status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                //dateModified: new Date(),
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '90') {
              // Cancelled status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '20') {
              // Provisional status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
                dateExpiry: newExpiryDate,
                dateExpiryNomad: newExpiryDate,
                ...(provisionalExtended && { provisionalExtended: true }),
              });
            } else if (code === '0') {
              // Quotation status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
              });
            }

            if (setShow !== null) setShow(false);
            //setShow(false);
            setIsLoading(false);
            toast.success('Booking updated');

            // setTimeout(() => {
            //   if (reload) {
            //     window.location.reload();
            //   }
            // }, 2000);

            return true;
          }

          return response.data.result;
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error(error);
        // !console.log(error);
      });
  } else {
    const addUid = doc(db, 'itineraries', uid);
    // await updateDoc(addUid, {
    //   resRequestStatus: 0,
    //   status: text,
    //   dateModified: new Date(),
    //   modifiedBy: userUid,
    // });
    if (code === '30') {
      // Confirmed status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        //dateModified: new Date(),
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
      });
    } else if (code === '90') {
      // Cancelled status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
      });
    } else if (code === '20') {
      // Provisional status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
        dateExpiry: newExpiryDate,
        dateExpiryNomad: newExpiryDate,
        ...(provisionalExtended && { provisionalExtended: true }),
      });
    } else if (code === '0') {
      // Quotation status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
      });
    }
    setShow(false);
    setIsLoading(false);
    toast.success('Booking updated');
    setTimeout(() => {
      if (reload) {
        window.location.reload();
      }
    }, 1500);
    return true;
  }
};

// code '20', pending
//await updateBooking('20', 'pendingProvisional');
const updateItineraryStatusEditItinerary = async (resReqReservationId, code, status, uid,  userUid, dateExpiry, provisionalExtended) => {
  console.log('dateExpiry');
  if (dateExpiry === null) {
    dateExpiry = new Date().toISOString().split('T')[0];
  }
  console.log(dateExpiry);
  // Parse the date, ensuring it's treated as UTC
  let parsedDate = parseISO(dateExpiry);

  // Set to end of day in UTC
  let newExpiryDate = zonedTimeToUtc(endOfDay(parsedDate), 'UTC');

  console.log('newExpiryDate:', newExpiryDate.toISOString());

  if (resReqReservationId !== null && resReqReservationId !== undefined) {
    //if (resReqReservationId !== null) {
    const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
    const url = '/api/resreq';

    var data = JSON.stringify({
      method: 'rv_update_status',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
        code,
        code === '20' ? moment(dateExpiry).format('YYYY-MM-DD') : '',
        '',
        '',
      ],
      id: 1,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log('response.status: ' + response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error && response.data.result === null) {
            toast.error(response.data.error.faultString);
          } else {
            const addUid = doc(db, 'itineraries', uid);
            if (code === '30') {
              // Confirmed status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: status,
                //dateModified: new Date(),
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '90') {
              // Cancelled status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: status,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '20') {
              // Provisional status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: status,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
                dateExpiry: newExpiryDate,
                dateExpiryNomad: newExpiryDate,
                ...(provisionalExtended && { provisionalExtended: true }),
              });
            } else if (code === '0') {
              // Quotation status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: status,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
              });
            }

            return true;
          }

          return response.data.result;
        }
      })
      .catch(function (error) {
        toast.error(error);
        // !console.log(error);
      });
  } 

};

const updateItineraryStatusMojo = async (resReqReservationId, code, text, uid, setShow, setIsLoading, count, userUid, dateExpiry, reload, itineraryCode) => {
  console.log('dateExpiry');
  console.log(moment(dateExpiry).format('YYYY-MM-DD'));
  // Set date to 12:00 midday UTC
  let newExpiryDate = moment.utc(dateExpiry).startOf('day').add(12, 'hours').toDate();

  if (resReqReservationId !== null) {
    const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
    const url = '/api/resreq';

    var data = JSON.stringify({
      method: 'rv_update_status',
      params: [
        {
          bridge_username: '',
          bridge_password: '',
          link_id: link_id,
        },
        resReqReservationId,
        code,
        code === '20' ? moment(dateExpiry).format('YYYY-MM-DD') : '',
        '',
        '',
      ],
      id: 1,
    });

    var config = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        console.log('response.status: ' + response.status);
        console.log(JSON.stringify(response.data, undefined, 4));

        if (response.status === 200) {
          if (response.data.error && response.data.result === null) {
            setIsLoading(false);
            toast.error(response.data.error.faultString);
          } else {
            const addUid = doc(db, 'itineraries', uid);
            if (code === '30') {
              // Confirmed status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                //dateModified: new Date(),
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
                mojoItineraryCode: itineraryCode,
                mojoItinerary: true,
              });
            } else if (code === '90') {
              // Cancelled status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
              });
            } else if (code === '20') {
              // Provisional status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
                dateExpiry: newExpiryDate,
              });
            } else if (code === '0') {
              // Quotation status
              await updateDoc(addUid, {
                resRequestStatus: code,
                status: text,
                dateModified: moment.utc().subtract(0, 'hours').toDate(),
                modifiedBy: userUid,
                dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
              });
            }

            if (setShow !== null) setShow(false);
            //setShow(false);
            setIsLoading(false);
            toast.success('Booking updated');

            // setTimeout(() => {
            //   if (reload) {
            //     window.location.reload();
            //   }
            // }, 2000);

            return true;
          }

          return response.data.result;
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toast.error(error);
        // !console.log(error);
      });
  } else {
    const addUid = doc(db, 'itineraries', uid);
    // await updateDoc(addUid, {
    //   resRequestStatus: 0,
    //   status: text,
    //   dateModified: new Date(),
    //   modifiedBy: userUid,
    //   mojoItinerary: true,
    // });
    if (code === '30') {
      // Confirmed status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        //dateModified: new Date(),
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateConfirmed: moment.utc().subtract(0, 'hours').toDate(),
        mojoItineraryCode: itineraryCode,
        mojoItinerary: true,
      });
    } else if (code === '90') {
      // Cancelled status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateCancelled: moment.utc().subtract(0, 'hours').toDate(),
      });
    } else if (code === '20') {
      // Provisional status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateProvisional: moment.utc().subtract(0, 'hours').toDate(),
        dateExpiry: newExpiryDate,
      });
    } else if (code === '0') {
      // Quotation status
      await updateDoc(addUid, {
        resRequestStatus: 0,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
      });
    }
    setShow(false);
    setIsLoading(false);
    toast.success('Booking updated');
    setTimeout(() => {
      if (reload) {
        window.location.reload();
      }
    }, 1500);
    return true;
  }
};

//update draft to quotation

const updateItineraryStatusQuotation = async (resReqReservationId, code, text, uid, setShow, setIsLoading, userUid, reload) => {
  if (resReqReservationId !== null) {
    const addUid = doc(db, 'itineraries', uid);
    if (code === '0') {
      // Quotation status
      await updateDoc(addUid, {
        resRequestStatus: code,
        status: text,
        dateModified: moment.utc().subtract(0, 'hours').toDate(),
        modifiedBy: userUid,
        dateQuotation: moment.utc().subtract(0, 'hours').toDate(),
      });
    }

    if (setShow !== null) setShow(false);
    setIsLoading(false);
    toast.success('Booking updated');

    return true;
  } else {
    const addUid = doc(db, 'itineraries', uid);
    await updateDoc(addUid, {
      resRequestStatus: 0,
      status: text,
      dateModified: new Date(),
      modifiedBy: userUid,
    });
    setShow(false);
    setIsLoading(false);
    toast.success('Booking updated');
    setTimeout(() => {
      if (reload) {
        window.location.reload();
      }
    }, 1500);
    return true;
  }
};

const updateItineraryStatusEdit = async (resReqReservationId, code, text, uid, userUid) => {
  console.log('dateExpiry');

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_update_status',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      code,
      '',
      '',
      '',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(async function (response) {
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));

      if (response.status === 200) {
        if (response.data.error && response.data.result === null) {
          toast.error(response.data.error.faultString);
        } else {
          const addUid = doc(db, 'itineraries', uid);
          if (code === '20') {
            // Provisional status
            await updateDoc(addUid, {
              resRequestStatus: 20,
              status: text,
              modifiedBy: userUid,
            });
          } else if (code === '0') {
            // Quotation status
            await updateDoc(addUid, {
              resRequestStatus: 0,
              status: text,
              modifiedBy: userUid,
            });
          }
          console.log('Edit booking status updated');
          return true;
        }
        return response.data.result;
      }
    })
    .catch(function (error) {
      toast.error(error);
    });
};

const updateItineraryStatusDelete = async (resReqReservationId, uid) => {
  console.log('dateExpiry');

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_update_status',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      '90',
      '',
      'deleted',
      '',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(async function (response) {
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));

      if (response.status === 200) {
        if (response.data.error && response.data.result === null) {
          toast.error(response.data.error.faultString);
          //return false;
        } else {
          const addUid = doc(db, 'itineraries', uid);
          await updateDoc(addUid, {
            resReqReservationId: null,
          });
          console.log('Delete booking status updated');
          return true;
        }
        return response.data.result;
      }
    })
    .catch(function (error) {
      toast.error(error);
      return false;
    });
};

const updateItineraryStatusConfirmation = async (resReqReservationId) => {
  console.log('updateItineraryStatusConfirmation');

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_update_status',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      '30',
      '',
      '',
      '',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(async function (response) {
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));
      return true;
    })
    .catch(function (error) {
      toast.error(error);
      return false;
    });
};

// Set's the itinerary status in ResRequest to quotation
const updateItineraryStatusReleaseHold = async (resReqReservationId) => {
  console.log('updateItineraryStatusConfirmation');

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_update_status',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      '0',
      '',
      '',
      '',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(async function (response) {
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));
      return true;
    })
    .catch(function (error) {
      toast.error(error);
      return false;
    });
};

const updateItineraryStatusCancel = async (resReqReservationId) => {
  console.log('updateItineraryStatusCancel');

  const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
  const url = '/api/resreq';

  var data = JSON.stringify({
    method: 'rv_update_status',
    params: [
      {
        bridge_username: '',
        bridge_password: '',
        link_id: link_id,
      },
      resReqReservationId,
      '90',
      '',
      '',
      '',
    ],
    id: 1,
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(async function (response) {
      console.log('response.status: ' + response.status);
      console.log(JSON.stringify(response.data, undefined, 4));
      return true;
    })
    .catch(function (error) {
      toast.error(error);
      return false;
    });
};

// ************************************* // Get properties object number from rooms array

function getRoomsNum(id, rooms) {
  const requiredIndex = rooms.findIndex((el) => {
    return el.propUid === String(id);
  });
  return requiredIndex === -1 ? false : requiredIndex;
}

//Get the correct rate
// function checkRate(rate, properties, propIndex) {
//   console.log('checkRate rate 1: ' + rate);
//   console.log('checkRate propIndex 1: ' + propIndex);
//   // Assuming thirdPartyRates is an array within properties[propIndex]
//   for (let i = 0; i < properties[propIndex].thirdPartyRates.length; i++) {
//     //console.log("NEW RATE CHECK 1: " + rate);
//     if (rate === 'fullBoard' && properties[propIndex].thirdPartyRates[i].type === 'fullBoard') {
//       return 'fullBoard';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'fullBoardNomad') {
//       return 'fullBoardNomad';
//     } else if (rate === 'fullBoard' && properties[propIndex].thirdPartyRates[i].type === 'fullBoardExclusive') {
//       return 'fullBoardExclusive';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'gamePackage' && properties[propIndex].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
//       return 'gamePackage';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'fullBoard' && properties[propIndex].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
//       return 'fullBoard';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'gamePackage') {
//       return 'gamePackage';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'gamePackageExclusive') {
//       return 'gamePackageExclusive';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'gamePackageShared') {
//       return 'gamePackageShared';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'gamePackagePrivate') {
//       return 'gamePackagePrivate';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'allInclusive') {
//       return 'allInclusive';
//     } else if (rate === 'gamePackage' && properties[propIndex].thirdPartyRates[i].type === 'bedBreakfast') {
//       return 'bedBreakfast';
//     }
//   }

//   console.log('checkRate rate 2: ' + rate);
//   return 'select';
// }

// function checkRate(rate, properties, propIndex) {
//   // Early return if no rates available
//   if (!properties[propIndex]?.thirdPartyRates?.length) {
//     return 'select';
//   }

//   const isNomadSupplier = properties[propIndex].supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  
//   // Define rate mappings with priorities
//   const rateMap = {
//     fullBoard: [
//       { type: 'fullBoard', condition: true },
//       { type: 'fullBoardExclusive', condition: true }
//     ],
//     gamePackage: [
//       { type: 'fullBoardNomad', condition: true },
//       { type: 'gamePackage', condition: isNomadSupplier },
//       { type: 'fullBoard', condition: !isNomadSupplier },
//       { type: 'gamePackageExclusive', condition: true },
//       { type: 'gamePackageShared', condition: true },
//       { type: 'gamePackagePrivate', condition: true },
//       { type: 'allInclusive', condition: true },
//       { type: 'bedBreakfast', condition: true },
//       { type: 'halfBoard', condition: true }
//     ]
//   };

//   // Get available rate types from property
//   const availableRates = properties[propIndex].thirdPartyRates.map(r => r.type);

//   // Find first matching rate based on priority
//   const matchingRate = rateMap[rate]?.find(mapping => 
//     mapping.condition && availableRates.includes(mapping.type)
//   );

//   return matchingRate?.type || 'select';
// }

function checkRate(rate, properties, propIndex) {
  // Early return if no rates available
  if (!properties[propIndex]?.thirdPartyRates?.length) {
    return 'select';
  }

  const supplierId = properties[propIndex].supplier.id;
  const NOMAD_SUPPLIER_ID = 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  
  // Get available rate types from property
  const availableRates = properties[propIndex].thirdPartyRates.map(r => r.type);

  // Check if a specific rate type is available
  const hasRate = (rateType) => availableRates.includes(rateType);

  // Define rate selection logic based on rate type
  if (rate === 'fullBoard') {
    // Try fullBoard first, then fallback to fullBoardExclusive
    if (hasRate('fullBoard')) return 'fullBoard';
    if (hasRate('fullBoardExclusive')) return 'fullBoardExclusive';
  }
  
  if (rate === 'gamePackage') {
    // Check rates in priority order
    if (hasRate('fullBoardNomad')) return 'fullBoardNomad';
    
    // Special case for Nomad supplier
    if (supplierId === NOMAD_SUPPLIER_ID) {
      if (hasRate('gamePackage')) return 'gamePackage';
    } else {
      if (hasRate('fullBoard')) return 'fullBoard';
    }
    
    // Check remaining rate types in priority order
    if (hasRate('gamePackage')) return 'gamePackage';
    if (hasRate('gamePackageExclusive')) return 'gamePackageExclusive';
    if (hasRate('gamePackageShared')) return 'gamePackageShared';
    if (hasRate('gamePackagePrivate')) return 'gamePackagePrivate';
    if (hasRate('allInclusive')) return 'allInclusive';
    if (hasRate('bedBreakfast')) return 'bedBreakfast';
    if (hasRate('halfBoard')) return 'halfBoard';
  }

  return 'select';
}

const rateLabelMap = {
  fullBoard: 'Full Board',
  gamePackageExclusive: 'Game Package Exclusive',
  gamePackage: 'Game Package',
  fullBoardExclusive: 'Full Board Exclusive',
  gamePackagePrivate: 'Game Package - Private',
  nomadGamePackage: 'Nomad Game Package',
  gamePackageShared: 'Game Package - Shared',
  fullBoardNomad: 'FB NT',
  allInclusive: 'All Inclusive',
  halfBoard: 'Half Board',
  bedBreakfast: 'Bed & Breakfast',
};

const rateLabelInfoMap = {
  // fullBoard: 'Full Board info',
  // gamePackageExclusive: 'Game Package Exclusive info',
  // gamePackage: 'Game Package info',
  // fullBoardExclusive: 'Full Board Exclusive info',
  // gamePackagePrivate: 'Game Package - Private info',
  // nomadGamePackage: 'Nomad Game Package info',
  // gamePackageShared: 'Game Package - Shared info',
  // fullBoardNomad: 'FB NT info',
  // allInclusive: 'All Inclusive info',
  // halfBoard: 'Half Board info',
  // bedBreakfast: 'Bed & Breakfast info',
};

// ************************************* // Add to booking array
function addBooking(
  propIndex,
  propUid,
  bookings,
  setBookings,
  date,
  rooms,
  packageTab,
  properties,
  northernGuideVehicleSupplement,
  northernGuideVehiclePrice,
  northernGuideVehicleResRequestId,
  guests,
  user,
  days,
  switchId,
  switchStartDate,
  switchEndDate,
) {
  const tempBookings = bookings;

  console.log('CHECK DATE', date);

  let firstDay, lastDay;

  if (bookings.length === 0) {
    firstDay = moment(date).format('YYYY-MM-DD'); // Assumes date is already a Date object or date string
    if (user.internalUser) {
      lastDay = moment(date).add(properties[propIndex].minNightsInternalUser, 'days').format('YYYY-MM-DD'); // Adds 0 days, effectively keeping the same date
    } else {
      lastDay = moment(date).add(properties[propIndex].minNights, 'days').format('YYYY-MM-DD'); // Adds 0 days, effectively keeping the same date
    }
  } else {
    firstDay = moment(switchStartDate ? switchStartDate : bookings[bookings.length - 1].endDay)
      .add(0, 'days')
      .format('YYYY-MM-DD'); // Adds 0 days
    lastDay = moment(switchEndDate ? switchEndDate : bookings[bookings.length - 1].endDay)
      .add(switchEndDate ? 0 : 1, 'days')
      .format('YYYY-MM-DD'); // Adds 1 day
  }

  let numberOfNights;
  if (switchStartDate !== null && switchEndDate !== null) {
    console.log('switchStartDate', switchStartDate);
    console.log('switchEndDate', switchEndDate);
    const startDate = moment(switchStartDate);
    const endDate = moment(switchEndDate);

    numberOfNights = endDate.diff(startDate, 'days');
  }

  //build pax

  const maxChildAge = properties[propIndex].maxChildAge;

  // Initialize the merged guests object
  const guestsMerged = {
    adults: guests.adults,
    children: 0,
    childAges: [],
    adultAges: [],
  };

  // Process childAges
  guests.childAges.forEach((child) => {
    if (child.age > maxChildAge) {
      // Count as an adult
      guestsMerged.adults += 1;
      guestsMerged.adultAges.push(child);
    } else {
      // Count as a child and add to childAges
      guestsMerged.children += 1;
      guestsMerged.childAges.push(child);
    }
  });

  // Add adult ages to the adultAges array
  guests.adultAges.forEach((adult) => {
    guestsMerged.adultAges.push(adult);
  });

  // Sort childAges and adultAges in descending order
  guestsMerged.childAges.sort((a, b) => b.age - a.age);
  guestsMerged.adultAges.sort((a, b) => a.age - b.age);

  //const roomPaxMerged = roomPaxMerged;
  //alert(packageTab);

  //Get exclusive room if it exists

  const exclusiveRoomCheck = properties[propIndex].rooms.find((room) => room.type === 'Exclusive');
  let exclusiveRoom;
  let exclusiveRoomObject;

  if (exclusiveRoomCheck && properties[propIndex].propertyType === 'private') {
    // Return the exclusive room object
    exclusiveRoom = exclusiveRoomCheck;
    exclusiveRoomObject = {
      selectedRoom: exclusiveRoomCheck.id,
      selectedName: exclusiveRoomCheck.name,
      ad: 0,
      ch: 0,
      chi: 0,
      inf: 0,
      edit: false,
      roomType: 'Exclusive',
      maxCapacity: exclusiveRoomCheck.max_capacity,
      maxAdults: exclusiveRoomCheck.max_adults,
      uuid: uuidv4(),
      selectedAges: [],
      selectedAgesAdults: [],
    };
  } else {
    // No exclusive room found
    exclusiveRoom = false;
    exclusiveRoomObject = [];
  }

  const newMinNights = user.internalUser ? properties[propIndex].minNightsInternalUser : properties[propIndex].minNights;

  if (switchId !== null) {
    console.log('switchId', switchId);
    console.log('propName', properties[propIndex].name);
    const newBooking = {
      //id: switchId, // Use the existing switchId instead of generating a new one
      id: uuidv4(),
      propUid: propUid,
      supplierUid: properties[propIndex].supplier.id,
      supplierName: properties[propIndex].supplier.name,
      propName: properties[propIndex].name,
      days: numberOfNights,
      minNights: newMinNights,
      startDay: firstDay,
      endDay: lastDay,
      selectedRoom: properties[propIndex].bookedDirect ? '' : 'select',
      selectedName: properties[propIndex].bookedDirect ? 'Pick me' : '',
      unit: 0,
      ad: 0,
      ch: 0,
      chi: 0,
      inf: 0,
      rooms: exclusiveRoom ? [exclusiveRoomObject] : [],
      expanded: false,
      addRoom: false,
      refresh: false,
      rateName: properties[propIndex].bookedDirect ? 'fullBoard' : checkRate(packageTab, properties, propIndex),
      type: 'property',
      rebuild: false,
      northernGuideVehicleSupplement,
      ...(northernGuideVehicleSupplement && {
        northernGuideVehiclePrice,
        northernGuideVehicleResRequestId,
      }),
      guestsMerged,
      locationCategory: properties[propIndex].locationCategory,
    };

    //const tempBookings = [...bookings];
    const bookingIndex = tempBookings.findIndex((booking) => booking.id === switchId);
    const switchedPropUid = tempBookings.find((booking) => booking.id === switchId).propUid;

    // remove from rooms array
    const count = countProperty(switchedPropUid, bookings);
    console.log('count', count);
    console.log('switchedPropUid', switchedPropUid);
    if (count === 1) {
      removeOne(switchedPropUid, rooms);
    }

    if (bookingIndex !== -1) {
      console.log('Replacing booking at index:', bookingIndex);
      tempBookings[bookingIndex] = newBooking;
      //tempBookings.splice(bookingIndex, 0, newBooking);
    }
    // else { // add to bottom of array if not found
    //   console.log('Adding new booking');
    //   tempBookings.push(newBooking);
    // }

    setBookings((tempBookings) => [...tempBookings]);
  } else {
    tempBookings.push({
      id: uuidv4(),
      propUid: propUid,
      supplierUid: properties[propIndex].supplier.id,
      supplierName: properties[propIndex].supplier.name,
      propName: properties[propIndex].name,
      days: days || newMinNights,
      minNights: newMinNights,
      // Set date to 12:00 midday UTC
      startDay: firstDay,
      //startDay: moment.utc(firstDay),
      // Set date to 12:00 midday UTC
      endDay: lastDay,
      //endDay: lastDay,
      selectedRoom: properties[propIndex].bookedDirect ? '' : 'select',
      selectedName: properties[propIndex].bookedDirect ? 'Pick me' : '',
      // NEW - start
      unit: 0, // ! Unit
      ad: 0, // ! Adult
      ch: 0, // ! Child 16 - 21
      chi: 0, // ! Child 5 - 15
      inf: 0, // ! Infant < 5
      // NEW - end
      rooms: exclusiveRoom ? [exclusiveRoomObject] : [],
      expanded: false,
      addRoom: false,
      refresh: false,
      rateName: properties[propIndex].bookedDirect ? 'fullBoard' : checkRate(packageTab, properties, propIndex),
      type: 'property',
      rebuild: false,
      northernGuideVehicleSupplement,
      ...(northernGuideVehicleSupplement && {
        northernGuideVehiclePrice,
        northernGuideVehicleResRequestId,
      }),
      guestsMerged,
      locationCategory: properties[propIndex].locationCategory,
    });
    setBookings((tempBookings) => [...tempBookings]);
  }
}

// ************************************* // Recount book days
/*
 * Recount the bookings after adding/removing properties and also after inc/dec days
 */
async function reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, refreshStatus, checkNoConsecutiveProperties, user) {
  console.log('reCountBookings');
  if (!rooms.some((room) => room.loading)) {
    const tempBookings = bookings;
    const updatedBookingsWithoutRates = tempBookings.map((booking) => {
      const updatedRooms = booking.rooms.map((room) => {
        const { rate, ...roomWithoutRate } = room;
        return roomWithoutRate;
      });
      return { ...booking, rooms: updatedRooms };
    });

    let updatedBookings = tempBookings.map((b) => {
      if (resRequestDisplay(b.propUid, properties)) {
        let room = rooms.find((r) => r.propUid === b.propUid);
        return room ? { ...b, roomsNum: rooms.indexOf(room) } : b;
      }
      return b;
    });

    for (let i = 0; i < updatedBookings.length; i++) {
      if (i === 0) {
        updatedBookings[i].startDay = moment(date).format('YYYY-MM-DD');
        updatedBookings[i].endDay = moment(updatedBookings[i].startDay).add(updatedBookings[i].days, 'days').format('YYYY-MM-DD');

        if (updatedBookings[i].rooms.length > 0 && rooms.length > 0) {
          callRoomAvailability(i, updatedBookings, rooms, setBookings, properties);
        }
      } else {
        // !console.log("-------- reCountBookings");
        //Set date to 12:00 midday UTC
        updatedBookings[i].startDay = moment(updatedBookings[i - 1].endDay).format('YYYY-MM-DD');
        updatedBookings[i].endDay = moment(updatedBookings[i - 1].endDay)
          .add(updatedBookings[i].days, 'days')
          .format('YYYY-MM-DD');

        if (updatedBookings[i].rooms.length > 0 && rooms.length > 0) {
          callRoomAvailability(i, updatedBookings, rooms, setBookings, properties);
        }
      }
      if (rooms.length > 0) {
        const availableVehicles1 = await calculatePaxStock(updatedBookings, i, properties, true);
        const availableVehicles2 = await calculatePaxStock(updatedBookings, i, properties, false);
        // updatedBookings[i].vehicles = availableVehicles1;
        // updatedBookings[i].vehiclesPropertiesRule = availableVehicles2;
        // NEW CODE - START
        if ((availableVehicles1 === 'na' || availableVehicles1 === 'none') && (availableVehicles2 !== 'na' || availableVehicles2 !== 'none')) {
          updatedBookings[i].vehicles = availableVehicles2;
          updatedBookings[i].vehiclesPropertiesRule = availableVehicles1;
        } else {
          updatedBookings[i].vehicles = availableVehicles1;
          updatedBookings[i].vehiclesPropertiesRule = availableVehicles2;
        }
        // NEW CODE - END
      }
    }

    setBookings((bookings) => [...updatedBookings]);
    if (refreshStatus === true) {
      //setRefreshRatesEffect(true);
      if (typeof setRefreshRatesEffect === 'function') {
        setRefreshRatesEffect(true);
      }
    }
  }
  if (checkNoConsecutiveProperties && user && !user.internalUser) {
    checkNoConsecutive(rooms, setRooms, bookings, setBookings, date, properties, setRefreshRatesEffect, user);
  }
}

// reset available to null - START
function resetAvailableRooms(bookings, setBookings) {
  for (let i = 0; i < bookings.length; i++) {
    if (bookings[i].rooms.length > 0) {
      for (let iii = 0; iii < bookings[i].rooms.length; iii++) {
        bookings[i].rooms[iii].available = null;
      }
    }
  }
  // !console.log("resetAvailableRooms");
  return setBookings((bookings) => [...bookings]);
}
// reset available to null - END

const merge = (items) => {
  var tmp = {},
    out = [];
  for (var i = 0, l = items.length; i < l; i++) {
    var id = items[i].selectedRoom;
    var name = items[i].selectedName;
    var amount = items[i].count;
    var available = items[i].available;
    if (!tmp[id]) tmp[id] = { count: 0, availableCount: 0 };
    tmp[id].count += amount;
    if (available) tmp[id].availableCount++;
  }
  for (var p in tmp) {
    out.push({
      roomName: name,
      roomId: p,
      roomCount: tmp[p].count,
      availableCount: tmp[p].availableCount,
    });
  }
  return out;
};

const mergeRooms = (items) => {
  const mergedRooms = {};
  for (const item of items) {
    const { selectedRoom, selectedName } = item;
    if (!mergedRooms[selectedRoom]) {
      mergedRooms[selectedRoom] = {
        roomName: selectedName,
        roomId: selectedRoom,
        roomCount: 0,
      };
    }
    mergedRooms[selectedRoom].roomCount++;
  }
  return Object.values(mergedRooms);
};

// ************************************* // Prepare to check if room is available for specific date/property combination
const callRoomAvailability = async (index, bookings, rooms, setBookings) => {
  console.log('callRoomAvailability');
  for (let ja = 0; ja < bookings[index].rooms.length; ja++) {
    bookings[index].rooms[ja].available = null;
  }
  // console.log("callRoomAvailability 0: " + index);
  // console.log("callRoomAvailability 1: " + bookings[index].roomsNum);

  // console.log("callRoomAvailability 2: " + rooms[bookings[index].roomsNum]);
  // console.log("callRoomAvailability 3: " + rooms.length);
  // console.log("callRoomAvailability 4: " + rooms[bookings[index].roomsNum].loading);

  if (rooms[bookings[index].roomsNum] && rooms[bookings[index].roomsNum].loading !== true) {
    //for (let i = 0; i < bookings[index].days; i++) {
    //check for dayRoom
    for (let i = 0; i < (bookings[index].days === 0 ? 1 : bookings[index].days); i++) {
      if (bookings[index].rooms.length > 0) {
        const mergedRooms = mergeRooms(bookings[index].rooms);
        for (let iR = 0; iR < bookings[index].rooms.length; iR++) {
          console.log('iR: ' + iR);
          const check = await getRoomAvailability(
            moment(bookings[index].startDay).add(i, 'days').format('YYYY-MM-DD'),
            bookings[index].roomsNum,
            rooms,
            'total',
            bookings[index].rooms[iR].selectedRoom,
          );

          const requiredIndexId = mergedRooms.findIndex((el) => {
            return el.roomId === String(bookings[index].rooms[iR].selectedRoom);
          });

          if (check <= 0) {
            if (bookings[index].rooms[iR].available !== false) {
              if (bookings[index].rooms[iR].vehicleType && bookings[index].rooms[iR].vehicleType === 'northernGuide') {
                bookings[index].rooms[iR].available = true;
              } else {
                bookings[index].rooms[iR].available = false;
              }

              console.log('check 1: ' + check);
              setBookings((bookings) => [...bookings]);
            }
          } else {
            let availableCount = 0;
            for (let j = 0; j < bookings[index].rooms.length; j++) {
              if (bookings[index].rooms[j].selectedRoom === bookings[index].rooms[iR].selectedRoom) {
                availableCount++;
                if (availableCount <= check) {
                  if (bookings[index].rooms[j].available !== false) {
                    bookings[index].rooms[j].available = true;
                    console.log('AVAILABLE');
                    console.log('check 2: ' + check);
                    setBookings((bookings) => [...bookings]);
                  }
                  //bookings[index].rooms[j].available = true;
                  console.log('check 2a: ' + check);
                  //setBookings((bookings) => [...bookings]);
                  mergedRooms[requiredIndexId].roomCount--;
                  if (mergedRooms[requiredIndexId].roomCount === 0) {
                    console.log('MERGE ROOM BREAK');
                    break;
                  }
                } else {
                  if (bookings[index].rooms[j].available !== false) {
                    console.log('NOT AVAILABLE');
                    if (bookings[index].rooms[j].vehicleType && bookings[index].rooms[j].vehicleType === 'northernGuide') {
                      bookings[index].rooms[j].available = true;
                    } else {
                      bookings[index].rooms[j].available = false;
                    }

                    console.log('check 3: ' + check);

                    setBookings((bookings) => [...bookings]);
                  }
                }
              } else if (bookings[index].rooms[j].selectedRoom !== bookings[index].rooms[iR].selectedRoom) {
                if (bookings[index].rooms[j].available !== false) {
                  bookings[index].rooms[j].available = true;
                  console.log('AVAILABLE');
                  console.log('check 4: ' + check);
                  setBookings((bookings) => [...bookings]);
                }
              }
            }
          }
        }
      }
    }
  }
};

// ************************************* // Check if room is available for specific date/property combination
const getRoomAvailability = (date, roomsNum, rooms, stockSplit, stockId) => {
  console.log('getRoomAvailability START');

  console.log(roomsNum);
  //console.log(rooms);
  console.log(stockSplit);
  console.log(stockId);
  // Set date to 12:00 midday UTC
  const targetDate = date;
  // const stockCount = rooms[roomsNum].stock.filter(
  //   (e) =>
  //     new Date(e.stockStart).toDateString() === new Date(filterYear, filterMonth, filterDate).toDateString() &&
  //     e.stockSplit === stockSplit &&
  //     e.stockId === stockId
  // );
  console.log('targetDate');
  console.log(targetDate);
  const stockCount = rooms[roomsNum].stock.filter((e) => e.stockStart === targetDate && e.stockSplit === stockSplit && e.stockId === stockId);
  console.log('Stock date');
  console.log(new Date(date).toDateString());
  console.log(date);
  console.log('stockCount');
  console.log(console.log(JSON.stringify(stockCount, undefined, 4)));

  if (stockCount[0]) {
    console.log('stockCount[0].stockCount 1');
    console.log(stockCount[0].stockCount);
    return stockCount[0].stockCount;
  }
  //console.log("stockCount[0].stockCount 2");
  // console.log(stockCount[0].stockCount);
  return 0;
};

// Remove a booking by sending UUID and then getting the object number from the array
const filterBookingsById = (id, bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, user) => {
  const tempBookings = bookings.filter((booking) => {
    return booking.id !== id;
  });
  setBookings(tempBookings);
  reCountBookings(tempBookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, true, user);
};

// Check that two properties are not next to each other in drag and drop
function checkNoConsecutive(rooms, setRooms, bookings, setBookings, date, properties, setRefreshRatesEffect, user) {
  console.log('checkNoConsecutive');
  if (bookings.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      if (i + 1 < bookings.length) {
        if (bookings[i].propUid === bookings[i + 1].propUid) {
          //alert("Can't have the same property next to each other");
          const toRemove = bookings[i + 1].id;

          return filterBookingsById(toRemove, bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, user);
        }
      }
    }
    setBookings((bookings) => [...bookings]);
    reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, true, user);
  }
}
//Count how many times this property is in the booking array
// function countProperty(propIndex, bookings) {
//   const counts = {};
//   bookings.forEach(function (x) {
//     counts[x.propUid] = (counts[x.propUid] || 0) + 1;
//   });

//   const countPropNum = [counts];
//   return countPropNum[0][propIndex];
// }
function countProperty(propUid, bookings) {
  const filteredBookings = bookings.filter((booking) => booking.propUid === propUid);
  return filteredBookings.length;
}

// ************************************* // Remove a selected property from the bookings array and also check how many times the property is in the is in the bookings array
//const removeOne = async (id, rooms) => {
const removeProperty = async (id, propUid, propIndex, rooms, setRooms, bookings, setBookings, date, setLoadingData, properties, setRefreshBtn, setRefreshRatesEffect, user) => {
  const count = countProperty(propUid, bookings);
  console.log(getPropObj(propUid, properties));
  console.log('removeProperty');
  console.log(count);
  //setLoadingData(true);

  // const requiredIndex = bookings.findIndex((el) => {
  //   return el.id === String(id);
  // });
  // if (requiredIndex === -1) {
  //   return false;
  // }
  // const splice = !!bookings.splice(requiredIndex, 1);
  // setBookings((bookings) => [...bookings]);

  if (count > 1) {
    await removeOneBooking(id, propUid, bookings, setBookings, date, rooms, setRooms, setRefreshBtn, properties, setRefreshRatesEffect, user);
    showRefresh(bookings, setRefreshBtn);
  } else if (count === 1) {
    await removeOneBooking(id, propUid, bookings, setBookings, date, rooms, setRooms, setRefreshBtn, properties, setRefreshRatesEffect, user);
    await removeOne(propUid, rooms);
    setLoadingData(false);
    //setBookings((bookings) => [...bookings]);
    propertySelected(getPropObj(propUid, properties), properties);
    propertyAccom(getPropObj(propUid, properties), properties);
    //checkNoConsecutive(rooms, setRooms, bookings, setBookings, date, properties, setRefreshRatesEffect);
    showRefresh(bookings, setRefreshBtn);
  }
  setRefreshBtn(true);
};

const removePropertyRoomsBookings = async () => {};

const incDecBookingNights = async (num, bookings, id, setBookings, properties, setRefreshRatesEffect, setRefreshBtn, user) => {
  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });

  // if (bookings[requiredIndex].rooms.length > properties[getPropObj(bookings[requiredIndex].propUid, properties)].minNights) {
  //   bookings[requiredIndex].refresh = true;
  // }
  if (
    bookings[requiredIndex].rooms.length >
    (user.internalUser ? properties[getPropObj(bookings[requiredIndex].propUid, properties)].minNightsInternalUser : properties[getPropObj(bookings[requiredIndex].propUid, properties)].minNights)
  ) {
    bookings[requiredIndex].refresh = true;
  }

  bookings[requiredIndex].days = Number(num);
  showRefresh(bookings, setRefreshBtn);
  bookings[requiredIndex].refresh = true;
  setRefreshRatesEffect(true);
  // ! Changed  booking ID
  setBookings((bookings) => [...bookings]);
};

//check if rooms array has changed

const areBookingRoomsSameOld = (booking) => {
  console.log('areBookingRoomsSame');
  const { rooms, lastPricedRoomsArray } = booking;

  // Check if both arrays exist and have the same length
  if (!rooms || !lastPricedRoomsArray || rooms.length !== lastPricedRoomsArray.length) {
    console.log('Arrays do not exist or have different lengths');
    return false;
  }

  // Compare each room in both arrays
  for (let i = 0; i < rooms.length; i++) {
    const room = rooms[i];
    const lastPricedRoom = lastPricedRoomsArray[i];

    if (!_.isEqual(room, lastPricedRoom)) {
      console.log(`Mismatch found in room ${i}:`);
      console.log('Current room:', room);
      console.log('Last priced room:', lastPricedRoom);

      // Specific checks for selectedAgesAdults and selectedAges
      if (!_.isEqual(room.selectedAgesAdults, lastPricedRoom.selectedAgesAdults)) {
        console.log('Mismatch in selectedAgesAdults');
      }
      if (!_.isEqual(room.selectedAges, lastPricedRoom.selectedAges)) {
        console.log('Mismatch in selectedAges');
      }

      return false;
    }
  }

  // if (!checkBookingDateKaraniFull(booking)) {
  //   console.log('checkBookingDateKaraniFull returned false');
  //   return false;
  // }

  console.log('All rooms match for: ', booking.propName);
  return true;
};

const areRoomsSame = (room1, room2) => {
  const propertiesToCompare = ['selectedName', 'selectedRoom', 'ch', 'ad', 'chi', 'inf', 'roomType', 'selectedAgesAdults', 'selectedAges'];

  return propertiesToCompare.every((prop) => _.isEqual(room1[prop], room2[prop]));
};

// const areBookingRoomsSame = (booking) => {
//   console.log('areBookingRoomsSame');
//   const { rooms, lastPricedRoomsArray } = booking;

//   if (!rooms || !lastPricedRoomsArray || rooms.length !== lastPricedRoomsArray.length) {
//     console.log('Arrays do not exist or have different lengths');
//     return false;
//   }

//   for (let i = 0; i < rooms.length; i++) {
//     if (!areRoomsSame(rooms[i], lastPricedRoomsArray[i])) {
//       console.log(`Mismatch found in room ${i}:`);
//       console.log('Current room:', rooms[i]);
//       console.log('Last priced room:', lastPricedRoomsArray[i]);
//       return false;
//     }
//   }

//   console.log('All rooms match for: ', booking.propName);
//   return true;
// };

// ! harry New code
const areBookingRoomsSame = (booking) => {
  console.log('areBookingRoomsSame');
  const { rooms, lastPricedRoomsArray } = booking;

  if (!rooms || !lastPricedRoomsArray) {
    console.log('Arrays do not exist');
    return false;
  }

  // Filter out rooms with roomType "Vehicle"
  const filteredRooms = rooms.filter((room) => room.roomType !== 'Vehicle');
  const filteredLastPricedRooms = lastPricedRoomsArray.filter((room) => room.roomType !== 'Vehicle');

  if (filteredRooms.length !== filteredLastPricedRooms.length) {
    console.log('Filtered arrays have different lengths');
    return false;
  }

  for (let i = 0; i < filteredRooms.length; i++) {
    if (!areRoomsSame(filteredRooms[i], filteredLastPricedRooms[i])) {
      console.log(`Mismatch found in room ${i}:`);
      console.log('Current room:', filteredRooms[i]);
      console.log('Last priced room:', filteredLastPricedRooms[i]);
      return false;
    }
  }

  console.log('All non-Vehicle rooms match for: ', booking.propName);
  return true;
};

// const checkAllBookings = (bookings) => {
//   const allMatch = bookings.every((booking) => {
//     const result = areBookingRoomsSame(booking);
//     console.log(`Booking ${booking.id}: ${result}`);
//     return result;
//   });

//   console.log(`All bookings match: ${allMatch}`);
//   return allMatch;
// };

// check rate has not changed
const checkRateKaraniId = (booking, createEdit) => {
  // console.log('checkRateKaraniId');
  // console.log('booking.karaniLastSearchedRate.toString()', booking.karaniLastSearchedRate ? booking.karaniLastSearchedRate.toString() : 'null');
  // console.log('booking.rateKaraniId.toString()', booking.rateKaraniId.toString());
  // check we are on edit else return true
  if (createEdit === 'edit') {
    if (booking.rateKaraniId && booking.prices && booking.prices[0]) {
      if (booking.karaniLastSearchedRate && booking.rateKaraniId && booking.karaniLastSearchedRate.toString() === booking.rateKaraniId.toString()) {
        //if (booking.karaniLastSearchedRate && booking.rateKaraniId && booking.karaniLastSearchedRate.toString() === booking.rateKaraniId.toString() && checkBookingDateKarani(booking, createEdit)) {

        console.log('checkRateKaraniId true');

        return true;
      } else {
        console.log('checkRateKaraniId false');
        console.log(booking.rateKaraniId.toString());
        //console.log(booking.prices[0].board.id.toString());
        return false;
      }
    } else {
      console.log('checkRateKaraniId checking resrequest rates: ', booking.propName);
      if (booking.rooms.some((room) => room?.rate?.periods)) {
        return false;
      } else {
        // property with final page pricing not checking rates
        console.log('checkRateKaraniId not checking resrequest rates');
        return true;
      }
    }
  } else {
    console.log('checkRateKaraniId create');
    return false;
  }
};

// const checkBookingDateKarani = (booking, createEdit) => {
//   if (createEdit === 'edit') {
//     const bookingStartDate = booking.startDay;
//     const bookingEndDate = booking.endDay;
//     const karaniStartDate = moment(booking.prices[0].startdate).format('YYYY-MM-DD');
//     const karaniEndDate = moment(booking.prices[0].enddate).add(1, 'days').format('YYYY-MM-DD');

//     if (bookingStartDate === karaniStartDate && bookingEndDate === karaniEndDate) {
//       console.log('checkBookingDateKarani true');
//       return true;
//     } else {
//       console.log(bookingStartDate);
//       console.log(karaniStartDate);
//       console.log(bookingEndDate);
//       console.log(karaniEndDate);
//       console.log('checkBookingDateKarani false');
//       return false;
//     }
//   } else {
//     console.log('checkBookingDateKarani create');
//     return false;
//   }
// };

// Check if property dates have changed create and edit
const checkBookingDate = (booking) => {
  if (!booking.prices) {
    return false;
  }
  console.log('checkBookingDateKaraniFull');
  const bookingStartDate = booking.startDay;
  const bookingEndDate = booking.endDay;
  const karaniStartDate = moment(booking.prices[0].startdate).format('YYYY-MM-DD');
  const karaniEndDate = moment(booking.prices[0].enddate).add(1, 'days').format('YYYY-MM-DD');

  if (bookingStartDate === karaniStartDate && bookingEndDate === karaniEndDate) {
    console.log('checkBookingDateKaraniFull true');
    return true;
  } else {
    console.log(bookingStartDate);
    console.log(karaniStartDate);
    console.log(bookingEndDate);
    console.log(karaniEndDate);
    console.log('checkBookingDateKaraniFull false');
    return false;
  }
};

// ************************************* // Check if longstay is used before or not
const checkLongStayInBooking = (booking, createEdit) => {
  console.log('checkLongStay');
  // check we are on edit else return true
  //if (createEdit === 'edit') {
  if (booking.rateKaraniId && booking.prices && booking.prices[0]) {
    if (booking.prices.some((price) => price?.pricepoint?.id === 1)) {
      console.log('longstay true');
      return true;
    } else {
      console.log('longstay false');
      return false;
    }
  } else {
    // console.log('longstay checking resrequest rates: ', booking.propName);
    // if (booking.rooms.some((room) => room?.rate?.periods)) {  // IS THIS REQUIRED?
    //   return false;
    // } else {
    //   // property with final page pricing not checking rates
    //   console.log('longstay not checking resrequest rates');
    //   return true;
    // }
    return false;
  }
  // } else {
  //   console.log('longstay create');
  //   return false;
  // }
};

// ************************************* // Refresh rates
const refreshRates = async (
  bookings,
  setBookings,
  setRefreshBtn,
  properties,
  setFetchingRates,
  agentRates,
  agentCommission,
  guests,
  agentObject,
  createVersionCheck,
  createEdit,
  user,
  isFamTrip,
  commissionAgent,
  commissionUser,
  dateCreated,
) => {
  console.log('refreshRates START');
  // let promises = []; // Array to hold all promises

  // for (let b = 0; b < bookings.length; b++) {
  //   //Collapse all properties when checking rates
  //   bookings[b].expanded = false;
  //   bookings[b].addRoom = false;

  //   if (bookings[b].rooms.length > 0) {
  //     for (let i = 0; i < bookings[b].rooms.length; i++) {
  //       bookings[b].refresh = false;
  //       setBookings((bookings) => [...bookings]);

  //       // Check longstay is used before or not LONGSTAY
  //       // const nomadTanzaniaBookings = bookings.filter((booking) => resRequestRatesFilter(booking.propUid, properties));
  //       // const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
  //       // const totalSafariNights = countSafariParkNights(bookings);
  //       // let longStayChecked = false;
  //       // // if(not nomad and version number is lt current version or version does not exist) -> longStayChecked = true; Do this first
  //       // if (properties[getPropObj(bookings[b].propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67' || semver.lt(createVersionCheck, '2.3.30')) {
  //       //   longStayChecked = true;
  //       // } else if (longStayCheck(totalNomadTanzaniaDays, bookings[b].startDay, createVersionCheck, totalSafariNights, createEdit, dateCreated) === checkLongStayInBooking(bookings[b], createEdit)) {
  //       //   console.log('longStayCheck true');
  //       //   longStayChecked = true;
  //       // }
  //       //const longStayChecked = checkLongStayStatus(bookings, bookings[b], properties, createVersionCheck, createEdit, dateCreated);

  //       //

  //       //if (bookings[b].rooms[i].roomType !== 'Vehicle' && !areBookingRoomsSame(bookings[b]) && (!checkRateKaraniId(bookings[b], createEdit) || !bookings[b].notModified || !longStayChecked)) {
  //         if (bookings[b].rooms[i].roomType !== 'Vehicle' && (!checkRateKaraniId(bookings[b], createEdit) || !bookings[b].notModified)) {
  //         console.log('refreshRates 1');
  //         console.log('bookings[b].propName', bookings[b].propName);
  //         bookings[b].rooms[i].edit = false;
  //         //bookings[b].lastPricedRoomsArray = bookings[b].rooms;
  //         // Push each promise into the array
  //         promises.push(
  //           getRoomRates(
  //             b,
  //             bookings,
  //             setBookings,
  //             bookings[b].rooms[i].selectedRoom,
  //             bookings[b].startDay,
  //             bookings[b].endDay,
  //             bookings[b].rooms[i].ad,
  //             bookings[b].rooms[i].ch,
  //             bookings[b].rooms[i].chi,
  //             bookings[b].rooms[i].inf,
  //             bookings[b].rooms[i].uuid,
  //             setRefreshBtn,
  //             properties,
  //             getPropObj(bookings[b].propUid, properties),
  //             bookings[b].propUid,
  //             setFetchingRates,
  //             agentRates,
  //             agentCommission,
  //             createVersionCheck,
  //             createEdit,
  //             dateCreated,
  //           ),
  //         );
  //       } else {
  //         console.log('refreshRates 2');
  //         //bookings[b].lastPricedRoomsArray = bookings[b].rooms;
  //       }
  //     }
  //   }
  // }

  // Wait for all getRoomRates promises to resolve
  // await Promise.all(promises);

  // Now call getRoomRatesKarani

  await getRoomRatesKarani(
    bookings,
    setBookings,
    setRefreshBtn,
    setFetchingRates,
    guests,
    properties,
    agentObject,
    createVersionCheck,
    createEdit,
    user,
    isFamTrip,
    commissionAgent,
    commissionUser,
    dateCreated,
  );
};

// ************************************* // Check if any rooms need to be refreshed
const showRefresh = async (bookings, setRefreshBtn) => {
  setRefreshBtn(false);
  setRefreshBtn(false, false);
  for (let b = 0; b < bookings.length; b++) {
    if (bookings[b].refresh && bookings[b].rooms.length > 0) {
      setRefreshBtn(true);

      //setRefreshBtn({ ...1 });
    } else {
      //setRefreshBtn(false);
    }
  }
};

// ************************************* // Set the room type for the select box of the property
function roomSelect(e, id, bookings, setBookings) {
  console.log('eeeeeeeeeeeeeee');
  console.log(e);

  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });
  bookings[requiredIndex].selectedRoom = e.value;
  bookings[requiredIndex].selectedName = e.name;
  // bookings[requiredIndex].adult = 0;
  // bookings[requiredIndex].child = 0;
  setBookings((bookings) => [...bookings]);
}

// ************************************* // Set the room type for the select box of the property

const agentSelect = async (
  uid,
  consultants,
  setConsultants,
  setSelectedAgent,
  setSelectedAgentItems,
  setSelectedConsultant,
  setSelectedConsultantName,
  agentRateId,
  setAgentRates,
  setSelectedConsultantUid,
  setConsultantResRequestId,
  companyId,
) => {
  console.log('getAgentRates 1');
  await loadAgentRates(agentRateId, setAgentRates);
  //const uid = e.target.value;
  console.log('consultants');
  for (let i = 0; i < consultants.length; i++) {
    //console.log(JSON.stringify(consultants[i], undefined, 4));
    //console.log("consultants[i].key");
    //console.log(JSON.stringify(Object.keys(consultants[i]), undefined, 4));
    //console.log(Object.keys(consultants[i])[0]);
    if (Object.keys(consultants[i])[0] === uid) {
      // console.log("consultants");
      // console.log(JSON.stringify(consultants, undefined, 4));
      setSelectedAgent(uid);
      getConsultants(uid, consultants, setSelectedAgentItems, setSelectedConsultantName, setConsultantResRequestId, setSelectedConsultantUid, companyId);
      setSelectedConsultant(true);
      return true;
    }
  }

  const fetchName = 'consultants';
  try {
    //Get reference
    const fetchRef = collection(db, fetchName);

    //Create query
    const q = query(fetchRef, where('companyId', '==', companyId), where('active', '==', true), orderBy('fullName'));

    //Execute query
    const querySnapshot = await getDocs(q);

    const fetch = [];
    if (querySnapshot.docs.length > 0) {
      setSelectedConsultant(true);
      querySnapshot.forEach((doc) => {
        return fetch.push({
          ...doc.data(),
        });
      });
      var jsonResult = {
        result: fetch,
      };
      consultants.push({
        [uid]: fetch,
      });

      setSelectedAgent(uid);
      setConsultants((consultants) => [...consultants]);

      getConsultants(uid, consultants, setSelectedAgentItems, setSelectedConsultantName, setConsultantResRequestId, setSelectedConsultantUid);
    } else {
      setSelectedConsultant(false);
    }
  } catch (error) {
    console.log(error);
  }
};

// getting rate info when the enquiry is selected
const agentEnquiry = async (agentRateId, setAgentRates, hit, bookings, setBookings) => {
  console.log('getAgentRates 1');
  await loadAgentRates(agentRateId, setAgentRates, hit, bookings, setBookings, true);
};

// Get consultants for the agent
const getConsultants = async (id, consultants, setSelectedAgentItems, setSelectedConsultantName, setConsultantResRequestId, setSelectedConsultantUid) => {
  console.log('Start');
  const agents = [];
  for (let i = 0; i < consultants.length; i++) {
    if (Object.keys(consultants[i])[0] === id) {
      consultants[i][Object.keys(consultants[i])[0]].map((res, resIndex) => {
        console.log('uid: ' + res.uid);
        console.log('resRequestId: ' + res.resRequestId);
        const value = { uid: res.uid, resRequestId: res.resRequestId };
        agents.push(
          <option key={uuidv4()} value={JSON.stringify(value)}>
            {res.fullName}
          </option>,
        );
        //return res;
      });
      setSelectedAgentItems(
        <select
          className="w-full rounded-sm h-11"
          //value="select"
          onChange={(e) => {
            const value = JSON.parse(e.target.value);
            const { uid, resRequestId } = value;
            console.log(e.target[e.target.selectedIndex].text);
            console.log(uid);
            console.log(resRequestId);
            setSelectedConsultantName(e.target[e.target.selectedIndex].text);
            setConsultantResRequestId(resRequestId);
            setSelectedConsultantUid(uid);
          }}
        >
          <option value="select" defaultValue="select">
            Select consultant *
          </option>
          {agents}
        </select>,
      );
      return true;
    }
  }
  return false;
};

// ************************************* // Increment/decrement number of adult/child  in a room at a property
// TODO add in ch/chi/inf and change to ch/chi/inf/adult
function incDecRoomGuests(incDec, bookings, id, guest, propIndex, properties) {
  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });

  if (bookings[requiredIndex].selectedRoom === 'select') {
    return toast.error(`Please select a room type before adding guests`);
  }

  //Check for capacity at `selectedRoom`

  const roomObject = properties[propIndex].rooms.filter((room) => {
    return room.id === bookings[requiredIndex].selectedRoom;
  });

  const maxAdults = roomObject[0].max_adults;
  const maxCapacity = roomObject[0].max_capacity;

  if (guest === 'ad') {
    if (incDec === 'inc') {
      const totalGuests = bookings[requiredIndex].ad + bookings[requiredIndex].ch + bookings[requiredIndex].chi + bookings[requiredIndex].inf;
      if (totalGuests < maxCapacity && bookings[requiredIndex].ad < maxAdults) {
        bookings[requiredIndex].ad++;
        return true;
      } else {
        toast.error(`Room has a max capacity of ${maxCapacity} ${maxCapacity > 1 ? 'people' : 'person'} (${maxAdults} adult${maxAdults > 1 ? 's' : ''})`);
        return false;
      }
    } else {
      if (bookings[requiredIndex].ad > 0) {
        bookings[requiredIndex].ad--;
        return true;
      }
    }
  } else if (guest === 'ch') {
    if (incDec === 'inc') {
      const totalGuests = bookings[requiredIndex].ad + bookings[requiredIndex].ch + bookings[requiredIndex].chi + bookings[requiredIndex].inf;
      if (totalGuests < maxCapacity) {
        bookings[requiredIndex].ch++;
        return true;
      } else {
        toast.error(`Room has a maximum capacity of ${maxCapacity} ${maxCapacity > 1 ? 'people' : 'person'} (${maxAdults} adult${maxAdults > 1 ? 's' : ''})`);
        return false;
      }
    } else {
      if (bookings[requiredIndex].ch > 0) {
        bookings[requiredIndex].ch--;
        return true;
      }
    }
  } else if (guest === 'chi') {
    if (incDec === 'inc') {
      const totalGuests = bookings[requiredIndex].ad + bookings[requiredIndex].ch + bookings[requiredIndex].chi + bookings[requiredIndex].inf;
      if (totalGuests < maxCapacity) {
        bookings[requiredIndex].chi++;
        return true;
      } else {
        toast.error(`Room has a maximum capacity of ${maxCapacity} ${maxCapacity > 1 ? 'people' : 'person'} (${maxAdults} adult${maxAdults > 1 ? 's' : ''})`);
        return false;
      }
    } else {
      if (bookings[requiredIndex].chi > 0) {
        bookings[requiredIndex].chi--;
        return true;
      }
    }
  }
}

// Remove a specfic room from the property in the booking array
const removeRoom = async (roomIndex, id, roomId, bookings, setBookings, date, rooms, setRooms, setRefreshBtn, properties, setRefreshRatesEffect) => {
  // set addRoom and edit to false
  for (let b = 0; b < bookings.length; b++) {
    bookings[b].addRoom = false;
    if (bookings[b].rooms.length > 0) {
      for (let i = 0; i < bookings[b].rooms.length; i++) {
        bookings[b].refresh = false;
        if (bookings[b].rooms[i].roomType !== 'Vehicle') {
          bookings[b].rooms[i].edit = false;
        }
      }
    }
  }

  const requiredIndex = bookings.findIndex((el) => el.id === String(id));

  // Remove the room using splice
  bookings[requiredIndex].rooms.splice(roomIndex, 1);

  // Check if the room is still present after the first splice call
  const roomStillExists = bookings[requiredIndex].rooms.some((room) => room.selectedRoom === roomId);

  // If the room is still present, perform the splice again
  if (roomStillExists) {
    //bookings[requiredIndex].rooms.splice(roomIndex, 1);
  }

  const availableVehicles = await calculatePaxStock(bookings, requiredIndex, properties);
  bookings[requiredIndex].vehicles = availableVehicles;
  bookings[requiredIndex].rebuild = true;
  bookings[requiredIndex].notModified = false;

  setBookings((prevBookings) => [...prevBookings]);

  // !This was causing rooms to be recalculated again when it is not necessary
  //reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect);

  if (bookings[requiredIndex].rooms.length === 0) {
    bookings[requiredIndex].available = null;
  }

  return showRefresh(bookings, setRefreshBtn);
};

function processAgesRoomUid(bookings, requiredIndex, uuid) {
  const requiredRoomIndex = bookings[requiredIndex].rooms.findIndex((room) => room.uuid === uuid);

  // Ensure that the room is found
  if (requiredRoomIndex === -1) {
    return { adNew: 0, chNew: 0, chiNew: 0 };
  }

  const room = bookings[requiredIndex].rooms[requiredRoomIndex];

  // Ensure selectedAges and selectedAgesAdults exist and are arrays
  const selectedAges = Array.isArray(room.selectedAges) ? room.selectedAges : [];
  const selectedAgesAdults = Array.isArray(room.selectedAgesAdults) ? room.selectedAgesAdults : [];

  const roomPeople = [...selectedAges, ...selectedAgesAdults];

  // Count people in each age group
  let adNew = roomPeople.filter((person) => person.age > 21).length;
  let chNew = roomPeople.filter((person) => person.age >= 16 && person.age <= 21).length;
  let chiNew = roomPeople.filter((person) => person.age < 16).length;

  return { adNew, chNew, chiNew };
}

function processAgesRoom(room) {
  console.log('processAgesRoom');
  console.log('Room details:', JSON.stringify(room, null, 2));

  let adNew, chNew, chiNew;

  if (room.roomType === 'Vehicle') {
    adNew = room.ad;
    chNew = room.ch;
    chiNew = room.chi;
  } else {
    // Ensure selectedAges and selectedAgesAdults exist and are arrays
    const selectedAges = Array.isArray(room.selectedAges) ? room.selectedAges : [];
    const selectedAgesAdults = Array.isArray(room.selectedAgesAdults) ? room.selectedAgesAdults : [];

    const roomPeople = [...selectedAges, ...selectedAgesAdults];

    // Count people in each age group
    adNew = roomPeople.filter((person) => person.age > 21).length;
    chNew = roomPeople.filter((person) => person.age >= 16 && person.age <= 21).length;
    chiNew = roomPeople.filter((person) => person.age < 16).length;
  }

  return { adNew, chNew, chiNew };
}

function processAgesRooms(bookings, requiredIndex) {
  // Sum up the age group counts for all rooms in a specific booking
  return bookings[requiredIndex].rooms.reduce(
    (acc, room) => {
      // Ensure selectedAges and selectedAgesAdults exist and are arrays
      const selectedAges = Array.isArray(room.selectedAges) ? room.selectedAges : [];
      const selectedAgesAdults = Array.isArray(room.selectedAgesAdults) ? room.selectedAgesAdults : [];

      const roomPeople = [...selectedAges, ...selectedAgesAdults];

      // Count people in each age group for the current room
      let adNew = roomPeople.filter((person) => person.age > 21).length;
      let chNew = roomPeople.filter((person) => person.age >= 16 && person.age <= 21).length;
      let chiNew = roomPeople.filter((person) => person.age < 16).length;

      // Accumulate the counts
      acc.adNew += adNew;
      acc.chNew += chNew;
      acc.chiNew += chiNew;

      return acc;
    },
    { adNew: 0, chNew: 0, chiNew: 0 },
  );
}

// START************************************* // Get room rates object!!!!!
const getRoomRates = async (
  requiredIndex,
  bookings,
  setBookings,
  selectedRoom,
  startDay,
  endDay,
  ad,
  ch,
  chi,
  inf,
  uuid,
  setRefreshBtn,
  properties,
  propIndex,
  propUid,
  setFetchingRates,
  agentRates,
  agentCommission,
  createVersionCheck,
  createEdit,
  dateCreated,
) => {
  return new Promise((resolve, reject) => {
    console.log('getRoomRates');
    console.log('requiredIndex: ' + requiredIndex);
    console.log('propIndex ' + propIndex);
    console.log('propUid ' + propUid);
    console.log('ad ' + ad);
    console.log('ch ' + ch);
    console.log('chi ' + chi);
    console.log('inf ' + inf);
    console.log('startDay ' + startDay);
    console.log('endDay ' + endDay);

    const nomadTanzaniaBookings = bookings.filter((booking) => resRequestRatesFilter(booking.propUid, properties));

    if (resRequestRates(propIndex, properties)) {
      const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
      const totalSafariNights = countSafariParkNights(bookings);
      console.log('totalNomadTanzaniaDays ' + totalNomadTanzaniaDays);

      var accommodation = [];
      console.log('nomadTanzaniaBookings 2 ' + nomadTanzaniaBookings.length);

      //nomadTanzaniaBookings.forEach((booking) => {
      //const endDayCheck = new Date(endDay);
      let selectedRate;
      let amountType;
      let commission;
      // if (totalNomadTanzaniaDays > 4 && endDayCheck > new Date("2023-05-31")) {
      if (longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, createEdit, dateCreated)) {
        selectedRate = agentRates.circuit[bookings[requiredIndex].rateName];
      } else {
        selectedRate = agentRates.standard[bookings[requiredIndex].rateName];
      }
      amountType = {
        payable: '0',
        gross: '0',
        nett: '1',
        comm: '0',
        tax: '0',
        detail: '1',
      };

      commission = agentCommission;

      console.log('selectedRate');
      console.log(selectedRate);
      console.log('selectedRoom');
      console.log(selectedRoom);

      // Vars

      // Check if supplier is nomad or not
      if (properties[getPropObj(propUid, properties)].supplier.id !== 'ded3a3ed-aeaf-4495-9069-7754a649de67') {
        // Nomad Tanzania
        //selectedRate = "11e7c8fe976efecbb0600cc47a969e7c";
        const rateObj = properties[getPropObj(propUid, properties)].thirdPartyRates.find((rateObj) => rateObj.type === bookings[requiredIndex].rateName);
        if (rateObj) {
          selectedRate = rateObj.id;
        } else {
          return toast.error('Rate not found');
        }

        amountType = '';
        commission = '';
      }
      console.log('supplier ' + properties[getPropObj(propUid, properties)].supplier.name);
      console.log('property name ' + properties[getPropObj(propUid, properties)].name);
      console.log('selectedRate 2');
      console.log(selectedRate);
      console.log('selectedRoom 2');
      console.log(selectedRoom);

      // TODO check is resRequest or not

      //const link_id = process.env.REACT_APP_RES_REQ_LINK_ID;
      const link_id = properties[getPropObj(propUid, properties)].platformResRequest.linkId;
      const url = '/api/resreq';
      // //build days

      //build days
      var rateData = [];

      /// console.log(bookings[requiredIndex].rooms.length);
      //for (let i = 0; i < bookings[requiredIndex].rooms.length; i++) {

      const ageCounts = processAgesRoomUid(bookings, requiredIndex, uuid);
      console.log('ageCounts');
      console.log(JSON.stringify(ageCounts, null, 2));

      let adNew = ageCounts.adNew;
      let chNew = ageCounts.chNew;
      let chiNew = ageCounts.chiNew;
      let infNew = 0;

      rateData.push([
        selectedRoom,
        startDay,
        moment(endDay).subtract(1, 'days').format('YYYY-MM-DD'),
        [
          ['RS1', '1'],
          ['RS2', `${adNew}`],
          ['RS3', `${chNew}`],
          ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `${chiNew}`],
          ['11ed2692dae5ee328f91ac1f6b1b6a6e', `${infNew}`],
        ],
      ]);
      //}
      // Ensure commission is a string
      if (typeof commission === 'number') {
        commission = commission.toString();
      }
      var data = JSON.stringify({
        method: 'rt_get_rate_detail',
        params: [
          {
            bridge_username: '',
            bridge_password: '',
            link_id: link_id,
          },
          selectedRoom,
          startDay,
          endDay,
          [
            ['RS1', '1'],
            ['RS2', `${adNew}`],
            ['RS3', `${chNew}`],
            ['11ed2692eeb9680c8f91ac1f6b1b6a6e', `${chiNew}`],
            ['11ed2692dae5ee328f91ac1f6b1b6a6e', `${infNew}`],
          ],

          selectedRate,
          amountType,
          '',
          commission,
          {
            period: '1',
            rate_group: '1',
          },
        ],
        id: 1,
      });

      var config = {
        method: 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      console.log('RATE CALL');
      console.log(data);
      console.log(JSON.stringify(data, undefined, 4));

      axios(config)
        .then(async function (response) {
          console.log(response.status);
          console.log(JSON.stringify(response.data, undefined, 4));

          if (response.status === 200) {
            // Send data to FB
            if (response.data.error && response.data.error.faultCode === 800) {
              toast.error(response.data.error.faultString);
            } else {
              // const docRef = await addDoc(collection(db, "itineraries"), {
              //   resReqReservationId: response.data.result,
              //   accommodationData,
              // });
              //const addUid = doc(db, "itineraries", docRef.id);
              // Set the "UID" field of the doc
              // await updateDoc(addUid, {
              //   uid: docRef.id,
              // });
            }
            //let ref = bookings[requiredIndex].rooms[roomObj].rate[response.data.result];

            // Find rooms object number by `uuid`
            const requiredRoomIndex = bookings[requiredIndex].rooms.findIndex((el) => {
              return el.uuid === String(uuid);
            });

            // Set rate for specific room
            console.log('response.data.result b4 percent');
            console.log(JSON.stringify(response.data.result, undefined, 4));

            if (properties[getPropObj(propUid, properties)].supplier.name !== 'Nomad Tanzania') {
              const netCostMarkUp = properties[getPropObj(propUid, properties)].netCostMarkUp;
              response.data.result.originalPayable = response.data.result.payable;
              response.data.result.payable *= 1 + netCostMarkUp / 100;
            }
            bookings[requiredIndex].rooms[requiredRoomIndex].rate = response.data.result;

            console.log('response.data.result AFTER percent');
            console.log(JSON.stringify(response.data.result, undefined, 4));

            setBookings((bookings) => [...bookings]);
            // set setTotalTicketPrice setAdultTicketPrice setChildTicketPrice for ticket item
            //setRefreshBtn(false);
            // setTimeout(() => {
            //   setFetchingRates(false);
            // }, 2000);
            resolve(response.data.result);

            //return response.data.result;
          }
        })
        .catch(function (error) {
          reject(error);
          console.log(error);
        });
    } else {
      const requiredRoomIndex = bookings[requiredIndex].rooms.findIndex((el) => {
        return el.uuid === String(uuid);
      });
      if (requiredRoomIndex === bookings.length - 1 || nomadTanzaniaBookings.length === 0) {
        // setRefreshBtn(false);
        // setFetchingRates(false);
      }

      if (properties[propIndex].bookedDirect) {
        resolve('Booked direct');
        return;
      } else {
        // karani: New get non-nomad rates - START

        // const loadKaraniData = async (karaniData, path) => {
        //   console.log('loadKaraniData');
        //   const url = '/api/karani'; // Assuming you've set up a route in your server for this

        //   try {
        //     const response = await axios.post(url, {
        //       data: karaniData,
        //       path: path,
        //     });

        //     console.log('response.status:', response.status);
        //     console.log(JSON.stringify(response.data, null, 4));

        //     if (response.status === 200) {
        //       return response.data;
        //     } else {
        //       console.log('No data');
        //       return false;
        //     }
        //   } catch (error) {
        //     console.error('API request failed', error);
        //     return Promise.reject(error);
        //   }
        // };
        if (!properties[propIndex]?.platformKarani?.active) {
          //toast.error(`Rates not available for ${properties[propIndex].name}`);
        }
      }
      resolve('No data');
    }
  });
};

// function createCampsArray(bookings, guestsPeople, properties) {
//   const camps = [];

//   bookings.forEach((booking, index) => {
//     const propertyObj = properties[getPropObj(booking.propUid, properties)];

//     // Check if platformKarani is active
//     if (propertyObj.platformKarani?.active && !booking.notKaraniPriced) {
//       const rooms = booking.rooms.map((room) => {
//         // Create roomPeople array
//         const roomPeople = [...room.selectedAges, ...room.selectedAgesAdults].map((person) => {
//           const guest = guestsPeople.find((g) => g.uuid === person.uuid);
//           return { ID: guest ? guest.ID : null };
//         });

//         // Get room details
//         const roomDetails = propertyObj.rooms.find((r) => r.id === room.selectedRoom);

//         return {
//           Type: {
//             ID: roomDetails ? roomDetails.karaniId : null,
//             Name: roomDetails ? roomDetails.name : null,
//           },
//           People: roomPeople,
//         };
//       });

//       // Get board ID
//       const boardID = propertyObj.thirdPartyRates.find((rate) => rate.type === booking.rateName)?.karaniId || null;

//       camps.push({
//         Index: index,
//         Camp: {
//           ID: propertyObj.platformKarani.id,
//           Name: booking.propName,
//         },
//         Board: {
//           ID: boardID,
//         },
//         StartDate: booking.startDay,
//         Nights: booking.days,
//         Rooms: rooms,
//       });
//     }
//   });

//   return camps;
// }

// function to check long stay status
function checkLongStayStatus(bookings, booking, properties, createVersionCheck, createEdit, dateCreated) {
  // const nomadTanzaniaBookings = bookings.filter((b) => resRequestRatesFilter(b.propUid, properties));
  const nomadTanzaniaBookings = bookings.filter((booking) => resRequestCreate(booking.propUid, properties));
  const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, b) => total + b.days, 0);
  const totalSafariNights = countSafariParkNights(bookings);

  const propertyObj = properties[getPropObj(booking.propUid, properties)];
  const isNomadSupplier = propertyObj.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67';
  const isOldVersion = semver.lt(createVersionCheck, '2.3.30');

  if (!isNomadSupplier || isOldVersion) {
    console.log('isNomadSupplier or isOldVersion');
    return true;
  }

  const longStayApplies = longStayCheck(totalNomadTanzaniaDays, booking.startDay, createVersionCheck, totalSafariNights, createEdit, dateCreated);
  const longStayUsedBefore = checkLongStayInBooking(booking, createEdit);

  console.log('longStayApplies', longStayApplies);
  console.log('longStayUsedBefore', longStayUsedBefore);
  console.log('longStayApplies === longStayUsedBefore', longStayApplies === longStayUsedBefore);

  return longStayApplies === longStayUsedBefore;
}

function createCampsArray(bookings, guestsPeople, properties, createVersionCheck, agentObject, rateType, createEdit, user, isFamTrip, commissionAgent, commissionUser, dateCreated) {
  const camps = [];

  bookings.forEach((booking, index) => {
    const propertyObj = properties.find((prop) => prop.uid === booking.propUid); // Assuming getPropObj() returns an index or key.

    // Check longstay is used before or not LONGSTAY
    const longStayChecked = checkLongStayStatus(bookings, booking, properties, createVersionCheck, createEdit, dateCreated);

    console.log('longStayChecked debug', longStayChecked);

    // Log all conditions
    console.log('propertyObj.platformKarani?.active:', propertyObj.platformKarani?.active);
    console.log('!booking.notKaraniPriced:', !booking.notKaraniPriced);
    console.log("!booking.propUid.includes('_D-I-R-E-C-T_'):", !booking.propUid.includes('_D-I-R-E-C-T_'));
    console.log('!checkRateKaraniId(booking, createEdit):', !checkRateKaraniId(booking, createEdit));
    console.log('!areBookingRoomsSame(booking):', !areBookingRoomsSame(booking));
    console.log('!checkBookingDate(booking):', !checkBookingDate(booking));
    console.log('!longStayChecked:', !longStayChecked);
    console.log("createEdit === 'create':", createEdit === 'create');

    // Check if platformKarani is active
    //if (propertyObj.platformKarani?.active && !booking.notKaraniPriced && !booking.propUid.includes('_D-I-R-E-C-T_') && (!checkRateKaraniId(booking, createEdit) || !booking.notModified)) {
    if (
      propertyObj.platformKarani?.active &&
      !booking.notKaraniPriced &&
      !booking.propUid.includes('_D-I-R-E-C-T_') &&
      (!checkRateKaraniId(booking, createEdit) || !areBookingRoomsSame(booking) || !checkBookingDate(booking) || !longStayChecked || createEdit === 'create')
    ) {
      console.log('CHECK FOR CAMP');
      const rooms = booking.rooms.map((room) => {
        // Ensure selectedAges and selectedAgesAdults are arrays
        const selectedAges = Array.isArray(room.selectedAges) ? room.selectedAges : [];
        const selectedAgesAdults = Array.isArray(room.selectedAgesAdults) ? room.selectedAgesAdults : [];

        // Create roomPeople array
        const roomPeople = [...selectedAges, ...selectedAgesAdults].map((person) => {
          const guest = guestsPeople.find((g) => g.uuid === person.uuid);
          return { ID: guest ? guest.ID : null };
        });

        // Get room details
        const roomDetails = propertyObj.rooms.find((r) => r.id === room.selectedRoom);

        return {
          Type: {
            ID: roomDetails ? roomDetails.karaniId : null,
            Name: roomDetails ? roomDetails.name : null,
          },
          People: roomPeople,
        };
      });

      // Get board ID
      const boardID = booking.rateKaraniId ? booking.rateKaraniId : propertyObj.thirdPartyRates.find((rate) => rate.type === booking.rateName)?.karaniId || null;

      // Total nomad nights (long stay rate)
      const nomadTanzaniaBookings = bookings.filter((booking) => resRequestCreate(booking.propUid, properties));

      const totalNomadTanzaniaDays = nomadTanzaniaBookings.reduce((total, booking) => total + booking.days, 0);
      const startDay = booking.startDay;

      const isNomad = propertyObj.supplier.id === 'ded3a3ed-aeaf-4495-9069-7754a649de67' ? true : false;
      const totalSafariNights = countSafariParkNights(bookings);
      console.log('isFamTrip debug', isFamTrip);

      camps.push({
        Index: index,
        PricePoint: {
          ID: longStayCheck(totalNomadTanzaniaDays, startDay, createVersionCheck, totalSafariNights, createEdit, dateCreated) ? 1 : null,
        },
        Camp: {
          ID: propertyObj.platformKarani.id,
          Name: booking.propName,
        },
        Board: {
          ID: boardID,
        },
        RateTypes: [
          {
            //ID: isNomad ? getReturnValue(agentObject, user) : rateType,
            ID: isNomad && isFamTrip ? 10 : isNomad ? getReturnValue(agentObject, user, createVersionCheck, createEdit, commissionAgent, commissionUser) : rateType,
            // Name: rateType,
          },
        ],
        StartDate: booking.startDay,
        Nights: booking.days,
        Rooms: rooms,
      });
    }
  });

  return camps;
}

function getReturnValue(agentObject, user, createVersionCheck, createEdit, commissionAgent, commissionUser) {
  let commission;

  console.log('commissionAgent', JSON.stringify(commissionAgent, null, 2));
  console.log('commissionUser', JSON.stringify(commissionUser, null, 2));
  // On create apply the higher commission if logged in user is internal
  if (createEdit === 'create') {
    if (user.internalUser) {
      commission = agentObject.rateBandOffline.commission;
    } else {
      commission = agentObject.rateBand.commission;
    }
    // On edit we take the commission from the document saved at time of create
  } else {
    if (commissionUser.internalUser && semver.gte(createVersionCheck, '2.5.0')) {
      commission = commissionAgent.commissionOffline;
    } else {
      commission = Number(commissionAgent.commission);
    }
  }

  // ! let commission = user.internalUser ? agentObject.rateBandOffline.commission : agentObject.rateBand.commission;

  switch (commission) {
    case 0:
      return 4;
    case 15:
      return 5;
    case 20:
      return 1;
    case 25:
      return 3;
    case 30:
      return 2;
    case 32.5:
      return 8;
    case 100:
      return 9;
    default:
      return 4;
  }
}

// START************************************* // Get room rates object KARANI!!!!!
const getRoomRatesKarani = async (
  bookings,
  setBookings,
  setRefreshBtn,
  setFetchingRates,
  guests,
  properties,
  agentObject,
  createVersionCheck,
  createEdit,
  user,
  isFamTrip,
  commissionAgent,
  commissionUser,
  dateCreated,
) => {
  // console.log('checkAllBookings');
  // console.log(checkAllBookings(bookings));
  // if (checkAllBookings(bookings)) {
  //   console.log('checkAllBookings 2');
  //   setRefreshBtn(false);
  //   setFetchingRates(false);
  //   return;
  // }
  const loadKaraniData = async (karaniData, path) => {
    // Deep clone the bookings array
    const clonedBookings = JSON.parse(JSON.stringify(bookings));
    console.log('loadKaraniData');

    console.log('karani agentObject:', JSON.stringify(agentObject, null, 2));

    const url = '/api/karani'; // Assuming you've set up a route in your server for this

    try {
      const response = await axios.post(url, {
        data: karaniData,
        path: path,
      });

      console.log('karaniData');
      console.log(JSON.stringify(karaniData, null, 4));

      console.log('response.status:', response.status);
      console.log(JSON.stringify(response.data, null, 4));

      // check for errors
      const hasError = response.data.camps.some((camp) => camp.prices.some((price) => price.status.id === 1)) || response.data.haserrors;

      let errorCampNames = [];

      if (hasError) {
        errorCampNames = response.data.camps.filter((camp) => camp.prices.some((price) => price.status.id === 1)).map((camp) => camp.camp.name);

        try {
          await addDoc(collection(db, 'pricingErrorLog'), {
            userEmail: user.email,
            error: JSON.stringify(response.data),
            timestamp: new Date(),
          });
          console.log('Error logged to Firestore');
        } catch (firestoreError) {
          console.error('Error writing to Firestore:', firestoreError);
        }

        const errorMessage =
          errorCampNames.length > 0
            ? `There was an error getting pricing for ${errorCampNames.join(', ')}. Please refresh rates to try again, or contact support`
            : 'There was an error getting pricing. Please refresh rates to try again, or contact support';

        toast.error(errorMessage);
        setRefreshBtn(true);
        setFetchingRates(false);
        return;
      } else {
        setRefreshBtn(false);
        setFetchingRates(false);
      }
      // const hasError = response.data.camps.some((camp) => camp.prices.some((price) => price.status.id === 1)) || response.data.haserrors;
      // if (hasError) {
      //   try {
      //     await addDoc(collection(db, 'pricingErrorLog'), {
      //       userEmail: user.email,
      //       error: JSON.stringify(response.data),
      //       timestamp: new Date(),
      //     });
      //     console.log('Error logged to Firestore');
      //   } catch (firestoreError) {
      //     console.error('Error writing to Firestore:', firestoreError);
      //   }
      //   toast.error('There was an error getting pricing. Please refresh rates to try again, or contact support');
      //   setRefreshBtn(true);
      //   setFetchingRates(false);
      //   return;
      // } else {
      //   setRefreshBtn(false);
      //   setFetchingRates(false);
      // }

      if (response.status === 200 && response.data.haserrors === false && response.data.camps.length > 0) {
        // add result to bookings object
        //bookings[requiredIndex].rooms[requiredRoomIndex].rate = response.data.result;

        // console.log('response.data.result AFTER percent');
        // console.log(JSON.stringify(response.data.result, undefined, 4));

        //setBookings((bookings) => [...bookings]);

        let updatedBookings = [...bookings];

        response.data.camps.forEach((camp) => {
          if (updatedBookings[camp.index]) {
            updatedBookings[camp.index].price = camp.price;
            updatedBookings[camp.index].prices = camp.prices;
            updatedBookings[camp.index].karaniLastSearchedRate = camp.board.id.toString();
            updatedBookings[camp.index].lastPricedRoomsArray = clonedBookings[camp.index].rooms;
            console.log('camp debug', JSON.stringify(camp, null, 2));
          } else {
            // Handle the case where the booking at the specified index doesn't exist
            console.warn(`No booking found at index ${camp.index}`);
          }
        });

        // Update the bookings state
        setBookings(updatedBookings);

        return response.data;
      } else {
        console.log('No data');
        toast.error('There are errors getting rates from Karani, please try again');
        setRefreshBtn(true);
        //setFetchingRates(false);
        return;
      }
    } catch (error) {
      toast.error('Karani API request failed, please try again');
      console.error('API request failed', error);
      setRefreshBtn(true);
      return Promise.reject(error);
    }
  };

  // Build Karani data
  // deal with guests to people
  let idCounter = 1;

  const guestsPeople = [
    ...guests.adultAges.map((adult) => ({
      ID: idCounter++,
      uuid: adult.uuid,
      Name: `Adult ${idCounter - 1}`,
      Age: adult.age,
    })),
    ...guests.childAges.map((child) => ({
      ID: idCounter++,
      uuid: child.uuid,
      Name: `Child ${idCounter - guests.adults - 1}`,
      Age: child.age,
    })),
  ];

  const people = guestsPeople.map((person) => ({
    ID: person.ID,
    Name: person.Name,
    Age: person.Age,
  }));
  console.log('guestsPeople');
  console.log(JSON.stringify(guestsPeople, undefined, 4));
  console.log('people');
  console.log(JSON.stringify(people, undefined, 4));

  let rateType = 1;
  if (agentObject.directBookingAgent) {
    rateType = 4;
  }

  console.log('rateType', rateType);

  // Iterate bookings and build camps
  const campsArray = createCampsArray(bookings, guestsPeople, properties, createVersionCheck, agentObject, rateType, createEdit, user, isFamTrip, commissionAgent, commissionUser, dateCreated);

  console.log('campsArray');
  console.log(JSON.stringify(campsArray, undefined, 4));

  const karaniData = {
    Name: 'Itinerary',
    People: people,
    ID: 1,
    Camps: campsArray,
    // RateTypes: [
    //   {
    //     ID: getReturnValue(agentObject),
    //     // Name: rateType,
    //   },
    // ],
    Response: 3,
  };

  const path = 'Pricing/PriceItinerary/';

  if (campsArray.length > 0) {
    try {
      const data = await loadKaraniData(karaniData, path);
      console.log('karani data');
      console.log(JSON.stringify(data, null, 2));
      // const hasError = data.camps.some((camp) => camp.prices.some((price) => price.status.id === 1)) || data.haserrors;
      // if (hasError) {
      //   try {
      //     await addDoc(collection(db, 'pricingErrorLog'), {
      //       userEmail: user.email,
      //       error: JSON.stringify(data),
      //       timestamp: new Date(),
      //     });
      //     console.log('Error logged to Firestore');
      //   } catch (firestoreError) {
      //     console.error('Error writing to Firestore:', firestoreError);
      //   }
      //   toast.error('There was an error getting pricing. Please refresh rates to try again, or contact support');
      //   setRefreshBtn(true);
      //   setFetchingRates(false);
      //   return;
      // } else {
      //   setRefreshBtn(false);
      //   setFetchingRates(false);
      // }
    } catch (error) {
      console.log('karani error');
      console.error(error);
      setRefreshBtn(true);
      setFetchingRates(false);
      return;
    }
  } else {
    console.log('No camps to price');
    setRefreshBtn(false);
    setFetchingRates(false);
  }

  // if (campsArray.length > 0) {
  //   const checkLoad = loadKaraniData(karaniData, path)
  //     // loadKaraniData(karaniData, path)
  //     .then((data) => {
  //       console.log('karani data');
  //       console.log(JSON.stringify(data, null, 2));
  //       // check for errors
  //       //const hasError = data.camps.some((camp) => camp.prices[0].status.id === 1) || data.haserrors;
  //       const hasError = data.camps.some((camp) => camp.prices.some((price) => price.status.id === 1)) || data.haserrors;
  //       if (hasError) {
  //         toast.error('There was an error getting pricing. Please refresh rates to try again, or contact support');
  //         setRefreshBtn(true);
  //         setFetchingRates(false);
  //         return;
  //       } else {
  //         setRefreshBtn(false);
  //         setFetchingRates(false);
  //       }

  //       // setRefreshBtn(false);
  //       //setFetchingRates(false);
  //     })
  //     .catch((error) => {
  //       console.log('karani error');
  //       console.error(error);
  //       setRefreshBtn(false);
  //       setFetchingRates(false);
  //     });
  //   console.log('CHECK LOAD', checkLoad);
  //   if (!checkLoad) {
  //     console.log('CHECK LOAD', checkLoad);
  //     setRefreshBtn(true);
  //     setFetchingRates(false);
  //     return;
  //   }
  // } else {
  //   console.log('No camps to price');
  //   setRefreshBtn(false);
  //   setFetchingRates(false);
  // }

  // karani: New get non-nomad rates - End
};

// set all rooms to rebuild

const rebuildBookings = (bookings, setBookings) => {
  console.log('rebuildBookings');
  // Create a new array with each booking's `rebuild` property set to true
  const updatedBookings = bookings.map((booking) => ({
    ...booking,
    rebuild: true,
  }));

  // Use the `setBookings` function to update the state
  setBookings(updatedBookings);
};

// ************************************* // Add room to booking object
const cloneRoom = async (itemId, room, bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect) => {
  // get booking object number
  const findObjectNumber = (id) => {
    const index = bookings.findIndex((booking) => booking.id === id);
    return index !== -1 ? index : 'Object not found';
  };

  const objectNumber = findObjectNumber(itemId);
  console.log('objectNumber');
  console.log(objectNumber);

  const tempRoom = Object.assign({}, room);
  tempRoom.uuid = uuidv4();

  console.log(JSON.stringify(tempRoom, undefined, 4));
  //add tempRoom to bookings

  if (bookings[objectNumber].rooms.some((room) => room.roomType === 'Exclusive') && room.roomType === 'Exclusive') {
    toast.error('You can only have one Exclusive per property.');
  } else {
    bookings[objectNumber].rooms.push(tempRoom);
    //new
    bookings[objectNumber].rebuild = true;
    setBookings((bookings) => [...bookings]);
  }

  // !This was causing the property rates to refresh when it is not necessary
  if (resRequestDisplay(bookings[objectNumber].propUid, properties)) {
    reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, false);

    //   //setRefreshPropertyRates(objectNumber);
  }
};

// ************************************* // Add room conditions
async function handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect, selectedAges, selectedAgesAdults) {
  // Make UUID for finding this room and adding rates etc
  const RoomUuid = uuidv4();
  bookings[requiredIndex].refresh = false;
  bookings[requiredIndex].notModified = false;
  setBookings((bookings) => [...bookings]);
  console.log('getrooms 1');

  console.log(bookings[requiredIndex].propUid);

  const tempRooms = bookings[requiredIndex].rooms;
  tempRooms.push({
    selectedRoom: bookings[requiredIndex].selectedRoom,
    selectedName: bookings[requiredIndex].selectedName,
    ad: bookings[requiredIndex].ad,
    ch: bookings[requiredIndex].ch,
    chi: bookings[requiredIndex].chi,
    inf: bookings[requiredIndex].inf,
    roomConfig: bookings[requiredIndex].selectedRoomConfig,
    customRoomConfig: bookings[requiredIndex].customRoomConfig,
    available: null,
    edit: false,
    roomType: properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms[requiredIndexRoom].type,
    maxCapacity: properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms[requiredIndexRoom].max_capacity,
    maxAdults: properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms[requiredIndexRoom].max_adults,
    uuid: RoomUuid,
    selectedAges: selectedAges,
    selectedAgesAdults: selectedAgesAdults,
  });

  // Reset everything
  bookings[requiredIndex].rooms = tempRooms;
  bookings[requiredIndex].selectedRoom = 'select';
  bookings[requiredIndex].selectedName = '';
  bookings[requiredIndex].selectedRoomConfig = 'Select';
  bookings[requiredIndex].customRoomConfig = '';

  bookings[requiredIndex].ad = 0;
  bookings[requiredIndex].ch = 0;
  bookings[requiredIndex].chi = 0;
  bookings[requiredIndex].inf = 0;
  bookings[requiredIndex].addRoom = false;
  //new
  bookings[requiredIndex].rebuild = true;

  setBookings((bookings) => [...bookings]);

  // !This was causing all rooms to be recalculated
  if (
    properties[getPropObj(bookings[requiredIndex].propUid, properties)].platformResRequest &&
    properties[getPropObj(bookings[requiredIndex].propUid, properties)].platformResRequest.active === true &&
    properties[getPropObj(bookings[requiredIndex].propUid, properties)].platformResRequest.id !== null &&
    properties[getPropObj(bookings[requiredIndex].propUid, properties)].platformResRequest.principalId !== null
  ) {
    reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, false);
  }
}

// ************************************* // Add room to booking object
function addRoom(bookings, setBookings, id, date, rooms, setRooms, properties, setRefreshBtn, setFetchingRates, agentRates, setRefreshRatesEffect, selectedAges, selectedAgesAdults) {
  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });

  const requiredIndexRoom = properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms.findIndex((el) => {
    return el.id === String(bookings[requiredIndex].selectedRoom);
  });
  const submit = async () => {
    let propertyType = properties[getPropObj(bookings[requiredIndex].propUid, properties)].propertyType;
    console.log('propertyType', propertyType);
    console.log('bookings[requiredIndex].selectedRoom', bookings[requiredIndex].selectedName);
    if (
      propertyType === 'private' &&
      getRoomType(properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms, bookings[requiredIndex].selectedName) !== 'Exclusive' &&
      getRoomType(properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms, bookings[requiredIndex].selectedName) !== 'Guide/Pilot Bed' &&
      // bookings[requiredIndex].selectedName !== 'Exclusive Use' &&
      // bookings[requiredIndex].selectedName !== 'Guide/Pilot Bed' &&
      bookings[requiredIndex].selectedRoom !== 'select'
    ) {
      console.log('condition 1');
      handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect, selectedAges, selectedAgesAdults);
    } else if (
      (propertyType === 'private' &&
        // (bookings[requiredIndex].selectedName === 'Exclusive Use' || bookings[requiredIndex].selectedName === 'Guide/Pilot Bed') &&
        (getRoomType(properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms, bookings[requiredIndex].selectedName) === 'Exclusive' ||
          getRoomType(properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms, bookings[requiredIndex].selectedName) === 'Guide/Pilot Bed') &&
        bookings[requiredIndex].selectedRoom !== 'select' &&
        (bookings[requiredIndex].ch > 0 || bookings[requiredIndex].ad > 0)) ||
      (propertyType !== 'private' && bookings[requiredIndex].selectedRoom !== 'select' && (bookings[requiredIndex].ch > 0 || bookings[requiredIndex].ad > 0))
    ) {
      console.log('condition 2');
      handleBooking(bookings, requiredIndex, properties, requiredIndexRoom, setBookings, date, rooms, setRooms, setRefreshRatesEffect, selectedAges, selectedAgesAdults);
    } else {
      toast.error('You must select a room type and add one adult or one child');
    }
  };

  let exclusiveRooms = bookings[requiredIndex].rooms.filter((room) => room.roomType === 'Exclusive');

  if (exclusiveRooms.length > 0 && (properties[getPropObj(bookings[requiredIndex].propUid, properties)].rooms[requiredIndexRoom].type === 'Exclusive' || exclusiveRooms.length > 1)) {
    confirmAlert({
      title: 'Cannot submit',
      message: 'Only one exclusive room per property allowed.',
      buttons: [
        {
          label: 'Ok',
        },
      ],
    });
  } else {
    submit();
  }
}

// ************************************* // Collapse/expand specific booking
function expandCollapse(id, bookings) {
  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });

  bookings[requiredIndex].expanded = !bookings[requiredIndex].expanded;
  bookings[requiredIndex].selectedRoom = 'select';
  bookings[requiredIndex].selectedName = '';
  // bookings[requiredIndex].adult = 0;
  // bookings[requiredIndex].child = 0;
}

// ************************************* // Collapse/expand all bookings
function expandCollapseAll(bookings) {
  for (let i = 0; i < bookings.length; i++) {
    bookings[i].expanded = false;
    bookings[i].selectedRoom = 'select';
    bookings[i].selectedName = '';
    // bookings[i].adult = 0;
    // bookings[i].child = 0;
  }
}

// ************************************* // Remove all rooms from results
const removeAllBookings = async (setBookings, bookings, setRefreshBtn) => {
  setBookings([]);
  showRefresh(bookings, setRefreshBtn);
};

// ************************************* // Remove one room from results
// const removePropertyBooking = async (propUid, bookings, setBookings, date, rooms, setRooms, setRefreshBtn, properties, setRefreshRatesEffect) => {
//   const requiredIndex = bookings.findIndex((el) => {
//     return el.propId === String(propUid);
//   });
//   if (requiredIndex === -1) {
//     return false;
//   }

//   const reset = !!bookings.splice(requiredIndex, 1);
//   setBookings((bookings) => [...bookings]);
//   reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, true);
//   showRefresh(bookings, setRefreshBtn);
//   return checkNoConsecutive(rooms, setRooms, bookings, setBookings, date, properties);
// };

// ************************************* // Remove one room from results
const removeOneBooking = async (id, propUid, bookings, setBookings, date, rooms, setRooms, setRefreshBtn, properties, setRefreshRatesEffect, user) => {
  console.log('removeOneBooking');
  console.log(JSON.stringify(bookings, undefined, 4));
  const requiredIndex = bookings.findIndex((el) => {
    return el.id === String(id);
  });
  if (requiredIndex === -1) {
    return false;
  }
  console.log('requiredIndex removeOneBooking', requiredIndex);
  console.log('bookings.length', bookings.length);
  const splice = !!bookings.splice(requiredIndex, 1);
  console.log('splice');
  console.log(JSON.stringify(splice, undefined, 4));
  console.log('bookings.length', bookings.length);

  console.log('checkNoConsecutive bookings');
  console.log(JSON.stringify(bookings, undefined, 4));
  setBookings((bookings) => [...bookings]);
  reCountBookings(bookings, setBookings, date, rooms, setRooms, properties, setRefreshRatesEffect, false, true, user);
  showRefresh(bookings, setRefreshBtn);
};

// ************************************* // Adding days to the stepper function
const stepperAdd = (num, bookings) => {
  let cntArray = [];
  for (let i = 0; i < num + 1; i++) {
    if (i === 0) {
      cntArray.push(1);
    } else if (i > 0) {
      cntArray.push(bookings.result[0].accommodationData[i].days);
    }

    var sum = cntArray.reduce((result, number) => result + number) - bookings.result[0].accommodationData[i].days + 1;
  }

  return sum;
};

//Get ticket start date
const ticketDatesStart = (bookings, make2digits) => {
  return bookings[0].startDay.getDate() + '/' + make2digits(bookings[0].startDay.getMonth() + 1) + '/' + (bookings[0].startDay.getFullYear() % 100);
};

//Get ticket end date
const ticketDatesEnd = (bookings, make2digits) => {
  return bookings[bookings.length - 1].endDay.getDate() + '/' + make2digits(bookings[bookings.length - 1].endDay.getMonth() + 1) + '/' + (bookings[bookings.length - 1].endDay.getFullYear() % 100);
};

// Get total Ticket nights
const totalTicketNights = (bookings) => {
  let cntArray = [];
  for (let i = 0; i < bookings.length; i++) {
    cntArray.push(bookings[i].days);
  }

  return cntArray.reduce((result, number) => result + number);
};

// get room details for a specific room from properties
function getRoomDetails(propertyUid, roomId, properties) {
  // Loop through each property in the properties array
  for (const property of properties) {
    // Check if this property has the uid we're looking for
    if (property.uid === propertyUid) {
      // Loop through each room in the property's rooms array
      for (const room of property.rooms) {
        // Check if this room has the id we're looking for
        if (room.id === roomId) {
          // If so, return the room object
          return room;
        }
      }
      // If we get here, it means no room with the given id was found in the property
      return null;
    }
  }
  // If we get here, it means no property with the given uid was found
  return null;
}

// remove property by propUid
function removeBookingsByPropUid(bookings, propUidToRemove) {
  return bookings.filter((booking) => booking.propUid !== propUidToRemove);
}
function removeRoomsByPropUid(rooms, propUidToRemove) {
  return rooms.filter((room) => room.propUid !== propUidToRemove);
}

// room configuration
const getConfigurationOptions = (selectedRoom, ad, ch, propUid, properties) => {
  console.log('getConfigurationOptions');
  let adultCount = ad;
  let childCount = ch;
  console.log('selectedRoom:', selectedRoom);
  console.log('adultCount:', adultCount);
  console.log('childCount:', childCount);
  console.log('propUid:', propUid);

  const propObj = properties[getPropObj(propUid, properties)];
  console.log('propObj:', propObj);

  if (!propObj || !propObj.rooms) {
    console.log('propObj or propObj.rooms is falsy, returning an empty array');
    return [{ value: 'Other', label: 'Other' }];
  }

  const room = propObj.rooms.find((room) => room.id === selectedRoom);
  console.log('room:', room);

  if (!room) {
    console.log('No room found with the selected configuration, returning an empty array');
    return [{ value: 'Other', label: 'Other' }];
  }

  if (!room.configuration || room.configuration.length === 0) {
    console.log('room.configuration is falsy or empty, returning only the "Other" option');
    return [{ value: 'Other', label: 'Other' }];
  }

  const configurations = room.configuration.filter((config) => {
    console.log('config:', config);
    console.log('config.pax <= adultCount + childCount:', adultCount + childCount === config.pax);
    return adultCount + childCount === config.pax;
  });

  console.log('configurations:', configurations);

  const options = configurations.map((config) => ({
    value: config.name,
    label: config.name,
  }));

  options.push({ value: 'Other', label: 'Other' });

  return options;
};

const rebuildGuestsFromSubcollectionFull = async (db, itineraryId, coll) => {
  const guestsCollectionRef = collection(db, coll, itineraryId, 'guests');
  const guestsQuery = query(guestsCollectionRef);
  const guestsSnapshot = await getDocs(guestsQuery);

  const rebuiltGuests = {
    adults: 0,
    children: 0,
    childAges: [],
    adultAges: [],
  };

  guestsSnapshot.forEach((doc) => {
    const guestData = doc.data();
    const guestWithId = { ...guestData, uuid: doc.id };

    if (guestData.type === 'adult') {
      rebuiltGuests.adults += 1;
      rebuiltGuests.adultAges.push(guestWithId);
    } else if (guestData.type === 'child') {
      rebuiltGuests.children += 1;
      rebuiltGuests.childAges.push(guestWithId);
    }
  });

  return rebuiltGuests;
};

// Only with age, uuid and isGuide
const rebuildGuestsFromSubcollection = async (db, itineraryId, coll) => {
  const guestsCollectionRef = collection(db, coll, itineraryId, 'guests');
  const guestsQuery = query(guestsCollectionRef);
  const guestsSnapshot = await getDocs(guestsQuery);

  const rebuiltGuests = {
    adults: 0,
    children: 0,
    childAges: [],
    adultAges: [],
  };

  guestsSnapshot.forEach((doc) => {
    const guestData = doc.data();
    const guestWithId = {
      uuid: doc.id,
      age: guestData.age,
      ...(guestData.isGuide && { isGuide: guestData.isGuide })
    };

    if (guestData.type === 'adult') {
      rebuiltGuests.adults += 1;
      rebuiltGuests.adultAges.push(guestWithId);
    } else if (guestData.type === 'child') {
      rebuiltGuests.children += 1;
      rebuiltGuests.childAges.push(guestWithId);
    }
  });

  return rebuiltGuests;
};

const buildGuestsFromSubcollection = async (db, itineraryId) => {
  const guestsCollectionRef = collection(db, 'itineraries', itineraryId, 'guests');
  const guestsQuery = query(guestsCollectionRef);
  const guestsSnapshot = await getDocs(guestsQuery);

  const guestsArray = [];

  guestsSnapshot.forEach((doc) => {
    const guestData = doc.data();
    const guestWithId = { ...guestData, uuid: doc.id };
    guestsArray.push(guestWithId);
  });

  return guestsArray;
};


// VALIDATE DATE FROM DatePicker FOR ITINERARY DATES
function validateBookingDate(selectedDate, user) {
  const today = moment.utc().startOf('day').subtract(1, 'days');
  const oneWeekFromToday = moment.utc().startOf('day').add(6, 'days');
  
  if (user.internalUser) {
    // Internal users can book from today onwards
    if (moment(selectedDate).isBefore(today)) {
      return {
        isValid: false,
        error: "Selected date cannot be before today's date."
      };
    }
  } else {
    // External users must book at least 1 week in advance
    if (moment(selectedDate).isBefore(oneWeekFromToday)) {
      return {
        isValid: false,
        error: 'You can only make bookings at least 1 week away. If booking within 1 week, please contact your consultant'
      };
    }
  }

  return {
    isValid: true,
    error: null
  };
}

export {
  addBooking,
  expandCollapse,
  expandCollapseAll,
  reCountBookings,
  //incDecBookingDays,
  incDecRoomGuests,
  roomSelect,
  removeAllBookings,
  removeProperty,
  //removePropertyBooking,
  removeRoom,
  //removeOneBooking,
  addRoom,
  createItineraryFunction,
  stepperAdd,
  totalTicketNights,
  ticketDatesStart,
  ticketDatesEnd,
  refreshRates,
  showRefresh,
  agentSelect,
  getConsultants,
  resetAvailableRooms,
  cloneRoom,
  updateItineraryStatus,
  updateItineraryStatusMojo,
  removePropertyRoomsBookings,
  checkRate,
  //refreshRatesProperty,
  incDecBookingNights,
  calculateRoomTotals,
  addExtra,
  rateLabelMap,
  rateLabelInfoMap,
  getRoomDetails,
  agentEnquiry,
  updateItineraryStatusEdit,
  removeBookingsByPropUid,
  removeRoomsByPropUid,
  rebuildBookings,
  updateItineraryStatusQuotation,
  processAgesRoom,
  processAgesRooms,
  getConfigurationOptions,
  longStayCheck,
  updateItineraryStatusExpiry,
  updateItineraryStatusDelete,
  updateItineraryStatusConfirmation,
  updateItineraryStatusReleaseHold,
  updateItineraryStatusCancel,
  countProperty,
  countSafariParkNights,
  rebuildGuestsFromSubcollection,
  buildGuestsFromSubcollection,
  updateItineraryStatusEditItinerary,
  rebuildGuestsFromSubcollectionFull,
  validateBookingDate,
};
