import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset, setIntendedUrl, resendVerification } from '../features/auth/authSlice';
import SideImage from '../images/pages/home/homepage.jpg';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import ButtonPrimary from '../components/Buttons/ButtonPrimary';

function Signin() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loginError, setLoginError] = useState(null);
  const { email, password } = formData;

  // Can resend verification link
  const [showResendLink, setShowResendLink] = useState(false);
  const [canResend, setCanResend] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message, intendedUrl } = useSelector((state) => state.auth);

  const handleResendVerification = useCallback(async () => {
    if (!canResend) return;

    setCanResend(false);
    try {
      const result = await dispatch(resendVerification({ email, password })).unwrap();
      if (result) {
        toast.success('Verification email has been resent. Please check your inbox.'); // Show success toast
        setShowResendLink(false);
      }
    } catch (error) {
      toast.error('Failed to resend verification email'); // Show error toast
    }

    // Enable resend after 1 minute
    setTimeout(() => {
      setCanResend(true);
    }, 60000);
  }, [dispatch, email, password, canResend]);


  useEffect(() => {
    if (isError) {
      if (message.includes('auth/user-not-found')) {
        setLoginError("We couldn't find an account with that email address. Please check your email or sign up for a new account.");
        setShowResendLink(false);
      } else if (message.includes('auth/wrong-password')) {
        setLoginError("Incorrect password. Please try again or use the 'Forgot Password' link to reset your password.");
        setShowResendLink(false);
      } else if (message.includes('auth/email-not-verified')) {
        setShowResendLink(true);
        setLoginError(
          <div>
            Please verify your email before signing in.{' '}
            <button 
              onClick={() => handleResendVerification()} 
              disabled={!canResend}
              className={`text-nomadBlue hover:underline ${!canResend ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {canResend ? 'Resend verification email' : 'Please wait 1 minute before resending'}
            </button>
          </div>
        );
      } else {
        setLoginError("There was an error logging you in, please try again or contact us.");
        setShowResendLink(false);
      }
      dispatch(reset()); // Only reset on error
    }
  
    if (isSuccess || user) {
      if (intendedUrl) {
        navigate(intendedUrl);
        dispatch(setIntendedUrl(null));
      } else {
        navigate('/');
      }
      dispatch(reset()); // Reset on successful navigation
    }
  }, [isError, isSuccess, user, message, navigate, dispatch, intendedUrl, handleResendVerification, canResend]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoginError(null);
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="flex-grow flex items-center justify-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-lg"
          >
            <div className="text-center mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue mb-2">Welcome!</h1>
              <p className="text-gray-600">Sign in to your account</p>
            </div>

            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="bg-white rounded-lg border border-[#f0ece7] p-6"
            >
              {loginError && (
                <motion.div 
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded"
                >
                  {loginError}
                </motion.div>
              )}
              <form onSubmit={onSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor="email">
                    Email Address <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="email"
                    className="form-input w-full"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Please enter your email"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1" htmlFor="password">
                    Password <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="password"
                    className="form-input w-full"
                    type="password"
                    autoComplete="current-password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    placeholder="Please enter your password"
                    required
                  />
                </div>
                <div className="flex items-center justify-between">
                  <Link className="text-sm text-nomadBlue hover:underline" to="/forgot">
                    Forgot Password?
                  </Link>
                </div>
                <div className="mt-6">
                  <ButtonPrimary
                    text="Sign In"
                    onClick={onSubmit}
                    color="dark"
                    className="w-full"
                    isLoading={isLoading}
                  />
                </div>
              </form>
              <div className="pt-5 mt-6 border-t border-[#f0ece7]">
                <div className="text-sm text-center">
                  Don't have an account?{' '}
                  <Link className="font-medium text-nomadBlue hover:underline" to="/signup">
                    Sign Up
                  </Link>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </main>
      </div>
      
         {/* Right side - Background Image and Logo */}
       <div className="hidden lg:block relative w-1/2">
        <img
          className="object-cover object-center w-full h-full"
          src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/f587fc43-dcc0-4122-09b0-e47edf301f00/public"
          alt="Safari background"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-between p-8 pb-2">
          <div className="w-full flex flex-col items-center mt-10">
            <img
              src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/c9d74a1b-3269-41ca-e5b3-e8d549c99900/public"
              alt="Hornbill Agent Platform"
              className="w-80 mb-8"
            />
            <p className="text-nomadBlue-800 text-xl mb-8 max-w-xl text-center shadow-text">
              A REVOLUTIONARY PLATFORM HELPING SAFARI SPECIALISTS
              BUILD INSPIRATIONAL SAFARIS
            </p>
          </div>
          <div className="text-center">
            <p className="text-white text-md">
              PROVIDED AND OPERATED BY
            </p>
            <img
              src="https://imagedelivery.net/rzvy4pvPPBoQtz0yLNpREw/b77d6736-4f33-4f25-e69f-64e304b00b00/public"
              alt="Nomad DMC"
              className="w-32 mx-auto mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;