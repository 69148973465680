import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PhotoIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Sidebar from '../../../partials/Sidebar';
import { client } from '../../../db/sanity';
import InfoBox from '../../../components/InfoBox';
import MediaGrid from './components/MediaGrid';
import SearchBar from './components/SearchBar';
import FilterBar from './components/FilterBar';

function MediaLibrary() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [view, setView] = useState('grid');
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    const fetchImages = async () => {
      console.log('🔍 Starting image fetch...');
      try {
        const query = `*[_type == "sanity.imageAsset"] {
            _id,
            originalFilename,
            url,
            title,
            description,
            extension,
            size,
            metadata {
              dimensions,
              lqip,
              palette
            },
            "tags": opt.media.tags[]->name.current
          }`;

        console.log('📜 Executing Sanity query:', query);
        const result = await client.fetch(query);
      //  console.log('📦 Raw query result count:', result.length);
        // console.log('🔍 First result details:', JSON.stringify(result[0], null, 2)); 


        // Transform the results into our expected format
        const formattedResults = result
          .filter((asset) => asset.url || asset._id)
          .map((asset) => ({
            _id: asset._id,
            title: asset.title || asset.originalFilename || 'Untitled',
            description: asset.description || '',
            thumbnailUrl: asset.metadata?.lqip,
            imageUrl: asset.url,
            previewUrl: {
              grid: `${asset.url}?w=400&h=300&fit=crop&auto=format`,
              list: `${asset.url}?w=100&h=100&fit=crop&auto=format`,
              full: `${asset.url}?w=1200&fit=max&auto=format`,
            },
            dimensions: asset.metadata?.dimensions,
            size: asset.size,
            mimeType: `image/${asset.extension || 'jpeg'}`,
            category: 'All',
            tags: asset.tags || [], // Now using the correctly fetched tags
          }));

        console.log('✨ Formatted first image:', formattedResults[0]);
        setImages(formattedResults);
        setIsLoading(false);
      } catch (error) {
        console.error('❌ Error fetching images:', error);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  const filteredImages = images.filter((image) => {
    const matchesSearch =
      (image.title?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (image.description?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (image.tags || []).some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()));
  
    // Modified category matching to check tags instead of category field
    const matchesCategory = 
      selectedCategory === 'All' || 
      (image.tags || []).some(tag => tag.toLowerCase() === selectedCategory.toLowerCase());
  
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-8">
              <h1 className="text-4xl font-roca text-nomadBlue-800 mb-4">Media Library</h1>
              <div className="flex items-center space-x-2 text-gray-600">
                <PhotoIcon className="h-5 w-5" />
                <span>Browse and download high-quality images from our collection</span>
              </div>
            </div>

            <div className="mb-6">
              <InfoBox
                icon={MagnifyingGlassIcon}
                text="Search through our media assets by name, description, or tags. Use filters to refine your results."
                bgColor="bg-white"
                textColor="text-nomadBlue-700"
                fontSize="text-md"
                className="shadow-sm border border-nomadGray-400"
              />
            </div>

            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="border-b border-gray-100">
                <div className="px-6 py-4">
                  <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                </div>
              </div>

              <FilterBar view={view} setView={setView} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

              <div className="p-6">
                <MediaGrid images={filteredImages} isLoading={isLoading} view={view} />
              </div>
            </div>
          </motion.div>
        </main>
      </div>
    </div>
  );
}

export default MediaLibrary;
