import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { differenceInDays, format, parseISO } from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';

import ButtonOutlineSmall from './Buttons/ButtonOutlineSmall';
import ButtonPrimarySmall from './Buttons/ButtonPrimarySmall';
import { LuFileEdit } from 'react-icons/lu';
import { MdDateRange } from 'react-icons/md';
import { RiCloseLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import { deeShadow } from '../css/additional-styles/custom';
import { formatNumber } from '../functions/generalFunctions';
import moment from 'moment';
import { updateItineraryStatus } from '../functions/itineraryFunctions';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

//import { activityCost } from "../functions/activityFunctionsFunctions";

// The PropertyItem component takes in five props: hit, properties, setLoadingData, setCallMakeRooms, and SetGetPropertiesAndStock
export function ManageBookingItem({
  hit,
  setSelectedItinerary,
  setSelectedHit,
  selectedItinerary,
  isOpen,
  setIsLoading,
  userUid,
  user,
  setIsSlideOutOpen,
  // setBookings
}) {
  const navigate = useNavigate();
  // Declare a state variable called open and set its initial value to false
  const [open, setOpen] = useState(false);
  const [activitiesCost, setActivitiesCost] = useState(0);
  //const propNames = hit.itineraryData ? hit.itineraryData.map((booking) => booking.propName) : [];

  // let propNameDisplay = '';

  // if (propNames.length === 0) {
  //   propNameDisplay = 'No properties available';
  // } else if (propNames.length === 1) {
  //   propNameDisplay = propNames[0];
  // } else if (propNames.length === 2) {
  //   propNameDisplay = propNames.join(' and ');
  // } else {
  //   propNameDisplay = propNames.slice(0, -1).join(', ') + ' and ' + propNames[propNames.length - 1];
  // }

  // const now = moment();

  // const daysDifference = now.diff(hit.dateExpiry, "days");

  // const now = moment();
  // const dateExpiry = moment(hit.dateExpiry);
  // const daysDifference = now.diff(dateExpiry, "days");

  const givenDate = moment(moment(hit.dateExpiry).format('Do MMM YYYY'), 'DD MMM YYYY');

  const now = moment();
  const daysDifference = givenDate.diff(now, 'days');

  // const formatExpiryDate = (dateExpiryMillis) => {
  //   const date = new Date(dateExpiryMillis);
  //   return format(date, "MMM d, yyyy 'at' HH:mm 'UTC'");
  // };

  const formatExpiryDate = (dateExpiryMillis) => {
    try {
      // Handle null or undefined
      if (!dateExpiryMillis) {
        console.log('Warning: dateExpiryMillis is null or undefined');
        return 'No date available';
      }
  
      // Log the input value to help debug
      console.log('Input dateExpiryMillis:', dateExpiryMillis);
      console.log('Type:', typeof dateExpiryMillis, dateExpiryMillis.constructor.name);
  
      // Handle Firebase Timestamp
      if (dateExpiryMillis?.toDate) {
        return format(dateExpiryMillis.toDate(), "MMM d, yyyy 'at' HH:mm 'UTC'");
      }
  
      // Handle regular Date object
      if (dateExpiryMillis instanceof Date) {
        return format(dateExpiryMillis, "MMM d, yyyy 'at' HH:mm 'UTC'");
      }
  
      // Handle milliseconds timestamp
      const date = new Date(dateExpiryMillis);
      return format(date, "MMM d, yyyy 'at' HH:mm 'UTC'");
    } catch (error) {
      console.error('Error formatting date:', error);
      console.error('Input value:', dateExpiryMillis);
      return 'Invalid date';
    }
  };



  // Return a div element
  return (
    <div className="w-full">
      <div
        key={uuidv4()}
        id={hit.uid + '_divId'}
        className={`z-10 cursor-pointer border-b border-b-zinc-200 ${
          selectedItinerary === hit.uid ? 'border border-slate-800 border-b-slate-800' : ''
        } items-center justify-center text-center py-3 px-3`}
        onClick={() => {
          if (!isOpen) {
            if (selectedItinerary === hit.uid) {
              setSelectedItinerary(null);
              setSelectedHit(null);
              //setBookings(null);
            } else {
              setSelectedItinerary(hit.uid);
              setSelectedHit(hit);
              //setBookings(hit);
              // setGeneratedLink(hit.itineraryUrl);
              // setGeneratedLinkSummary(hit.resRequestSummaryUrl);
              // setGeneratedExcelCostingLink(hit.costingUrl);
              // setInvoiceLink(hit.invoiceUrl);
              console.log('hit:');
              console.log(JSON.stringify(hit, undefined, 4));
              setIsSlideOutOpen(true);
            }
          }
        }}
      >
        {/* PROPERTY ROW 1 */}

        {/* Display the first image of the images array in the hit object */}
        <div className={`grid grid-cols-12 w-full`}>
          <div key={uuidv4()} className="col-span-1 text-neutral-500 text-sm text-left">
            {hit.code ? hit.code : 'N/A'}
          </div>
          <div key={uuidv4()} className="col-span-4">
            <div className="ml-0">
              <div className="">
                <div>
                  <div className="mt-0 text-[15px] font-semibold text-left truncate text-ellipsis overflow-hidden text-nomadBlue-600">{hit.itineraryName}</div>
                  <div className="mt-1 text-left text-sm font-light brand-text-grey-v2">
                    {hit.status === 'provisional' ? (
                      <div className="mt-1 text-left text-xs font-light text-red-500">
                       Expires: {formatExpiryDate(hit.dateExpiry)} {/* Space added here */}
                        (in {daysDifference + 1} day{daysDifference !== 1 && 's'})
                        {/* {daysDifference < 2 && (user.internalUser || !hit.provisionalExtended) && (
                          <span
                            className="ml-1 underline cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <div className="p-5 bg-white rounded-lg" style={deeShadow}>
                                      <p className="text-sm font-semibold pb-2">Are you sure you would like to extend the expiry date?</p>
                                      <p className="text-sm pb-2">This will extend the expiry date to {moment(calculateExpiryDate(dateStart, dateExpiry)).format('Do MMM YYYY')}</p>
                                      <p className="text-sm pb-2">Please note, this will only extend Nomad properties.</p>

                                      <div className="flex mt-5 justify-end gap-4">
                                       <ButtonOutlineSmall
                                                  onClick={() => {
                                                    onClose();
                                                  }}
                                                text="close"
                                                color="dark"
                                                />
                                            
                                           <ButtonPrimarySmall
                                            onClick={async () => {
                                              await updateBooking('20', 'provisional', hit.resReqReservationId, hit.uid, hit.resRequestStatus === 'x' ? 0 : 1);
                                              onClose();
                                            }}
                                            text="Confirm"
                                            color="dark"
                                            />
                                      </div>
                                    </div>
                                  );
                                },
                              });
                            }}
                          >
                            (Extend Nomad Camps)
                          </span>
                        )} */}
                      </div>
                    ) : null}
                  </div>
                </div>
                {hit.properties && (
                <div>
                  <div className="mt-0 text-left text-sm font-light brand-text-grey-v2 truncate text-ellipsis overflow-hidden pr-5">{hit.properties ? hit.properties : ''}</div>
                </div>)}
              </div>
            </div>
          </div>

          <div key={uuidv4()} className="col-span-3 text-left">
            <div className=" align-bottom">
              <div>
                <span className="pr-1 text-slate-800 text-sm">{hit.agent && hit.agent.name ? hit.agent.name : 'N/A'}</span>
              </div>
              <div>
                <span className="pr-1 text-neutral-500 text-sm font-light">{hit.consultant && hit.consultant.name ? hit.consultant.name : 'N/A'}</span>
              </div>
            </div>
          </div>
          <div key={uuidv4()} className="col-span-2 text-neutral-500 text-sm text-left">
            {moment(hit.dateStart).format('Do MMM YYYY')}
          </div>
          <div key={uuidv4()} className="col-span-1 flex items-center justify-center">
            <div>
              <span
                className={`inline-block text-[12px] font-medium pl-3 pr-3 py-0.5 brand-bg-color-v2-grey-tab
        ${hit.status === 'draft' && 'bg-[#F4DE8E]'}
        ${hit.status === 'provisional' && 'bg-[#C3DAF5]'}
        ${hit.status === 'pendingProvisional' && 'bg-[#C3DAF5]'}
        ${hit.status === 'confirmed' && 'bg-[#B9F8D4]'}
        ${hit.status === 'pendingConfirmation' && 'bg-[#B9F8D4]'}
        ${hit.status === 'cancelled' && 'bg-[#EDEDED]'}
        ${hit.status === 'expired' && 'bg-red-600 text-white'}
        rounded-lg capitalize w-fit`}
              >
                {hit.status === 'pendingConfirmation'
                  ? 'Pending Confirmation'
                  : hit.status === 'pendingProvisional'
                  ? 'Pending Provisional'
                  : hit.status === 'pendingCancellation'
                  ? 'Pending Cancellation'
                  : hit.status}{' '}
              </span>
            </div>
          </div>

          <div key={uuidv4()} className="col-span-1 mt-0 text-left flex items-center justify-center">
            <div className="flex flex-1 flex-col items-center">
              <div>
                {user.internalUser && (
                  <button
                    className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer"
                    onClick={async () => {
                      navigate(`/itinerary/edit/editItinerary/${hit.uid}`, {
                        replace: true,
                      });
                    }}
                  >
                    <LuFileEdit className="text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-6 h-6" />
                  </button>
                )}

                {
                  user.internalUser && (user.email === 'prideaux@gmail.com' || user.email === 'harry.prowse@nomad-tanzania.net') && null

                }

                {!user.internalUser && hit.status !== 'confirmed' && hit.status !== 'cancelled' && !hit.user.internalUser && (
                  <button
                    className="bg-nomadEvergreen-100 border-none rounded-full p-3 flex items-center justify-center hover:cursor-pointer"
                    onClick={async () => {
                      navigate(`/itinerary/edit/editItinerary/${hit.uid}`, {
                        replace: true,
                      });
                    }}
                  >
                    <LuFileEdit className="text-nomadEvergreen-700 hover:text-nomadEvergreen-800 w-6 h-6" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* PROPERTY ROW 2 */}
      </div>

      <Transition.Root show={open} as={Fragment}>
        {/* Display a modal with the name of the hit object when the value of open is truthy */}
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            {/* Display a semi-transparent background behind the modal */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            {/* Display the modal content */}
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  {/* Display a close button in the top right corner of the modal */}
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    {/* Close the modal when the close button is clicked */}
                    <button type="button" className="bg-white text-gray-400 hover:text-gray-500 outline-none" onClick={() => setOpen(false)}>
                      <span className="sr-only">Close</span>
                      <RiCloseLine className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {/* Display the name of the hit object in the modal */}
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {hit.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{hit.name}</p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
