import { Badge } from '../../../../components/ui/Badge';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import ClosuresDisplay from './ClosuresDisplay';
import { Disclosure } from '@headlessui/react';
import HTMLContent from '../../../../components/HTMLContent';
import MapComponent from './MapComponent';
import MobileLocationsTable from './MobileLocationsTable';
import React from 'react';
function OverviewTab({ camp, firestoreData }) {
  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  // const MobileLocationsTable = () => {
  //   const currentDate = new Date();
  //   const futureLocations = firestoreData.customLocations.filter((location) => location.periodEndDate.toDate() > currentDate).sort((a, b) => a.periodStartDate.toDate() - b.periodStartDate.toDate());

  //   return (
  //     <div className="bg-white rounded-lg border border-[#f0ece7] flex flex-col p-6 gap-2">
  //       <div className="flex justify-between items-center">
  //         <h3 className="text-[#162716] text-lg font-semibold leading-[27px]">Mobile Locations</h3>
  //       </div>
  //       {futureLocations.length > 0 ? (
  //         <div className="overflow-x-auto">
  //           <table className="w-full">
  //             <thead>
  //               <tr className="bg-[#f4f3f2] rounded-lg">
  //                 <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Period</th>
  //                 <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Location</th>
  //                 <th className="p-4 text-left text-gray-600 text-sm font-bold uppercase">Closest Airport</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {futureLocations.map((location, index) => (
  //                 <tr key={index} className="border-b border-[#f0ece7]">
  //                   <td className="p-4 text-gray-600 text-sm">
  //                     {formatDate(location.periodStartDate)} - {formatDate(location.periodEndDate)}
  //                   </td>
  //                   <td className="p-4 text-gray-600 text-sm">{location.parkName}</td>
  //                   <td className="p-4 text-gray-600 text-sm">{location.airportName}</td>
  //                 </tr>
  //               ))}
  //             </tbody>
  //           </table>
  //         </div>
  //       ) : (
  //         <p className="text-gray-600 text-sm">No upcoming mobile locations scheduled.</p>
  //       )}
  //     </div>
  //   );
  // };

  // const ClosuresDisplay = ({ closureDates }) => {
  //   const formatDate = (timestamp) => {
  //     if (!timestamp) return '';
  //     const date = timestamp.toDate();
  //     return date.toLocaleDateString('en-GB', {
  //       day: 'numeric',
  //       month: 'long',
  //       year: 'numeric',
  //     });
  //   };

  //   const getClosureStatus = (closure) => {
  //     if (closure.indefinitelyClosed) {
  //       return {
  //         label: 'Indefinite Closure',
  //         color: 'bg-red-100 text-red-800',
  //       };
  //     }
  //     if (closure.subsequentYearsAlso) {
  //       return {
  //         label: 'Annual Closure',
  //         color: 'bg-amber-100 text-amber-800',
  //       };
  //     }
  //     return {
  //       label: 'Temporary Closure',
  //       color: 'bg-blue-100 text-blue-800',
  //     };
  //   };

  //   // Filter out past dates unless indefinitely closed
  //   const currentDate = new Date();
  //   const relevantClosures = closureDates
  //     ?.filter((closure) => {
  //       if (closure.indefinitelyClosed) {
  //         return closure.periodStartDate.toDate() <= currentDate;
  //       }
  //       return closure.periodEndDate.toDate() >= currentDate;
  //     })
  //     .sort((a, b) => a.periodStartDate.toDate() - b.periodStartDate.toDate());

  //   if (!relevantClosures || relevantClosures.length === 0) {
  //     return (
  //       <div className="bg-[#f4f3f2] rounded-lg p-6">
  //         <p className="text-gray-600">No upcoming closure dates scheduled.</p>
  //         <p className="text-sm text-gray-500 mt-2">The property is expected to be open year-round, but seasonal closures may be added in the future.</p>
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className="space-y-4">
  //       {closureDates.map((closure) => {
  //         const status = getClosureStatus(closure);

  //         return (
  //           <div key={closure.uid} className="bg-[#f4f3f2] rounded-lg p-6">
  //             <div className="flex items-start justify-between">
  //               <div className="space-y-2">
  //                 <div className="flex items-center gap-3">
  //                   <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${status.color}`}>{status.label}</span>
  //                   {closure.reason && <span className="text-gray-600 text-sm">{closure.reason}</span>}
  //                 </div>

  //                 <div className="text-gray-600">
  //                   {closure.indefinitelyClosed ? (
  //                     <p>Closed from {formatDate(closure.periodStartDate)} until further notice</p>
  //                   ) : (
  //                     <p>
  //                       Closed from {formatDate(closure.periodStartDate)} to {formatDate(closure.periodEndDate)}
  //                       {closure.subsequentYearsAlso && <span className="block text-sm text-gray-500 mt-1">* Similar closure dates may apply in subsequent years. Please verify specific dates.</span>}
  //                     </p>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const sections = [
    { title: 'About', content: <HTMLContent html={camp.campInformation.description} /> },
    {
      title: 'Key Facts',
      content: (
        <div className="flex flex-col space-y-2">
          <div className="bg-[#f4f3f2] rounded-lg p-6 mt-2">
            <div className="grid grid-cols-2 gap-x-4 text-sm">
              <div className="space-y-2 font-bold uppercase text-gray-600">
                <div>SUPPLIER</div>
                <div>PRODUCT TIER</div>
                <div>NUMBER OF ROOMS</div>
                <div>LOCATION</div>
                <div>NEAREST AIRPORT</div>
                <div>CHECK-IN TIME</div>
                <div>CHECK-OUT TIME</div>
              </div>
              <div className="space-y-2 text-gray-600">
                <div>{firestoreData.supplier.name || 'Not specified'}</div>
                <div>Most Preferred</div>
                <div>{camp.campInformation.rooms || 'Not specified'}</div>
                <div>{firestoreData.mobileLocation ? 'Check Location Tab' : firestoreData.park.name || 'Not specified'}</div>
                <div>{firestoreData.mobileLocation ? 'Check Location Tab' : firestoreData.airport.name || 'Not specified'}</div>
                <div>{camp.campInformation.check_in_time || 'Not specified'}</div>
                <div>{camp.campInformation.check_out_time || 'Not specified'}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Location',
      content: (
        <div className="flex flex-col space-y-4">
          <div className="text-[#636366]">
            {firestoreData.park.name}, {firestoreData.country.name}
          </div>
          {firestoreData.mobileLocation && <MobileLocationsTable customLocations={firestoreData.customLocations} />}
          <MapComponent lat={camp.campInformation.lat} long={camp.campInformation.long} />
        </div>
      ),
    },
    { title: 'Seasons and closures', content: <ClosuresDisplay closureDates={firestoreData.closureDates} />, comingSoon: false },
    // { title: 'Seasons', content: camp.seasons },
    //  { title: 'Activities', content: camp.activities },
    //  { title: 'General Facilities', content: camp.generalFacilities },
    { title: 'Dining', content: camp.dining, comingSoon: true },
  ];

  return (
    <div className="w-full space-y-4">
      {sections.map((section, index) => (
        <Disclosure as="div" key={section.title} className="border border-[#f0ece7] rounded-lg overflow-hidden" defaultOpen={index < 3}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between items-center px-6 py-4 text-left text-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 ${
                  open ? 'bg-white text-nomadEvergreen-900 font-semibold' : 'bg-nomadGray-300 text-black'
                }`}
              >
                <div className="flex items-center space-x-2">
                  <span>{section.title}</span>
                  {section.comingSoon && (
                    <Badge variant="warning" size="sm">
                      Coming Soon
                    </Badge>
                  )}
                </div>
                <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-6 w-6 ${open ? 'text-nomadEvergreen-900' : 'text-black'}`} />
              </Disclosure.Button>
              <Disclosure.Panel className="px-6 pt-4 pb-6 text-base text-[#636366] bg-white">{section.content}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}

export default OverviewTab;
