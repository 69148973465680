import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function SeasonalSlider({ slides }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  const handleTabClick = (index) => {
    setActiveSlide(index);
    swiperRef.current.slideTo(index);
  };

  return (
    <div className="relative">
      {/* Tabs */}
      <div className="grid grid-cols-10 gap-4">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`col-span-2 px-4 py-2 text-center text-base font-normal uppercase tracking-wide cursor-pointer ${
              activeSlide === index ? 'bg-stone-300 text-red-700' : 'text-slate-800'
            }`}
            onClick={() => handleTabClick(index)}
          >
            {slide.tabTitle}
          </div>
        ))}
      </div>

      {/* Slider */}
      <div className="bg-stone-300 py-6">
        <Swiper
          ref={swiperRef}
          slidesPerView={1.2}
          spaceBetween={20}
          navigation
          pagination={{ clickable: true }}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <img
                  className="w-full h-[400px]"
                  src={slide.imageUrl}
                  alt={slide.heading}
                />
                <div className="absolute inset-0 flex flex-col justify-center items-left text-white py-20 px-24 pr-80">
                  <div className="text-lg font-semibold uppercase tracking-widest mb-2">
                    {slide.overtitle}
                  </div>
                  <div className="text-5xl font-normal font-roca leading-tight mb-4">
                    {slide.heading}
                  </div>
                  <div className="text-base font-normal font-['Montserrat'] leading-tight">
                    {slide.description}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SeasonalSlider;